import React from "react";
import dayjs from "dayjs";
import { Box, Button } from "@mui/material";
//
import { Contact } from "../../accounting/api/list-of-contacts";
import TimesheetActivityDetails from "./timesheet-activity-details";
import TimesheetTime from "./timesheet-time";
import TimesheetCharge from "./timesheet-charge";
import TimesheetOther from "./timesheet-other";
import { TimesheetErrors } from "../../../pages/TimesheetDetailed/TimesheetDetailed";
import { BasicTimesheetErrors } from "../../../pages/Timesheet/Timesheet";


interface TimesheetDetailedFormProps {
  // Activity Details props
  client: Contact | null;
  onClientChange: (client: Contact | null) => void;
  user: { usrID: string; usrName: string };
  onUserChange: (user: any) => void;
  department: any;
  onDepartmentChange: (department: any) => void;
  activityType: any;
  onActivityTypeChange: (activityType: any) => void;
  description: string;
  onDescriptionChange: (description: string) => void;
  task: any;
  onTaskChange: (task: any) => void;
  
  // Data lists
  contacts: Contact[];
  activityTypes: any[];
  users: any[];
  departments: any[];
  tasks: any[];
  
  // Time props
  date: dayjs.Dayjs | null;
  onDateChange: (date: dayjs.Dayjs | null) => void;
  startTime: dayjs.Dayjs | null;
  onStartTimeChange: (time: dayjs.Dayjs | null) => void;
  endTime: dayjs.Dayjs | null;
  onEndTimeChange: (time: dayjs.Dayjs | null) => void;
  timeUnits: number;
  setTimeUnits: (value: number) => void;
  
  // Charge props
  isChargeable: boolean;
  onChargeableChange: (isChargeable: boolean) => void;
  charge: number;
  onChargeChange: (charge: number) => void;
  hourlyRate: number;
  onHourlyRateChange: (rate: number) => void;
  
  // Other props
  markForAttention: boolean;
  onMarkForAttentionChange: (mark: boolean) => void;
  note: string;
  onNoteChange: (note: string) => void;
  
  // Form actions
  onSave: () => void;
  onSaveAndNew: () => void;
  onSaveAndCreateInvoice: () => void;
  onCancel: () => void;
  onClearAll: () => void;
  
  // Validation
  errors: BasicTimesheetErrors;

  // others
  isTimerRunning: boolean;
}

export const TimesheetDetailedForm = ({
  // Activity Details props
  client,
  onClientChange,
  user,
  onUserChange,
  department,
  onDepartmentChange,
  activityType,
  onActivityTypeChange,
  description,
  onDescriptionChange,
  task,
  onTaskChange,
  
  // Data lists
  contacts,
  activityTypes,
  users,
  departments,
  tasks,
  
  // Time props
  date,
  onDateChange,
  startTime,
  onStartTimeChange,
  endTime,
  onEndTimeChange,
  timeUnits,
  setTimeUnits,
  
  // Charge props
  isChargeable,
  onChargeableChange,
  charge,
  onChargeChange,
  hourlyRate,
  onHourlyRateChange,
  
  // Other props
  markForAttention,
  onMarkForAttentionChange,
  note,
  onNoteChange,
  
  // Form actions
  onSave,
  onSaveAndNew,
  onSaveAndCreateInvoice,
  onCancel,
  onClearAll,
  
  // Validation
  errors,

  // others
  isTimerRunning,
}: TimesheetDetailedFormProps) => {
  return (
    <Box display="flex" flexDirection="column" gap={4} mt={4}>
      <TimesheetActivityDetails
        client={client}
        onClientChange={onClientChange}
        user={user}
        onUserChange={onUserChange}
        department={department}
        onDepartmentChange={onDepartmentChange}
        activityType={activityType}
        onActivityTypeChange={onActivityTypeChange}
        description={description}
        onDescriptionChange={onDescriptionChange}
        task={task}
        onTaskChange={onTaskChange}
        contacts={contacts}
        activityTypes={activityTypes}
        users={users}
        departments={departments}
        tasks={tasks}
        errors={errors}
      />
      
      <TimesheetTime
        date={date}
        onDateChange={onDateChange}
        startTime={startTime}
        onStartTimeChange={onStartTimeChange}
        endTime={endTime}
        onEndTimeChange={onEndTimeChange}
        timeUnits={timeUnits}
        setTimeUnits={setTimeUnits}
        errors={errors}
        isTimerRunning={isTimerRunning}
      />
      
      <TimesheetCharge
        isChargeable={isChargeable}
        onChargeableChange={onChargeableChange}
        charge={charge}
        onChargeChange={onChargeChange}
        hourlyRate={hourlyRate}
        onHourlyRateChange={onHourlyRateChange}
      />
      
      <TimesheetOther
        markForAttention={markForAttention}
        onMarkForAttentionChange={onMarkForAttentionChange}
        note={note}
        onNoteChange={onNoteChange}
      />
      
      <Box
        display="flex"
        gap={2}
        flexDirection={{ xs: "column", md: "row" }}
        mt={2}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={onSave}
          sx={{ width: { xs: "100%", md: "auto" } }}
        >
          Save
        </Button>
        
        <Button
          variant="contained"
          color="primary"
          onClick={onSaveAndNew}
          sx={{ width: { xs: "100%", md: "auto" } }}
        >
          Save New
        </Button>
        
        <Button
          variant="outlined"
          onClick={onSaveAndCreateInvoice}
          sx={{ width: { xs: "100%", md: "auto" } }}
        >
          Save & Create Invoice
        </Button>
        
        <Button
          variant="outlined"
          onClick={onCancel}
          sx={{ width: { xs: "100%", md: "auto" } }}
        >
          Cancel
        </Button>
        <Button
          variant="outlined"
          onClick={onClearAll}
          sx={{ width: { xs: "100%", md: "auto" } }}
        >
          Clear All
        </Button>
      </Box>
    </Box>
  );
};
