import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { OlivsDataPicker } from "../../../components/ui/date-picker";
import dayjs from "dayjs";
import { formatDateToISO } from "../../../utils/format-date";

interface PayrollEventChangeDatesDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (dates: {
    paymentDate: string | null;
    startDate: string | null;
    endDate: string | null;
  }) => void;
  currentPaymentDate?: string;
  currentStartDate?: string;
  currentEndDate?: string;
  periodType: string;
}

export const PayrollEventChangeDatesDialog: React.FC<
  PayrollEventChangeDatesDialogProps
> = ({
  open,
  onClose,
  onConfirm,
  currentPaymentDate,
  currentStartDate,
  currentEndDate,
  periodType,
}) => {
  const [paymentDate, setPaymentDate] = useState<dayjs.Dayjs | null>(
    dayjs(formatDateToISO(currentPaymentDate)) || null,
  );
  const [startDate, setStartDate] = useState<dayjs.Dayjs | null>(
    dayjs(formatDateToISO(currentStartDate)) || null,
  );
  const [endDate, setEndDate] = useState<dayjs.Dayjs | null>(
    dayjs(formatDateToISO(currentEndDate)) || null,
  );
  const [datesUnlocked, setDatesUnlocked] = useState(false);

  const handleClose = () => {
    setPaymentDate(dayjs(formatDateToISO(currentPaymentDate)) || null);
    setStartDate(dayjs(formatDateToISO(currentStartDate)) || null);
    setEndDate(dayjs(formatDateToISO(currentEndDate)) || null);
    setDatesUnlocked(false);
    onClose();
  };

  const handleConfirm = () => {
    onConfirm({
      paymentDate: paymentDate?.format("YYYY-MM-DD") || null,
      startDate: startDate?.format("YYYY-MM-DD") || null,
      endDate: endDate?.format("YYYY-MM-DD") || null,
    });
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Change Pay Event Dates</DialogTitle>
      <DialogContent>
        <Typography
          variant="body1"
          sx={{ mb: 3 }}
          dangerouslySetInnerHTML={{
            __html: `Dates and various settings for this pay event are locked to <b>Period: ${periodType}</b>. You can update and unlock dates, but tax will be calculated per ${periodType} tables. To change this, you must delete and re-do this pay event.`,
          }}
        />

        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale="en-gb"
          >
            <OlivsDataPicker
              label="Payment Date"
              value={paymentDate ? dayjs(paymentDate) : null}
              onChange={(newValue) =>
                setPaymentDate(newValue ? newValue.toDate() : null)
              }
            />

            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={datesUnlocked}
                  onChange={(e) => setDatesUnlocked(e.target.checked)}
                />
              }
              label="Unlock Dates"
            />

            <OlivsDataPicker
              label="Start Date"
              value={startDate ? dayjs(startDate) : null}
              onChange={(newValue) =>
                setStartDate(newValue ? newValue.toDate() : null)
              }
              disabled={!datesUnlocked}
            />

            <OlivsDataPicker
              label="End Date"
              value={endDate ? dayjs(endDate) : null}
              onChange={(newValue) =>
                setEndDate(newValue ? newValue.toDate() : null)
              }
              disabled={!datesUnlocked}
              minDate={startDate ? dayjs(startDate) : undefined}
            />
          </LocalizationProvider>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined">
          Cancel
        </Button>
        <Button onClick={handleConfirm} color="primary" variant="contained">
          Apply Changes
        </Button>
      </DialogActions>
    </Dialog>
  );
};
