import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Box,
  Typography,
  Button,
  Modal,
  TextField,
  LinearProgress,
  Select,
  MenuItem,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { DataGrid, Column, Scrolling } from "devextreme-react/data-grid";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import envConfig from "../../config";
import { getHeaders } from "../../utils/api";
import ReportDatesContext from "../../context/ReportDatesContext";
import { createSessionForExternal } from "../../utils/reusable";
import useApi from "../../hooks/useApi";
import { useQuery } from "../../hooks/useQuery";
import {
  addMonths,
  startOfMonth,
  endOfMonth,
  addQuarters,
  startOfQuarter,
  endOfQuarter,
  addYears,
  startOfYear,
  endOfYear,
} from "date-fns";
import GridDownloadExcelPdfButtons from "../../components/GridDownloadExcelPdfButtons/GridDownloadExcelPdfButtons";
import { exportDataGrid } from "devextreme/excel_exporter";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

const periodOptions = [
  { label: "Current Month", value: "current_month" },
  { label: "Current Quarter", value: "current_quarter" },
  { label: "Current Financial Year", value: "current_financialYear" },
  { label: "Current Calendar Year", value: "current_calendarYear" },
  { label: "Previous Month", value: "previous_month" },
  { label: "Previous Quarter", value: "previous_quarter" },
  { label: "Previous Financial Year", value: "previous_financialYear" },
  { label: "Previous Calendar Year", value: "previous_calendarYear" },
  { label: "Custom", value: "custom" },
];

const GeneralJournalReport = () => {
  const dataGridRef = useRef(null);
  const { dbId, lang } = useParams();
  const { reportDates } = useContext(ReportDatesContext);
  const { apiCall } = useApi();
  const [filters, setFilters] = useState({
    startDate: null,
    endDate: null,
    selectedAccount: null,
  });
  const [appliedFilters, setAppliedFilters] = useState({
    startDate: null,
    endDate: null,
    selectedAccount: null,
  });
  const [data, setData] = useState([]);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [periodType, setPeriodType] = useState("custom");
  const [isApplyDisabled, setIsApplyDisabled] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);

  const navigate = useNavigate();

  // Fetch accounts data using useQuery
  const accountsURL = `https://${envConfig.apiDev1Exacc}/api/v1/en-au/accounts/get-selection-list?activeYN=Y&postableYN=N&BaseHostURL=${envConfig.mainServiceUrl}`;

  const {
    data: accountsResult,
    isLoading: accountsLoading,
    isError: accountsError,
    error: accountsErrorMessage,
  } = useQuery(
    accountsURL,
    {
      method: "GET",
      headers: getHeaders(),
    },
    null,
  );

  const accountsData = accountsResult?.ListOfAccounts || [];

  const handleDownloadExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("GeneralJournalReport");

    exportDataGrid({
      component: dataGridRef.current.instance, // Reference to the DataGrid instance
      worksheet: worksheet,
      autoFilterEnabled: true,
      customizeCell: ({ gridCell, excelCell }) => {
        if (gridCell.rowType === "header") {
          excelCell.font = { bold: true };
        }
      },
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, "GeneralJournalReport.xlsx");
      });
    });
  };

  // Fetch report data using apiCall when appliedFilters change
  useEffect(() => {
    const fetchData = async () => {
      if (appliedFilters.startDate && appliedFilters.endDate) {
        setDataLoading(true);
        const payload = {
          AccNo: appliedFilters.selectedAccount
            ? appliedFilters.selectedAccount.accNo
            : 0,
          StartDate: appliedFilters.startDate.toISOString(),
          EndDate: appliedFilters.endDate.toISOString(),
        };
        await apiCall({
          url: `https://${envConfig.apiDev2}/api/en-au/accounting/get-general-journal-report-data-rs1?BaseHostURL=${envConfig.mainServiceUrl}`,
          method: "POST",
          headers: {
            ...getHeaders(),
            "Content-Type": "application/json",
          },
          body: payload,
          onSuccess: (responseData) => {
            setData(responseData.DataDT || []);
            setIsApplyDisabled(false); // Re-enable Apply button after data fetch starts
          },
          onError: (errorMessage) => {
            console.error("Error fetching report data:", errorMessage);
            setIsApplyDisabled(false); // Re-enable Apply button after data fetch
          },
        });
        setDataLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appliedFilters]);

  const handleApply = () => {
    setIsApplyDisabled(true); // Disable Apply button
    setAppliedFilters({ ...filters });
    setFiltersOpen(false);
  };

  const openTransaction = async (linkTodets) => {
    const LinkToDets = linkTodets;

    const updatedLink = LinkToDets.replace(
      "[A]",
      envConfig.correspondingService,
    )
      .replace("[DbID]", dbId)
      .replace("[Lang]", "en-au");

    const urlToUse = `https://${updatedLink}`;

    if (LinkToDets.includes("[S]")) {
      const navigatedLink = updatedLink.replace("[S]", "");
      navigate(navigatedLink);
    } else {
      // Use createSessionForExternal for other links
      await createSessionForExternal(
        urlToUse,
        "127.0.0.2",
        false,
        apiCall,
        dbId,
      );
    }
  };

  const handleRowClick = async (data) => {
    if (data.AB01) {
      setDataLoading(true);
      setIsApplyDisabled(true);
      await openTransaction(data.AB01);
      setDataLoading(false);
      setIsApplyDisabled(false);
    }
  };

  // Adjust row appearance for 'H' rowType and clickable rows
  const onRowPrepared = (e) => {
    if (e.rowType === "data") {
      if (e.data.RowType === "H") {
        e.rowElement.style.fontWeight = "bold";
        const cells = e.rowElement.querySelectorAll("td");
        const colCount = e.component.columnCount();
        cells.forEach((cell, index) => {
          if (index === 0) {
            cell.colSpan = colCount;
            cell.style.whiteSpace = "normal";
          } else {
            cell.style.display = "none";
          }
        });
      }
      if (e.data.AB01) {
        e.rowElement.style.cursor = "pointer";
        e.rowElement.classList.add("clickable-row");
      }
    }
  };

  const accountCellRender = (cellData) => {
    const { data } = cellData;
    if (data.RowType === "H") {
      return (
        <div
          style={{ width: "100%", fontWeight: "bold", whiteSpace: "normal" }}
        >
          {data.Account}
        </div>
      );
    } else {
      return cellData.text;
    }
  };

  const otherCellRender = (cellData) => {
    const { data } = cellData;
    if (data.RowType === "H") {
      return null;
    } else {
      return cellData.text;
    }
  };

  useEffect(() => {
    if (reportDates?.reportsStartDate) {
      if (reportDates?.reportsEndDate) {
        setFilters({
          ...filters,
          startDate: moment(new Date(reportDates?.reportsStartDate + "Z")),
          endDate: moment(new Date(reportDates?.reportsEndDate + "Z")),
        });
        setPeriodType("custom");
        setFiltersOpen(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportDates]);

  const handlePeriodTypeChange = (event) => {
    const newPeriodType = event.target.value;
    setPeriodType(newPeriodType);

    if (newPeriodType === "custom") {
      // If "Custom" is selected, do not modify the dates
      return;
    }

    let startDate, endDate;
    const currentDate = new Date();
    const isCurrent = newPeriodType.startsWith("current_");
    const isPrevious = newPeriodType.startsWith("previous_");
    const actualPeriodType = newPeriodType.split("_").pop(); // e.g., "month", "quarter"

    if (isCurrent || isPrevious) {
      if (actualPeriodType === "month") {
        startDate = isCurrent
          ? startOfMonth(currentDate)
          : startOfMonth(addMonths(currentDate, -1));
        endDate = isCurrent
          ? endOfMonth(currentDate)
          : endOfMonth(addMonths(currentDate, -1));
      } else if (actualPeriodType === "quarter") {
        startDate = isCurrent
          ? startOfQuarter(currentDate)
          : startOfQuarter(addQuarters(currentDate, -1));
        endDate = isCurrent
          ? endOfQuarter(currentDate)
          : endOfQuarter(addQuarters(currentDate, -1));
      } else if (actualPeriodType === "financialYear") {
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth(); // 0-based index

        if (currentMonth >= 6) {
          // July onwards
          startDate = isCurrent
            ? new Date(currentYear, 6, 1) // July 1st
            : new Date(currentYear - 1, 6, 1); // Previous July 1st
          endDate = isCurrent
            ? new Date(currentYear + 1, 5, 30) // June 30th next year
            : new Date(currentYear, 5, 30); // June 30th this year
        } else {
          // Before July
          startDate = isCurrent
            ? new Date(currentYear - 1, 6, 1) // Previous July 1st
            : new Date(currentYear - 2, 6, 1); // Two years ago July 1st
          endDate = isCurrent
            ? new Date(currentYear, 5, 30) // June 30th this year
            : new Date(currentYear - 1, 5, 30); // June 30th last year
        }
      } else if (actualPeriodType === "calendarYear") {
        startDate = isCurrent
          ? startOfYear(currentDate)
          : startOfYear(addYears(currentDate, -1));
        endDate = isCurrent
          ? endOfYear(currentDate)
          : endOfYear(addYears(currentDate, -1));
      }
    }

    // Convert computed dates to moment objects
    const momentStartDate = moment(startDate);
    const momentEndDate = moment(endDate);

    // Update the filters state with the new dates
    setFilters((prevFilters) => ({
      ...prevFilters,
      startDate: momentStartDate,
      endDate: momentEndDate,
    }));
  };

  return (
    <Box display={"flex"} flexDirection={"column"}>
      <Box display={"flex"} alignItems={"center"} gap={2} marginTop={2}>
        <Box sx={{ mr: 3 }}>
          <Typography variant="h5">Journals per Account</Typography>
        </Box>
        {appliedFilters.startDate && appliedFilters.endDate && (
          <Typography component={"p"} fontSize={14} fontWeight={700}>
            {`From ${moment(appliedFilters.startDate).format(
              "DD/MM/YYYY",
            )} to ${moment(appliedFilters.endDate).format("DD/MM/YYYY")}`}
          </Typography>
        )}
        <Button
          variant="contained"
          onClick={() => setFiltersOpen(true)}
          disabled={isApplyDisabled}
        >
          Update
        </Button>
      </Box>

      <Modal open={filtersOpen} onClose={() => setFiltersOpen(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            p: 4,
            boxShadow: 24,
          }}
        >
          <Typography variant="h6" component="h2">
            Update Filters
          </Typography>
          <Box sx={{ mt: 2 }}>
            <Autocomplete
              sx={{ mb: 2 }}
              id="predefined-period-autocomplete"
              options={periodOptions}
              getOptionLabel={(option) => option.label}
              value={
                periodOptions.find((option) => option.value === periodType) ||
                null
              }
              onChange={(_, newValue) => {
                if (newValue) {
                  handlePeriodTypeChange({ target: { value: newValue.value } });
                } else {
                  handlePeriodTypeChange({ target: { value: "custom" } });
                }
              }}
              renderInput={(params) => (
                <TextField {...params} label="Predefined Period" fullWidth />
              )}
              renderOption={(props, option) => (
                <li {...props} style={{ fontSize: "12px" }}>
                  {option.label}
                </li>
              )}
              disableClearable
            />

            <TextField
              label="Start Date"
              type="date"
              value={
                filters.startDate
                  ? moment(filters.startDate).format("YYYY-MM-DD")
                  : ""
              }
              onChange={(e) => {
                setFilters({ ...filters, startDate: moment(e.target.value) });
                setPeriodType("custom");
              }}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              sx={{ mb: 2 }}
            />
            <TextField
              label="End Date"
              type="date"
              value={
                filters.endDate
                  ? moment(filters.endDate).format("YYYY-MM-DD")
                  : ""
              }
              onChange={(e) => {
                setFilters({ ...filters, endDate: moment(e.target.value) });
                setPeriodType("custom");
              }}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              sx={{ mb: 2 }}
            />
            <Autocomplete
              id="account-autocomplete"
              options={accountsData}
              getOptionLabel={(option) => `${option.accNo} ${option.accName}`}
              value={filters.selectedAccount}
              onChange={(_, newValue) => {
                setFilters({ ...filters, selectedAccount: newValue });
              }}
              isOptionEqualToValue={(option, value) =>
                option.accNo === value.accNo
              }
              renderInput={(params) => (
                <TextField {...params} label="Accounts" fullWidth />
              )}
            />
          </Box>
          <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
            <Button onClick={() => setFiltersOpen(false)} sx={{ mr: 1 }}>
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleApply}
              disabled={isApplyDisabled}
            >
              {isApplyDisabled ? (
                <LinearProgress style={{ width: "100%" }} />
              ) : (
                "Apply"
              )}
            </Button>
          </Box>
        </Box>
      </Modal>

      <Box sx={{ mt: 2 }}>
        <Box sx={{ mb: 1 }}>
          <GridDownloadExcelPdfButtons
            displayDownloadPDF={false}
            downloadExcel={handleDownloadExcel}
          />
        </Box>
        {dataLoading && <LinearProgress />}
        <DataGrid
          ref={dataGridRef}
          dataSource={data}
          keyExpr="RowID"
          showBorders={true}
          onRowClick={(e) => handleRowClick(e.data)}
          onRowPrepared={onRowPrepared}
          rowAlternationEnabled={true}
          hoverStateEnabled={true}
        >
          <Scrolling mode="virtual" />
          <Column
            dataField="Account"
            minWidth={300}
            caption="Account"
            cellRender={accountCellRender}
          />
          <Column
            dataField="Debit"
            caption="Debit"
            dataType="number"
            format={{ type: "fixedPoint", precision: 2 }}
            cellRender={otherCellRender}
          />
          <Column
            dataField="Credit"
            caption="Credit"
            dataType="number"
            format={{ type: "fixedPoint", precision: 2 }}
            cellRender={otherCellRender}
          />
          <Column
            dataField="TaxCode"
            caption="Tax Code"
            cellRender={otherCellRender}
          />
          <Column
            dataField="TaxAmt"
            caption="Tax Amount"
            dataType="number"
            format={{ type: "fixedPoint", precision: 2 }}
            cellRender={otherCellRender}
          />
          <Column
            dataField="PrivAmt"
            caption="Private Amount"
            dataType="number"
            format={{ type: "fixedPoint", precision: 2 }}
            cellRender={otherCellRender}
          />
          <Column
            dataField="Memo"
            caption="Memo"
            cellRender={otherCellRender}
          />
        </DataGrid>
      </Box>
    </Box>
  );
};

export default GeneralJournalReport;
