import React from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { useFinancialReportStore } from "../store/financial-report-store";
import { decodeHTMLEntities } from "../../../../utils/format-text";

export const FinancialReportSignatures = ({
  onGeneratePDFReport,
}: {
  onGeneratePDFReport: () => void;
}) => {
  const signatory1 = useFinancialReportStore.use.Signatory1();
  const signatory2 = useFinancialReportStore.use.Signatory2();
  const compilantSignatory = useFinancialReportStore.use.CompilantSignatory();
  const myFooter = useFinancialReportStore.use.MyFooter();

  const setSignatory1 = useFinancialReportStore.use.setSignatory1();
  const setSignatory2 = useFinancialReportStore.use.setSignatory2();
  const setCompilantSignatory =
    useFinancialReportStore.use.setCompilantSignatory();
  const setMyFooter = useFinancialReportStore.use.setMyFooter();

  return (
    <Box
      flex={1}
      display={"flex"}
      flexDirection="column"
      width={"100%"}
      gap={1}
      maxWidth={500}
    >
      <Box>
        <Typography variant={"body1"}>Signature 1</Typography>
        <TextField
          variant="outlined"
          fullWidth
          margin="normal"
          value={signatory1}
          onChange={(e) => setSignatory1(e.target.value)}
        />
      </Box>
      <Box>
        <Typography variant={"body1"}>Signature 2</Typography>
        <TextField
          variant="outlined"
          fullWidth
          margin="normal"
          value={signatory2}
          onChange={(e) => setSignatory2(e.target.value)}
        />
      </Box>
      <Box>
        <Typography variant={"body1"}>Compilation Signatory</Typography>
        <TextField
          variant="outlined"
          fullWidth
          margin="normal"
          multiline
          rows={2}
          value={compilantSignatory}
          onChange={(e) => setCompilantSignatory(e.target.value)}
        />
      </Box>
      <Box>
        <Typography variant={"body1"}>Cover page footer</Typography>
        <TextField
          variant="outlined"
          fullWidth
          margin="normal"
          multiline
          rows={2}
          value={myFooter ?? ""}
          onChange={(e) => setMyFooter(e.target.value)}
        />
      </Box>
      <Button variant="contained" color="primary" onClick={onGeneratePDFReport}>
        Generate PDF
      </Button>
    </Box>
  );
};
