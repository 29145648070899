import React, { useState, useEffect } from "react";
import {
  FormControl,
  FormControlLabel,
  Select,
  MenuItem,
  useMediaQuery,
  InputLabel,
  Typography,
  Box,
  Checkbox,
  Button,
  TextField,
} from "@mui/material";
import {
  addMonths,
  startOfMonth,
  endOfMonth,
  addQuarters,
  startOfQuarter,
  endOfQuarter,
  addYears,
  startOfYear,
  endOfYear,
} from "date-fns";
import { commonThemes, darkTheme, lightTheme } from "../../utils/themes/themes";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/en-gb";
import dayjs from "dayjs";
import { useTheme } from "../../context/ThemeContext";
import { ReactComponent as FilterIcon } from "../../assets/filter.svg";
import ProfitAndLossSettingsModal, {
  CustomShortcuts,
} from "../../components/ProfitAndLossModal/ProfitAndLossModal";
import ProfitAndLossTable from "../../components/ProfitAndLossTable/ProfitAndLossTable";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { downloadPDF } from "../../utils/reusable";
import { useLoginPopup } from "../../context/LoginPopupContext";
import { DatePicker } from "@mui/x-date-pickers-pro";
import { enqueueSnackbar } from "notistack";
import BackdropLoading from "../../components/BackdropLoading/BackdropLoading";
import useApi from "../../hooks/useApi";
import envConfig from "../../config";
import { useGetIP } from "../../hooks/get-ip";
import { EditButton } from "../../components/ui/buttons/edit-button";
import { DataGridWrapper } from "../../components/ui/table/data-grid-wrapper";
import { PageTitle } from "../../components/page-title";

const controllerName = "accounting";
const actionName = "get-profit-loss-periods-grid-rs1";
const apiUrl = `https://${envConfig.apiDev2}/api/en-au/${controllerName}/${actionName}?BaseHostURL=${envConfig.mainServiceUrl}`;

const ProfitAndLoss = () => {
  const { isDarkMode } = useTheme();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const hasFilterParams = searchParams.toString().length > 0;

  const [openFilters, setOpenFilters] = useState(!hasFilterParams);

  const [openFullscreen, setOpenFullscreen] = useState(false);

  const [sortByAccountName, setSortByAccountName] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [compareValue, setCompareValue] = useState(0);
  const [compareYearsValue, setCompareYearsValue] = useState(0);
  const [periodType, setPeriodType] = useState("current_financialYear");
  const [showAccountsNumbers, setShowAccountsNumbers] = useState(true);
  const [showZeroBalanceAccounts, setShowZeroBalanceAccounts] = useState(false);
  const [showCashMethod, setShowCashMethod] = useState(false);
  const [compareChoice, setCompareChoice] = useState("none");
  const [groupsOrAccounts, setGroupsOrAccounts] = useState("accountsOnly");
  const [selectedSites, setSelectedSites] = useState([]);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const [sortBy, setSortBy] = useState("Account Number");
  const [lastUsedParams, setLastUsedParams] = useState({});
  const [showDownloadDate, setShowDownloadDate] = useState(false);

  const [loading, setLoading] = useState(false);
  const { apiCall } = useApi();
  const ip = useGetIP();
  const navigate = useNavigate();

  const [gridData, setGridData] = useState(null);

  const generatePDF = async () => {
    const controllerName = "accounting";
    const actionName = "get-profit-loss-simple-pdf-v3";
    const pdfUrl = `https://${envConfig.apiDev2}/api/en-au/${controllerName}/${actionName}?BaseHostURL=${envConfig.mainServiceUrl}`;

    await apiCall({
      ip: ip,
      url: pdfUrl,
      method: "POST",
      body: {
        ...lastUsedParams,
        ShowAccountNumbersYN: showAccountsNumbers ? "Y" : "N",
        SetColumnNamesForPdfYN: showDownloadDate ? "Y" : "N",
        SortBy_M_Name_N_Number: sortBy === "Account Name" ? "M" : "N",
      },
      onSuccess: async (result) => {
        if (result?.ReportBase64) {
          const isPDFDownloaded = await downloadPDF(
            result?.ReportBase64,
            result?.FileName,
          );
          if (!isPDFDownloaded) {
            setLoading(false);
          } else {
            setLoading(false);
          }
        }
      },
      onError: (errorMessage) => {
        enqueueSnackbar(errorMessage, { variant: "error" });
        setLoading(false);
      },
    });
  };

  const handleStartDateChange = (newStartDate) => {
    setDateRange([newStartDate, dateRange[1]]);
    setPeriodType("custom");
  };

  const handleEndDateChange = (newEndDate) => {
    setDateRange([dateRange[0], newEndDate]);
    setPeriodType("custom");
  };

  useEffect(() => {
    if (periodType === "custom") {
      return;
    } else {
      let startDate, endDate;
      const currentDate = new Date();
      const isCurrent = periodType?.startsWith("current_");
      const isPrevious = periodType?.startsWith("previous_");
      const actualPeriodType = periodType?.split("_").pop();

      if (!actualPeriodType) return;
      if (isCurrent || isPrevious) {
        if (actualPeriodType === "month") {
          startDate = isCurrent
            ? startOfMonth(currentDate)
            : startOfMonth(addMonths(currentDate, -1));
          endDate = isCurrent
            ? endOfMonth(currentDate)
            : endOfMonth(addMonths(currentDate, -1));
        } else if (actualPeriodType === "quarter") {
          startDate = isCurrent
            ? startOfQuarter(currentDate)
            : startOfQuarter(addQuarters(currentDate, -1));
          endDate = isCurrent
            ? endOfQuarter(currentDate)
            : endOfQuarter(addQuarters(currentDate, -1));
        } else if (actualPeriodType === "financialYear") {
          const currentYear = currentDate.getFullYear();
          const currentMonth = currentDate.getMonth();

          if (currentMonth >= 6) {
            startDate = isCurrent
              ? new Date(currentYear, 6, 1)
              : new Date(currentYear - 1, 6, 1);
            endDate = isCurrent
              ? new Date(currentYear + 1, 5, 30)
              : new Date(currentYear, 5, 30);
          } else {
            startDate = isCurrent
              ? new Date(currentYear - 1, 6, 1)
              : new Date(currentYear - 2, 6, 1);
            endDate = isCurrent
              ? new Date(currentYear, 5, 30)
              : new Date(currentYear - 1, 5, 30);
          }
        } else if (actualPeriodType === "calendarYear") {
          startDate = isCurrent
            ? startOfYear(currentDate)
            : startOfYear(addYears(currentDate, -1));
          endDate = isCurrent
            ? endOfYear(currentDate)
            : endOfYear(addYears(currentDate, -1));
        }
      }

      const formattedStartDate = new Date(
        Date.UTC(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate(),
        ),
      )
        .toISOString()
        .split("T")[0];
      const formattedEndDate = new Date(
        Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()),
      )
        .toISOString()
        .split("T")[0];

      const dayjsStartDate = dayjs(formattedStartDate);
      const dayjsEndDate = dayjs(formattedEndDate);
      setDateRange([dayjsStartDate, dayjsEndDate]);
    }
  }, [periodType]);

  const onAddEarlierPeriod = () => {
    setCompareValue((prev) => +prev + 1);
    setCompareYearsValue((prev) => +prev + 1);

    if (compareChoice === "none") {
      setCompareChoice("earlierPeriods");
    }
    // if (compareValue === 0) {
    //   handleApplyButtonClick(
    //     Number(compareValue) + 2,
    //     Number(compareYearsValue) + 2,
    //     compareChoice === "none" ? "earlierPeriods" : undefined,
    //   );
    // }
    // else {

    handleApplyButtonClick(
      Number(compareValue) + 1,
      Number(compareYearsValue) + 1,
      "earlierPeriods",
    );
    // }
  };

  const updateUrlParams = (val, yearsVal, newCompareChoice) => {
    let startDate, endDate;

    if (periodType === "custom") {
      startDate = dateRange[0];
      endDate = dateRange[1];
      if (!startDate || !endDate) return;
    } else {
      const currentDate = new Date();
      const isCurrent = periodType.startsWith("current_");
      const isPrevious = periodType.startsWith("previous_");
      const actualPeriodType = periodType.split("_").pop();

      if (isCurrent || isPrevious) {
        if (actualPeriodType === "month") {
          startDate = isCurrent
            ? startOfMonth(currentDate)
            : startOfMonth(addMonths(currentDate, -1));
          endDate = isCurrent
            ? endOfMonth(currentDate)
            : endOfMonth(addMonths(currentDate, -1));
        } else if (actualPeriodType === "quarter") {
          startDate = isCurrent
            ? startOfQuarter(currentDate)
            : startOfQuarter(addQuarters(currentDate, -1));
          endDate = isCurrent
            ? endOfQuarter(currentDate)
            : endOfQuarter(addQuarters(currentDate, -1));
        } else if (actualPeriodType === "financialYear") {
          const currentYear = currentDate.getFullYear();
          const currentMonth = currentDate.getMonth();

          if (currentMonth >= 6) {
            startDate = isCurrent
              ? new Date(currentYear, 6, 1)
              : new Date(currentYear - 1, 6, 1);
            endDate = isCurrent
              ? new Date(currentYear + 1, 5, 30)
              : new Date(currentYear, 5, 30);
          } else {
            startDate = isCurrent
              ? new Date(currentYear - 1, 6, 1)
              : new Date(currentYear - 2, 6, 1);
            endDate = isCurrent
              ? new Date(currentYear, 5, 30)
              : new Date(currentYear - 1, 5, 30);
          }
        } else if (actualPeriodType === "calendarYear") {
          startDate = isCurrent
            ? startOfYear(currentDate)
            : startOfYear(addYears(currentDate, -1));
          endDate = isCurrent
            ? endOfYear(currentDate)
            : endOfYear(addYears(currentDate, -1));
        }
      }
    }

    let sYear, sMonth, sDay;
    let eYear, eMonth, eDay;
    if (!startDate || !endDate) return;
    if (startDate?.$d) {
      sYear = startDate?.year();
      sMonth = startDate?.month() + 1;
      sDay = startDate?.date();
    } else {
      sYear = startDate?.getFullYear();
      sMonth = startDate?.getMonth() + 1;
      sDay = startDate?.getDate();
    }

    sMonth = sMonth < 10 ? "0" + sMonth : sMonth;
    sDay = sDay < 10 ? "0" + sDay : sDay;

    const formattedStartDate = `${sYear}-${sMonth}-${sDay}`;

    if (endDate.$d) {
      eYear = endDate.year();
      eMonth = endDate.month() + 1;
      eDay = endDate.date();
    } else {
      eYear = endDate.getFullYear();
      eMonth = endDate.getMonth() + 1;
      eDay = endDate.getDate();
    }

    eMonth = eMonth < 10 ? "0" + eMonth : eMonth;
    eDay = eDay < 10 ? "0" + eDay : eDay;

    const formattedEndDate = `${eYear}-${eMonth}-${eDay}`;

    const params = new URLSearchParams({
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      cashMethod: showCashMethod ? "Y" : "N",
      compareChoice: newCompareChoice || compareChoice,
      compareValue: val || compareValue,
      compareYearsValue: yearsVal || compareYearsValue,
      groupsOrAccounts,
      showAccountsNumbers: showAccountsNumbers ? "Y" : "N",
      showZeroBalanceAccounts: showZeroBalanceAccounts ? "Y" : "N",
      selectedSites: selectedSites.join(","),
      selectedContacts: selectedContacts.join(","),
      selectedUsers: selectedUsers.join(","),
    });

    navigate(`?${params.toString()}`);
  };

  const handleApplyButtonClick = (val, yearsVal, newCompareChoice) => {
    updateUrlParams(val, yearsVal, newCompareChoice);
  };

  const fetchData = async () => {
    const params = new URLSearchParams(location.search);
    if (!params) return;
    const requestData = {
      StartDate: params.get("startDate"),
      EndDate: params.get("endDate"),
      CashMethodYN: params.get("cashMethod"),
      PreviousPeriodType_GE14:
        params.get("compareChoice") === "earlierYears"
          ? 1
          : params.get("compareChoice") === "earlierPeriods"
            ? 2
            : 0,
      DpsList: params.get("selectedSites")?.split(","),
      UsrList: params.get("selectedUsers")?.split(","),
      CntList: params.get("selectedContacts")?.split(","),
      ShowZeroAmountsYN: params.get("showZeroBalanceAccounts"),
      ShowAccountNumbersYN: "N",
      NumberOfPeriods:
        params.get("compareChoice") === "earlierYears"
          ? Number(params.get("compareYearsValue")) + 1
          : params.get("compareChoice") === "earlierPeriods"
            ? Number(params.get("compareValue")) + 1
            : 1,
      PresentationTypeAccountsHeadersBothAHB:
        params.get("groupsOrAccounts") === "groupsOnly"
          ? "H"
          : params.get("groupsOrAccounts") === "accountsOnly"
            ? "A"
            : "B",
      Acc001: "string",
      BuildHeadersAndDividersYN: "Y",
      ShowPrintDetailsYN: "N",
      ShowFooterNoAuditedUnauditedNAU: "N",
      OrderLatestonleftOldestonleftLO: "O",
      SetColumnNamesForPdfYN: "N",
      SortBy_M_Name_N_Number: sortByAccountName ? "M" : "N",
    };

    setLastUsedParams(requestData);

    setLoading(true);
    await apiCall({
      url: apiUrl,
      ip: ip,
      method: "POST",
      body: requestData,
      onSuccess: (result) => {
        setGridData(result);
        setLoading(false);
      },
      onError: (errorMessage) => {
        enqueueSnackbar(errorMessage, { variant: "error" });
        setGridData(null);
        setLoading(false);
      },
    });
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get("startDate") && params.get("endDate")) {
      fetchData();
    }
  }, [location.search]);

  return (
    <>
      <BackdropLoading open={loading} />
      <ProfitAndLossSettingsModal
        periodType={periodType}
        setPeriodType={setPeriodType}
        dateRange={dateRange}
        setDateRange={setDateRange}
        showAccountsNumbers={showAccountsNumbers}
        setShowAccountsNumbers={setShowAccountsNumbers}
        showZeroBalanceAccounts={showZeroBalanceAccounts}
        setShowZeroBalanceAccounts={setShowZeroBalanceAccounts}
        showCashMethod={showCashMethod}
        setShowCashMethod={setShowCashMethod}
        compareValue={compareValue}
        setCompareValue={setCompareValue}
        compareYearsValue={compareYearsValue}
        setCompareYearsValue={setCompareYearsValue}
        open={openFilters}
        setGridData={setGridData}
        compareChoice={compareChoice}
        setCompareChoice={setCompareChoice}
        groupsOrAccounts={groupsOrAccounts}
        setGroupsOrAccounts={setGroupsOrAccounts}
        selectedSites={selectedSites}
        setSelectedSites={setSelectedSites}
        selectedContacts={selectedContacts}
        setSelectedContacts={setSelectedContacts}
        selectedUsers={selectedUsers}
        setSelectedUsers={setSelectedUsers}
        sortByAccountName={sortByAccountName}
        setSortByAccountName={setSortByAccountName}
        onClose={() => {
          setOpenFilters(false);
        }}
        setLastUsedParams={setLastUsedParams}
      />

      <Box display={"flex"} alignItems={"center"} gap={2} marginTop={2}>
        <Box sx={{ mr: 3 }}>
          <PageTitle title="Profit and Loss Report" />
        </Box>
        {dateRange?.at(0) && dateRange?.at(1) && (
          <Typography component={"p"} fontSize={14} fontWeight={700}>
            {`From ${dateRange[0].format("DD/MM/YYYY")} to ${dateRange[1].format("DD/MM/YYYY")}${showCashMethod ? ", Cash Method" : ""}`}
          </Typography>
        )}
        <EditButton title="Update" onClick={() => setOpenFilters(true)} />
      </Box>

      <DataGridWrapper isFullScreen={openFullscreen}>
        <ProfitAndLossTable
          openFullscreen={openFullscreen}
          setOpenFullscreen={setOpenFullscreen}
          gridData={gridData}
          onApply={fetchData}
          loading={loading}
          onAddEarlierPeriod={onAddEarlierPeriod}
          showCashMethod={showCashMethod}
          generatePDF={generatePDF}
          sortBy={sortBy}
          setSortBy={setSortBy}
          showDownloadDate={showDownloadDate}
          setShowDownloadDate={setShowDownloadDate}
          showAccountsNumbers={showAccountsNumbers}
        />
      </DataGridWrapper>
    </>
  );
};

export default ProfitAndLoss;
