import React from "react";
import { useSnackbar } from "notistack";
import useApi from "../../../hooks/useApi";
import envConfig from "../../../config";

interface UpdateDatesPayload {
  startDate: string;
  endDate: string;
  paymentDate: string;
}

interface UpdateDraftPayEventDatesParams {
  id: string;
  onSuccess?: () => void;
  onError?: (error: string) => void;
}

export interface UpdateDraftPayEventDatesResponse {
  // TODO: Define specific response type
  [key: string]: any;
}

export const useUpdateDraftPayEventDates = ({
  id,
  onSuccess,
  onError,
}: UpdateDraftPayEventDatesParams) => {
  const { apiCall } = useApi();
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = React.useState(false);
  const [isError, setIsError] = React.useState(false);

  const updateDates = React.useCallback(
    async ({ startDate, endDate, paymentDate }: UpdateDatesPayload) => {
      setIsLoading(true);
      setIsError(false);

      console.log("updateDates", startDate, endDate, paymentDate);

      try {
        await apiCall({
          url: `https://${envConfig.apiDev2}/api/en-au/payroll-au/update-draft-pay-event-dates?BaseHostURL=${envConfig.mainServiceUrl}`,
          method: "POST",
          // @ts-ignore
          body: {
            PrlAu1104: "",
            PevID: id,
            StartDate: startDate,
            EndDate: endDate,
            PayDate: paymentDate,
          },
          // @ts-ignore
          onSuccess: (response: UpdateDraftPayEventDatesResponse) => {
            if (onSuccess) onSuccess();
            enqueueSnackbar("Dates updated successfully", {
              variant: "success",
            });
          },
          // @ts-ignore
          onError: (error: string) => {
            setIsError(true);
            enqueueSnackbar(error, { variant: "error" });
            if (onError) onError(error);
          },
        });
      } catch (error) {
        console.error("updateDraftPayEventDates error", error);
        setIsError(true);
        enqueueSnackbar((error as Error).message, { variant: "error" });
        if (onError) onError((error as Error).message);
      } finally {
        setIsLoading(false);
      }
    },
    [apiCall, enqueueSnackbar, id, onSuccess, onError],
  );

  return {
    isLoading,
    isError,
    updateDates,
  };
};
