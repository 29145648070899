import React from "react";
import { Box, Checkbox, FormControlLabel, useMediaQuery } from "@mui/material";
import { CustomReportsParameters } from "../../../../types/api/custom-reports";
import { YNToBool } from "../../../../utils/format-bool";
import { commonThemes } from "../../../../utils/themes/themes";

export const CustomReportsCheckbox = ({
  parameter,
  value = false,
  handleOnChange,
  // isFieldValid = true,
  // errorMessage = ""
}: {
  parameter: CustomReportsParameters;
  value: boolean;
  handleOnChange: any;
  // isFieldValid: boolean;
  // errorMessage: string;
}) => {
  const breakAdvanced = useMediaQuery("(max-width: 847px)");

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: breakAdvanced ? "column" : "row",
        flexWrap: "wrap",
        maxWidth: "750px",
      }}
    >
      <FormControlLabel
        key={parameter.Label}
        // value={!!value}
        control={
          <Checkbox
            checked={!!value}
            // defaultChecked={YNToBool(parameter.DefaultValue)}
            onChange={() => handleOnChange(`p${parameter.Order}`, !value)}
            id={parameter.Label}
          />
        }
        label={parameter.Label}
        // @ts-ignore
        sx={{ mr: 2}}
      />
    </Box>
  );
};
