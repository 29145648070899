import { useMutation } from "@tanstack/react-query";
import { getHeaders } from "../../../../utils/api";
import { MutationConfig } from "../../../../lib/react-query";
import envConfig from "../../../../config";
import { checkAndUpdateApiKeyExpiration } from "../../../../hooks/useApi";
import { EXAMPLE_GET_REPORTS_PARAMETERS_RESPONSE_DATA_GRID } from "./example-response";
import { useLoginPopup } from "../../../../context/LoginPopupContext";
import { useParams } from "react-router-dom";

export const getCustomReportDataGrid = async (
   body, handleOpen, dbId
): Promise<any> => {

  // TODO: IMPORTANT: Remove this line in final version
  if (process.env.NODE_ENV === "development") {
    return EXAMPLE_GET_REPORTS_PARAMETERS_RESPONSE_DATA_GRID;
  }


  const controllerName = "reports";
  const actionName = "get-grid-report-pack-rs1";
  const api = `https://${envConfig.apiDev2}/api/en-au/${controllerName}/${actionName}?BaseHostURL=${envConfig.mainServiceUrl}`;

  const headers = getHeaders(dbId);
  
  const response = await fetch(api, {
    method: "POST",
    // @ts-ignore
    headers: headers,
    body: JSON.stringify(body),
  });

  if (response.status === 403 || response.status === 401) {
    setTimeout(async () => {
      handleOpen();
      // window.location = `https://${envConfig.loginUrl}/?redirectBackTo=${window.location.href}` as unknown as Location;
    }, 1000);
    throw new Error("Your session has expired, please log back in.");
  }

  const apiKeyExpirationDate = response.headers.get(
    "apikeyexpirationdate",
  );
    checkAndUpdateApiKeyExpiration(apiKeyExpirationDate);

  const result = await response.json();

  if (response.ok && result?.SuccessYN === "Y") {
    return result;
  } else {
    throw new Error(
      `An error occurred: ${result?.ErrorMessage || result?.errorMessage || "Unknown error"}`,
    );
  }
};

type UseGetCustomReportDataGrid = {
  mutationConfig?: MutationConfig<typeof getCustomReportDataGrid>;
};

export const useGetCustomReportDataGrid = ({
  mutationConfig,
}: UseGetCustomReportDataGrid = {}) => {
  const { onSuccess, ...restConfig } = mutationConfig || {};
  const { handleOpen } = useLoginPopup();
  const { dbId } = useParams();

  return useMutation({
    ...restConfig,
    retry: 0,
    onSuccess: (...args) => {
      onSuccess?.(...args);
    },
    mutationFn: (body) => getCustomReportDataGrid(body, handleOpen, dbId),
  });
};
