import React from "react";
import { useSnackbar } from "notistack";
import useApi from "../../../hooks/useApi";
import envConfig from "../../../config";

export const usePayEventPaydataFile = ({
  id,
  onSuccess,
  onError,
}: {
  id: string;
  onSuccess?: () => void;
  onError?: (error: string) => void;
}) => {
  const { apiCall } = useApi();
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = React.useState(false);
  const [isError, setIsError] = React.useState(false);

  const downloadPaydataFile = React.useCallback(async () => {
    setIsLoading(true);
    setIsError(false);

    try {
      await apiCall({
        url: `https://${envConfig.apiDev2}/api/en-au/payroll-au/get-pay-event-paydata?BaseHostURL=${envConfig.mainServiceUrl}`,
        method: "POST",
        // @ts-ignore
        body: {
          PevID: id,
        },
        // @ts-ignore
        onSuccess: (response) => {
          console.log(
            "[DEBUGGING][PAY EVENT PAYDATA FILE] onSuccess response: ",
            response,
          );
          // Create blob from response and trigger download
          // const blob = new Blob([response], { type: "text/csv" });
          // const url = window.URL.createObjectURL(blob);
          // const a = document.createElement("a");
          // a.href = url;
          // a.download = `paydata-${id}.csv`;
          // document.body.appendChild(a);
          // a.click();
          // window.URL.revokeObjectURL(url);
          // document.body.removeChild(a);

          if (onSuccess) onSuccess();
          enqueueSnackbar("To be implemented", {
            variant: "info",
          });
        },
        // @ts-ignore
        onError: (error: string) => {
          setIsError(true);
          enqueueSnackbar(error, { variant: "error" });
          if (onError) onError(error);
        },
      });
    } catch (error) {
      console.error("downloadPaydataFile error", error);
      setIsError(true);
      enqueueSnackbar((error as Error).message, { variant: "error" });
      if (onError) onError((error as Error).message);
    } finally {
      setIsLoading(false);
    }
  }, [apiCall, enqueueSnackbar, id, onSuccess, onError]);

  return {
    isLoading,
    isError,
    downloadPaydataFile,
  };
};
