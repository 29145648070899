import React from "react";
import dayjs from "dayjs";
import { Box, Typography } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";

interface TimesheetHeaderProps {
  date: dayjs.Dayjs | null;
  onChangeDate: (date: dayjs.Dayjs | null) => void;
  totalUnits: number;
  missingUnits: number;
  databaseName: string;
}

const TimesheetHeader = ({
  date,
  onChangeDate,
  totalUnits,
  missingUnits,
  databaseName,
}: TimesheetHeaderProps) => {
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Box display="flex" flexDirection="row" alignItems={"center"} gap={1}>
        <Box display="flex" flexDirection="row" alignItems={"center"} gap={1}>
          <Typography
            variant="h6"
            sx={{ display: { xs: "none", sm: "none", md: "block" } }}
          >
            EXACC Timesheet
          </Typography>
          {/* <Typography variant="subtitle1">
            {date?.format("DD/MM/YYYY") || ""}
          </Typography> */}
        </Box>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
          <DatePicker
            value={date}
            onChange={(newDate) => onChangeDate(newDate)}
            sx={{ width: 200 }}
            format="DD/MM/YYYY"
          />
        </LocalizationProvider>
      </Box>
      <Box display={{ xs: "none", sm: "none", md: "flex" }} gap={4}>
        <Typography>
          Total Units: <strong>{totalUnits}</strong>
        </Typography>
        <Typography>
          Missing units: <strong>{missingUnits}</strong>
        </Typography>
        <Typography>Database: {databaseName}</Typography>
      </Box>
    </Box>
  );
};

export default TimesheetHeader;
