import React from "react";
import {
  Box,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

import { useTheme } from "../../../../context/ThemeContext";
import { commonThemes } from "../../../../utils/themes/themes";

interface UserListEmployeeStatusFilterProps {
  employeeStatus: string;
  setEmployeeStatus: (status: string) => void;
}

export const UserListEmployeeStatusFilter = ({
  employeeStatus,
  setEmployeeStatus,
}: UserListEmployeeStatusFilterProps) => {
  const { isDarkMode } = useTheme();

  return (
    <Box mt={2}>
      <Box
        px={4}
        py={2}
        border={isDarkMode ? "1px solid #3e424d" : "1px solid #e0e0e0"}
      >
        <FormLabel>Employee Status</FormLabel>
        <RadioGroup
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
          value={employeeStatus}
          onChange={(e) => setEmployeeStatus(e.target.value)}
        >
          <FormControlLabel
            value="ALL"
            control={<Radio />}
            label="All"
            // @ts-ignore
            sx={{ mr: 2  }}
          />
          <FormControlLabel
            value="X"
            control={<Radio />}
            label="Not employees"
            // @ts-ignore
            sx={{ mr: 2  }}
          />
          <FormControlLabel
            value="A"
            control={<Radio />}
            label="Active"
            // @ts-ignore
            sx={{ mr: 2  }}
          />
          <FormControlLabel
            value="NOT_ACTIVE"
            control={<Radio />}
            label="Not active"
            // @ts-ignore
            sx={{ mr: 2  }}
          />
        </RadioGroup>
      </Box>
    </Box>
  );
};
