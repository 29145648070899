import React, { useRef, useState, useEffect } from "react";
import { Box, TextField, Button } from "@mui/material";
import { IconTrash } from "../../../components/icons/trash";
import dayjs from "dayjs";

interface TimesheetTimerProps {
  duration?: string;
  onStart?: (startTime: dayjs.Dayjs) => void;
  onStop?: (duration: string) => void;
  onResume?: (duration: string) => void;
  onFinish?: (duration: string) => void;
  error?: string | null;
  beforeOnStart?: () => boolean;
  beforeOnFinish?: () => boolean;
  forceTriggerStart?: boolean;
  setForceTriggerStart?: (value: boolean) => void;
}

const ELEMENT_BORDER_RADIUS = "8px";

const TimesheetTimer: React.FC<TimesheetTimerProps> = ({
  duration,
  onStart,
  onStop,
  onResume,
  onFinish,
  error,
  beforeOnStart,
  beforeOnFinish,
  forceTriggerStart = false,
  setForceTriggerStart,
}) => {
  const [status, setStatus] = useState<"idle" | "started" | "paused">("idle");
  const [time, setTime] = useState(duration || "00:00:00");
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const secondsRef = useRef(0);
  const hasStartedRef = useRef(false);

  const startTimer = () => {
    if (intervalRef.current) return;

    if (!hasStartedRef.current) {
      onStart?.(dayjs());
      hasStartedRef.current = true;
      // setForceTriggerStart?.(false);
    } else {
      onResume?.(time);
    }

    const [hours, minutes, seconds] = time.split(":").map(Number);
    secondsRef.current = hours * 3600 + minutes * 60 + seconds;

    intervalRef.current = setInterval(() => {
      secondsRef.current += 1;
      const h = Math.floor(secondsRef.current / 3600);
      const m = Math.floor((secondsRef.current % 3600) / 60);
      const s = secondsRef.current % 60;
      setTime(
        `${h.toString().padStart(2, "0")}:${m.toString().padStart(2, "0")}:${s
          .toString()
          .padStart(2, "0")}`,
      );
    }, 1000);
  };

  const stopTimer = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
      onStop?.(time);
    }
  };

  const handleFinishTimer = () => {
    if (beforeOnFinish && !beforeOnFinish()) {
      return;
    }
    setStatus("idle");
    onFinish?.(time);
    setTime("00:00:00");
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
    secondsRef.current = 0;
    hasStartedRef.current = false;
  };

  const handleDelete = () => {
    stopTimer();
    setTime("00:00:00");
    setStatus("idle");
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
    secondsRef.current = 0;
    hasStartedRef.current = false;
  };

  const handleTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/^[0-9:]*$/.test(value)) {
      const parts = value.split(":");
      if (parts.length <= 3 && parts.every((part) => part.length <= 2)) {
        setTime(value);
      }
    }
  };

  const handleTimeBlur = () => {
    const [h = "00", m = "00", s = "00"] = time.split(":");
    const formattedTime = `${h.padStart(2, "0")}:${m.padStart(2, "0")}:${s.padStart(2, "0")}`;
    setTime(formattedTime);
  };

  useEffect(() => {
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (forceTriggerStart && status === "idle") {
      if (beforeOnStart && !beforeOnStart()) {
        return;
      }
      setStatus("started");
      startTimer();
    }
  }, [forceTriggerStart]);

  console.log("timer duration", duration);

  // Will this be updated when the timer is started?
  const timerIsRunning = secondsRef.current > 0;

  return (
    <Box
      display="flex"
      gap={2}
      width={{ xs: "100%", md: "auto" }}
      flex={{ md: 1 }}
    >
      <TextField
        label="Time"
        size="small"
        fullWidth
        value={time}
        onChange={handleTimeChange}
        onBlur={handleTimeBlur}
        disabled={status === "started"}
        error={!!error}
        helperText={error ?? ""}
        FormHelperTextProps={{
          sx: { color: error ? "error.main" : "inherit" },
        }}
        inputProps={{
          pattern: "[0-9:]*",
          maxLength: 8,
        }}
        sx={{
          minWidth: 120,
          color: error ? "error.main" : "inherit",
          bgcolor: "background.paper",
          borderRadius: ELEMENT_BORDER_RADIUS,
          "& .MuiOutlinedInput-root": {
            borderRadius: ELEMENT_BORDER_RADIUS,
            "& input": {
              color: error ? "error.main" : "currentColor",
            },
          },
        }}
      />

      <Box
        display="flex"
        alignItems="center"
        gap={2}
        width={{ xs: "100%", md: "auto" }}
      >
        <TimesheetTimerButton
          status={status}
          onClick={() => {
            if (status === "idle") {
              if (beforeOnStart && !beforeOnStart()) {
                return;
              }
              setStatus("started");
              startTimer();
            } else {
              setStatus("idle");
              stopTimer();
            }
          }}
        />
        {timerIsRunning && (
          <>
            <TimesheetTimerButton
              status={"paused"}
              onClick={handleFinishTimer}
            />
            <Button
              variant="text"
              color="error"
              size="small"
              onClick={handleDelete}
              sx={{
                minWidth: 36,
                height: 36,
                borderRadius: "50%",
                p: 0,
              }}
            >
              <IconTrash color="error" paletteType="main" />
            </Button>
          </>
        )}
        <Button
          variant="contained"
          color="secondary"
          size="small"
          sx={{
            minWidth: 36,
            height: 36,
            borderRadius: "50%",
            display: { xs: "flex", md: "none" },
          }}
        >
          ✏️
        </Button>
      </Box>
    </Box>
  );
};

const TimesheetTimerButton = ({
  status = "idle",
  onClick,
}: {
  status: "idle" | "started" | "paused";
  onClick: () => void;
}) => {
  const getButtonStyles = () => {
    switch (status) {
      case "started":
        return {
          bgcolor: "warning.main",
          "&:hover": {
            bgcolor: "warning.dark",
          },
        };
      case "paused":
        return {
          bgcolor: "error.main",
          "&:hover": {
            bgcolor: "error.dark",
          },
        };
      default:
        return {
          bgcolor: "success.main",
          "&:hover": {
            bgcolor: "success.dark",
          },
        };
    }
  };

  const getIcon = () => {
    return status === "idle" ? "▶" : status === "paused" ? "⏹" : "⏸";
  };

  return (
    <Button
      variant="contained"
      size="small"
      onClick={onClick}
      sx={{
        minWidth: { md: "36px" },
        width: { xs: "100%", md: "36px" },
        height: { xs: "36px", md: "36px" },
        borderRadius: { xs: 8, md: "50%" },
        ...getButtonStyles(),
      }}
    >
      {getIcon()}
    </Button>
  );
};

export { TimesheetTimer, TimesheetTimerButton };
