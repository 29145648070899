import React from "react";
import { TextField } from "@mui/material";
import { CustomReportsParameters } from "../../../../types/api/custom-reports";
import { YNToBool } from "../../../../utils/format-bool";

export const CustomReportsTextField = ({
  parameter,
  value = "",
  handleOnChange,
  isFieldValid = true,
  errorMessage = "",
}: {
  parameter: CustomReportsParameters;
  value: string;
  handleOnChange: any;
  isFieldValid: boolean;
  errorMessage: string;
}) => {
  return (
    <TextField
      key={parameter.Label}
      label={parameter.Label}
      value={value}
      onChange={(e) => handleOnChange(`p${parameter.Order}`, e.target.value)}
      required={YNToBool(parameter.RequiredYN)}
      variant="outlined"
      size="small"
      sx={{ width: "100%", maxWidth: "240px" }}
      error={!isFieldValid}
      helperText={errorMessage}
    />
  );
};
