import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  MenuItem,
  DialogTitle,
  Dialog,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useTheme } from "../../../context/ThemeContext";
import { commonThemes } from "../../../utils/themes/themes";
import useApi from "../../../hooks/useApi";
import envConfig from "../../../config";
import BackdropLoading from "../../../components/BackdropLoading/BackdropLoading";

const EditAccountPopup = ({
  accountDataSets,
  setOpenAddAccountPopup,
  taxCodes = [],
  setListOfAccounts = () => {},
  accounts,
  initialData = null,
  isClone,
  refetchData,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { isDarkMode } = useTheme();
  const { apiCall } = useApi();

  const usedAccNumbers = useMemo(
    () => accounts?.map((account) => account.accNo),
    [accounts],
  );

  const [postableVisible, setPostableVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openAccountDialog, setOpenAccountDialog] = useState(false);
  const [suggestedAccounts, setSuggestedAccounts] = useState({
    prev: null,
    next: null,
  });

  const [formData, setFormData] = useState({
    checked: true,
    postable: false,
    accountNo: "",
    accountName: "",
    privatePortion: "",
    accountHeader: "",
    taxCode: "",
    cashFlow: "",
    accountType: "",
    notes: "",
  });

  useEffect(() => {
    if (initialData) {
      setFormData({
        checked: initialData.accActiveYN === "Y",
        postable: initialData.accPostableYN === "Y",
        accountNo: initialData.accNo ? initialData.accNo.toString() : "",
        accountName: initialData.accName || "",
        privatePortion: initialData.accDefPriv
          ? initialData.accDefPriv.toString()
          : "",
        accountHeader: initialData.accHeader_achID
          ? initialData.accHeader_achID.toString()
          : "",
        taxCode: initialData.accDefTxc_txcID
          ? initialData.accDefTxc_txcID.toString()
          : "",
        cashFlow: initialData.accCashFlow
          ? initialData.accCashFlow.toString()
          : "",
        accountType: initialData.accType_actID
          ? initialData.accType_actID.toString()
          : "",
        notes: initialData.accNotes || "",
      });

      // Set postable visibility based on accountType
      const postableTypes = [3, 4, 7, 8];
      setPostableVisible(postableTypes.includes(initialData.accType_actID));
    } else {
      setFormData({
        checked: true,
        postable: false,
        accountNo: "",
        accountName: "",
        privatePortion: "",
        accountHeader: "",
        taxCode: "",
        cashFlow: "",
        accountType: "",
        notes: "",
      });
      setPostableVisible(false);
    }
  }, [initialData]);

  const findNearestAvailableNumbers = (accountNo) => {
    let prev = accountNo - 1;
    let next = accountNo + 1;

    while (usedAccNumbers.includes(prev)) prev -= 1;
    while (usedAccNumbers.includes(next)) next += 1;

    return { prev, next };
  };

  const handleAccountNumberBlur = () => {
    const accountNo = parseInt(formData.accountNo, 10);
    if (usedAccNumbers.includes(accountNo)) {
      const nearest = findNearestAvailableNumbers(accountNo);
      setSuggestedAccounts(nearest);
      setOpenAccountDialog(true);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };

  const handleRadioChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === "accountType") {
      const postableTypes = [3, 4, 7, 8];
      setPostableVisible(postableTypes.includes(parseInt(value, 10)));
    }
  };

  const handleSave = async () => {
    const payload = {
      CurrentAccountNo: initialData?.accNo || 0,
      Details: {
        accNo: parseInt(formData.accountNo, 10),
        accType_actID: parseInt(formData.accountType, 10),
        accName: formData.accountName,
        accNotes: formData.notes,
        accDefTxc_txcID: parseInt(formData.taxCode, 10),
        accDefPriv: parseInt(formData.privatePortion, 10),
        accPostableYN: formData.postable ? "Y" : "N",
        accCashFlow: formData.cashFlow,
        accHeader_achID: parseInt(formData.accountHeader, 10),
        accLockDate: "0001-01-01T00:00:00",
        accLinkID: "",
        accActiveYN: formData.checked ? "Y" : "N",
      },
    };

    console.log("Payload:", payload);

    const url =
      initialData && !isClone
        ? `https://${envConfig.apiDev1Exacc}/api/v1/en-au/accounts/update?BaseHostURL=${envConfig.mainServiceUrl}`
        : `https://${envConfig.apiDev1}/api/v3/en-au/accounts/save?BaseHostURL=${envConfig.mainServiceUrl}`;

    await apiCall({
      url,
      method: "POST",
      body: payload,
      onSuccess: async (dataFromBe) => {
        await refetchData();
        enqueueSnackbar("Account saved successfully", { variant: "success" });
        setOpenAddAccountPopup(false);
      },
      onError: (errorMessage) => {
        enqueueSnackbar(errorMessage, { variant: "error" });
      },
    });
  };

  const handleSuggestedAccountClick = (accountNo) => {
    setFormData({ ...formData, accountNo: accountNo.toString() });
    setOpenAccountDialog(false);
  };

  return (
    <Dialog
      open={true}
      onClose={() => setOpenAddAccountPopup(false)}
      maxWidth="md"
    >
      <BackdropLoading open={loading} />
      <Box p={2}>
        <DialogTitle>
          {initialData
            ? isClone
              ? "Clone Account"
              : "Edit Account"
            : "New Account"}
        </DialogTitle>
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                checked={formData.checked}
                onChange={handleCheckboxChange}
                name="checked"
              />
            }
            label="Active"
          />
        </Box>
        <TextField
          sx={{
            mx: ".5%",
            label: {
              fontSize: 12,
              ...commonThemes.inputLabel,
              color: isDarkMode ? "#FFF" : "rgba(0, 0, 0, 0.6)",
            },
            minWidth: "30%",
            maxWidth: "30%",
          }}
          type="number"
          name="accountNo"
          label="Account No"
          placeholder="Account No"
          value={formData.accountNo}
          onChange={handleInputChange}
          onBlur={handleAccountNumberBlur}
          margin="normal"
          disabled={!!initialData && !isClone} // Disable editing of account number for existing accounts
        />
        <TextField
          label="Account Name"
          name="accountName"
          value={formData.accountName}
          onChange={handleInputChange}
          margin="normal"
          sx={{
            mx: ".5%",
            minWidth: "30%",
            maxWidth: "30%",
            label: {
              fontSize: 12,
              ...commonThemes.inputLabel,
              color: isDarkMode ? "#FFF" : "rgba(0, 0, 0, 0.6)",
            },
          }}
        />

        <TextField
          sx={{
            mx: ".5%",
            label: {
              fontSize: 12,
              ...commonThemes.inputLabel,
              color: isDarkMode ? "#FFF" : "rgba(0, 0, 0, 0.6)",
            },
            minWidth: "30%",
            maxWidth: "30%",
          }}
          type="number"
          name="privatePortion"
          label="Default Private Portion %"
          placeholder="Portion in 0% - 99%"
          InputProps={{
            inputProps: {
              min: 0,
              max: 99,
            },
          }}
          value={formData.privatePortion}
          onChange={(e) => {
            if (Number(e.target.value) > 99) {
              handleInputChange({
                target: { name: "privatePortion", value: 99 },
              });
            } else if (Number(e.target.value < 0)) {
              handleInputChange({
                target: { name: "privatePortion", value: 0 },
              });
            } else {
              handleInputChange(e);
            }
          }}
          margin="normal"
        />
        <TextField
          select
          label="Account Header"
          name="accountHeader"
          value={formData.accountHeader}
          onChange={handleInputChange}
          margin="normal"
          sx={{
            mx: ".5%",
            minWidth: "30%",
            maxWidth: "30%",
            label: {
              fontSize: 12,
              ...commonThemes.inputLabel,
              color: isDarkMode ? "#FFF" : "rgba(0, 0, 0, 0.6)",
            },
          }}
        >
          {accountDataSets?.ListOfAccountHeaders?.map((header) => (
            <MenuItem key={header.achID} value={header.achID}>
              {header.achDrCrName}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          select
          label="Default Tax Code"
          name="taxCode"
          value={formData.taxCode}
          onChange={handleInputChange}
          margin="normal"
          sx={{
            mx: ".5%",
            minWidth: "30%",
            maxWidth: "30%",
            label: {
              fontSize: 12,
              ...commonThemes.inputLabel,
              color: isDarkMode ? "#FFF" : "rgba(0, 0, 0, 0.6)",
            },
          }}
        >
          {taxCodes?.map((code) => (
            <MenuItem key={code.txcID} value={code.txcID}>
              {code.txcCode}
            </MenuItem>
          ))}
        </TextField>
        <Typography
          variant="subtitle1"
          sx={{ color: isDarkMode ? "#7D8CAA" : "#495570", fontWeight: "bold" }}
        >
          Cash Flow
        </Typography>
        <RadioGroup
          sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
          name="cashFlow"
          value={formData.cashFlow}
          onChange={handleRadioChange}
        >
          {accountDataSets?.ListOfCashFlow?.map((flow) => (
            <FormControlLabel
              key={flow.chfID}
              value={flow.chfID}
              control={<Radio />}
              label={flow.chfName}
            />
          ))}
        </RadioGroup>
        <Typography
          variant="subtitle1"
          sx={{ color: isDarkMode ? "#7D8CAA" : "#495570", fontWeight: "bold" }}
        >
          Account Type
        </Typography>
        <RadioGroup
          sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
          name="accountType"
          value={formData.accountType}
          onChange={handleRadioChange}
        >
          {accountDataSets?.ListOfAccountTypes?.map((type) => (
            <FormControlLabel
              key={type.actID}
              value={type.actID}
              control={<Radio />}
              label={type.actName}
            />
          ))}
        </RadioGroup>
        {postableVisible && (
          <FormControlLabel
            control={
              <Checkbox
                checked={formData.postable}
                onChange={handleCheckboxChange}
                name="postable"
              />
            }
            label="Postable"
          />
        )}
        <TextField
          sx={{
            width: "100%",
            label: {
              fontSize: 12,
              ...commonThemes.inputLabel,
              color: isDarkMode ? "#FFF" : "rgba(0, 0, 0, 0.6)",
            },
          }}
          name="notes"
          label="Notes"
          multiline
          rows={4}
          value={formData.notes}
          onChange={handleInputChange}
          margin="normal"
        />
        <Box
          sx={{ display: "flex", justifyContent: "flex-end", gap: 2, mt: 2 }}
        >
          <Button variant="contained" color="primary" onClick={handleSave}>
            Save
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setOpenAddAccountPopup(false)}
          >
            Cancel
          </Button>
        </Box>
      </Box>

      <Dialog
        open={openAccountDialog}
        onClose={() => setOpenAccountDialog(false)}
      >
        <DialogTitle>Choose Account Number</DialogTitle>
        <Box p={2}>
          <Typography sx={{ textAlign: "center" }}>
            This account number is in use.
            <br />
            Nearest available numbers:
            <br />
            <Button
              onClick={() =>
                handleSuggestedAccountClick(suggestedAccounts.prev)
              }
            >
              {suggestedAccounts.prev}
            </Button>{" "}
            or{" "}
            <Button
              onClick={() =>
                handleSuggestedAccountClick(suggestedAccounts.next)
              }
            >
              {suggestedAccounts.next}
            </Button>
          </Typography>
        </Box>
      </Dialog>
    </Dialog>
  );
};

export default EditAccountPopup;
