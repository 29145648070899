import { useEffect, useState } from "react";
import { Tabs, Tab, Button, Box } from "@mui/material";
import BankFeedsAllAccounts from "../../components/BankFeeds/BankFeedsAllAccounts";
import BankFeedsConnected from "../../components/BankFeeds/BankFeedsConnected";
import BankFeedsNotConnected from "../../components/BankFeeds/BankFeedsNotConnected";
import { getCookie, getHeaders } from "../../utils/api";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useLoginPopup } from "../../context/LoginPopupContext";
import { useSnackbar } from "notistack";
import BackdropLoading from "../../components/BackdropLoading/BackdropLoading";
import PageTitle from "../../components/PageTitle/PageTitle";
import envConfig from "../../config";
import useApi from "../../hooks/useApi";
import { useGetIP } from "../../hooks/get-ip";
import { getUrlForNewSession } from "../../utils/reusable";

const BankFeeds = () => {
  const { dbId, lang } = useParams();
  const { apiCall } = useApi();
  const ip = useGetIP();
  const prefix = `/${dbId}/${lang}/`;
  const headers = getHeaders(dbId);
  const navigate = useNavigate();

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("jobId");

  const [value, setValue] = useState(0);
  const [shouldRefetch, setShouldRefetch] = useState(false);
  const [loadingActivateDeactivate, setLoadingActivateDeactivate] =
    useState(false);
  const [loading, setLoading] = useState(true);
  const [
    allUsersLinkedConnectionsActiveAccounts,
    setAllUsersLinkedConnectionsActiveAccounts,
  ] = useState(null);

  const { enqueueSnackbar } = useSnackbar();

  const fetchAllAccounts = async () => {
    setLoading(true);
    await apiCall({
      url: `https://${envConfig.apiDev1}/api/v3/en-au/bank-feeds/get-all-accounts-from-all-business-files?BaseHostURL=${envConfig.mainServiceUrl}`,
      method: "GET",
      headers,
      onSuccess: (data) => {
        setAllUsersLinkedConnectionsActiveAccounts(data);
        setLoading(false);
      },
      onError: (errorMessage) => {
        setLoading(false);
        enqueueSnackbar(errorMessage, { variant: "error" });
      },
    });
  };

  const handleChange = async (event, newValue) => {
    setLoading(true);
    setValue(newValue);
    await fetchAllAccounts();
  };

  const handleActivate = async ({ accountId, connectionId }) => {
    setLoadingActivateDeactivate(true);
    await apiCall({
      ip: ip,
      url: `https://${envConfig.apiDev1}/api/v3/en-au/bank-feeds/activate-account?BaseHostURL=${envConfig.mainServiceUrl}`,
      method: "POST",
      body: {
        accountId: accountId,
        connectionId: connectionId,
      },
      onSuccess: (data) => {
        setShouldRefetch(true);
        setLoadingActivateDeactivate(false);
      },
      onError: (errorMessage) => {
        setLoadingActivateDeactivate(false);
        enqueueSnackbar({
          variant: "error",
          message: errorMessage || "Error activating account",
        });
      },
    });
  };

  const handleDeactivate = async ({ accountId, connectionId }) => {
    setLoadingActivateDeactivate(true);
    await apiCall({
      ip: ip,
      url: `https://${envConfig.apiDev1}/api/v3/en-au/bank-feeds/deactivate-account?BaseHostURL=${envConfig.mainServiceUrl}`,
      method: "POST",
      body: {
        accountId: accountId,
        connectionId: connectionId,
      },
      onSuccess: (data) => {
        setShouldRefetch(true);
        setLoadingActivateDeactivate(false);
      },
      onError: (errorMessage) => {
        setLoadingActivateDeactivate(false);
        enqueueSnackbar({
          variant: "error",
          message: errorMessage || "Error deactivating account",
        });
      },
    });
  };

  const onLinkAnother = (connectionId) => {
    onNewConnection(connectionId);
  };

  const onNewConnection = async (connectionId) => {
    setLoadingActivateDeactivate(true);
    await apiCall({
      ip: ip,
      url: `https://${envConfig.apiDev1}/api/v3/en-au/bank-feeds/create-new-bank-connection-link?BaseHostURL=${envConfig.mainServiceUrl}`,
      method: "POST",
      onSuccess: async (data) => {
        const action = "&action=connect";
        if (data?.ConsentUrl) {
          const url = `https://${envConfig.mainServiceUrl}/${dbId || getCookie("databaseId")}/en-au/my-bank-feeds`;
          const urlForRedirect = await getUrlForNewSession(
            url,
            apiCall,
            enqueueSnackbar,
            dbId,
          );
          console.log(urlForRedirect);
          if (urlForRedirect) {
            if (connectionId) {
              window.location =
                data.ConsentUrl +
                `&state=${urlForRedirect}&connectionId=${connectionId}` +
                action;
            } else {
              window.location =
                data.ConsentUrl + `&state=${urlForRedirect}` + action;
            }
            setLoadingActivateDeactivate(false);
          } else {
            enqueueSnackbar("Something went wrong, please try again.", {
              variant: "warning",
            });
            setLoadingActivateDeactivate(false);
          }
        }
      },
      onError: (errorMessage) => {
        setLoadingActivateDeactivate(false);
        enqueueSnackbar(errorMessage, { variant: "error" });
      },
    });
  };

  useEffect(() => {
    if (shouldRefetch) {
      setShouldRefetch(false);
      fetchAllAccounts();
    }
  }, [shouldRefetch]);

  useEffect(() => {
    fetchAllAccounts();
  }, []);

  useEffect(() => {
    let fetchCount = 0;

    const fetchData = async () => {
      try {
        if (token && token !== "null") {
          const response = await fetch(
            `https://${envConfig.apiDev1}/api/v3/en-au/bank-feeds/process-job?jobId=${token}&BaseHostURL=${envConfig.mainServiceUrl}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "olivs-api-real-ip": "115.70.37.103",
                "olivs-api-computer-name": "BTM-WS2019",
                "session-id": getCookie("sessionId"),
                "browser-agent": window.navigator.userAgent,
                "olivs-root-password": "OlivsWillBeBetterThanBTMSoft!",
                "api-key":
                  getCookie("apiKey") + "-" + (dbId || getCookie("databaseId")),
              },
            },
          );
          const data = await response.json();
          if (
            data.SuccessYN === "Y" &&
            data.ConnectionId &&
            data.CredentialsValidatedYN === "Y"
          ) {
            enqueueSnackbar({
              variant: "success",
              message: "Basiq data has been updated",
            });
            fetchCount = 10;
          } else {
            console.error("Data does not meet success criteria - refetching");
          }
        } else {
          return;
        }
      } catch (error) {
        enqueueSnackbar(error, {
          variant: "error",
        });
      }

      fetchCount += 1;
      if (fetchCount < 10) {
        setTimeout(fetchData, 7000);
      }
    };

    fetchData();
  }, [token]);

  const allAccounts =
    allUsersLinkedConnectionsActiveAccounts?.ListOfAccounts || [];

  const notConnected = allAccounts?.filter(
    (val) => val.bfaLinkStatus !== "Linked",
  );

  const connected = allAccounts?.filter(
    (val) => val.bfaLinkStatus === "Linked",
  );

  return (
    <div>
      <BackdropLoading open={loading || loadingActivateDeactivate} />
      <PageTitle
        onBack={() => navigate(prefix + "bank-feeds-linked")}
        displayBackIcon={true}
        title="Bank Feeds - your accounts"
      />
      <Tabs value={value} onChange={handleChange}>
        <Tab disableRipple label="All Accounts" />
        <Tab disableRipple label="Not connected" />
        <Tab disableRipple label="Connected" />
      </Tabs>
      <Box sx={{ marginTop: 2, textAlign: "right" }}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => onNewConnection()}
          sx={{ maxWidth: "200px" }}
        >
          + Connect another bank
        </Button>
      </Box>
      {value === 0 && (
        <BankFeedsAllAccounts
          handleDeactivate={handleDeactivate}
          handleActivate={handleActivate}
          onLinkAnother={onLinkAnother}
          data={allAccounts}
        />
      )}
      {value === 1 && (
        <BankFeedsNotConnected
          handleDeactivate={handleDeactivate}
          handleActivate={handleActivate}
          onLinkAnother={onLinkAnother}
          data={notConnected?.map((row, index) => ({ ...row, id: index }))}
        />
      )}
      {value === 2 && (
        <BankFeedsConnected
          handleDeactivate={handleDeactivate}
          handleActivate={handleActivate}
          onLinkAnother={onLinkAnother}
          data={connected}
        />
      )}
    </div>
  );
};

export default BankFeeds;
