import envConfig from "../../../../config";
import { getCookie } from "../../../../utils/api";
import { createSessionForExternal } from "../../../../utils/reusable";

// TODO: rename the file - remove get keyword
// TODO: remove the file
export const openTransactionInURL = (
  transID: any,
  rowType: any,
  dbId: any,
  ip: any,
  apiCall: any,
  linkTodets: string | null | undefined,
) => {
  if (!linkTodets) {
    console.error("Link to details is not provided");
    return;
  }

  const LinkToDets = linkTodets;

  if (LinkToDets.includes("[S]")) {
    const updatedLink = LinkToDets.replace("[S]", envConfig.mainServiceUrl)
      .replace("[DbID]", dbId as string)
      .replace("[Lang]", "en-au");

    const urlToUse = `https://${updatedLink}`;
    window.open(urlToUse, "_blank");
    return;
  } else if (LinkToDets.includes("[A]")) {
    const updatedLink = LinkToDets.replace(
      "[A]",
      envConfig.correspondingService,
    )
      .replace("[DbID]", dbId as string)
      .replace("[Lang]", "en-au");

    const urlToUse = `https://${updatedLink}`;
    createSessionForExternal(urlToUse, ip, true, apiCall, dbId);
  }
};
