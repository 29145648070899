import { ItemClickEvent } from "devextreme/ui/context_menu";
import {
  openInCurrentTabFromPartialUrl,
  openInNewTabFromPartialUrl,
} from "./open-in-new-tab";

export type ActionType = "OPEN" | "OPEN_NEW_TAB" | "PDF" | String | null;

export interface GridAction {
  text: string;
  ActionType: ActionType;
  ColumnWithLink: string;
  DisplayText: string;
}

interface HandleActionParams {
  event: ItemClickEvent | any;
  rowData: any;
  apiCall: any;
  dbId?: string;
  onPdfDownload?: (link: string) => Promise<void>;
}

export const handleGridAction = async ({
  event,
  rowData,
  apiCall,
  dbId,
  onPdfDownload,
}: HandleActionParams): Promise<void> => {
  const action = event.itemData?.ActionType as ActionType;
  const column = event.itemData?.ColumnWithLink;

  console.log(
    "[DEBUGGING][GRID ACTION] trying to call the action: ",
    action,
    column,
    rowData,
  );

  if (!column || !rowData[column]) return;

  switch (action) {
    case "OPEN":
      await openInCurrentTabFromPartialUrl(rowData[column], apiCall, dbId);
      break;
    case "OPEN_NEW_TAB":
      await openInNewTabFromPartialUrl(rowData[column], apiCall, dbId);
      break;
    case "PDF":
      if (onPdfDownload) {
        await onPdfDownload(rowData[column]);
      }
      break;
    default:
      console.warn("Unknown Action Type:", action);
      await openInCurrentTabFromPartialUrl(rowData[column], apiCall, dbId);
  }
};
