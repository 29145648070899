import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";

interface PayrollEventFinalizeDialogProps {
  open: boolean;
  onClose: () => void;
  onFinalize: () => void;
  onSeeWarnings: () => void;
  hasErrors: boolean;
  errorPrompts: {
    ErrorsPrompt: string;
    EmptyEmployeesPrompt: string;
    TerminatingEmployeesPrompt: string;
    ZeroNetPayPrompt: string;
  };
}

export const PayrollEventFinalizeDialog: React.FC<
  PayrollEventFinalizeDialogProps
> = ({ open, onClose, onFinalize, onSeeWarnings, hasErrors, errorPrompts }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        {hasErrors ? "Errors Found" : "Finalize Pay Event"}
      </DialogTitle>
      <DialogContent>
        {hasErrors ? (
          <>
            <Typography variant="body1" color="error" gutterBottom>
              {errorPrompts.ErrorsPrompt}
            </Typography>
            {errorPrompts.EmptyEmployeesPrompt && (
              <Typography variant="body1" gutterBottom>
                {errorPrompts.EmptyEmployeesPrompt}
              </Typography>
            )}
            {errorPrompts.TerminatingEmployeesPrompt && (
              <Typography variant="body1" gutterBottom>
                {errorPrompts.TerminatingEmployeesPrompt}
              </Typography>
            )}
            {errorPrompts.ZeroNetPayPrompt && (
              <Typography variant="body1" gutterBottom>
                {errorPrompts.ZeroNetPayPrompt}
              </Typography>
            )}
          </>
        ) : (
          <Typography variant="body1">
            Are you sure you want to finalize this pay event?
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        {hasErrors ? (
          <>
            <Button onClick={onSeeWarnings} color="warning" variant="contained">
              See Errors and Warnings
            </Button>
            <Button onClick={onClose}>Go Back</Button>
          </>
        ) : (
          <>
            <Button onClick={onFinalize} color="primary" variant="contained">
              Finalize
            </Button>
            <Button onClick={onClose}>Go Back</Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};
