import React from "react";
import { useSnackbar } from "notistack";
import useApi from "../../../hooks/useApi";
import envConfig from "../../../config";

interface StripeCreateAccountProps {
  onSuccess?: (response: StripeCreateAccountResponse) => void;
  onError?: (error: string) => void;
}

interface StripeCreateAccountPayload {
  RefreshUrl: string;
  SuccessUrl: string;
}

interface StripeCreateAccountResponse {
  SuccessYN: "Y" | "N";
  ErrorMessage: string;
  StripeAccountUrl: string;
}

export const useStripeCreateAccount = ({
  onSuccess,
  onError,
}: StripeCreateAccountProps = {}) => {
  const { apiCall } = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [data, setData] = React.useState<StripeCreateAccountResponse | null>(null);

  const createAccount = React.useCallback(async (payload: StripeCreateAccountPayload) => {
    setIsLoading(true);
    setIsError(false);

    if (process.env.NODE_ENV === "development") {
      const mockResponse: StripeCreateAccountResponse = {
        SuccessYN: "Y",
        ErrorMessage: "",
        StripeAccountUrl: "https://mock-stripe-url.com"
      };
      setData(mockResponse);
      setIsLoading(false);
      if (onSuccess) onSuccess(mockResponse);
      return;
    }

    try {
      await apiCall({
        url: `https://${envConfig.apiDev1Exacc}/api/v1/en-au/stripe/create-account-async`,
        method: "POST",
        // @ts-ignore
        body: payload,
        // @ts-ignore
        onSuccess: (response: StripeCreateAccountResponse) => {
          setData(response);
          if (response.SuccessYN === "Y") {
            enqueueSnackbar("Stripe account created successfully", { variant: "success" });
            if (onSuccess) onSuccess(response);
          } else {
            setIsError(true);
            const errorMessage = response.ErrorMessage || "Failed to create Stripe account";
            enqueueSnackbar(errorMessage, { variant: "error" });
            if (onError) onError(errorMessage);
          }
        },
        // @ts-ignore
        onError: (error: string) => {
          setIsError(true);
          enqueueSnackbar(error, { variant: "error" });
          if (onError) onError(error);
        }
      });
    } catch (error) {
      setIsError(true);
      const errorMessage = (error as Error).message;
      enqueueSnackbar(errorMessage, { variant: "error" });
      if (onError) onError(errorMessage);
    } finally {
      setIsLoading(false);
    }
  }, [apiCall, enqueueSnackbar]);

  return {
    isLoading,
    isError,
    data,
    createAccount
  };
};
