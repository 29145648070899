import dayjs from "dayjs";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useParams } from "react-router-dom";
import { axiosService } from "../../../api/axios";
import envConfig from "../../../config";
import { useLoginPopup } from "../../../context/LoginPopupContext";

export interface TimesheetUpdatePayload {
  acvID: string;
  acvType_avtID: number;
  acvUser_usrID: number | string;
  acvTimeUnits: number;
  acvDescription: string;
  acvNotes: string;
  acvTaskDate: string;
  acvStartTime: string;
  acvEndTime: string;
  acvInvoice_invID: number;
  acvChargeableYN: "Y" | "N";
  acvContact_cntID: number;
  acvHourlyRate: number;
  acvManualUnitsYN: "Y" | "N";
  acvCharge: number;
  acvOurCost: number;
  acvTask_prdID: number;
  acvSite_dpsID: number;
  acvPayroll_pevID: number;
  acvPaidBreakUnits: number;
  acvUnpaidBreakUnits: number;
  acvDocument_docID: string;
  acvTransferGUID: string;
  acvEntrySource: string;
  acvForActionYN: "Y" | "N";
  acvInvReadyYN: "Y" | "N";
}

export interface TimesheetUpdateResponse {
  SuccessYN: "Y" | "N";
  ErrorMessage: string;
  ActivityId: string;
}

export interface UseTimesheetUpdateProps {
  date: dayjs.Dayjs | null;
}

export const useTimesheetUpdate = ({ date }: UseTimesheetUpdateProps = { date: null }) => {
  const { dbId, lang } = useParams();
  const { reAuthenticate } = useLoginPopup();
  const queryClient = useQueryClient();

  const dateString = date ? date.format("DD/MM/YYYY") : "";
  const queryKey = ['timesheet-daily-list', dbId, lang, dateString];

  return useMutation({
    mutationFn: async (payload: TimesheetUpdatePayload) => {
      const response = await axiosService.request<TimesheetUpdateResponse>({
        dbId: dbId,
        method: 'POST',
        baseURL: envConfig.apiDev1Exacc,
        endpoint: `/api/v2/${lang}/timesheet/update-async`,
        data: payload,
        reAuthenticate: reAuthenticate,
      });

      return response;
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: queryKey });
    }
  });
};
