import React from "react";
import { useSnackbar } from "notistack";
import dayjs from "dayjs";
import useApi from "../../../hooks/useApi";
import envConfig from "../../../config";
import { EXAMPLE_RESPONSE_DAILY_TIMESHEET } from "./example-responses";
import { DailyTimesheetResponse } from "../types";
import { formatDateToISO } from "../../../utils/format-date";

export interface UseTimesheetDailyListProps {
  date: dayjs.Dayjs | null;
  onSuccess?: (data: DailyTimesheetResponse) => void;
  onError?: (error: string) => void;
}

export const useTimesheetDailyList = (
  { date, onSuccess, onError }: UseTimesheetDailyListProps = {
    date: dayjs(),
  },
) => {
  const { apiCall } = useApi();
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [data, setData] = React.useState<DailyTimesheetResponse | null>(null);

  const fetchDailyList = React.useCallback(async () => {
    console.log("fetchDailyList", date);
    setIsLoading(true);
    setIsError(false);

    if (process.env.NODE_ENV === "development") {
      setData(EXAMPLE_RESPONSE_DAILY_TIMESHEET);
      if (onSuccess) onSuccess(EXAMPLE_RESPONSE_DAILY_TIMESHEET);
      setIsLoading(false);
      return;
    }

    if (!date) {
      throw new Error("Date is required");
    }

    const searchParams = new URLSearchParams();
    searchParams.append("startDate", date.format("DD/MM/YYYY"));
    searchParams.append("endDate", date.format("DD/MM/YYYY"));

    console.log("GET DAILY LIST params: ", searchParams?.toString());

    try {
      await apiCall({
        url: `https://${envConfig.apiDev1Exacc}/api/v2/en-au/timesheet/get-daily-list-async?${searchParams.toString()}`,
        method: "GET",
        // @ts-expect-error TODO: fix this
        onSuccess: (response: DailyTimesheetResponse) => {
          setData(response);
          if (onSuccess) onSuccess(response);
        },
        // @ts-expect-error TODO: fix this
        onError: (error: string) => {
          setIsError(true);
          enqueueSnackbar(error, { variant: "error" });
          if (onError) onError(error);
        },
      });
    } catch (error) {
      setIsError(true);
      const errorMessage = (error as Error).message;
      enqueueSnackbar(errorMessage, { variant: "error" });
      if (onError) onError(errorMessage);
    } finally {
      setIsLoading(false);
    }
  }, [apiCall, date]);

  React.useEffect(() => {
    fetchDailyList();
  }, [fetchDailyList]);

  return {
    isLoading,
    isError,
    data,
    refetch: fetchDailyList,
  };
};
