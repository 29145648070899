import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import DataGrid, { Column } from "devextreme-react/data-grid";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import useApi from "../../hooks/useApi";
import envConfig from "../../config";
import BackdropLoading from "../../components/BackdropLoading/BackdropLoading";
import { useTheme } from "../../context/ThemeContext";
import { closeSnackbar, enqueueSnackbar } from "notistack";

const TPARBatches = () => {
  const { isDarkMode } = useTheme();
  const navigate = useNavigate();
  const { apiCall } = useApi();

  const { dbId, lang } = useParams();
  const prefix = `/${dbId}/${lang}/`;

  const [batches, setBatches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [newBatchDialogOpen, setNewBatchDialogOpen] = useState(false);
  const [newBatchYear, setNewBatchYear] = useState(null);

  useEffect(() => {
    const fetchBatches = async () => {
      const url = `https://${envConfig.apiDev2}/api/en-au/accounting/tpar-get-list-of-batches`;
      await apiCall({
        url,
        method: "GET",
        onSuccess: (data) => {
          setBatches(data.DataDT || []);
          setLoading(false);
        },
        onError: (error) => {
          console.error("Failed to fetch batches:", error);
          setLoading(false);
        },
      });
    };

    fetchBatches();
  }, [apiCall]);

  const handleRowClick = (e) => {
    const batchId = e.data.atbID;
    navigate(`${prefix}books/tpar-batch/${batchId}`);
  };

  const handleNewBatchClick = () => {
    setNewBatchDialogOpen(true);
  };

  const handleCreateBatch = async () => {
    setLoading(true);
    const url = `https://${envConfig.apiDev2}/api/en-au/accounting/tpar-create-new-batch`;
    await apiCall({
      url,
      method: "POST",
      body: { year: newBatchYear.year() },
      onSuccess: (data) => {
        setNewBatchYear(null);
        navigate(`${prefix}books/tpar-batch/${data.NewAtbID}`);
        setLoading(false);
      },
      onError: (error, data) => {
        setLoading(false);
        enqueueSnackbar(error, {
          variant: "error",
          action: (key) => (
            <Box>
              <Box
                display="flex"
                justifyContent="flex-end"
                flexDirection="row"
                mt={1}
                mb={1}
              >
                <Button
                  onClick={() => {
                    navigate(
                      `${prefix}books/tpar-batch/${data.RedirectToAtbID}`,
                    );
                    closeSnackbar(key);
                  }}
                  color="primary"
                  variant="contained"
                  sx={{ marginRight: 1 }} // space between buttons
                >
                  Go to batch {data.RedirectToAtbID}
                </Button>
                <Button
                  onClick={() => closeSnackbar(key)}
                  color="inherit"
                  variant="outlined"
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          ),
        });
      },
    });
    setNewBatchDialogOpen(false);
    setNewBatchYear(null);
  };

  useEffect(() => {
    const currentDate = new Date();

    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();

    if (currentMonth >= 6) {
      // July onwards
      setNewBatchYear(dayjs(new Date(currentYear, 6, 1)));
    } else {
      setNewBatchYear(dayjs(new Date(currentYear - 1, 6, 1)));
    }
  }, [newBatchDialogOpen]);

  return (
    <Box sx={{ padding: "20px" }}>
      <BackdropLoading open={loading} />
      <Typography variant="h4" gutterBottom>
        TPAR Batches
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        Taxable Payments Annual Report
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handleNewBatchClick}
        >
          New Batch
        </Button>
      </Box>
      <Box
        sx={{
          ".dx-datagrid .dx-row-alt > td, .dx-datagrid .dx-row-alt > tr > td": {
            backgroundColor: isDarkMode ? "#1E2432" : "#FAFAFA",
          },
        }}
      >
        <DataGrid
          dataSource={batches}
          onRowClick={handleRowClick}
          loading={loading}
          columnAutoWidth={true}
          rowAlternationEnabled={true}
          onCellPrepared={(e) => {
            if (e.rowType === "data") {
              e.cellElement.style.cursor = "pointer";
            }
          }}
        >
          <Column dataField="atbID" caption="ID" dataType="number" />
          <Column dataField="atbYear" caption="Year" dataType="number" />
          <Column dataField="StatusInfo" caption="Status" dataType="string" />
          <Column dataField="atbNotes" caption="Notes" dataType="string" />
        </DataGrid>
      </Box>

      <Dialog
        open={newBatchDialogOpen}
        onClose={() => setNewBatchDialogOpen(false)}
      >
        <form
          onSubmit={(event) => {
            event.preventDefault();
            handleCreateBatch();
          }}
        >
          <DialogTitle>Create New Batch</DialogTitle>
          <DialogContent>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                views={["year"]}
                value={newBatchYear}
                autoFocus
                onChange={(newValue) => setNewBatchYear(dayjs(newValue))}
                renderInput={(params) => (
                  <TextField {...params} fullWidth autoFocus />
                )}
              />
            </LocalizationProvider>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setNewBatchDialogOpen(false)}
              color="primary"
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              disabled={loading}
              color="primary"
              type="submit"
              variant="contained"
            >
              Create
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
};

export default TPARBatches;
