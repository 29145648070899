import React from "react";
import DataGrid, { Column, Paging, Pager } from "devextreme-react/data-grid";
import { useNavigate, useParams } from "react-router-dom";

const NewPayEventTable = ({ data }) => {
  const { dbId, lang } = useParams();
  const dataSource = data || [];
  const navigate = useNavigate();

  const handleRowClick = (e) => {
    const rowData = e.data;
    const id = rowData.id;
    navigate(`/${dbId}/${lang}/payroll/pay-event-open/${id}`);
  };

  return (
    <DataGrid
      dataSource={dataSource}
      showBorders={true}
      onRowClick={handleRowClick}
      hoverStateEnabled={true}
      rowAlternationEnabled
    >
      <Paging enabled={true} />
      <Pager showPageSizeSelector={true} showInfo={true} />

      <Column dataField="id" caption="ID" />
      <Column alignment="right" dataField="payDate" caption="Pay Date" />
      <Column dataField="period" caption="Period" />
      <Column dataField="employeeCount" caption="Employees" />
      <Column
        dataField="totalNet"
        caption="Total Net"
        dataType="number"
        format="#,##0.00"
      />
      <Column dataField="createdBy" caption="Created By" />
      <Column dataField="createdOn" caption="Created On" />
    </DataGrid>
  );
};

export default NewPayEventTable;
