import React, { useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import envConfig from "../../config";
import useApi from "../../hooks/useApi";
import { default_headers } from "../../utils/api";

const SessionManagement = () => {
  const { lang, dbId, sessionGuid } = useParams();
  const navigate = useNavigate();
  const location = useLocation(); // Import useLocation
  const { enqueueSnackbar } = useSnackbar();
  const { apiCall } = useApi();

  // Extract current query parameters
  const currentQueryParams = location.search; // Includes '?'

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log(dbId);
        console.log("sessionGuid: ", sessionGuid);
        const url = `https://${envConfig.apiLogin}/api/v1/en-au/session-management/register-new-session-instance?BaseHostURL=${envConfig.mainServiceUrl}`;
        await apiCall({
          ip: "127.0.0.2",
          url,
          method: "POST",
          skipAuth: true,
          headers: {
            ...default_headers,
            "Content-Type": "application/json",
            "browser-agent": window.navigator.userAgent,
          },
          body: {
            IpAddress: "127.0.0.2",
            UserAgentAkaBrowserDetails: navigator.userAgent,
            CurrentHost: window.location.origin,
            SessionGuid: sessionGuid,
          },
          onSuccess: (dataApiCall) => {
            console.log("dataApiCall", dataApiCall);
            const regex = new RegExp(`${envConfig.mainServiceUrl}/([^/]+)/`);
            const match = dataApiCall?.redirectUrl.match(regex);
            let databaseId;
            if (match && match[1]) {
              databaseId = match[1];
            }
            console.log("new databaseId", databaseId);
            localStorage.setItem("savedReferrer", dataApiCall.referrerUrl);
            document.cookie = `loginEmail=${dataApiCall.loginEmail}; path=/`;
            document.cookie = `loginPassword=${dataApiCall.loginPassword}; path=/`;
            document.cookie = `sessionGuid=${sessionGuid}; path=/`;
            document.cookie = `globalUserId=${dataApiCall.globalUserID}; path=/`;
            document.cookie = `databaseId=${databaseId || dataApiCall.databaseID}; path=/`;
            document.cookie = `login2FASecKey=${dataApiCall.login2FASecKey}; path=/`;
            document.cookie = `login2FaCodeLastUsedUTCDate=${dataApiCall.login2FaCodeLastUsedUTCDate}; path=/`;
            document.cookie = `loginLastSec2CheckInUTCDate=${dataApiCall.LoginLastSec2CheckInUTCDate}; path=/`;
            document.cookie = `loginLastPwdUTCDate=${dataApiCall.loginLastPwdUTCDate}; path=/`;
            document.cookie = `apiKeyExpirationUTCDate=${dataApiCall.apiKeyExpirationUTCDate}; path=/`;
            document.cookie = `sessionId=${dataApiCall.sessionId}; path=/`;
            document.cookie = `apiKey=${dataApiCall.apiKey}; path=/`;

            const redirectURL = new URL(dataApiCall?.redirectUrl);
            console.log("redirectURL: ", redirectURL);
            const path = redirectURL.pathname;
            console.log("path: ", path);
            const redirectSearch = redirectURL.search;
            console.log("redirectSearch: ", redirectSearch);

            const combinedSearch = `${redirectSearch}${currentQueryParams}`;

            console.log("combinedSearch: ", combinedSearch);
            if (path) {
              navigate(`${path}${combinedSearch}`);
            } else {
              navigate(`/0/en-au/dashboard${currentQueryParams}`);
            }
          },
          onError: (errorMessage, data) => {
            console.log("errorMessage", errorMessage);
            console.log("data", data);
            if (
              errorMessage === "Session Guid has been expired" ||
              errorMessage === "Session Guid has been used before"
            ) {
              const savedReferrer = localStorage.getItem("savedReferrer");
              if (savedReferrer) {
                localStorage.removeItem("savedReferrer");
                window.location.href = savedReferrer;
                return;
              }
              enqueueSnackbar(errorMessage, { variant: "error" });
              window.location = `https://${envConfig.loginUrl}`;
            } else {
              enqueueSnackbar(errorMessage || "An error occurred", {
                variant: "error",
              });
              window.location = `https://${envConfig.loginUrl}`;
            }
          },
        });
      } catch (error) {
        console.error("Error fetching IP address or making API call:", error);
        enqueueSnackbar(error.message || "An error occurred", {
          variant: "error",
        });
        navigate(`${envConfig.loginUrl}${currentQueryParams}`);
      }
    };

    fetchData();
  }, []);

  return <div></div>;
};

export default SessionManagement;
