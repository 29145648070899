import dayjs from "dayjs";
import {
  formatDateToISO,
  isOneOfSupportedDateFormat,
  parseSupportedDateToJSDate,
} from "../../../utils/format-date";
import { CustomReportState } from "./types";

export const isParamDateFormatValid = (str: string): boolean => {
  const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
  return dateRegex.test(str);
};

export const parseDateComponents = (str: string): number[] => {
  return str.split("-").map(Number);
};

export const isParamValidDate = (str: string): boolean => {
  if (!isParamDateFormatValid(str)) {
    return false;
  }

  const [year, month, day] = parseDateComponents(str);

  const date = new Date(str);

  return (
    date.getFullYear() === year &&
    date.getMonth() + 1 === month &&
    date.getDate() === day
  );
};

export const isParamBoolFormat = (str: string): boolean => {
  return str === "Y" || str === "N" || str === "y" || str === "n";
};

export const isParamValidBool = (str: string): boolean => {
  return isParamBoolFormat(str);
};

export const getStateValueFromParam = (
  param: string,
  defaultValue: string,
): string | dayjs.Dayjs => {
  if (isParamValidBool(param)) {
    return param;
  }

  if (isOneOfSupportedDateFormat(param)) {
    const date = parseSupportedDateToJSDate(param);
    if (dayjs.isDayjs(date)) {
      return date;
    } else {
      return dayjs(date);
    }
  }

  return param || defaultValue;
};

export const updateUrlParamsWithState = (state: CustomReportState) => {
  const currentUrl = new URL(window.location.href);
  const searchParams = new URLSearchParams(currentUrl.search);

  Object.keys(state).forEach((key) => {
    if (key === "predefinedPeriod") {
      return;
    }

    const value = state[key].value;
    const controlType = state[key].ControlType;

    if (value instanceof dayjs) {
      // @ts-ignore
      searchParams.set(key, value.format("YYYY-MM-DD"));
    } else if (typeof value === "boolean") {
      searchParams.set(key, value ? "Y" : "N");
    } else if (controlType === "single-selection") {
      searchParams.set(key, value);
    } else if (controlType === "multi-selection") {
      searchParams.set(key, value.map((v: any) => v.Value).join(","));
    } else {
      searchParams.set(key, value);
    }
  });

  window.history.pushState(
    {},
    "",
    `${currentUrl.pathname}?${searchParams.toString()}`,
  );
};
