import { Box, Button } from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import BackdropLoading from "../../components/BackdropLoading/BackdropLoading";
import { PageTitle } from "../../components/page-title";
import { useTheme } from "../../context/ThemeContext";
import { useUsersList } from "../../features/users/users-list/api/users-list";
import { UsersListDataGrid } from "../../features/users/users-list/components/users-list-data-grid";
import { UserListEmployeeStatusFilter } from "../../features/users/users-list/components/users-list-employee-status-filter";
import { UsersListStatusFilters } from "../../features/users/users-list/components/users-list-status-filter";
import { UserStatus } from "../../features/users/users-list/types";

export function UsersList() {
  const { isDarkMode } = useTheme();
  const { dbId } = useParams();
  const navigate = useNavigate();

  const userLists = useUsersList({});

  const [userStatus, setUserStatus] = React.useState<UserStatus>("A");
  const [employeeStatus, setEmployeeStatus] = React.useState<string>("ALL");

  const filteredDataDT =
    userLists.data?.DataDT.filter((item) => {
      let empStatusMatch = false;
      let usrStatusMatch = false;

      switch (employeeStatus) {
        case "ALL":
          empStatusMatch = true;
          break;
        case "A":
          empStatusMatch = item.empStatus === "A";
          break;
        case "X":
          empStatusMatch = item.empStatus === "X";
          break;
        case "NOT ACTIVE":
          empStatusMatch = item.empStatus !== "A" && item.empStatus !== "X";
          break;
        default:
          empStatusMatch = false;
      }

      switch (userStatus) {
        case "ALL":
          usrStatusMatch = true;
          break;
        case "A":
          usrStatusMatch = item.usrStatus === "A";
          break;
        case "P":
          usrStatusMatch = item.usrStatus === "P";
          break;
        case "NOT ACTIVE":
          usrStatusMatch = item.usrStatus !== "A" && item.usrStatus !== "P";
          break;
        default:
          usrStatusMatch = false;
      }

      return empStatusMatch && usrStatusMatch;
    }) || [];

  return (
    <>
      <BackdropLoading open={userLists.isLoading} />

      <Box display={"flex"} flexDirection={"column"}>
        <Box display={"flex"} alignItems={"center"} gap={2} marginTop={2}>
          <Box sx={{ mr: 3 }}>
            <PageTitle title="Users List" />
          </Box>
        </Box>
        <UsersListStatusFilters
          userStatus={userStatus}
          setUserStatus={setUserStatus}
        />
        <UserListEmployeeStatusFilter
          employeeStatus={employeeStatus}
          setEmployeeStatus={setEmployeeStatus}
        />
        <Box mt={4} mb={2}>
          <Button
            variant="contained"
            onClick={() => {
              navigate(`/${dbId}/en-au/invite-user`);
            }}
          >
            New User
          </Button>
        </Box>
        <UsersListDataGrid
          data={filteredDataDT || []}
          columnsList={userLists.data?.ColumnsList || []}
          actionsList={userLists.data?.ActionsList || []}
          rptUniqueKeyColumn={userLists.data?.RptUniqueKeyColumn || ""}
          onRefresh={userLists.refetch}
        />
        <Box mb={16} />
      </Box>
    </>
  );
}
