import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";

interface PayrollEventDeleteDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const PayrollEventDeleteDialog: React.FC<
  PayrollEventDeleteDialogProps
> = ({ open, onClose, onConfirm }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Delete Pay Event</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          Do you really want to DELETE this Pay Event?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onConfirm} color="error" variant="contained">
          Yes
        </Button>
        <Button onClick={onClose} variant="outlined">
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
};
