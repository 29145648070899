import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import RefreshIcon from "@mui/icons-material/Refresh";
import { DataGrid } from "devextreme-react";
import { Column, Paging, SearchPanel } from "devextreme-react/data-grid";
import { useNavigate, useParams } from "react-router-dom";
import useApi from "../../hooks/useApi";
import envConfig from "../../config";
import { enqueueSnackbar } from "notistack";
import BackdropLoading from "../../components/BackdropLoading/BackdropLoading";

const Departments = () => {
  const { dbId, lang } = useParams();
  const { apiCall } = useApi();
  const navigate = useNavigate();

  const [departments, setDepartments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [anchorPosition, setAnchorPosition] = useState(null); // For Menu positioning
  const [selectedRow, setSelectedRow] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);

  // Fetch departments data
  const fetchDepartments = useCallback(async () => {
    setLoading(true);
    try {
      await apiCall({
        url: `https://${envConfig.apiDev1Exacc}/api/v1/en-au/departments/get-list-of-departments-async`,
        method: "GET",
        onSuccess: (data) => {
          console.log(data);
          setDepartments(data.ListOfDepartments || []);
        },
        onError: (error) => {
          enqueueSnackbar(error, { variant: "error" });
        },
      });
    } catch (error) {
      console.error("Error fetching departments:", error);
    } finally {
      setLoading(false);
    }
  }, [apiCall]);

  useEffect(() => {
    fetchDepartments();
  }, [fetchDepartments]);

  // Handle row click
  const handleRowClick = (e) => {
    navigate(`/${dbId}/${lang}/department/${e.data.dptID}`);
  };

  // Handle context menu (actions) click
  const handleContextMenuClick = (event, rowData) => {
    event.stopPropagation();
    // Set the anchor position based on the click event
    setAnchorPosition({
      top: event.nativeEvent.clientY,
      left: event.nativeEvent.clientX,
    });
    setSelectedRow(rowData);
  };

  // Close the context menu
  const handleMenuClose = () => {
    setAnchorPosition(null);
  };

  // Open confirmation dialog for deletion
  const handleDeleteClick = () => {
    handleMenuClose();
    setConfirmOpen(true);
  };

  const handleAddSiteClick = () => {
    navigate(`/${dbId}/${lang}/site/${selectedRow.dptID}`);
  };

  const handleSiteClick = () => {
    navigate(`/${dbId}/${lang}/sites/${selectedRow.dptID}`);
  };

  // Confirm deletion of the selected department
  const handleConfirmDelete = async () => {
    setConfirmOpen(false);
    setLoading(true);
    if (selectedRow) {
      try {
        await apiCall({
          url: `https://${envConfig.apiDev1Exacc}/api/v1/en-au/departments/delete-async?departmentId=${selectedRow.dptID}&BaseHostURL=s1.olivs.com`,
          method: "POST",
          onSuccess: (data) => {
            console.log(data);
            enqueueSnackbar("Department deleted successfully.", {
              variant: "success",
            });
            fetchDepartments();
          },
          onError: (error) => {
            enqueueSnackbar(error, { variant: "error" });
          },
        });
      } catch (error) {
        console.error(error || "Failed to delete department");
      } finally {
        setLoading(false);
        handleMenuClose();
        setSelectedRow(null);
      }
    }
  };

  // Render the Actions cell with the MoreVertIcon
  const renderActionsCell = (cellData) => {
    return (
      <IconButton
        onClick={(event) => handleContextMenuClick(event, cellData.data)}
      >
        <MoreVertIcon />
      </IconButton>
    );
  };

  // Refresh the departments data
  const handleRefresh = () => {
    fetchDepartments();
  };

  return (
    <Box sx={{ padding: 2 }}>
      {/* Loading Backdrop */}
      <BackdropLoading open={loading} />

      {/* Confirmation Dialog for Deletion */}
      <Dialog open={confirmOpen} onClose={() => setConfirmOpen(false)}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this department?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmOpen(false)}>Cancel</Button>
          <Button onClick={handleConfirmDelete} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Page Title */}
      <Typography variant="h4">Departments</Typography>

      {/* Action Buttons */}
      <Box sx={{ display: "flex", gap: 2, marginY: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate(`/${dbId}/${lang}/department`)}
        >
          + New Department
        </Button>
        {/* <Button
          variant="contained"
          color="primary"
          onClick={() => navigate(`/${dbId}/${lang}/site`)}
        >
          + New Site
        </Button> */}
        <Button
          variant="outlined"
          onClick={handleRefresh}
          startIcon={<RefreshIcon />}
        >
          Refresh
        </Button>
      </Box>

      {/* DataGrid */}
      <DataGrid
        dataSource={departments}
        keyExpr="dptID"
        showBorders={true}
        onRowClick={handleRowClick}
        hoverStateEnabled={true}
        loading={loading}
      >
        <SearchPanel visible={true} width={240} placeholder="Search..." />
        <Paging defaultPageSize={10} />
        <Column dataField="dptName" caption="Name" />
        <Column dataField="dptCode" caption="Code" />
        <Column dataField="usrFullName" caption="Manager" />
        <Column
          dataField="dptActive"
          caption="Active"
          calculateCellValue={(rowData) =>
            rowData.dptActive === "Y" ? "Yes" : "No"
          }
        />
        <Column
          caption="Actions"
          cellRender={renderActionsCell}
          allowSorting={false}
          width={100}
        />
      </DataGrid>

      {/* Context Menu */}
      <Menu
        anchorReference="anchorPosition" // Use anchorPosition for positioning
        anchorPosition={anchorPosition} // Set the position based on the click
        open={Boolean(anchorPosition)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleSiteClick}>Sites</MenuItem>
        <MenuItem onClick={handleAddSiteClick}>Add Site</MenuItem>
        <MenuItem onClick={handleDeleteClick}>Delete</MenuItem>
      </Menu>
    </Box>
  );
};

export default Departments;
