import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { axiosService } from "../../../api/axios";
import envConfig from "../../../config";
import { useLoginPopup } from "../../../context/LoginPopupContext";
import { PayrollDashboardData } from "../types";
import { EXAMPLE_DASHBOARD_DATA } from "./example-response";

export const usePayrollDashboardData = ({ isAuthenticated = false }: { isAuthenticated: boolean }) => {
  const { dbId, lang } = useParams();
  const { reAuthenticate } = useLoginPopup();

  return useQuery({
    queryKey: ["payroll-dashboard-data", dbId, lang],
    queryFn: async () => {
      // optional - used for development purposes only
      if (process.env.NODE_ENV === "development") {
        return Promise.resolve(EXAMPLE_DASHBOARD_DATA);
      }

      const searchParams = new URLSearchParams();
      searchParams.append("BaseHostURL", envConfig.mainServiceUrl);

      const response = await axiosService.request<PayrollDashboardData>({
        dbId: dbId,
        method: "GET",
        baseURL: envConfig.apiDev2,
        endpoint: `/api/${lang}/payroll-au/get-dashboard-data?${searchParams.toString()}`,
        reAuthenticate: reAuthenticate,
      });

      return response;
    },
    enabled: isAuthenticated,
  });
};
