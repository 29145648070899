import React, { useRef, useState } from "react";
import { Box, Stack } from "@mui/material";
import DataGrid, {
  Column,
  Item,
  Sorting,
  Toolbar,
} from "devextreme-react/cjs/data-grid";

import {
  BASColumn,
  BASDataDT,
} from "../../../../../types/api/business-activity";
import { useTheme } from "../../../../../context/ThemeContext";
import {
  BASDownloadAsExcelDialog,
  BASDownloadAsPDFDialog,
} from "../bas-download-data";
import { RefreshButton } from "../../../../../components/ui/buttons/refresh-button";
import { DownloadButton } from "../../../../../components/ui/buttons/download-button";
import { getAlignment } from "../../../../../helpers/table";
import { YNToBool } from "../../../../../utils/format-bool";
import { basTableRenderCell } from "./bas-table-render-cell";
import { Workbook } from "exceljs";
import { exportDataGrid } from "devextreme/excel_exporter";
import { saveAs } from "file-saver-es";
import { formatDateToISO } from "../../../../../utils/format-date";
import { BASConfigureReportButton } from "../bas-configure-report-button";
import GridDownloadExcelPdfButtons from "../../../../../components/GridDownloadExcelPdfButtons/GridDownloadExcelPdfButtons";

const getColumnWidth = (column: BASColumn) => {
  if (column.RpcHeader === "BAS Label") return 400;
  return column.RpcMaxWidth;
};

interface Props {
  data: BASDataDT[] | undefined;
  columns: BASColumn[] | undefined;
  endDate: any;
  onRefresh: () => void;
  onModalOpen: () => void;
}

export const BASTable = React.memo(
  ({ data, columns = [], onRefresh, endDate, onModalOpen }: Props) => {
    const { isDarkMode } = useTheme();
    const dataGridRef = useRef<any>();
    const [isPDFDialogOpen, setIsPDFDialogOpen] = useState(false);
    const [isExcelDialogOpen, setIsExcelDialogOpen] = useState(false);

    const handleOnExporting = async () => {
      try {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("Main sheet");

        await exportDataGrid({
          component: dataGridRef?.current?.instance,
          worksheet,
          autoFilterEnabled: true,
        });

        const buffer = await workbook.xlsx.writeBuffer();
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          `BAS ${formatDateToISO(endDate.toDate())}.xlsx`,
        );
      } catch (e) {
        console.error(
          "Error exporting Business Activity Statement to Excel: ",
          e,
        );
      }
    };

    if (!data)
      return (
        <Stack
          className=""
          height="100%"
          py={2}
          alignItems="center"
          justifyContent="center"
          sx={{
            border: "1px solid rgba(224, 224, 224, 1)",
            borderRadius: "8px",
            color: isDarkMode ? "#FFF" : "rgba(0, 0, 0, 0.87)",
            fontFamily: "Inter",
            fontWeight: 400,
            lineHeight: 1.43,
            boxShadow: "0px 0px 10px 0px rgba(69, 90, 100, 0.10)",
            marginTop: "100px",
          }}
        >
          <span>Apply filters to view data</span>
        </Stack>
      );

    return (
      <div
        style={{
          width: "100%",
          marginTop: 50,
          boxShadow: "0px 0px 10px 0px rgba(69, 90, 100, 0.10)",
          paddingTop: 5,
        }}
      >
        <BASDownloadAsPDFDialog
          isOpen={isPDFDialogOpen}
          onClose={() => setIsPDFDialogOpen(false)}
        />
        <BASDownloadAsExcelDialog
          isOpen={isExcelDialogOpen}
          onExporting={handleOnExporting}
          onClose={() => setIsExcelDialogOpen(false)}
        />

        <Box
          sx={{
            transition: "all 1s ease",
            position: "relative",
            // reseting padding on the td elements
            // didn't find a better way to do this
            // - will control padding from the Cell level
            ".dx-row td[role='gridcell']": {
              padding: 0,
            },
            ".dx-datagrid .dx-row-alt > td, .dx-datagrid .dx-row-alt > tr > td": {
              backgroundColor: isDarkMode ? "#1E2432" : "#FAFAFA",
            },
          }}
        >
          <DataGrid
            rowAlternationEnabled={true}
            ref={dataGridRef}
            dataSource={data}
            showBorders={false}
            style={{
              border: "none",
              marginTop: "10px",
              marginBottom: "100px",
            }}
            hoverStateEnabled={true}
            showColumnHeaders={true}
            className="bas-table-datagrid-override"
          >
            <Toolbar>
              <Item location="before">
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    width: "100%",
                    gap: "8px",
                    px: "8px",
                  }}
                >
                  <BASConfigureReportButton onClick={onModalOpen} />
                  <RefreshButton title={"Refresh"} onClick={onRefresh} />


                  <GridDownloadExcelPdfButtons
                    downloadExcel={() => setIsExcelDialogOpen(true)}
                    downloadPDF={() => setIsPDFDialogOpen(true)}
                    displayDownloadPDF={true}
                  />
                </Box>
              </Item>
            </Toolbar>

            <Sorting mode="none" />

            {columns?.length &&
              columns.map((column, index) => (
                <Column
                  key={index}
                  dataField={column.RpcDataSource}
                  caption={column.RpcHeader}
                  allowResizing={YNToBool(column.RpcAllowResizing)}
                  minWidth={column.RpcMinWidth}
                  width={getColumnWidth(column)}
                  allowFiltering={YNToBool(column.RpcFilter)}
                  alignment={getAlignment(column.RpcAlign)}
                  allowSearch={YNToBool(column.RpcSearchable)}
                  allowSorting={YNToBool(column.RpcSort)}
                  cellRender={(cellInfo) =>
                    basTableRenderCell(cellInfo, column)
                  }
                  visible={YNToBool(column.RpcVisible)}
                  fixed={YNToBool(column.RpcFreeze)}
                />
              ))}
            {/* Empty column just for the visual */}
            <Column
              allowResizing={false}
              allowFiltering={false}
              alignment={"center"}
              allowSearch={false}
              allowSorting={false}
              cellRender={(cellInfo) => basTableRenderCell(cellInfo)}
            />
          </DataGrid>
        </Box>
      </div>
    );
  },
);
