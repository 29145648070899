import React, { useEffect, useState } from "react";
import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Checkbox,
  Typography,
  FormLabel,
  TextField,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import {
  addPeriod,
  subtractPeriod,
  formatDateHelper,
  isValidDate,
} from "../../../utils/format-date";
import { DatePicker } from "@mui/x-date-pickers-pro";

const NewPayEventSettings = ({
  listOfPayEventGroups,
  listOfPayEventPeriods,
  selectedGroup,
  setSelectedGroup,
  selectedPeriod,
  setSelectedPeriod,
  paymentDate,
  setPaymentDate,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  customDates,
  setCustomDates,
}) => {
  const [lastChangedField, setLastChangedField] = useState("start");

  const synchronizeEndDate = (newStartDate, period) => {
    if (newStartDate && period && isValidDate(newStartDate)) {
      const calculatedEndDate = addPeriod(
        dayjs(newStartDate, "DD/MM/YYYY").toISOString(),
        period,
      );
      setEndDate(calculatedEndDate);
    }
  };

  const synchronizeStartDate = (newEndDate, period) => {
    if (newEndDate && period && isValidDate(newEndDate)) {
      const calculatedStartDate = subtractPeriod(
        dayjs(newEndDate, "DD/MM/YYYY").toISOString(),
        period,
      );
      setStartDate(calculatedStartDate);
    }
  };

  const handleGroupChange = (e) => {
    const selectedGroupID = e.target.value;
    const selectedGroupObj = listOfPayEventGroups.find(
      (group) => group.prcID.toString() === selectedGroupID,
    );
    setSelectedGroup(selectedGroupID);
    if (selectedGroupObj?.prcPeriod) {
      setSelectedPeriod(selectedGroupObj.prcPeriod);
    } else {
      setSelectedPeriod("");
    }
  };

  useEffect(() => {
    if (!customDates && lastChangedField) {
      if (lastChangedField === "start" && selectedPeriod) {
        synchronizeEndDate(startDate, selectedPeriod);
      } else if (lastChangedField === "end" && selectedPeriod) {
        synchronizeStartDate(endDate, selectedPeriod);
      } else if (lastChangedField === "period" && startDate) {
        synchronizeEndDate(startDate, selectedPeriod);
      }
      setLastChangedField(null);
    }
  }, [startDate, endDate, selectedPeriod, customDates, lastChangedField]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
      <Box sx={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
        <Box>
          <FormLabel variant="subtitle1">Group</FormLabel>
          <RadioGroup value={selectedGroup} onChange={handleGroupChange}>
            {listOfPayEventGroups.map((group) => (
              <FormControlLabel
                key={group.prcID}
                value={group.prcID.toString()}
                control={<Radio />}
                label={group.prcName}
              />
            ))}
          </RadioGroup>
        </Box>

        <Box>
          <Typography variant="subtitle1">Period</Typography>
          <RadioGroup
            value={selectedPeriod}
            onChange={(e) => {
              setSelectedPeriod(e.target.value);
              setLastChangedField("period");
            }}
          >
            {listOfPayEventPeriods.map((period) => (
              <FormControlLabel
                key={period.Value}
                value={period.Value}
                control={<Radio />}
                label={period.Display}
              />
            ))}
          </RadioGroup>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            width: "300px",
          }}
        >
          <DatePicker
            label="Payment Date"
            value={paymentDate ? dayjs(paymentDate, "DD/MM/YYYY") : null}
            onChange={(newValue) =>
              setPaymentDate(
                newValue ? dayjs(newValue).format("DD/MM/YYYY") : null,
              )
            }
            format="DD/MM/YYYY"
            slotProps={{ textField: { fullWidth: true } }}
          />
          <DatePicker
            label="Start Date"
            value={startDate ? dayjs(startDate, "DD/MM/YYYY") : null}
            onChange={(newValue) => {
              setStartDate(
                newValue ? dayjs(newValue).format("DD/MM/YYYY") : null,
              );

              if (!customDates) setLastChangedField("start");
            }}
            format="DD/MM/YYYY"
            slotProps={{ textField: { fullWidth: true } }}
          />
          <DatePicker
            label="End Date"
            value={endDate ? dayjs(endDate, "DD/MM/YYYY") : null}
            onChange={(newValue) => {
              setEndDate(
                newValue ? dayjs(newValue).format("DD/MM/YYYY") : null,
              );
              if (!customDates) setLastChangedField("end");
            }}
            format="DD/MM/YYYY"
            slotProps={{ textField: { fullWidth: true } }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={customDates}
                onChange={(e) => setCustomDates(e.target.checked)}
              />
            }
            label="Unlock Dates"
          />
        </Box>
      </Box>
    </LocalizationProvider>
  );
};

export default NewPayEventSettings;
