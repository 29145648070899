import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

interface ImportSuccessModalProps {
  isModalOpen: boolean;
  onClose: () => void;
  totalTransactionsCounter: number;
  journalsCreatedCounter: number;
  requiredAttentionCounter: number;
  selectedAccountNo: string | number;
}

export const ImportSuccessModal = ({
  isModalOpen,
  onClose,
  totalTransactionsCounter,
  journalsCreatedCounter,
  requiredAttentionCounter,
  selectedAccountNo,
}: ImportSuccessModalProps) => {
  const { dbId: dbFromParam } = useParams();
  const navigate = useNavigate();

  console.log("selectedAccountNo: ", selectedAccountNo);

  return (
    <Dialog
      open={isModalOpen}
      onClose={onClose}
      sx={{
        ">div>div": { maxWidth: "xl" },
      }}
    >
      <DialogTitle textAlign={"center"}>Import successful</DialogTitle>
      <DialogContent sx={{ overflow: "visible" }}>
        <Typography>
          Total transactions imported: {totalTransactionsCounter}
          <br />
          Transactions posted to Accounts: {journalsCreatedCounter}
          <br />
          Transactions requiring further information: {requiredAttentionCounter}
        </Typography>
        <DialogActions sx={{ pt: 0, mt: 5 }}>
          <Button
            onClick={() => {
              if (requiredAttentionCounter > 0) {
                navigate(
                  `../${dbFromParam}/en-au/books/process-feeds?accNo=${selectedAccountNo}`,
                );
              } else {
                navigate(`../${dbFromParam}/en-au/mobile/dashboard`);
              }
            }}
            fullWidth
            // @ts-ignore
            variant="outlined"
          >
            Ok
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
