import React from "react";
import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { IMPORT_TRANS_CSV_MAX_HEADER_COL_WIDTH } from "../constants";

interface SelectColumnHeaderProps {
  value: string;
  listOfHeaders: string[];
  onHeaderSelect: (event: SelectChangeEvent<string>) => void; //TODO: fix type
}

export const SelectColumnHeader = ({
  value,
  listOfHeaders,
  onHeaderSelect,
}: SelectColumnHeaderProps) => {
  return (
    <Select
      labelId="column-header-selection"
      value={value}
      onChange={onHeaderSelect}
      fullWidth
      sx={{ maxWidth: IMPORT_TRANS_CSV_MAX_HEADER_COL_WIDTH }}
    >
      {listOfHeaders.map((header) => (
        <MenuItem key={header} sx={{ fontSize: "12px" }} value={header}>
          {header}
        </MenuItem>
      ))}
    </Select>
  );
};
