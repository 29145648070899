import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  Typography,
  TextField,
  Autocomplete,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import useApi from "../../hooks/useApi";
import envConfig from "../../config";
import { useSnackbar } from "notistack";
import BackdropLoading from "../../components/BackdropLoading/BackdropLoading";

const CreateDepartment = () => {
  const { apiCall } = useApi();
  const navigate = useNavigate();
  const { dbId, lang } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  // State variables
  const [loading, setLoading] = useState(true);
  const [sites, setSites] = useState([]);
  const [managers, setManagers] = useState([]);

  // Form fields
  const [dptName, setDptName] = useState("");
  const [dptCode, setDptCode] = useState("");
  const [dptHeadUsrID, setDptHeadUsrID] = useState(null);
  const [dptActive, setDptActive] = useState("Y");
  const [dptNotes, setDptNotes] = useState("");
  const [dptDefSiteDpsID, setDptDefSiteDpsID] = useState(null);

  // Fetch lists for Autocomplete fields
  const fetchInitialData = useCallback(async () => {
    setLoading(true);
    try {
      await apiCall({
        url: `https://${envConfig.apiDev1Exacc}/api/v1/en-au/departments/get-list-of-departments-async`,
        method: "GET",
        onSuccess: (data) => {
          if (data.SuccessYN !== "Y") {
            enqueueSnackbar(data.ErrorMessage || "Failed to fetch data.", {
              variant: "error",
            });
            return;
          }

          setSites(data.ListOfSites || []);
          setManagers(data.ListOfManagers || []);
        },
        onError: (error) => {
          enqueueSnackbar(error, { variant: "error" });
        },
      });
    } catch (error) {
      console.error("Error fetching initial data:", error);
      enqueueSnackbar("An unexpected error occurred.", { variant: "error" });
    } finally {
      setLoading(false);
    }
  }, [apiCall, enqueueSnackbar]);

  useEffect(() => {
    fetchInitialData();
  }, [fetchInitialData]);

  // Handle form submission
  const handleSave = async () => {
    // Basic validation
    if (!dptName.trim() || !dptCode.trim()) {
      enqueueSnackbar("Department Name and Code are required.", {
        variant: "warning",
      });
      return;
    }

    setLoading(true);
    try {
      const payload = {
        dptName: dptName.trim(),
        dptCode: dptCode.trim(),
        dptHead_usrID: dptHeadUsrID,
        dptActive,
        // dptNotes: dptNotes.trim(),
        // dptDefSite_dpsID: sites?.length > 0 ? sites[0] : null,
      };

      await apiCall({
        url: `https://${envConfig.apiDev1Exacc}/api/v1/en-au/departments/insert-async`,
        method: "POST",
        body: payload,
        onSuccess: (data) => {
          if (data.SuccessYN === "Y") {
            enqueueSnackbar("Department created successfully.", {
              variant: "success",
            });
            navigate(`/${dbId}/${lang}/site/${data.DepartmentId}`);
          } else {
            enqueueSnackbar(
              data.ErrorMessage || "Failed to create department.",
              { variant: "error" },
            );
          }
        },
        onError: (error) => {
          enqueueSnackbar(error, { variant: "error" });
        },
      });
    } catch (error) {
      console.error("Error creating department:", error);
      enqueueSnackbar("An unexpected error occurred.", { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ padding: 2, maxWidth: 800, margin: "0 auto" }}>
      {/* Loading Backdrop */}
      <BackdropLoading open={loading} />

      {/* Page Title */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 3,
        }}
      >
        <Typography variant="h4">Create New Department</Typography>
        <Box>
          <Button
            variant="outlined"
            onClick={() => navigate(`/${dbId}/${lang}/departments`)}
            sx={{ mr: 2 }}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            disabled={loading}
          >
            Save
          </Button>
        </Box>
      </Box>

      {/* Form */}
      <Box
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
        }}
        noValidate
        autoComplete="off"
      >
        {/* Department Name */}
        <TextField
          label="Department Name"
          value={dptName}
          onChange={(e) => setDptName(e.target.value)}
          required
        />

        {/* Department Code */}
        <TextField
          label="Department Code"
          value={dptCode}
          onChange={(e) => setDptCode(e.target.value)}
          required
        />

        {/* Department Head */}
        <Autocomplete
          options={managers}
          getOptionLabel={(option) => `${option.usrName}`}
          value={
            managers.find((manager) => manager.usrID === dptHeadUsrID) || null
          }
          onChange={(event, newValue) =>
            setDptHeadUsrID(newValue ? newValue.usrID : null)
          }
          renderInput={(params) => (
            <TextField {...params} label="Department Head" />
          )}
          isOptionEqualToValue={(option, value) => option.usrID === value.usrID}
        />

        {/* Active Status */}
        <FormControl component="fieldset">
          <FormLabel component="legend">Active Status</FormLabel>
          <RadioGroup
            row
            value={dptActive}
            onChange={(e) => setDptActive(e.target.value)}
          >
            <FormControlLabel value="Y" control={<Radio />} label="Yes" />
            <FormControlLabel value="N" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>

        {/* Department Notes */}
        <TextField
          label="Department Notes"
          value={dptNotes}
          onChange={(e) => setDptNotes(e.target.value)}
          multiline
          rows={4}
        />

        {/* Default Site */}
        {/* <Autocomplete
          options={sites}
          getOptionLabel={(option) => `${option.dpsName} (${option.dpsCode})`}
          value={sites.find((site) => site.dpsID === dptDefSiteDpsID) || null}
          onChange={(event, newValue) =>
            setDptDefSiteDpsID(newValue ? newValue.dpsID : null)
          }
          renderInput={(params) => (
            <TextField {...params} label="Default Site" />
          )}
          isOptionEqualToValue={(option, value) => option.dpsID === value.dpsID}
        /> */}
      </Box>
    </Box>
  );
};

export default CreateDepartment;
