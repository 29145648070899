import React from "react";
import { Box, Button, Typography } from "@mui/material";

interface ImportTransactionsFromCSVPanelImportingProps {
  onImport: () => void;
  onCancelImport: () => void;
  accountSelected: string;
  selectedRows: number;
  totalRows: number;
  numberOfInvalidRecords: number;
}

export const ImportTransactionsFromCSVPanelImporting = ({
  onImport,
  onCancelImport,
  accountSelected,
  selectedRows,
  totalRows,
  numberOfInvalidRecords,
}: ImportTransactionsFromCSVPanelImportingProps) => {
  return (
    <Box
      display={"flex"}
      flexDirection={"row"}
      marginBottom={1}
      padding={3}
      boxShadow="0px 0px 10px 0px rgba(69, 90, 100, 0.10)"
      borderRadius={1}
      marginY={2}
    >
      <Box
        display={"flex"}
        flexDirection={"column"}
        gap={2}
        sx={{ width: "100%" }}
      >
        <Box display="flex" flexDirection="row" gap={2}>
          <Button
            onClick={onImport}
            // @ts-ignore
            variant="outlined"
            sx={{ flex: 2, maxWidth: 250 }}
          >
            Import to Bank Entries
          </Button>
          <Button
            onClick={onCancelImport}
            // @ts-ignore
            variant="outlined"
            sx={{ flex: 1, maxWidth: 200 }}
          >
            Cancel Import
          </Button>
          <Typography
            sx={{
              fontSize: 16,
            }}
          >
            Account Selected: {accountSelected}.{" "}
            {numberOfInvalidRecords > 0 && (
              <span>{` Uploaded CSV had ${numberOfInvalidRecords} invalid records.`}</span>
            )}
            <br />
            Rows selected {selectedRows} of {totalRows}.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
