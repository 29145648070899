import React, { useState } from "react";
import { Box, Tabs, Tab } from "@mui/material";
import UserSelfService from "./UserSelfService";
import EmployeeSelfService from "./EmployeeSelfService";

const MyDetails = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const tabLabels = ["Main details", "Employment"];

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Box>
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        aria-label="My Details Tabs"
        variant="scrollable"
        scrollButtons="auto"
      >
        {tabLabels.map((label, index) => (
          <Tab disableRipple key={label} label={label} />
        ))}
      </Tabs>
      {/* Tab Content */}
      {selectedTab === 0 && <UserSelfService />}
      {selectedTab === 1 && <EmployeeSelfService />}
    </Box>
  );
};

export default MyDetails;
