import React, { useState } from 'react';
import { 
  Box, 
  Button, 
  Divider, 
  Stack, 
  Radio, 
  RadioGroup, 
  FormControlLabel, 
  FormControl, 
  FormLabel 
} from '@mui/material';
import { Dayjs } from 'dayjs';
import { TimeFrame, Period } from './types';

interface ShortcutsBarProps {
  onPeriodSelect: (timeFrame: TimeFrame, period: Period) => void;
  startDate: Dayjs | null;
  endDate: Dayjs | null;
}

export const ShortcutsBar: React.FC<ShortcutsBarProps> = ({
  onPeriodSelect,
  startDate,
  endDate,
}) => {
  const [selectedPeriod, setSelectedPeriod] = useState<Period | null>(null);

  const handlePeriodChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedPeriod(event.target.value as Period);
  };

  const timeFrames: TimeFrame[] = ['Week', 'Month', 'Quarter', 'Year'];

  return (
    <Box
      sx={{
        display: 'flex',
        p: 1,
        borderTop: '1px solid',
        borderColor: 'divider',
        width: '100%',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >      
    <Box sx={{
        display: 'flex',
        flexDirection: 'column'
    }}>
      <FormControl component="fieldset" sx={{ mb: 1, width: '100%' }}>
        <FormLabel component="legend">Select Period:</FormLabel>
        <RadioGroup
          
          value={selectedPeriod}
          onChange={handlePeriodChange}
          aria-label="period"
          name="period"
        >
          <FormControlLabel value="Current" control={<Radio />} label="Current" />
          <FormControlLabel value="CurrentToDate" control={<Radio />} label="Current-to-Date" />
          <FormControlLabel value="Previous" control={<Radio />} label="Previous" />
        </RadioGroup>
      </FormControl>

      {/* TimeFrame Buttons */}
      <Stack spacing={1} direction="column" sx={{ width: '100%', justifyContent: 'space-between' }}>
        {timeFrames.map((timeFrame) => (
          <Button
            key={timeFrame}
            onClick={() => onPeriodSelect(timeFrame, selectedPeriod!)}
            variant="contained"
            size="small"
            fullWidth
            disabled={!selectedPeriod}
          >
            {timeFrame}
          </Button>
        ))}
      </Stack>
       {/* Today Button */}
       {/* <Button
        onClick={onSetToday}
        size="medium"
        variant='outlined'
        sx={{position: 'absolute', right: 8, bottom: 8}}
      >
        Today
      </Button> */}
      </Box>
    </Box>
  );
};
