import { useEffect, useState } from "react";
import { getCookie } from "../../utils/api";

import { LicenseInfo } from "@mui/x-license-pro";
import { ReactComponent as PlusIcon } from "../../assets/plus-in-circle.svg";
import { ReactComponent as DownloadIcon } from "../../assets/download.svg";
import { ReactComponent as RefreshIcon } from "../../assets/refresh.svg";
import { ReactComponent as FullscreenIcon } from "../../assets/full-size.svg";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
  useMediaQuery,
} from "@mui/material";
import { commonThemes, darkTheme, lightTheme } from "../../utils/themes/themes";
import { useTheme } from "../../context/ThemeContext";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import numeral from "numeral";
import { createSessionForExternal, downloadFile } from "../../utils/reusable";
import { GridCloseIcon } from "@mui/x-data-grid-pro";
import { DataGridPremium, useGridApiRef } from "@mui/x-data-grid-premium";
import envConfig from "../../config";
import useApi from "../../hooks/useApi";
import GridDownloadExcelPdfButtons from "../../components/GridDownloadExcelPdfButtons/GridDownloadExcelPdfButtons";

LicenseInfo.setLicenseKey(
  "d4fd116dcdd62ee808f5368ad91259faTz03ODUxNCxFPTE3MzEyNzMxNTcwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y",
);

const ProfitLossPerGroupTable = ({
  loading,
  gridData,
  onApply = () => {},
  showCashMethod,
  generatePDF = () => {},
  sortBy,
  setSortBy = () => {},
  showDownloadDate,
  setShowDownloadDate = () => {},
  showAccountsNumbers,
  openFullscreen,
  setOpenFullscreen,
}) => {
  const { apiCall } = useApi();
  const { dbId } = useParams();
  const location = useLocation();

  const urlparams = new URLSearchParams(location.search);

  const apiRef = useGridApiRef();
  const navigate = useNavigate();
  const isScreenBelow1050px = useMediaQuery("(max-width: 1050px)");

  const isMobilePhone = useMediaQuery("(max-width: 847px)");
  const gridButtonsMedia = useMediaQuery("(max-width: 525px)");
  const { isDarkMode } = useTheme();
  const theme = isDarkMode ? darkTheme : lightTheme;

  const [open, setOpen] = useState(false);
  const [format, setFormat] = useState("");
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loadingPDF, setLoadingPDF] = useState(false);

  const [initialState, setInitialState] = useState({});
  const [columnsConfig, setColumnsConfig] = useState({});
  const [ip, setIp] = useState();

  const [anchorEl, setAnchorEl] = useState(null);
  const [contextMenuData, setContextMenuData] = useState(null);

  const handleContextMenu = (event, rowData) => {
    event.preventDefault();
    setContextMenuData(rowData);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseContextMenu = () => {
    setAnchorEl(null);
    setContextMenuData(null);
  };

  const handleOpenInNewTab = () => {
    const { accNo, startDate, endDate, cashMethodYN } = contextMenuData;

    createSessionForExternal(
      `https://${envConfig.mainServiceUrl}/${dbId}/en-au/reports/general-ledger?accNo=${accNo}&startDate=${urlparams.get("startDate")}&endDate=${urlparams.get("endDate")}&cashMethodYN=${cashMethodYN}`,
      ip,
      true,
      apiCall,
      dbId,
    );
    handleCloseContextMenu();
  };

  useEffect(() => {
    const fetchData = async () => {
      const responseIp = await fetch("https://api.ipify.org?format=json");
      const dataIp = await responseIp.json();
      setIp(dataIp?.ip);
    };
    if (!ip) fetchData();
  }, []);

  useEffect(() => {
    if (gridData?.DataDT) {
      setRows(
        gridData.DataDT.map((row, index) => ({
          id: index,
          ...row,
        })),
      );
    } else {
      setRows([]);
    }
    if (gridData?.ColumnsList && gridData?.DataDT) {
      const cols = gridData.ColumnsList.map((column) => {
        return {
          field: column.RpcDataSource,
          headerName: column.RpcHeader,
          headerClassName:
            column.RpcAlign === 1
              ? "header-align-left grid-header-title "
              : column.rpcAlign === 2
                ? "header-align-center grid-header-title "
                : "header-align-right grid-header-title ",
          maxWidth:
            column.RpcDataSource === "accNo"
              ? 90
              : column.RpcHeader === "Account"
                ? 280
                : 155,
          minWidth:
            column.RpcDataSource === "accNo"
              ? 90
              : column.RpcHeader === "Account"
                ? 280
                : 155,
          align:
            column.RpcAlign === 1
              ? "left"
              : column.RpcAlign === 2
                ? "center"
                : "right",
          sortable: false,
          wrapText: column.RpcWrapText === "Y",
          resizable: false,
          disableColumnMenu: true,
          flex: 1,
          renderCell: (params) => {
            const accNo = params.row.accNo;
            const rpcDataSource = column.RpcDataSource;
            const startDate = rpcDataSource.slice(1, 7);
            const endDate = rpcDataSource.slice(8, 14);
            const formattedStartDate = `${startDate.slice(4)}/${startDate.slice(
              2,
              4,
            )}/${startDate.slice(0, 2)}`;
            const formattedEndDate = `${endDate.slice(4)}/${endDate.slice(
              2,
              4,
            )}/${endDate.slice(0, 2)}`;

            const cashMethodYN = showCashMethod ? "Y" : "N";
            if (rpcDataSource === "AccountName") {
              if (showAccountsNumbers && accNo && typeof accNo === "number") {
                return <div>{params.row.accNo + " " + params.value}</div>;
              }
              return <div>{params.value}</div>;
            }

            if (rpcDataSource === "accNo") {
              return accNo && typeof accNo === "number" ? (
                <Link
                  style={{
                    color: "inherit",
                    textDecoration: "inherit",
                    cursor: "pointer",
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                  // onClick={(e) => {
                  //   e.preventDefault();
                  //   navigate(
                  //     `/${getCookie(
                  //       "databaseId",
                  //     )}/en-au/reports/general-ledger?accNo=${accNo}&startDate=${formattedStartDate}&endDate=${formattedEndDate}&cashMethodYN=${cashMethodYN}`,
                  //     ip,
                  //     false,
                  //     apiCall,
                  //   );
                  // }}
                  onContextMenu={(e) =>
                    handleContextMenu(e, {
                      accNo,
                      startDate: formattedStartDate,
                      endDate: formattedEndDate,
                      cashMethodYN,
                    })
                  }
                  to={`/${dbId}/en-au/reports/general-ledger?accNo=${accNo}&startDate=${urlparams.get("startDate")}&endDate=${urlparams.get("endDate")}&cashMethodYN=${cashMethodYN}`}
                >
                  {params.value}
                </Link>
              ) : (
                <div>{params.value || ""}</div>
              );
            }

            return (
              <>
                {accNo && typeof params.value === "number" ? (
                  <Link
                    style={{
                      color: "inherit",
                      textDecoration: "inherit",
                      cursor: "pointer",
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                    // onClick={(e) => {
                    //   e.preventDefault();
                    //   navigate(
                    //     `/${getCookie(
                    //       "databaseId",
                    //     )}/en-au/reports/general-ledger?accNo=${accNo}&startDate=${formattedStartDate}&endDate=${formattedEndDate}&cashMethodYN=${cashMethodYN}`,
                    //     ip,
                    //     false,
                    //     apiCall,
                    //   );
                    // }}
                    onContextMenu={(e) =>
                      handleContextMenu(e, {
                        accNo,
                        startDate: formattedStartDate,
                        endDate: formattedEndDate,
                        cashMethodYN,
                      })
                    }
                    to={`/${dbId}/en-au/reports/general-ledger?accNo=${accNo}&startDate=${urlparams.get("startDate")}&endDate=${urlparams.get("endDate")}&cashMethodYN=${cashMethodYN}`}
                  >
                    {typeof params.value === "number"
                      ? params.value < 0
                        ? `(${numeral(Math.abs(params.value)).format(
                            "0,0.00",
                          )})`
                        : params.value === 0 || params.value === "0"
                          ? "-"
                          : `${numeral(Math.abs(params.value)).format("0,0.00")}`
                      : params.value}
                  </Link>
                ) : (
                  <div>
                    {typeof params.value === "number"
                      ? params.value < 0
                        ? `(${numeral(Math.abs(params.value)).format(
                            "0,0.00",
                          )})`
                        : params.value === 0 || params.value === "0"
                          ? "-"
                          : `${numeral(Math.abs(params.value)).format("0,0.00")}`
                      : params.value}
                  </div>
                )}
              </>
            );
          },
        };
      });
      setColumns(cols);
    } else {
      setColumns([]);
    }
  }, [gridData, showAccountsNumbers]);

  const handleClickOpen = (format) => {
    setFormat(format);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDownloadExcel = async () => {
    apiRef.current.exportDataAsExcel({
      fields: [
        ...columns
          .map((col) => {
            if (col.field !== "RowNo" && col.field !== "RowType") {
              return col.field;
            }
            return;
          })
          .filter((bln) => !!bln),
      ],
    });

    handleClose();
  };

  useEffect(() => {
    setInitialState(
      isMobilePhone
        ? {
            pinnedColumns: {
              left: ["accNo"],
            },
          }
        : {
            pinnedColumns: {
              left: ["AccountName"],
              right: ["add_earlier_period"],
            },
          },
    );

    setColumnsConfig(
      isMobilePhone
        ? {
            RowNo: false,
            RowType: false,
            AccountName: false,
          }
        : {
            RowNo: false,
            RowType: false,
            accNo: false,
          },
    );
  }, [isMobilePhone]);

  return (
    <div style={{ width: "100%", marginBottom: 100, marginTop: 86 }}>
      {/* <FullscreenPNL
        openFullscreen={openFullscreen}
        setOpenFullscreen={setOpenFullscreen}
        rows={rows}
        columns={columns}
        showDownloadDate={showDownloadDate}
        loading={loading}
        gridData={gridData}
        isMobilePhone={isMobilePhone}
      /> */}

      <Dialog sx={{ ">div>div": { p: 4 } }} open={open} onClose={handleClose}>
        <DialogTitle
          sx={{ ...commonThemes.popupTitle, p: 0 }}
        >{`Download to ${format}`}</DialogTitle>
        <DialogContent sx={{ p: 0 }}>
          <Box sx={{ mb: 2, mt: 2 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!!showDownloadDate}
                  onChange={() => setShowDownloadDate(!showDownloadDate)}
                  id="showDownloadDate"
                />
              }
              label="Show Download Date"
            />
          </Box>
          {format === "PDF" && (
            <FormControl component="fieldset">
              <FormLabel component="legend">Sort By</FormLabel>
              <RadioGroup
                aria-label="sort by"
                value={sortBy}
                sx={{ display: "flex", flexDirection: "row", mt: -0.5 }}
                onChange={(event) => setSortBy(event.target.value)}
              >
                <FormControlLabel
                  value="Account Number"
                  control={<Radio />}
                  label="Account Number"
                />
                <FormControlLabel
                  value="Account Name"
                  control={<Radio />}
                  label="Account Name"
                />
              </RadioGroup>
            </FormControl>
          )}
        </DialogContent>
        <DialogActions sx={{ p: 0, mt: "30px" }}>
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            disabled={loading || loadingPDF}
            onClick={async () => {
              setLoadingPDF(true);
              await generatePDF();
              setLoadingPDF(false);

              handleClose();
            }}
          >
            Download Report
          </Button>
        </DialogActions>
      </Dialog>
      <Box
        sx={{
          transition: "all 1s ease",
          width: openFullscreen ? "calc(100vw - 8px)" : "100%",
          position: "relative",
          left: openFullscreen ? "50%" : 0,
          right: openFullscreen ? "50%" : 0,
          marginLeft: openFullscreen ? "-50vw" : 0,
          marginRight: openFullscreen ? "-50vw" : 0,
          paddingLeft: openFullscreen ? "10px" : 0,
          paddingRight: openFullscreen ? "10px" : 0,
          ".MuiBox-root > .MuiDataGrid-root": {
            marginTop: 0,
          },
        }}
      >
        {columns?.length > 0 ? (
          <DataGridPremium
            disableRowSelectionOnClick={true}
            loading={loading}
            apiRef={apiRef}
            getCellClassName={() => "column-border"}
            slots={{
              toolbar: () => (
                <Box
                  className="print-hide"
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    marginBottom: "1rem",
                    alignItems: "center",
                    marginRight: "16px",
                    marginBottom: 0,
                    position: "absolute",
                    top: gridButtonsMedia ? "-60px" : "-47px",
                    width: "100%",
                  }}
                >
                  <Box
                    onClick={() => !loading && onApply()}
                    className="print-hide"
                    sx={{
                      marginLeft: isScreenBelow1050px ? "0" : "16px",
                      color: theme.palette.primary.main,
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      fontWeight: 700,
                      pr: "2px",
                      mr: isMobilePhone ? 0 : 5,
                    }}
                  >
                    <RefreshIcon style={{ marginRight: "8px" }} />
                    Refresh
                  </Box>
                  <GridDownloadExcelPdfButtons
                    downloadExcel={handleDownloadExcel}
                    downloadPDF={() => handleClickOpen("PDF")}
                    displayDownloadPDF={
                      gridData?.ColumnsList?.length === 5 ||
                      gridData?.ColumnsList?.length === 6
                    }
                  />
                  <Box
                    sx={{
                      flex: 1,
                      textAlign: "right",
                    }}
                  >
                    <Button
                      className="print-hide"
                      style={{ fontWeight: 700 }}
                      onClick={() => setOpenFullscreen((prev) => !prev)}
                    >
                      <FullscreenIcon style={{ marginRight: "8px" }} />
                      Full Screen
                    </Button>
                  </Box>
                </Box>
              ),
            }}
            sx={{
              ".MuiDataGrid-main": {
                // maxWidth: "calc(100% - 230px)",
              },
              "div.MuiDataGrid-virtualScroller": {
                // maxWidth: "calc(100% - 230px)",
                // overflowY: "auto",
                // maxHeight: "80vh",
                paddingBottom: "30px",
                minWidth: "100%",
                minHeight: columns?.length === 0 ? "50px" : "unset",
                paddingTop: columns?.length === 0 ? "25px" : 0,
                // ">div": {
                //   height: "401px !important",
                // },
              },
              "div.MuiDataGrid-columnHeaders": {
                // maxWidth: columns?.length > 0 ? "calc(100% - 230px)" : "unset",
                display: columns?.length > 0 ? "flex" : "none",
              },
              "@media print": {
                "@page": { margin: showDownloadDate ? "auto" : 0 },
                body: { margin: showDownloadDate ? "auto" : "1.6cm" },
                ".print-hide": {
                  display: "none",
                },
                ".MuiDataGrid-main": {
                  width: "max-content",
                  height: "max-content",
                  // overflow: "visible",
                  color: "black !important",
                },
              },
              boxShadow: "0px 0px 10px 0px rgba(69, 90, 100, 0.10)",
              border: "none",
              borderRadius: "8px",
              mt: "10px",
              "& .MuiDataGrid-cell:focus": {
                outline: "none",
              },
              ".MuiDataGrid-columnHeader:focus": {
                outline: "none",
              },
              "& .MuiDataGrid-cell:focus-within": {
                outline: "none",
              },
              ".MuiDataGrid-columnSeparator": {
                display: "none !important",
              },
            }}
            rowHeight={40}
            rows={rows}
            columns={columns}
            hideFooter={true}
            // autoHeight={true}

            columnVisibilityModel={columnsConfig}
            initialState={initialState}
            getRowClassName={(params) => {
              switch (params.row.RowType) {
                case "H":
                  return "bold";
                case "D":
                  return `indent ${
                    isDarkMode
                      ? "underline-thicker-dark"
                      : "underline-thicker-light"
                  }`;
                case "F":
                  return "bold underline";
                case "S":
                  return "spaceholder";
                default:
                  return "";
              }
            }}
          />
        ) : (
          <Stack
            className=""
            height="100%"
            py={2}
            alignItems="center"
            justifyContent="center"
            sx={{
              border: "1px solid rgba(224, 224, 224, 1)",
              borderRadius: "8px",
              color: isDarkMode ? "#FFF" : "rgba(0, 0, 0, 0.87)",
              fontFamily: "Inter",
              fontWeight: 400,
              lineHeight: 1.43,
              boxShadow: "0px 0px 10px 0px rgba(69, 90, 100, 0.10)",
              marginTop: "10px",
            }}
          >
            <span>Apply filters to view data</span>
          </Stack>
        )}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseContextMenu}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <MenuItem onClick={handleOpenInNewTab}>Open in new tab</MenuItem>
        </Menu>
      </Box>
    </div>
  );
};

export default ProfitLossPerGroupTable;
