import React, { forwardRef } from "react";
import DataGrid, {
  Column,
  Scrolling,
  Selection,
} from "devextreme-react/cjs/data-grid";
import { SelectColumnHeader } from "./import-transactions-from-csv-table-header-cell";
import { Box } from "@mui/material";
import {
  IMPORT_TRANS_CSV_MAX_COL_WIDTH,
  IMPORT_TRANS_CSV_MAX_DESC_COL_WIDTH,
  IMPORT_TRANS_CSV_MAX_ROW_NO_COL_WIDTH,
} from "../constants";

interface ImportTransactionFromCSVTableProps {
  dataSource: any[];
  columns: any[];
  listOfHeaders: string[];
  handleHeaderChange: any;
  headers: any;
  onSelectionChanged: (e: any) => void;
  selectedRowKeys: any[];
}

export const ImportTransactionFromCSVTable = forwardRef<
  any,
  ImportTransactionFromCSVTableProps
>(
  (
    {
      dataSource,
      columns,
      listOfHeaders,
      headers,
      handleHeaderChange,
      onSelectionChanged,
      selectedRowKeys,
    },
    ref,
  ) => {
    const renderHeaderCell = React.useCallback(
      (cell: any) => {
        if (cell.column.caption === "RowNo") {
          return <div>{cell.column.caption}</div>;
        }

        return (
          <SelectColumnHeader
            listOfHeaders={listOfHeaders}
            value={cell.column.caption}
            onHeaderSelect={(e) =>
              handleHeaderChange(e.target.value, cell.columnIndex - 1)
            }
          />
        );
      },
      [handleHeaderChange, listOfHeaders],
    );

    const getColumnWidth = (column: string) => {
      if (column === "RowNo") {
        return IMPORT_TRANS_CSV_MAX_ROW_NO_COL_WIDTH;
      }
      if (column === "Description") {
        return IMPORT_TRANS_CSV_MAX_DESC_COL_WIDTH;
      }
      return IMPORT_TRANS_CSV_MAX_COL_WIDTH;
    };

    const getColumnMinWidth = (column: string) => {
      if (column === "RowNo") {
        return IMPORT_TRANS_CSV_MAX_ROW_NO_COL_WIDTH;
      }
      if (column === "Description") {
        return IMPORT_TRANS_CSV_MAX_DESC_COL_WIDTH;
      }
      return 200;
    };

    if (columns.length === 0) {
      return null;
    }

    return (
      <Box
        sx={{
          "& .dx-datagrid-text-content": {
            width: "100%",
          },
        }}
        marginBottom={8}
      >
        <DataGrid
          ref={ref}
          dataSource={dataSource}
          columnAutoWidth={true}
          repaintChangesOnly={true}
          allowColumnResizing={false}
          height={750}
          onSelectionChanged={onSelectionChanged}
          selectedRowKeys={selectedRowKeys}
          width="100%"
          style={{ maxWidth: "100%" }}
        >
          <Selection mode="multiple" />
          <Scrolling mode="virtual" />
          {columns.map((col, colIndex) => (
            <Column
              key={col}
              dataField={col}
              caption={headers[colIndex]}
              headerCellRender={renderHeaderCell}
              width={getColumnWidth(headers[colIndex])}
              minWidth={getColumnMinWidth(headers[colIndex])}
              allowResizing
            />
          ))}
        </DataGrid>
      </Box>
    );
  },
);
