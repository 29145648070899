import React from "react";
import BackdropLoading from "../../components/BackdropLoading/BackdropLoading";
import { PageTitle } from "../../components/page-title";
import {
  Account,
  useAccountsInformation,
} from "../../features/books/import-transactions-from-csv/api/accounts-list";
import { ImportTransactionsFromCSVPanel } from "../../features/books/import-transactions-from-csv/components/import-transcations-from-csv-panel";
import { enqueueSnackbar } from "notistack";
import Papa from "papaparse";
import {
  ACCOUNT_COLUMN,
  AMOUNT1_COLUMN,
  AMOUNT2_COLUMN,
  AMOUNT3_COLUMN,
  AMOUNT4_COLUMN,
  BALANCE_COLUMN,
  BANK_R1_COLUMN,
  BANK_R2_COLUMN,
  BANK_R3_COLUMN,
  BANK_R4_COLUMN,
  CONTACT_COLUMN,
  DATE_COLUMN,
  DESCRIPTION_COLUMN,
  PRIV_PORT_COLUMN,
  SITE_COLUMN,
  TAX_CODE_COLUMN,
} from "../../features/books/import-transactions-from-csv/constants";
import {
  cleanArrays,
  generateInvalidRowsReportsCSV,
  InvalidRowReport,
  isAccValidRow,
  isDate,
  isOnlyCreditValidRow,
  isOnlyDebitValidRow,
  isOnlyWtd2ValidRow,
  isOnlyWtdValidRow,
  isPrivPortValidRow,
  parseTranscationsCSV,
  processRowsAndHeaders,
  updateInvalidRowReport,
} from "../../features/books/import-transactions-from-csv/helpers/get-headers-from-csv";
import { useColumnsHeaderList } from "../../features/books/import-transactions-from-csv/api/columns-header-list";
import { ImportTransactionFromCSVTable } from "../../features/books/import-transactions-from-csv/components/import-transactions-from-csv-table";
import DataGrid from "devextreme-react/cjs/data-grid";
import { ImportTransactionsFromCSVPanelImporting } from "../../features/books/import-transactions-from-csv/components/import-transactions-from-csv-panel-importing";
import {
  findMinDate,
  parseDateString,
} from "../../features/books/import-transactions-from-csv/helpers/dates";
import { ImportTranscationsFromCSVValidationConsistencyModal } from "../../features/books/import-transactions-from-csv/components/import-transactions-from-csv-validation-consistency-modal";
import { ImportTranscationsFromCSVValidationHeadersModal } from "../../features/books/import-transactions-from-csv/components/import-transactions-from-csv-validation-headers-modal";
import useApi from "../../hooks/useApi";
import envConfig from "../../config";
import { useAccountsSelectionList } from "../../features/books/import-transactions-from-csv/api/acconts-selection-list";
import { ImportTransactionFromCSVValidationAllRecordsModal } from "../../features/books/import-transactions-from-csv/components/import-transactions-from-csv-validation-all-records-modal";
import { ImportSuccessModal } from "../../features/books/import-transactions-from-csv/components/import-transactions-from-csv-success-modal";

type VALIDATION_STATUS =
  | "VALIDATE_HEADERS"
  | "VALIDATE_CONSISTENCY_WITH_PREVIOUS"
  | "NO_AMOUNT_OR_DATE"
  | "NO_ACC_OR_TAX"
  | "NO_BANK_SEARCH"
  | "VALIDATE_FINAL_ALL_RECORDS";

export function ImportTransactionsFromCSVPage() {
  const dtGridRef = React.useRef<DataGrid>(null);

  // UI Related State
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const [selectedAccount, setSelectedAccount] = React.useState<
    Account | undefined
  >(undefined);
  const [file, setFile] = React.useState<File | undefined>(undefined);

  // Grid Related States
  const [rows, setRows] = React.useState<string[][]>([]); // rows that will be displayed in Grid
  const [currValidRows, setCurrValidRows] = React.useState<any[]>([]); // rows that are saved between diff validations step
  const [columns, setColumns] = React.useState<string[]>([]); // columns for the grid
  const [headers, setHeaders] = React.useState<string[]>([]); // headers for grid and final API call
  const [currSelectedRows, setCurrSelectedRows] = React.useState<any[]>([]);
  const [prevValidRows, setPrevValidRows] = React.useState<any[]>([]);

  // Forgot what the hell is that
  const [isImporting, setIsImporting] = React.useState<boolean>(false);

  // First Validation related states
  const [invalidCSVRows, setInvalidCSVRows] = React.useState<string[][]>([]);

  // Second Validation related states
  const [minDate, setMinDate] = React.useState<Date | null>(null);
  const [lastTransDate, setLastTransDate] = React.useState<Date | null>(null);
  const [invalidRows, setInvalidRows] = React.useState<string[][]>([]);
  const [invalidRowsReports, setInvalidRowsReports] = React.useState<
    InvalidRowReport[]
  >([]);

  const [validationStatus, setValidationStatus] =
    React.useState<VALIDATION_STATUS | null>(null);

  // success
  const [isSuccessModalOpen, setIsSuccessModalOpen] =
    React.useState<boolean>(false);
  const [successData, setSuccessData] = React.useState<any>(null);

  const { apiCall } = useApi();
  const accountsInfomration = useAccountsInformation({});
  const columnHeadersList = useColumnsHeaderList({});

  const accountsSelectionList = useAccountsSelectionList({});
  // const taxCodesSelectionList = useTaxCodeSelectionList({});
  // const contactsSelectionList = useContactsSelectionList({});
  // const sitesSelectionsList = useSitesSelectionList({});

  const handleFileUpload = (file: File) => {
    setFile(file);
  };

  const handShowRecords = () => {
    if (!isAccountSelected) {
      enqueueSnackbar("Please select an account first!", {
        variant: "warning",
      });
      return;
    }

    if (!file) {
      enqueueSnackbar("Please upload a file first!", {
        variant: "warning",
      });
      return;
    }

    Papa.parse(file, {
      worker: true,
      complete: (completedResult) => {
        const parsedData = completedResult.data as string[][];

        // TODO: change the name parseTranscationsCSV to validateTranscationsCSV
        const validatedCSV = parseTranscationsCSV({
          data: parsedData,
          isFirstRowHeader: false,
        });

        const firstRow = validatedCSV.allRows[0];
        const columns = firstRow.map((_, index) => `${index}`);

        setColumns(columns);
        setRows(validatedCSV.allRows);
        setHeaders(validatedCSV.headers);
        setIsImporting(true);
        setInvalidCSVRows(validatedCSV.invalidCSVRows);
      },
      header: false,
      skipEmptyLines: true,
    });
  };

  const isAccountSelected = selectedAccount !== undefined;
  const listOfHeaders = columnHeadersList.data?.ListOfColumns || [];

  const listOfAccounts = accountsSelectionList.data?.ListOfAccounts || [];
  const listOfValidAccounts = listOfAccounts.map((acc) => `${acc.accNo}`);

  // const listOfTaxCodes = taxCodesSelectionList.data?.listOfTaxCodes || [];
  // const listOfValidTaxCodes = listOfTaxCodes.map((taxCode) => taxCode.txcCode);

  // const listOfContacts = contactsSelectionList.data?.ListOfContacts || [];
  // const listOfValidContacts = listOfContacts.map((cnt) => cnt?.cntCode);

  // const listOfSites = sitesSelectionsList.data?.ListOfSites || [];
  // const listOfValidSites = listOfSites.map((st) => st?.dpsCode);

  const handleHeaderChange = React.useCallback(
    (newValue: string, index: string) => {
      setHeaders((prev) => {
        const newHeaders = [...prev];
        newHeaders[parseInt(index)] = newValue;
        return newHeaders;
      });
    },
    [],
  );

  const isColumnDuplicated = (
    columnName: string,
    headers: string[],
  ): boolean => {
    const count = headers.filter((header) => header === columnName).length;
    return count > 1;
  };

  const validateHeadersSelection = () => {
    const allPossibleColumns = [
      DATE_COLUMN,
      ACCOUNT_COLUMN,
      TAX_CODE_COLUMN,
      DESCRIPTION_COLUMN,
      CONTACT_COLUMN,
      SITE_COLUMN,
      AMOUNT1_COLUMN,
      AMOUNT2_COLUMN,
      AMOUNT3_COLUMN,
      AMOUNT4_COLUMN,
      BALANCE_COLUMN,
      PRIV_PORT_COLUMN,
      BANK_R1_COLUMN,
      BANK_R2_COLUMN,
      BANK_R3_COLUMN,
      BANK_R4_COLUMN,
    ];

    for (const coll of allPossibleColumns) {
      if (isColumnDuplicated(coll, headers)) {
        enqueueSnackbar(
          `Column ${coll} is selected twice! Each column can be assigned only once!`,
          {
            variant: "error",
          },
        );
        return false;
      }
    }

    const wtd1Index = headers.findIndex((header) => header === AMOUNT1_COLUMN);
    const isWtd1Selected = wtd1Index >= 0;

    const wtd2Index = headers.findIndex((header) => header === AMOUNT2_COLUMN);
    const isWtd2Selected = wtd2Index >= 0;

    const debitIndex = headers.findIndex((header) => header === AMOUNT3_COLUMN);
    const isDebitSelected = debitIndex >= 0;

    const crdtIndex = headers.findIndex((header) => header === AMOUNT4_COLUMN);
    const isCreditSelected = crdtIndex >= 0;

    const balanceIndex = headers.findIndex(
      (header) => header === BALANCE_COLUMN,
    );

    const isDateColSelected = isColSelected(DATE_COLUMN);

    const isAccColSelected = isColSelected(ACCOUNT_COLUMN);
    const isTaxColSelected = isColSelected(TAX_CODE_COLUMN);

    const isAmtSelected =
      wtd1Index >= 0 || wtd2Index >= 0 || debitIndex >= 0 || crdtIndex >= 0;

    if (!isAmtSelected || !isDateColSelected) {
      setValidationStatus("NO_AMOUNT_OR_DATE");
      setIsModalOpen(true);
      return false;
    }

    if (
      (isWtd1Selected && isWtd2Selected) ||
      (isWtd1Selected && (isDebitSelected || isCreditSelected)) ||
      (isWtd2Selected && (isDebitSelected || isCreditSelected)) ||
      ((isWtd1Selected || isWtd2Selected) &&
        isDebitSelected &&
        isCreditSelected)
    ) {
      // setValidationStatus("NO_AMOUNT_OR_DATE"); // TODO: Change this to another status
      // setIsModalOpen(true);
      enqueueSnackbar(
        "Please select only one amount column or Debit and Credit together!",
        {
          variant: "warning",
        },
      );
      return false;
    }

    const isBankSearchColSelected = isColSelected(BANK_R1_COLUMN);
    const isAnyBankColSelected =
      isColSelected(BANK_R2_COLUMN) ||
      isColSelected(BANK_R3_COLUMN) ||
      isColSelected(BANK_R4_COLUMN);

    if (!isBankSearchColSelected && isAnyBankColSelected) {
      setValidationStatus("NO_BANK_SEARCH");
      setIsModalOpen(true);
      return false;
    }

    if (
      (!isAccColSelected && isTaxColSelected) ||
      (isAccColSelected && !isTaxColSelected)
    ) {
      setValidationStatus("NO_ACC_OR_TAX");
      setIsModalOpen(true);
      return false;
    }

    return true;
  };

  const validateWithPreviousImports = () => {
    if (!selectedAccount) {
      enqueueSnackbar("Please select an account first!", {
        variant: "warning",
      });

      return false;
    }

    const unfinishedImports =
      selectedAccount?.UnfinImport && selectedAccount?.UnfinImport > 0;

    if (unfinishedImports) {
      enqueueSnackbar("You have unfinished imports for this account!", {
        variant: "error",
      });
      return false;
    }

    const dateIndex = headers.findIndex((header) => header === DATE_COLUMN);

    if (dateIndex < 0) {
      enqueueSnackbar("Please select a date column first!", {
        variant: "warning",
      });
      return false;
    }

    // const selectedRows = dtGridRef.current?.instance.getSelectedRowsData();

    // @ts-ignore
    // const minDate = findMinDate(selectedRows, dateIndex);
    const minDate = findMinDate(currSelectedRows, dateIndex);
    const lockDate = parseDateString(selectedAccount?.LockDate);
    const lastTransDate = parseDateString(selectedAccount?.LastTransDate);

    if (minDate <= lockDate) {
      enqueueSnackbar(
        `Your import has transactions starting ${minDate}. This import is aborted.`,
        {
          variant: "error",
        },
      );
      return false;
    }

    if (minDate <= lastTransDate) {
      setValidationStatus("VALIDATE_CONSISTENCY_WITH_PREVIOUS");
      setMinDate(minDate);
      setLastTransDate(lastTransDate);
      setIsModalOpen(true);
      return false;
    }

    return true;
  };

  const isColSelected = (columnName: string) => {
    return headers.includes(columnName);
  };

  const validationOnEachRecord = (
    selectedRowsToValidate: string[][],
  ): {
    shouldContinueImporting: boolean;
    validRows: string[][];
    invalidRows: string[][];
    invRowsReports: InvalidRowReport[];
    validPrevRows: any[];
  } => {
    setIsModalOpen(false);

    let invalidRows: any[] = [];
    let validRows: any[] = [];
    let validPrevRows: any[] = [];
    let currInvalidRowsReports: InvalidRowReport[] = [];

    const isAccColSelected = isColSelected(ACCOUNT_COLUMN);
    const isTaxColSelected = isColSelected(TAX_CODE_COLUMN);
    const isCntColSelected = isColSelected(CONTACT_COLUMN);
    const isSiteColSelected = isColSelected(SITE_COLUMN);

    const dateIndex = headers.findIndex((header) => header === DATE_COLUMN);
    const wtd1Index = headers.findIndex((header) => header === AMOUNT1_COLUMN);
    const wtd2Index = headers.findIndex((header) => header === AMOUNT2_COLUMN);
    const debitIndex = headers.findIndex((header) => header === AMOUNT3_COLUMN);
    const crdtIndex = headers.findIndex((header) => header === AMOUNT4_COLUMN);

    const privIndex = headers.findIndex((h) => h === PRIV_PORT_COLUMN);
    const accIndex = headers.findIndex((header) => header === ACCOUNT_COLUMN);
    const taxCodeIndex = headers.findIndex((h) => h === TAX_CODE_COLUMN);
    const cntCodeIndex = headers.findIndex((h) => h === CONTACT_COLUMN);
    const siteIndex = headers.findIndex((h) => h === SITE_COLUMN);
    const balanceIndex = headers.findIndex((h) => h === BALANCE_COLUMN);

    const isWtd1Selected = wtd1Index >= 0;
    const isWtd2Selected = wtd2Index >= 0;
    const isDebitSelected = debitIndex >= 0;
    const isCreditSelected = crdtIndex >= 0;
    const isPrivSelected = privIndex >= 0;
    const isBalanceSelected = balanceIndex >= 0;

    if (
      !isWtd1Selected &&
      !isWtd2Selected &&
      !isDebitSelected &&
      !isCreditSelected &&
      !isBalanceSelected
    ) {
      enqueueSnackbar("Please select at least one amount column!", {
        variant: "warning",
      });
      return {
        shouldContinueImporting: false,
        invalidRows: [],
        validRows: [],
        invRowsReports: [],
        validPrevRows: [],
      };
    }

    for (let i = 0; i < selectedRowsToValidate.length; i++) {
      let row = selectedRowsToValidate[i];
      let rowIndex = i;
      let currRow = row;
      let prevRow = row.slice();

      const date = row[dateIndex];
      const isDateValid = isDate(date);
      // const isAmountValid = isAmountValidRow(
      //   row,
      //   wtd1Index,
      //   wtd2Index,
      //   debitIndex,
      //   crdtIndex,
      //   // balanceIndex,
      // );

      // Change this function that the value is valid if it is empty and the credit column is selected
      const isOnlyDbtValidRow = isDebitSelected
        ? isOnlyDebitValidRow(row, debitIndex, isCreditSelected)
        : true;

      // Change this function that the value is valid if it is empty and the debit column is selected
      const isOnlyCditValidRow = isCreditSelected
        ? isOnlyCreditValidRow(row, crdtIndex, isDebitSelected)
        : true;

      const isOnlyWthdValidRow = isWtd1Selected
        ? isOnlyWtdValidRow(row, wtd1Index)
        : true;

      const isOnlyWthd2ValidRow = isWtd2Selected
        ? isOnlyWtd2ValidRow(row, wtd2Index)
        : true;

      const isAmountValid =
        isOnlyDbtValidRow &&
        isOnlyCditValidRow &&
        isOnlyWthdValidRow &&
        isOnlyWthd2ValidRow;

      const isPrivValid = isPrivSelected
        ? isPrivPortValidRow(row, privIndex)
        : true;

      if (isPrivSelected && !isPrivValid) {
        currRow[privIndex] = "0";
        currInvalidRowsReports = updateInvalidRowReport(
          currInvalidRowsReports,
          rowIndex,
          `Private portion out of range: ${row[privIndex]}. `,
        );
      }

      const isAccValid = isAccountSelected
        ? isAccValidRow(row, accIndex, listOfValidAccounts)
        : true;

      if (isAccColSelected && !isAccValid) {
        currInvalidRowsReports = updateInvalidRowReport(
          currInvalidRowsReports,
          rowIndex,
          `Account ${prevRow[accIndex]} not found in your Chart of Accounts.`,
        );
        currRow[accIndex] = "0";
      }

      if (!isDateValid) {
        currInvalidRowsReports = updateInvalidRowReport(
          currInvalidRowsReports,
          rowIndex,
          `Date ${prevRow[dateIndex]} is not valid.`,
        );
      }

      // if (!isAmountValid) {
      //   currInvalidRowsReports = updateInvalidRowReport(
      //     currInvalidRowsReports,
      //     rowIndex,
      //     `Amounts are not valid. Please check your amounts.`,
      //   );
      // }

      // const isTaxCodeValid = isTaxColSelected
      //   ? isTaxCodeValidRow(row, taxCodeIndex, listOfValidTaxCodes)
      //   : true;

      // if (isTaxColSelected && !isTaxCodeValid) {
      //   currInvalidRowsReports = updateInvalidRowReport(
      //     currInvalidRowsReports,
      //     rowIndex,
      //     `Tax Code ${prevRow[taxCodeIndex]} not found in your Tax Codes.`,
      //   );
      //   currRow[taxCodeIndex] = "0";
      // }

      // const isCntCodeValid = isCntColSelected
      //   ? isCntCodeValidRow(row, cntCodeIndex, listOfValidContacts)
      //   : true;

      // if (isCntColSelected && !isCntCodeValid) {
      //   currInvalidRowsReports = updateInvalidRowReport(
      //     currInvalidRowsReports,
      //     rowIndex,
      //     `Contact with the ${prevRow[cntCodeIndex]} not found.`,
      //   );
      //   currRow[cntCodeIndex] = "0";
      // }

      // const isSiteValid = isSiteColSelected
      //   ? isSiteValidRow(row, siteIndex, listOfValidSites)
      //   : true;

      // if (isSiteColSelected && !isSiteValid) {
      //   currInvalidRowsReports = updateInvalidRowReport(
      //     currInvalidRowsReports,
      //     rowIndex,
      //     `Site with the ${prevRow[siteIndex]} code not found.`,
      //   );
      //   currRow[siteIndex] = "0";
      // }

      if (!isDateValid) {
        invalidRows.push(currRow);
      } else {
        validRows.push(currRow);
        validPrevRows.push(prevRow);
      }
    }

    const totalPartiallyInvalidRecords = currInvalidRowsReports.length;
    const totalTotallyInvalidRecords = invalidRows.length;
    const shouldContinueImporting =
      totalPartiallyInvalidRecords === 0 && totalTotallyInvalidRecords === 0;

    if (totalPartiallyInvalidRecords > 0 || totalTotallyInvalidRecords > 0) {
      setValidationStatus("VALIDATE_FINAL_ALL_RECORDS");
      setIsModalOpen(true);
      setInvalidRowsReports(currInvalidRowsReports);
      setInvalidRows(invalidRows);

      return {
        shouldContinueImporting: false,
        validRows: validRows,
        invalidRows: invalidRows,
        invRowsReports: currInvalidRowsReports,
        validPrevRows: validPrevRows,
      };
    }

    return {
      shouldContinueImporting: shouldContinueImporting,
      validRows: validRows,
      invalidRows: invalidRows,
      invRowsReports: currInvalidRowsReports,
      validPrevRows: validPrevRows,
    };
  };

  const handleSelectionChanged = (e: any) => {
    setCurrSelectedRows(e.selectedRowKeys);
  };

  const handleOnImport = () => {
    // const selectedRows =
    //   dtGridRef.current?.instance.getSelectedRowsData() || ([] as any[]);

    const areHeadersValid = validateHeadersSelection();
    if (!areHeadersValid) return;

    const arePreviousImportValid = validateWithPreviousImports();
    if (!arePreviousImportValid) return;

    const { shouldContinueImporting, validRows } = validationOnEachRecord([
      ...currSelectedRows,
    ]);

    if (!shouldContinueImporting) return;

    importRowsToBankEntriesAPI(validRows, headers);
  };

  const importRowsToBankEntriesAPI = async (validRows, headers) => {
    const [cleanRows, cleanHeaders] = cleanArrays(validRows, headers);

    const csvData = cleanRows.map((row) => row.slice(1));
    const headersColumn = cleanHeaders.slice(1);

    const [newRows, newHeaders] = processRowsAndHeaders(csvData, headersColumn);

    setIsLoading(true);
    await apiCall({
      // url: `https://${envConfig.apiDev1Exacc}/api/v1/en-au/bank-entries-import/import-csv-data-to-transactions-async`,
      // url: `https://${envConfig.apiDev1Exacc}/api/v1/en-au/bank-entries-import/import-csv-data-to-bank-entries-async`,
      url: `https://${envConfig.apiDev1Exacc}/api/v1/en-au/bank-transactions-import/import-csv-data-to-transactions-async`,
      // post /api/v1/{lang}/bank-entries-import/import-csv-data-to-bank-entries-async
      method: "POST",
      // @ts-ignore
      body: {
        CSVData: JSON.stringify(newRows),
        headersColumns: JSON.stringify(newHeaders),
        accountNo: selectedAccount?.accNo,
        // validationEnableYN: "N",
      },
      // @ts-ignore
      onSuccess: (data) => {
        console.log("[OVERALL]: IMPORT SUCCESSFUL: ", data);

        setIsLoading(false);
        handleCancelImport();

        if (data?.SuccessYN === "Y") {
          setIsSuccessModalOpen(true);
          setSuccessData(data);
          return;
        }
      },
      // @ts-ignore
      onError: (errorMessage) => {
        enqueueSnackbar(`Import failed: ${JSON.stringify(errorMessage)}`, {
          variant: "error",
        });
        setIsLoading(false);
      },
    });

    //
  };

  const handleContinueAfterHeadersValidation = async () => {
    setIsModalOpen(false);
    setValidationStatus("VALIDATE_CONSISTENCY_WITH_PREVIOUS");

    const arePreviousImportValid = validateWithPreviousImports();

    if (!arePreviousImportValid) return;

    // const selectedRows =
    //   dtGridRef.current?.instance.getSelectedRowsData() || ([] as any[]);

    const selectedRows = currSelectedRows.slice();

    const selectedRowsCopy = Array.from(selectedRows);
    const { shouldContinueImporting, validRows, validPrevRows } =
      validationOnEachRecord(selectedRowsCopy);

    setCurrValidRows(validRows);
    setPrevValidRows(validPrevRows);

    if (!shouldContinueImporting) return;

    await importRowsToBankEntriesAPI(validRows, headers);
  };

  const handleExcludeTransactions = async () => {
    const dateIndex = headers.findIndex((header) => header === DATE_COLUMN);
    const lockDate = parseDateString(selectedAccount?.LockDate);
    const lastTransDate = parseDateString(selectedAccount?.LastTransDate);

    // const selectedRows =
    //   dtGridRef.current?.instance.getSelectedRowsData() || ([] as any[]);

    const selectedRows = currSelectedRows.slice();

    const excludedRows = selectedRows.filter((row) => {
      const date = parseDateString(row[dateIndex]);
      return date < lockDate || date > lastTransDate;
    });

    const selectedRowsCopy = Array.from(excludedRows);
    const { shouldContinueImporting, validRows, validPrevRows } =
      validationOnEachRecord(selectedRowsCopy);

    setCurrValidRows(validRows);
    setPrevValidRows(validPrevRows);

    if (!shouldContinueImporting) return;

    await importRowsToBankEntriesAPI(validRows, headers);
  };

  const handleImportDuplicates = async () => {
    // const selectedRows =
    //   dtGridRef.current?.instance.getSelectedRowsData() || ([] as any[]);

    const selectedRows = currSelectedRows.slice();

    const selectedRowsCopy = Array.from(selectedRows);
    const { shouldContinueImporting, validRows, validPrevRows } =
      validationOnEachRecord(selectedRowsCopy);

    setCurrValidRows(validRows);
    setPrevValidRows(validPrevRows);

    if (!shouldContinueImporting) return;

    await importRowsToBankEntriesAPI(validRows, headers);
  };

  const handleCSVReportGeneration = async () => {
    try {
      await generateInvalidRowsReportsCSV(
        prevValidRows,
        invalidRowsReports,
        "import_report.csv",
      );
      enqueueSnackbar("CSV report generated successfully", {
        variant: "success",
      });
    } catch (error) {
      console.error("Error generating CSV report:", error);
      enqueueSnackbar("Failed to generate CSV report", { variant: "error" });
    }
  };

  const handleManualImport = async () => {
    console.log("[OVERALL]: MANUAL IMPORT valid rows: ", currValidRows);
    await importRowsToBankEntriesAPI(currValidRows, headers);
  };

  const handleCancelImport = () => {
    setFile(undefined);
    setHeaders([]);
    setRows([]);
    setColumns([]);
    setMinDate(null);
    setLastTransDate(null);
    setValidationStatus(null);
    setCurrValidRows([]);
    setCurrSelectedRows([]);
    setIsImporting(false);
    setPrevValidRows([]);
  };

  React.useEffect(() => {
    if (isImporting) {
      dtGridRef.current?.instance.selectAll();
    }
  }, [isImporting]);

  return (
    <>
      <BackdropLoading
        open={
          accountsInfomration.isLoading ||
          columnHeadersList.isLoading ||
          isLoading
        }
      />
      <PageTitle title={"Import Transactions from CSV"} />
      {isImporting ? (
        <ImportTransactionsFromCSVPanelImporting
          accountSelected={`${selectedAccount?.accNo}`}
          // accountSelected={`${selectedAccount?.accNo} - ${selectedAccount?.accName}`}
          numberOfInvalidRecords={invalidCSVRows.length}
          selectedRows={currSelectedRows.length}
          totalRows={rows.length}
          onImport={handleOnImport}
          onCancelImport={() => {
            handleCancelImport();
            setSelectedAccount(undefined);
          }}
        />
      ) : (
        <ImportTransactionsFromCSVPanel
          accounts={accountsInfomration.data?.ListOfAccounts || []}
          selectedAccount={selectedAccount}
          onAccountChange={(account) => setSelectedAccount(account)}
          onFileUpload={handleFileUpload}
          onImport={() => {}}
          onShowRecords={handShowRecords}
        />
      )}
      <ImportSuccessModal
        isModalOpen={isSuccessModalOpen}
        onClose={() => {
          setIsSuccessModalOpen(false);
          handleCancelImport();
          setSelectedAccount(undefined);
        }}
        totalTransactionsCounter={successData?.TotalTransactionsCounter}
        journalsCreatedCounter={successData?.JournalsCreatedCounter}
        requiredAttentionCounter={successData?.RequiredAttentionCounter}
        selectedAccountNo={selectedAccount?.accNo || ""}
      />
      <ImportTranscationsFromCSVValidationHeadersModal
        isModalOpen={
          isModalOpen &&
          (validationStatus === "NO_AMOUNT_OR_DATE" ||
            validationStatus === "NO_ACC_OR_TAX" ||
            validationStatus === "NO_BANK_SEARCH")
        }
        onClose={() => {
          setIsModalOpen(false);
          setValidationStatus(null);
        }}
        onContinue={handleContinueAfterHeadersValidation}
        validationStatus={validationStatus}
      />
      <ImportTranscationsFromCSVValidationConsistencyModal
        isModalOpen={
          isModalOpen &&
          validationStatus === "VALIDATE_CONSISTENCY_WITH_PREVIOUS"
        }
        onClose={() => {
          setIsModalOpen(false);
          setValidationStatus(null);
          setCurrValidRows([]);
          setPrevValidRows([]);
        }}
        minDate={minDate?.toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "short",
          year: "numeric",
        })}
        lastTransDate={lastTransDate?.toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "short",
          year: "numeric",
        })}
        onExcludeTransactions={handleExcludeTransactions}
        onImportDuplicates={handleImportDuplicates}
      />
      <ImportTransactionFromCSVValidationAllRecordsModal
        isModalOpen={
          isModalOpen && validationStatus === "VALIDATE_FINAL_ALL_RECORDS"
        }
        onClose={() => {
          setIsModalOpen(false);
          setValidationStatus(null);
        }}
        totalPartiallyInvalidRecords={invalidRowsReports?.length || 0}
        totalTotallyInvalidRecords={invalidRows?.length || 0}
        onContinue={() => {
          handleManualImport();
          setIsModalOpen(false);
          setValidationStatus(null);
        }}
        onCancel={() => {
          setIsModalOpen(false);
          setValidationStatus(null);
          setCurrValidRows([]);
        }}
        onGenerateCSVReport={() => {
          handleCSVReportGeneration();
          setIsModalOpen(false);
          setValidationStatus(null);
        }}
      />
      <ImportTransactionFromCSVTable
        ref={dtGridRef}
        columns={columns}
        dataSource={rows}
        handleHeaderChange={handleHeaderChange}
        headers={headers}
        listOfHeaders={listOfHeaders}
        onSelectionChanged={handleSelectionChanged}
        selectedRowKeys={currSelectedRows}
      />
    </>
  );
}
