import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

interface ImportTranscationsFromCSVValidationConsistencyModalProps {
  minDate?: string;
  lastTransDate?: string;
  isModalOpen: boolean;
  onClose: () => void;
  onExcludeTransactions: () => void;
  onImportDuplicates: () => void;
}

export const ImportTranscationsFromCSVValidationConsistencyModal = ({
  minDate,
  lastTransDate,
  isModalOpen,
  onClose,
  onExcludeTransactions,
  onImportDuplicates,
}: ImportTranscationsFromCSVValidationConsistencyModalProps) => {
  return (
    <Dialog
      open={isModalOpen}
      onClose={onClose}
      sx={{
        ">div>div": { maxWidth: "xl" },
      }}
    >
      <DialogTitle textAlign={"center"}>
        Incosistency with previous imports!
      </DialogTitle>
      <DialogContent sx={{ overflow: "visible" }}>
        <Box>
          Your minimum date is {minDate} and last transaction in Ledger is{" "}
          {lastTransDate}.
        </Box>
        <DialogActions sx={{ pt: 0, mt: 5 }}>
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyItems={"center"}
            alignItems={"center"}
            gap={2}
            width={"100%"}
          >
            <Button
              onClick={() => {
                onExcludeTransactions();
              }}
              // @ts-ignore
              variant="outlined"
            >
              Exclude transactions falling within dates of other imports
            </Button>
            <Button
              onClick={() => {
                onImportDuplicates();
              }}
              // @ts-ignore
              variant="outlined"
            >
              Import possible duplicates, for manual handling
            </Button>
            <Button
              onClick={onClose}
              // @ts-ignore
              variant="outlined"
            >
              Cancel
            </Button>
          </Box>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
