import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  Typography,
  TextField,
  Autocomplete,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  ListItemText,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import useApi from "../../hooks/useApi";
import envConfig from "../../config";
import { useSnackbar } from "notistack";
import BackdropLoading from "../../components/BackdropLoading/BackdropLoading";

const SiteDetails = () => {
  const { dbId, lang, dptId, dpsId } = useParams();
  const { apiCall } = useApi();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  // State variables
  const [loading, setLoading] = useState(true);
  const [site, setSite] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [departments, setDepartments] = useState([]);
  const [managers, setManagers] = useState([]);

  // Form fields
  const [dpsName, setDpsName] = useState("");
  const [dpsCode, setDpsCode] = useState("");
  const [dpsDeptDptID, setDpsDeptDptID] = useState(null);
  const [dpsManagerUsrID, setDpsManagerUsrID] = useState(null);
  const [dpsRestrictedYN, setDpsRestrictedYN] = useState("N");
  const [dpsActiveYN, setDpsActiveYN] = useState("Y");
  const [dpsNotes, setDpsNotes] = useState("");

  // Delete confirmation dialog
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  // Fetch site details and related lists
  const fetchSiteDetails = useCallback(async () => {
    setLoading(true);
    try {
      await apiCall({
        url: `https://${envConfig.apiDev1Exacc}/api/v1/en-au/sites/get-list-of-sites-async?departmentId=${dptId}`,
        method: "GET",
        onSuccess: (data) => {
          if (data.SuccessYN !== "Y") {
            enqueueSnackbar(data.ErrorMessage || "Failed to fetch data.", {
              variant: "error",
            });
            return;
          }

          const fetchedSite = data.ListOfSites.find(
            (s) => s.dpsID === parseInt(dpsId, 10),
          );

          if (!fetchedSite) {
            enqueueSnackbar("Site not found.", { variant: "error" });
            navigate(`/${dbId}/${lang}/sites/${dptId}/`);
            return;
          }

          setSite(fetchedSite);
          setDpsName(fetchedSite.dpsName);
          setDpsCode(fetchedSite.dpsCode);
          setDpsDeptDptID(fetchedSite.dpsDept_dptID || null);
          setDpsManagerUsrID(fetchedSite.dpsManager_usrID || null);
          setDpsRestrictedYN(fetchedSite.dpsRestrictedYN || "N");
          setDpsActiveYN(fetchedSite.dpsActiveYN || "Y");
          setDpsNotes(fetchedSite.dpsNotes || "");

          setDepartments(data.ListOfDepartments || []);
          setManagers(data.ListOfManagers || []);
        },
        onError: (error) => {
          enqueueSnackbar(error, { variant: "error" });
        },
      });
    } catch (error) {
      console.error("Error fetching site details:", error);
      enqueueSnackbar("An unexpected error occurred.", { variant: "error" });
    } finally {
      setLoading(false);
    }
  }, [apiCall, dpsId, enqueueSnackbar, navigate]);

  useEffect(() => {
    fetchSiteDetails();
  }, [fetchSiteDetails]);

  // Handle form submission
  const handleSave = async () => {
    // Basic validation
    if (!dpsName.trim() || !dpsCode.trim()) {
      enqueueSnackbar("Site Name and Code are required.", {
        variant: "warning",
      });
      return;
    }

    setLoading(true);
    try {
      // Construct payload, skipping fields if no data
      const payload = {
        dpsID: parseInt(dpsId, 10),
        dpsName: dpsName.trim(),
        dpsCode: dpsCode.trim(),
        ...(dpsDeptDptID && { dpsDept_dptID: dpsDeptDptID }),
        ...(dpsManagerUsrID && { dpsManager_usrID: dpsManagerUsrID }),
        ...(dpsRestrictedYN && { dpsRestrictedYN }),
        ...(dpsActiveYN && { dpsActiveYN }),
        ...(dpsNotes && { dpsNotes: dpsNotes.trim() }),
      };

      await apiCall({
        url: `https://${envConfig.apiDev1Exacc}/api/v1/en-au/sites/update-async`,
        method: "POST",
        body: payload,
        onSuccess: (data) => {
          if (data.SuccessYN === "Y") {
            enqueueSnackbar("Site updated successfully.", {
              variant: "success",
            });
            navigate(`/${dbId}/${lang}/sites/${dptId}/`);
          } else {
            enqueueSnackbar(data.ErrorMessage || "Failed to update site.", {
              variant: "error",
            });
          }
        },
        onError: (error) => {
          enqueueSnackbar(error, { variant: "error" });
        },
      });
    } catch (error) {
      console.error("Error updating site:", error);
      enqueueSnackbar("An unexpected error occurred.", { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  const handleDepartmentDetails = () => {
    navigate(`/${dbId}/${lang}/department/${dptId}/`);
  };

  // Handle delete
  const handleDelete = async () => {
    setLoading(true);
    try {
      await apiCall({
        url: `https://${envConfig.apiDev1Exacc}/api/v1/en-au/sites/delete-async?siteId=${dpsId}&BaseHostURL=s1.olivs.com`,
        method: "POST",
        onSuccess: (data) => {
          if (data.SuccessYN === "Y") {
            enqueueSnackbar("Site deleted successfully.", {
              variant: "success",
            });
            navigate(`/${dbId}/${lang}/sites/${dptId}/`);
          } else {
            enqueueSnackbar(data.ErrorMessage || "Failed to delete site.", {
              variant: "error",
            });
          }
        },
        onError: (error) => {
          enqueueSnackbar(error, { variant: "error" });
        },
      });
    } catch (error) {
      console.error("Error deleting site:", error);
      enqueueSnackbar("An unexpected error occurred.", { variant: "error" });
    } finally {
      setLoading(false);
      setIsDeleteDialogOpen(false);
    }
  };

  return (
    <Box sx={{ padding: 2, maxWidth: 800, margin: "0 auto" }}>
      {/* Loading Backdrop */}
      <BackdropLoading open={loading} />

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this site?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDeleteDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Page Header */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 3,
        }}
      >
        <Typography variant="h4">Site Details</Typography>
        <Box>
          <Button
            onClick={handleDepartmentDetails}
            color="primary"
            variant="contained"
            sx={{ mr: 2 }}
          >
            Department details
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => setIsDeleteDialogOpen(true)}
            sx={{ mr: 2 }}
          >
            Delete
          </Button>
          <Button
            variant="outlined"
            onClick={() => navigate(`/${dbId}/${lang}/sites/${dptId}/`)}
          >
            Back
          </Button>
        </Box>
      </Box>

      {/* Form */}
      <Box
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
        }}
        noValidate
        autoComplete="off"
      >
        {/* Department */}
        <Autocomplete
          options={departments}
          getOptionLabel={(option) => `${option.dptName} (${option.dptCode})`}
          value={
            departments.find((dept) => dept.dptID === dpsDeptDptID) || null
          }
          onChange={(event, newValue) =>
            setDpsDeptDptID(newValue ? newValue.dptID : null)
          }
          renderInput={(params) => (
            <TextField {...params} disabled label="Department" />
          )}
          isOptionEqualToValue={(option, value) => option.dptID === value.dptID}
        />
        {/* Site Name */}
        <TextField
          label="Site Name"
          value={dpsName}
          onChange={(e) => setDpsName(e.target.value)}
          required
        />

        {/* Site Code */}
        <TextField
          label="Site Code"
          value={dpsCode}
          onChange={(e) => setDpsCode(e.target.value)}
          required
        />

        {/* Manager */}
        <Autocomplete
          options={managers}
          renderOption={(props, item) => (
            <li {...props} key={item.key}>
              <ListItemText>{item.usrName}</ListItemText>
            </li>
          )}
          getOptionLabel={(option) => `${option.usrName}`}
          value={
            managers.find((manager) => manager.usrID === dpsManagerUsrID) ||
            null
          }
          onChange={(event, newValue) =>
            setDpsManagerUsrID(newValue ? newValue.usrID : null)
          }
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          renderInput={(params) => <TextField {...params} label="Manager" />}
          isOptionEqualToValue={(option, value) => option.usrID === value.usrID}
          // filterOptions={(options) => {
          //   console.log(options);
          //   const searchValue = inputValue.toLowerCase();
          //   console.log("searchValue", searchValue);
          //   const filtered = options.filter((option) => {
          //     return option.usrName.toLowerCase().includes(searchValue);
          //   });
          //   console.log("filtered", filtered);
          //   return filtered;
          // }}
        />

        {/* Restricted Status */}
        <FormControl component="fieldset">
          <FormLabel component="legend">Restricted Status</FormLabel>
          <RadioGroup
            row
            value={dpsRestrictedYN}
            onChange={(e) => setDpsRestrictedYN(e.target.value)}
          >
            <FormControlLabel value="Y" control={<Radio />} label="Yes" />
            <FormControlLabel value="N" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>

        {/* Active Status */}
        <FormControl component="fieldset">
          <FormLabel component="legend">Active Status</FormLabel>
          <RadioGroup
            row
            value={dpsActiveYN}
            onChange={(e) => setDpsActiveYN(e.target.value)}
          >
            <FormControlLabel value="Y" control={<Radio />} label="Yes" />
            <FormControlLabel value="N" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>

        {/* Department Notes */}
        {/* <TextField
          label="Department Notes"
          value={dpsNotes}
          onChange={(e) => setDpsNotes(e.target.value)}
          multiline
          rows={4}
        /> */}

        {/* Save Button */}
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            disabled={loading}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default SiteDetails;
