import React from "react";
import { Box, Button } from "@mui/material";
import { IconFileAdd2 } from "../../../components/icons/file-add-2";
import { IconActivityType } from "../../../components/icons/activity-type";
interface TimesheetActionsProps {
  currActivityId: string | null;
  onClickNewTimesheet: () => void;
  onClickActivityTypeSettings: () => void;
  disableNewTimesheet?: boolean;
}

const TimesheetActions: React.FC<TimesheetActionsProps> = ({
  onClickNewTimesheet,
  onClickActivityTypeSettings,
  disableNewTimesheet = false,
  currActivityId,
}) => {
  return (
    <Box
      display={{ xs: "none", sm: "none", md: "flex" }}
      flexWrap="wrap"
      gap={2}
    >
      <Button
        variant="contained"
        color="primary"
        size="large"
        onClick={onClickNewTimesheet}
        disabled={disableNewTimesheet}
        startIcon={<IconFileAdd2 color="primary" />}
      >
        {currActivityId ? "Edit current" : "New"} Timesheet
      </Button>
      <Button
        variant="outlined"
        color="primary"
        size="large"
        disabled
        onClick={onClickActivityTypeSettings}
        // startIcon={<IconActivityType color="primary" paletteType="co" />}
      >
        Activity Type Settings
      </Button>
    </Box>
  );
};

export default TimesheetActions;
