import React from "react";
import { ButtonOwnProps } from "@mui/material";
import { IconWrapper } from "./icon-wrapper";

export const IconArrowLeft = ({
  color,
  paletteType,
}: {
  color: ButtonOwnProps["color"];
  paletteType?: "main" | "light" | "dark" | "contrastText";
}) => {
  return (
    <IconWrapper color={color} paletteType={paletteType}>
      {(strokeColor) => (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4 12H20M4 12L8 8M4 12L8 16"
            stroke={strokeColor}
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      )}
    </IconWrapper>
  );
};
