import React from "react";
import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { IconFileCheck, IconPlus, IconTrash } from "../../../components/icons";
import { IconCalendarDay } from "../../../components/icons/calendar-day";
import { IconCircleExclamation } from "../../../components/icons/circle-exclamation";
import { IconArrowLeft } from "../../../components/icons/arrow-left";

interface PayrollEventActionsProps {
  onFinalize: () => void;
  onAddEmployee: () => void;
  onDelete: () => void;
  onSeeWarnings: () => void;
  onChangeDates: () => void;
  onBack: () => void;
  warningsCount: number;
}

export const PayrollEventActions: React.FC<PayrollEventActionsProps> = ({
  onFinalize,
  onAddEmployee,
  onDelete,
  onSeeWarnings,
  onChangeDates,
  onBack,
  warningsCount,
}) => {
  const navigate = useNavigate();

  return (
    <Box
      display="flex"
      flexDirection={{ xs: "column", sm: "row" }}
      gap={2}
      mt={6}
      mb={8}
    >
      <Button
        variant="contained"
        color="primary"
        onClick={onFinalize}
        startIcon={<IconFileCheck color="primary" />}
      >
        Approve and Pay
      </Button>
      <Button
        variant="outlined"
        color="secondary"
        onClick={onAddEmployee}
        startIcon={<IconPlus color="secondary" paletteType="main" />}
      >
        Add Employee
      </Button>
      <Button
        variant="contained"
        color="inherit"
        onClick={onDelete}
        startIcon={<IconTrash color="inherit" paletteType="contrastText" />}
      >
        Delete
      </Button>
      <Button
        variant="outlined"
        color={warningsCount > 0 ? "warning" : "secondary"}
        onClick={onSeeWarnings}
        startIcon={
          <IconCircleExclamation
            color={warningsCount > 0 ? "warning" : "secondary"}
            paletteType="main"
          />
        }
      >
        See Warnings {warningsCount > 0 && `(${warningsCount})`}
      </Button>
      <Button
        variant="outlined"
        color="secondary"
        onClick={onChangeDates}
        startIcon={<IconCalendarDay color="secondary" paletteType="main" />}
      >
        Change Dates
      </Button>
      <Button
        variant="outlined"
        color="secondary"
        onClick={onBack}
        startIcon={<IconArrowLeft color="secondary" paletteType="main" />}
      >
        Back
      </Button>
    </Box>
  );
};
