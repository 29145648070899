import dayjs from "dayjs";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";


import { axiosService } from "../../../api/axios";
import envConfig from "../../../config";
import { useLoginPopup } from "../../../context/LoginPopupContext";
import { DailyTimesheet, DailyTimesheetResponse, TimesheetDetailsResponse } from "../types";

export interface TimesheetInsertPayload {
  acvType_avtID: number;
  acvUser_usrID: number | string;
  acvTimeUnits: number;
  acvDescription: string;
  acvNotes: string;
  acvTaskDate: string;
  acvStartTime: string;
  acvEndTime: string;
  acvInvoice_invID: number;
  acvChargeableYN: "Y" | "N";
  acvContact_cntID: number;
  acvHourlyRate: number;
  acvManualUnitsYN: "Y" | "N";
  acvCharge: number;
  acvOurCost: number;
  acvTask_prdID: number;
  acvSite_dpsID: number;
  acvPayroll_pevID: number;
  acvPaidBreakUnits: number;
  acvUnpaidBreakUnits: number;
  acvDocument_docID: string;
  acvTransferGUID: string;
  acvEntrySource: string;
  acvForActionYN: "Y" | "N";
  acvInvReadyYN: "Y" | "N";
}

export interface TimesheetInsertResponse {
  SuccessYN: "Y" | "N";
  ErrorMessage: string;
  NewActivityId: string;
}

export interface UseTimesheetInsertProps {
  date: dayjs.Dayjs | null;
  onSuccess?: (data: TimesheetInsertResponse) => void;
  onError?: (error: string) => void;
}

export const useTimesheetInsert = ({ date }: UseTimesheetInsertProps = { date: null }) => {
  const { dbId, lang } = useParams();
  const { reAuthenticate } = useLoginPopup();
  const queryClient = useQueryClient();
  
  const dateString = date ? date.format("DD/MM/YYYY") : "";
  const queryKey = ['timesheet-daily-list', dbId, lang, dateString];

  return useMutation({
    mutationFn: async (payload: TimesheetInsertPayload) => {
      console.log("payload: ", payload);

      if (process.env.NODE_ENV === "development") {
        return Promise.resolve({
          SuccessYN: "Y",
          ErrorMessage: "",
          NewActivityId: "1234567890",
        });
      }

      const response = await axiosService.request<TimesheetInsertResponse>({
        dbId: dbId,
        method: 'POST',
        baseURL: envConfig.apiDev1Exacc,
        endpoint: `/api/v2/${lang}/timesheet/insert-async?BaseHostURL=${envConfig.mainServiceUrl}`,
        data: payload,
        reAuthenticate: reAuthenticate,
      });

      return response;
    },
    onMutate: async (timesheetInsertPayload) => {
      console.log("timesheetInsertPayload: ", timesheetInsertPayload);

      const newDailyTimesheet: DailyTimesheet = {
        acvID: "",
        acvTaskDate: timesheetInsertPayload.acvTaskDate,
        cntCode: "",
        avtCode: "",
        acvStartTime: timesheetInsertPayload.acvStartTime,
        acvEndTime: timesheetInsertPayload.acvEndTime,
        acvTimeUnits: timesheetInsertPayload.acvTimeUnits,
        acvDescription: timesheetInsertPayload.acvDescription,
        acvCharge: timesheetInsertPayload.acvCharge,
        acvForActionYN: timesheetInsertPayload.acvForActionYN,
        acvInvoiced: "N",
        acvMobileColumn: "",
      };

      // Cancel any ongoing timesheet details query so they don't override the new timesheet
      await queryClient.cancelQueries({ queryKey: queryKey });

      // Snapshot the previous timesheet details value
      const prevTimesheetDailyList = queryClient.getQueryData<DailyTimesheetResponse>(queryKey);

      console.log("queryKey: ", queryKey);
      console.log("prevTimesheetDailyList: ", prevTimesheetDailyList);

      // Optimistically update to the new value
      if (prevTimesheetDailyList) {
        queryClient.setQueryData<DailyTimesheetResponse>(queryKey, {
          SuccessYN: prevTimesheetDailyList.SuccessYN,
          ErrorMessage: prevTimesheetDailyList.ErrorMessage,
          ListOfDailyTimesheet: [
            newDailyTimesheet,
            ...prevTimesheetDailyList.ListOfDailyTimesheet,
          ],
        });
      }

      // Return the previous timesheet daily list so we can revert to it if the mutation fails
      // everything that is returned here will be passed to the onError function as context
      return { prevTimesheetDailyList };
    },
    onError: (_, __, context) => {
      if (context?.prevTimesheetDailyList) {
        queryClient.setQueryData<DailyTimesheetResponse>(queryKey, context.prevTimesheetDailyList);
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });
};
