export const IMPORT_TRANS_CSV_MAX_COL_WIDTH = 200;
export const IMPORT_TRANS_CSV_MAX_DESC_COL_WIDTH = 150;
export const IMPORT_TRANS_CSV_MAX_ROW_NO_COL_WIDTH = 60;
export const IMPORT_TRANS_CSV_MAX_HEADER_COL_WIDTH = 150;

export const DATE_COLUMN = "Date";
export const ACCOUNT_COLUMN = "Account";
export const TAX_CODE_COLUMN = "Tax Code";
export const DESCRIPTION_COLUMN = "Description";
export const CONTACT_COLUMN = "Contact Code";
export const SITE_COLUMN = "Site Code / Name";
export const AMOUNT1_COLUMN = "+ Withdrawal / - Deposit";
export const AMOUNT2_COLUMN = "- Withdrawal / + Deposit";
export const AMOUNT3_COLUMN = "Debit / Withdrawal";
export const AMOUNT4_COLUMN = "Credit / Deposit";
export const BALANCE_COLUMN = "Balance";
export const PRIV_PORT_COLUMN = "Priv Port 1..99";
export const BANK_R1_COLUMN = "Bank Rule - Search Text";
export const BANK_R2_COLUMN = "Bank Rule – Ranking";
export const BANK_R3_COLUMN = "Bank Rule – Minimum Amount";
export const BANK_R4_COLUMN = "Bank Rule – Maximum Amount";

// export const DATE_COLUMN = "bfcDate";
// export const ACCOUNT_COLUMN = "bfcAccount";
// export const TAX_CODE_COLUMN = "bfcTaxCode";
// export const DESCRIPTION_COLUMN = "bfcDescription";
// export const CONTACT_COLUMN = "bfcContactCode";
// export const SITE_COLUMN = "bfcSiteCode";
// export const AMOUNT1_COLUMN = "bfcAmount";
// export const BALANCE_COLUMN = "Balance";
// export const PRIV_PORT_COLUMN = "Priv Port 1..99";
// export const BANK_R1_COLUMN = "Bank Rule - Search Text";
// export const BANK_R2_COLUMN = "Bank Rule – Ranking";
// export const BANK_R3_COLUMN = "Bank Rule – Minimum Amount";
// export const BANK_R4_COLUMN = "Bank Rule – Maximum Amount";
