import dayjs from "dayjs";
import { create } from "zustand";

import { PeriodType } from "../../../../components/ui/date-predefined-period-picker";
import { GeneralLedgerGridRS1RequestBody } from "../../../../types/api/general-ledger";
import { createSelectors } from "../../../../utils/createSelectors";
// import { Client } from "../../../accounting/api/list-of-contacts";
// import { Site } from "../../../accounting/api/get-sites";
// import { Account } from "../../../../types/api/accounts";

type IDate = dayjs.Dayjs | null;

type ShowFilters = {
  cashMethod: boolean;
  payrollByPayDate: boolean;
  showDeptSiteYN: boolean;
  showUserYN: boolean;
  showRecDateYN: boolean;
  showCntCodeYN: boolean;
  showIfNoTransactionsYN: boolean;
  showPrintDetailsYN: boolean;
};

interface State {
  startDate: IDate;
  endDate: IDate;
  periodType: PeriodType;
  // selectedSites: Site[];
  // selectedAccounts: Account[];
  // selectedContacts: Client[];
  selectedSites: string[];
  selectedAccounts: number[];
  selectedContacts: string[];
  reconciliationStatus: GeneralLedgerGridRS1RequestBody["ReconciliationStatus_GE9"];
  recordStatus: GeneralLedgerGridRS1RequestBody["RecordStatus_GE11"];
  showFilters: ShowFilters;
}

interface Actions {
  setStartDate: (startDate: IDate) => void;
  setEndDate: (endDate: IDate) => void;
  setPeriodType: (periodType: PeriodType) => void;
  // setSelectedAccounts: (selectedAccounts: Account[]) => void;
  // setSelectedSites: (selectedSites: Site[]) => void;
  // setSelectedContacts: (selectedContacts: Client[]) => void;
  setSelectedAccounts: (selectedAccounts: number[]) => void;
  setSelectedSites: (selectedSites: string[]) => void;
  setSelectedContacts: (selectedContacts: string[]) => void;
  setReconciliationStatus: (
    reconciliationStatus: State["reconciliationStatus"],
  ) => void;
  setRecordStatus: (recordStatus: State["recordStatus"]) => void;
  setShowFilterByType: (
    filterType: keyof ShowFilters,
    checked: boolean,
  ) => void;
}

export const useGeneralLedgerStoreBase = create<State & Actions>()((set) => ({
  // STATE
  startDate: null,
  endDate: null,
  periodType: "custom",
  selectedSites: [],
  selectedAccounts: [],
  selectedContacts: [],
  reconciliationStatus: "1",
  recordStatus: "1",
  showFilters: {
    cashMethod: false,
    payrollByPayDate: false,
    showDeptSiteYN: false,
    showUserYN: false,
    showRecDateYN: false,
    showCntCodeYN: false,
    showIfNoTransactionsYN: false,
    showPrintDetailsYN: false,
  },

  // ACTIONS
  setStartDate: (startDate) => set(() => ({ startDate })),
  setEndDate: (endDate) => set(() => ({ endDate })),
  setPeriodType: (periodType) => set(() => ({ periodType })),
  setSelectedSites: (selectedSites) => set(() => ({ selectedSites })),
  setSelectedAccounts: (selectedAccounts) => set(() => ({ selectedAccounts })),
  setSelectedContacts: (selectedContacts) => set(() => ({ selectedContacts })),
  setReconciliationStatus: (reconciliationStatus) =>
    set(() => ({ reconciliationStatus })),
  setRecordStatus: (recordStatus) => set(() => ({ recordStatus })),
  setShowFilterByType: (filterType, checked) =>
    set((state) => ({
      showFilters: {
        ...state.showFilters,
        [filterType]: checked,
      },
    })),
}));

export const useLedgedStore = createSelectors(useGeneralLedgerStoreBase);
