import React from "react";
import {
  Box,
  Typography,
  Checkbox,
  TextField,
  FormControlLabel,
  useTheme,
  useMediaQuery,
} from "@mui/material";

interface TimesheetOtherProps {
  markForAttention: boolean;
  onMarkForAttentionChange: (value: boolean) => void;
  note: string;
  onNoteChange: (value: string) => void;
}

const TimesheetOther = ({
  markForAttention,
  onMarkForAttentionChange,
  note,
  onNoteChange,
}: TimesheetOtherProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box>
      <Typography variant="subtitle1" mb={2}>
        Other
      </Typography>

      <Box
        display="flex"
        gap={2}
        alignItems="center"
        flexDirection={isMobile ? "column" : "row"}
        sx={{
          "& > *": {
            width: isMobile ? "100%" : "auto",
          },
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={markForAttention}
              onChange={(e) => onMarkForAttentionChange(e.target.checked)}
            />
          }
          label="Mark for attention"
          sx={{
            minWidth: isMobile ? "100%" : "auto",
            flexShrink: 0,
          }}
        />

        <TextField
          label="Note"
          size="small"
          fullWidth
          multiline
          rows={1}
          value={note}
          onChange={(e) => onNoteChange(e.target.value)}
          sx={{
            flex: isMobile ? "none" : 1,
          }}
        />
      </Box>
    </Box>
  );
};

export default TimesheetOther;
