import React from "react";
import { Box, Typography } from "@mui/material";
import { SearchInput } from "../../../components/ui/inputs/search-input";
import { DownloadButton } from "../../../components/ui/buttons/download-button";

interface NewPaymentToolbarProps {
  onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSearchClear: () => void;
  onExport: () => void;
}

export const NewPaymentToolbar: React.FC<NewPaymentToolbarProps> = ({
  onSearchChange,
  onSearchClear,
  onExport,
}) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      flexWrap="wrap"
      gap={3}
      sx={{ marginBottom: 3, marginTop: 1 }}
    >
      <Box>
        {/* <Typography variant="body1">Payment entries: </Typography> */}
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        gap={3}
        flexWrap="wrap"
      >
        <SearchInput onChange={onSearchChange} onClear={onSearchClear} />
        <DownloadButton title="Export" onClick={onExport} />
      </Box>
    </Box>
  );
};
