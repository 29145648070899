import React from "react";
import { Box } from "@mui/material";

interface DataGridWrapperProps {
  isFullScreen?: boolean;
  height?: string;
  children: React.ReactNode;
}

export const DataGridWrapper = ({
  isFullScreen,
  children,
  height,
}: DataGridWrapperProps) => {
  return (
    <Box
      component="div"
      boxShadow="0px 0px 10px 0px rgba(69, 90, 100, 0.10)"
      marginTop={3}
      // paddingX={10}
      paddingTop={2}
      paddingBottom={4}
      // height={height ? height : "calc(100vh - 300px)"}
      position="relative"
      left={isFullScreen ? "50%" : 0}
      right={isFullScreen ? "50%" : 0}
      marginLeft={isFullScreen ? "-50vw" : 0}
      marginRight={isFullScreen ? "-50vw" : 0}
      paddingLeft={isFullScreen ? "10px" : "24px"}
      paddingRight={isFullScreen ? "10px" : "24px"}
      width={isFullScreen ? "calc(100vw)" : "100%"}
      sx={{
        transition: "all 1s ease",
      }}
    >
      {children}
    </Box>
  );
};
