import React, { createContext, useEffect } from "react";
import { useLoginPopup } from "./LoginPopupContext";
import { getCookie } from "../utils/api";
import envConfig from "../config";
import { useLocation } from "react-router-dom";
import { differenceInMinutes, parseISO } from "date-fns";
import { DateTime } from "luxon";

const AuthContext = createContext();

const PAYROLL_PAGES_REAUTH = ["/payroll/"];

const AuthProvider = ({ children }) => {
  const { handleOpen, handleClose, setIsReAuthCompleted } = useLoginPopup();
  const location = useLocation();

  const checkAuth = (isPayrollpage) => {
    if (process.env.NODE_ENV === "development") {
      return;
    }

    const apiKeyExpiry = getCookie("apiKeyExpirationUTCDate");

    if (!apiKeyExpiry) {
      handleOpen();
      return;
    }

    const currentTimeUTC = new Date().toUTCString();
    const storedTimeUTC = new Date(apiKeyExpiry + "Z").toUTCString();
    const differenceAPIKey =
      new Date(storedTimeUTC).getTime() - new Date(currentTimeUTC).getTime();

    const maxDiffAPIKey = envConfig.loginUrl.includes(".app") ? 1 : 0.1; // in minutes
    if (differenceAPIKey <= maxDiffAPIKey * 60 * 1000) {
      handleOpen();
      return;
    }

    // **2. Additional Re-Authentication Checks for Specific Pages**
    // Check if the current path matches any of the specified pages

    if (isPayrollpage) {
      const last2FaUsedUTC = getCookie("login2FaCodeLastUsedUTCDate");
      const lastLoginPwdUTC = getCookie("loginLastPwdUTCDate");

      const isOlderThan30Minutes = (utcDate) => {
        console.log(utcDate);
        if (!utcDate) return true; // Treat missing dates as older than 30 minutes

        try {
          // Parse the UTC date string into a Luxon DateTime object in UTC
          const parsedDate = DateTime.fromISO(utcDate, { zone: "utc" });

          // Get the current UTC time
          const now = DateTime.utc();

          // Calculate the difference in minutes
          const diffMinutes = now
            .diff(parsedDate, "minutes")
            .toObject().minutes;

          return diffMinutes > 30;
        } catch (error) {
          console.error("Error parsing UTC Date:", error);
          return true; // On error, treat as older than 30 minutes
        }
      };
      const needsReAuth =
        isOlderThan30Minutes(last2FaUsedUTC) ||
        isOlderThan30Minutes(lastLoginPwdUTC);

      if (needsReAuth) {
        handleOpen("re-auth");
      } else {
        setIsReAuthCompleted(true);
      }
    }
  };

  useEffect(() => {
    const isPayrollpage = PAYROLL_PAGES_REAUTH.some((path) =>
      location.pathname.includes(path),
    );
    if (isPayrollpage) {
      checkAuth(true);
      // handleOpen("re-auth");
    } else {
      handleClose();
    }

    const interval = setInterval(() => {
      checkAuth(isPayrollpage);
    }, 5000); // Every 5 seconds

    return () => clearInterval(interval);
  }, [location.pathname]);

  return <AuthContext.Provider value={{}}>{children}</AuthContext.Provider>;
};

export { AuthContext, AuthProvider };
