import React from "react";
import {
  Autocomplete,
  Box,
  FormControl,
  ListItem,
  TextField,
} from "@mui/material";

import { useTheme } from "../../../../context/ThemeContext";
import { commonThemes } from "../../../../utils/themes/themes";
import {
  Contact,
  useListOfContacts,
} from "../../../accounting/api/list-of-contacts";
import { useLedgedStore } from "../store";
import { VirtualizedAutocomplete } from "../../../../components/ui/autocomlete/virtualized-autocomplete";

export const GeneralLedgerContacts = () => {
  const { isDarkMode } = useTheme();
  const { data, isLoading } = useListOfContacts({});

  const selectedContacts = useLedgedStore.use.selectedContacts();
  const setSelectedContacts = useLedgedStore.use.setSelectedContacts();

  // @ts-ignore
  const contacts = data?.ListOfContacts || [];
  const values = contacts.filter((contact) =>
    selectedContacts.includes(contact.cntCode),
  );

  return (
    <FormControl sx={{ mt: 1 }} size="small" variant="outlined">
      <VirtualizedAutocomplete
        options={contacts}
        getOptionLabel={(option: Contact) => `${option.cntCode}`}
        value={values}
        onChange={(_, newValue: Contact[]) => {
          setSelectedContacts(newValue.map((v) => v.cntCode));
        }}
        label="Contacts"
        width={240}
        otherAutoCompleteProps={{
          multiple: true,
          id: "contacts-autocomplete",
          isLoading: isLoading,
          sx: {
            width: "240px",
            "& .MuiInputBase-root": {
              width: "240px",
            },
          },
        }}
      />
    </FormControl>
  );
};
