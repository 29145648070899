import React from "react";
import { useSnackbar } from "notistack";
import useApi from "../../../hooks/useApi";
import envConfig from "../../../config";
import { CheckPayEventResponse } from "../types";

export const useCheckPayEventBeforeApproving = ({
  id,
  onSuccess,
  onError,
}: {
  id: string;
  onSuccess?: (response: CheckPayEventResponse) => void;
  onError?: (error: string) => void;
}) => {
  const { apiCall } = useApi();
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = React.useState(false);
  const [isError, setIsError] = React.useState(false);

  const checkPayEvent = React.useCallback(async () => {
    setIsLoading(true);
    setIsError(false);

    try {
      await apiCall({
        url: `https://${envConfig.apiDev2}/api/en-au/payroll-au/check-pay-event-before-approving?BaseHostURL=${envConfig.mainServiceUrl}`,
        method: "POST",
        // @ts-ignore body type mismatch with null | undefined
        body: {
          PrlAu004: "",
          PevID: id,
        },
        // @ts-ignore
        onSuccess: (response: CheckPayEventResponse) => {
          console.log("[DEBUGGING][CHECK PAY EVENT] response: ", response);
          if (onSuccess) onSuccess(response);
        },
        // @ts-ignore
        onError: (error: string) => {
          setIsError(true);
          enqueueSnackbar(error, { variant: "error" });
          if (onError) onError(error);
        },
      });
    } catch (error) {
      console.error("checkPayEvent error", error);
      setIsError(true);
      enqueueSnackbar((error as Error).message, { variant: "error" });
      if (onError) onError((error as Error).message);
    } finally {
      setIsLoading(false);
    }
  }, [apiCall, enqueueSnackbar, id, onSuccess, onError]);

  return {
    isLoading,
    isError,
    checkPayEvent,
  };
};
