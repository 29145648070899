import React from "react";
import {
  DataGrid,
  Column,
  Summary,
  TotalItem,
} from "devextreme-react/cjs/data-grid";
import { Button, Box } from "@mui/material";
import { PaymentDetail } from "../types/invoice-payment";
import { useSnackbar } from "notistack";
import NumberField from "../../../components/ui/inputs/number-input";

interface NewPaymentDataGridProps {
  paymentDetails: (PaymentDetail & { allocated: number })[];
  onAllocationChange: (rowIndex: number, newValue: number) => void;
  amount: number;
}

export const NewPaymentDataGrid = React.forwardRef<
  DataGrid<PaymentDetail>,
  NewPaymentDataGridProps
>(({ paymentDetails, onAllocationChange, amount }, ref) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleAllocation = React.useCallback(
    (rowIndex: number, value: number, maxAmount: number) => {
      onAllocationChange(rowIndex, value);
    },
    [onAllocationChange],
  );

  const cellButtonRender = React.useCallback(
    (cellData) => (
      <Button
        variant="contained"
        size="small"
        onClick={() =>
          handleAllocation(
            cellData.rowIndex,
            cellData.data.documentDueAmt || 0,
            cellData.data.documentDueAmt || 0,
          )
        }
      >
        Pay
      </Button>
    ),
    [handleAllocation],
  );

  const cellInputRender = React.useCallback(
    (cellData) => {
      const totalAllocatedExcludingCurrent = paymentDetails.reduce(
        (sum, detail, index) =>
          index === cellData.rowIndex ? sum : sum + detail.allocated,
        0,
      );
      const remainingAmount = amount - totalAllocatedExcludingCurrent;
      const maxAllowedAmount = Math.min(
        cellData.data.documentDueAmt || 0,
        remainingAmount,
      );

      return (
        <NumberField
          value={paymentDetails[cellData?.rowIndex]?.allocated || 0}
          onChange={(value) => {
            if (value > maxAllowedAmount) {
              enqueueSnackbar(
                `Maximum amount for this invoice is ${maxAllowedAmount}`,
                { variant: "warning" },
              );
              handleAllocation(cellData.rowIndex, 0, maxAllowedAmount);
            } else {
              handleAllocation(cellData.rowIndex, value, maxAllowedAmount);
            }
          }}
          sx={{
            width: "100%",
            "& .MuiOutlinedInput-notchedOutline": {
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              borderBottom: "1px solid rgba(0, 0, 0, 0.23)",
              borderRadius: 0,
            },
            "& .MuiInputLabel-root": {
              display: "none",
            },
          }}
        />
      );
    },
    [handleAllocation, paymentDetails, amount, enqueueSnackbar],
  );

  return (
    <DataGrid
      ref={ref}
      keyExpr="documentNo"
      dataSource={paymentDetails}
      showBorders={false}
      rowAlternationEnabled={true}
      columnAutoWidth={true}
      wordWrapEnabled={true}
      showColumnLines={true}
      showRowLines={true}
      repaintChangesOnly
      // editing={{
      //   mode: "cell",
      //   allowUpdating: true,
      // }}
      // onRowUpdating={(e: {
      //   key: number;
      //   oldData: PaymentDetail;
      //   newData: Partial<PaymentDetail & { allocated: number }>;
      // }) => {
      //   if (typeof e.newData.allocated === "number") {
      //     console.log("e", e);
      //     validateAndHandleAllocation(
      //       e.oldData.documentNo,
      //       e.newData.allocated,
      //     );
      //   }
      // }}
    >
      <Column
        dataField="documentNo"
        caption="Number"
        alignment="left"
        allowSearch
      />
      <Column
        dataField="documentType"
        caption="Type"
        alignment="left"
        allowSearch
      />
      <Column
        dataField="documentDueDate"
        caption="Due Date"
        alignment="left"
        dataType="date"
        format="dd/MM/yyyy"
        allowSearch
      />
      <Column
        dataField="documentDueAmt"
        caption="Due Amount"
        alignment="left"
        format={{
          type: "fixedPoint",
          precision: 2,
        }}
        allowSearch
      />
      <Column
        dataField="allocated"
        caption="Allocated"
        alignment="left"
        cellRender={cellInputRender}
        width={200}
        allowEditing={false}
      />
      <Column
        dataField=""
        caption=""
        alignment="right"
        cellRender={cellButtonRender}
        width={100}
      />

      <Summary>
        <TotalItem
          column="Number"
          summaryType="count"
          displayFormat="Total records: {0}"
        />
        <TotalItem
          column="documentDueAmt"
          summaryType="sum"
          displayFormat="{0}"
          valueFormat={{
            type: "fixedPoint",
            precision: 2,
          }}
        />
        <TotalItem
          column="allocated"
          summaryType="sum"
          displayFormat="Total allocated: {0}"
          valueFormat={{
            type: "fixedPoint",
            precision: 2,
          }}
        />
      </Summary>
    </DataGrid>
  );
});
