import { Box } from "@mui/material";
import BankFeedsNotConnected from "./BankFeedsNotConnected";

const BankFeedsConnected = ({
  handleActivate,
  onLinkAnother,
  handleDeactivate,
  data,
}) => {
  return (
    <BankFeedsNotConnected
      handleDeactivate={handleDeactivate}
      onLinkAnother={onLinkAnother}
      handleActivate={handleActivate}
      data={data?.map((row, index) => ({ ...row, id: index }))}
    />
  );
};

export default BankFeedsConnected;
