import React, { useState, useEffect } from "react";
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
  useMediaQuery,
  InputLabel,
  Typography,
  Box,
  Checkbox,
  Button,
  TextField,
} from "@mui/material";
import {
  addMonths,
  endOfMonth,
  addQuarters,
  endOfQuarter,
  endOfDay,
} from "date-fns";
import { darkTheme, lightTheme } from "../../utils/themes/themes";
import "dayjs/locale/en-gb";
import dayjs from "dayjs";
import { useTheme } from "../../context/ThemeContext";
// import PageTitle from "../../components/PageTitle/PageTitle";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { downloadPDF } from "../../utils/reusable";
import { useLoginPopup } from "../../context/LoginPopupContext";
import BalanceSheetSettings from "../../components/BalanceSheetSettings/BalanceSheetSettings";
import BalanceSheetTable from "../../components/BalanceSheetTable/BalanceSheetTable";
import BackdropLoading from "../../components/BackdropLoading/BackdropLoading";
import { enqueueSnackbar } from "notistack";
import envConfig from "../../config";
import useApi from "../../hooks/useApi";
import { useGetIP } from "../../hooks/get-ip";
import { EditButton } from "../../components/ui/buttons/edit-button";
import { DataGridWrapper } from "../../components/ui/table/data-grid-wrapper";
import { getHeaders } from "../../utils/api";
import { PageTitle } from "../../components/page-title";

const controllerName = "accounting";
const actionName = "get-balance-sheet-grid-rs1";
const apiUrl = `https://${envConfig.apiDev2}/api/en-au/${controllerName}/${actionName}?BaseHostURL=${envConfig.mainServiceUrl}`;

const BalanceSheet = () => {
  const { isDarkMode } = useTheme();
  const { handleOpen } = useLoginPopup();
  const navigate = useNavigate();
  const { dbId, lang } = useParams();
  const prefix = `/${dbId}/${lang}/`;
  const theme = isDarkMode ? darkTheme : lightTheme;
  const isMobile = useMediaQuery("(max-width: 580px)");
  const breakDropdowns = useMediaQuery("(max-width: 880px)");
  const isScreenBelow1050px = useMediaQuery("(max-width: 1150px)");
  const breakAdvanced = useMediaQuery("(max-width: 847px)");

  const { apiCall } = useApi();
  const ip = useGetIP();

  const location = useLocation();

  const [openFilters, setOpenFilters] = useState(true);

  const [sortByAccountName, setSortByAccountName] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [compareValue, setCompareValue] = useState(1);
  const [compareYearsValue, setCompareYearsValue] = useState(1);
  const [periodType, setPeriodType] = useState("custom");
  const [showAccountsNumbers, setShowAccountsNumbers] = useState(true);
  const [showZeroBalanceAccounts, setShowZeroBalanceAccounts] = useState(false);
  const [compareChoice, setCompareChoice] = useState("none");
  const [groupsOrAccounts, setGroupsOrAccounts] = useState("accountsOnly");
  const [selectedSites, setSelectedSites] = useState([]);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [predefinedPeriod, setPredefinedPeriod] = useState("year");

  const [sortBy, setSortBy] = useState("Account Number");
  const [lastUsedParams, setLastUsedParams] = useState({});
  const [showDownloadDate, setShowDownloadDate] = useState(false);

  const [openFullscreen, setOpenFullscreen] = useState(false);

  const numberOfFilters =
    Number(compareChoice === "earlierPeriods") +
    Number(compareChoice === "earlierYears") +
    Number(showAccountsNumbers) +
    Number(showZeroBalanceAccounts) +
    selectedSites?.length +
    selectedContacts?.length +
    selectedUsers?.length;

  const [loading, setLoading] = useState(false);

  const [gridData, setGridData] = useState(null);

  const generatePDF = async () => {
    const controllerName = "accounting";
    const actionName = "get-balance-sheet-simple-pdf-v2";
    const pdfUrl = `https://${envConfig.apiDev2}/api/en-au/${controllerName}/${actionName}?BaseHostURL=${envConfig.mainServiceUrl}`;
    const headers = getHeaders();

    setLoading(true);

    await apiCall({
      url: pdfUrl,
      ip: ip,
      method: "POST",
      body: {
        ...lastUsedParams,
        ShowAccountNumbersYN: showAccountsNumbers ? "Y" : "N",
        SetColumnNamesForPdfYN: showDownloadDate ? "Y" : "N",
        SortBy_M_Name_N_Number: sortBy === "Account Name" ? "M" : "N",
      },
      onSuccess: async (result) => {
        if (result?.ReportBase64) {
          const isPDFDownloaded = await downloadPDF(
            result?.ReportBase64,
            result?.FileName,
          );
          if (isPDFDownloaded) {
            setLoading(false);
          } else {
            setLoading(false);
          }
        }
      },
      onError: (errorMessage) => {
        enqueueSnackbar(errorMessage, { variant: "error" });
        setLoading(false);
      },
    });
  };

  const handleEndDateChange = (newEndDate) => {
    setDateRange([dateRange[0], newEndDate]);
    setPeriodType("custom");
  };

  const handlePeriodTypeChange = (event) => {
    setPeriodType(event.target.value);
  };

  const updateUrlParams = (val, yearsVal) => {
    let endDate;

    if (periodType === "custom") {
      endDate = dateRange?.at(1);
      if (!endDate) return;
    } else {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      if (periodType === "today") {
        endDate = endOfDay(new Date());
      } else if (periodType === "end_of_previous_month") {
        endDate = endOfMonth(addMonths(currentDate, -1));
      } else if (periodType === "end_of_previous_quarter") {
        endDate = endOfQuarter(addQuarters(currentDate, -1));
      } else if (periodType === "end_of_previous_year") {
        endDate =
          currentDate >= new Date(currentYear, 6, 1)
            ? new Date(currentYear, 5, 30)
            : new Date(currentYear - 1, 5, 30);
      }
    }

    let eYear, eMonth, eDay;
    if (!endDate) return;
    if (endDate.$d) {
      eYear = endDate.year();
      eMonth = endDate.month() + 1;
      eDay = endDate.date();
    } else {
      console.log("l");
      console.log(endDate.getFullYear());
      eYear = endDate.getFullYear();
      eMonth = endDate.getMonth() + 1;
      eDay = endDate.getDate();
    }

    eMonth = eMonth < 10 ? "0" + eMonth : eMonth;
    eDay = eDay < 10 ? "0" + eDay : eDay;

    const formattedEndDate = `${eYear}-${eMonth}-${eDay}`;

    const params = new URLSearchParams({
      endDate: formattedEndDate,
      periodType,
      compareChoice,
      compareValue: val || compareValue,
      compareYearsValue: yearsVal || compareYearsValue,
      predefinedPeriod,
      showAccountsNumbers: showAccountsNumbers ? "Y" : "N",
      showZeroBalanceAccounts: showZeroBalanceAccounts ? "Y" : "N",
      groupsOrAccounts,
      selectedSites: selectedSites.map((site) => site.dpsID).join(","),
      selectedContacts: selectedContacts
        .map((contact) => contact.cntId)
        .join(","),
      selectedUsers: selectedUsers.map((user) => user.usrID).join(","),
    });

    navigate(`?${params.toString()}`);
  };

  useEffect(() => {
    if (periodType === "custom") {
      return;
    } else {
      let endDate;
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();

      if (periodType === "today") {
        endDate = endOfDay(new Date());
      } else if (periodType === "end_of_previous_month") {
        endDate = endOfMonth(addMonths(currentDate, -1));
      } else if (periodType === "end_of_previous_quarter") {
        endDate = endOfQuarter(addQuarters(currentDate, -1));
      } else if (periodType === "end_of_previous_year") {
        if (currentDate >= new Date(currentYear, 6, 1)) {
          endDate = new Date(currentYear, 5, 30);
        } else {
          endDate = new Date(currentYear - 1, 5, 30);
        }
      }
      if (!endDate) return;

      console.log(endDate);
      const formattedEndDate = new Date(
        Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()),
      )
        .toISOString()
        .split("T")[0];

      console.log(formattedEndDate);

      const dayjsEndDate = dayjs(formattedEndDate);
      setDateRange([null, dayjsEndDate]);
    }
  }, [periodType]);

  const onAddEarlierPeriod = () => {
    setCompareValue((prev) => +prev + 1);
    setCompareYearsValue((prev) => +prev + 1);

    if (compareValue === 0) {
      handleApplyButtonClick(
        Number(compareValue) + 2,
        Number(compareYearsValue) + 2,
      );
    } else {
      handleApplyButtonClick(
        Number(compareValue) + 1,
        Number(compareYearsValue) + 1,
      );
    }
  };

  const onApply = async () => {
    const params = new URLSearchParams(location.search);

    console.log('params.get("compareValue")', params.get("compareValue"));
    console.log("+1 ", Number(params.get("compareValue")) + 1);

    const requestData = {
      EndDate: params.get("endDate"),
      PreviousPeriodType_WFMQY:
        params.get("compareChoice") === "earlierYears" ||
        params.get("compareChoice") === "earlierPeriods"
          ? params.get("predefinedPeriod") === "week"
            ? "W"
            : params.get("predefinedPeriod") === "fortnight"
              ? "F"
              : params.get("predefinedPeriod") === "month"
                ? "M"
                : params.get("predefinedPeriod") === "quarter"
                  ? "Q"
                  : "Y"
          : null,
      DpsList: params.get("selectedSites")?.split(","),
      UsrList: params.get("selectedUsers")?.split(","),
      CntList: params.get("selectedContacts")?.split(","),
      ShowZeroAmountsYN: params.get("showZeroBalanceAccounts"),
      ShowAccountNumbersYN: "N",
      NumberOfPeriods:
        params.get("compareChoice") === "none"
          ? 1
          : Number(params.get("compareValue")) + 1,
      PresentationTypeAccountsHeadersBothAHB:
        params.get("groupsOrAccounts") === "groupsOnly"
          ? "H"
          : params.get("groupsOrAccounts") === "accountsOnly"
            ? "A"
            : "B",
    };

    setLastUsedParams(requestData);

    setLoading(true);

    await apiCall({
      url: apiUrl,
      ip: ip,
      method: "POST",
      body: requestData,
      onSuccess: (result) => {
        setGridData(result);
        setLoading(false);
      },
      onError: (errorMessage) => {
        enqueueSnackbar(errorMessage, { variant: "error" });
        setGridData(null);
        setLoading(false);
      },
    });
  };

  const handleApplyButtonClick = (val, yearsVal) => {
    updateUrlParams(val, yearsVal);
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    console.log("params", params);
    console.log('params.get("endDate")', params.get("endDate"));
    if (params.get("endDate")) {
      setOpenFilters(false);
      onApply();
    }
  }, [location.search]);

  return (
    <>
      <BackdropLoading open={loading} />
      <BalanceSheetSettings
        predefinedPeriod={predefinedPeriod}
        setPredefinedPeriod={setPredefinedPeriod}
        periodType={periodType}
        setPeriodType={setPeriodType}
        dateRange={dateRange}
        setDateRange={setDateRange}
        showAccountsNumbers={showAccountsNumbers}
        setShowAccountsNumbers={setShowAccountsNumbers}
        showZeroBalanceAccounts={showZeroBalanceAccounts}
        setShowZeroBalanceAccounts={setShowZeroBalanceAccounts}
        compareValue={compareValue}
        setCompareValue={setCompareValue}
        compareYearsValue={compareYearsValue}
        setCompareYearsValue={setCompareYearsValue}
        open={openFilters}
        setGridData={setGridData}
        compareChoice={compareChoice}
        setCompareChoice={setCompareChoice}
        groupsOrAccounts={groupsOrAccounts}
        setGroupsOrAccounts={setGroupsOrAccounts}
        selectedSites={selectedSites}
        setSelectedSites={setSelectedSites}
        selectedContacts={selectedContacts}
        setSelectedContacts={setSelectedContacts}
        selectedUsers={selectedUsers}
        setSelectedUsers={setSelectedUsers}
        sortByAccountName={sortByAccountName}
        setSortByAccountName={setSortByAccountName}
        onClose={() => {
          setOpenFilters(false);
        }}
        setLastUsedParams={setLastUsedParams}
      />

      <Box display={"flex"} alignItems={"center"} gap={2} marginTop={2}>
        <Box sx={{ mr: 3 }}>
          <PageTitle title="Balance Sheet" />
        </Box>
        {dateRange?.at(1) && (
          <Typography component={"p"} fontSize={14} fontWeight={700}>
            {`As of ${dateRange[1].format("DD/MM/YYYY")}`}
          </Typography>
        )}
        <EditButton title="Update" onClick={() => setOpenFilters(true)} />
      </Box>

      <DataGridWrapper isFullScreen={openFullscreen}>
        <BalanceSheetTable
          openFullscreen={openFullscreen}
          setOpenFullscreen={setOpenFullscreen}
          gridData={gridData}
          onApply={onApply}
          loading={loading}
          onAddEarlierPeriod={onAddEarlierPeriod}
          generatePDF={generatePDF}
          sortBy={sortBy}
          setSortBy={setSortBy}
          showDownloadDate={showDownloadDate}
          setShowDownloadDate={setShowDownloadDate}
          showAccountsNumbers={showAccountsNumbers}
        />
      </DataGridWrapper>
    </>
  );
};

export default BalanceSheet;
