import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import envConfig from "../../../../config";
import { getHeaders } from "../../../../utils/api";
import { useParams } from "react-router-dom";

export interface AccountSelection {
  accNo: number;
  accName: string;
  accType: string;
}

interface AccountsSelectionListResponse {
  SuccessYN: string;
  ErrorMessage: string;
  ListOfAccounts: AccountSelection[];
}

const EXAMPLE_ACCOUNTS_SELECTION_LIST_RESPONSE: AccountsSelectionListResponse =
  {
    SuccessYN: "Y",
    ErrorMessage: "",
    ListOfAccounts: [
      {
        accNo: 1000,
        accName: "Business account",
        accType: "Current Assets",
      },
      {
        accNo: 1530,
        accName: "Cars at cost",
        accType: "Non-Current Assets",
      },
      {
        accNo: 7100,
        accName: "Cars at cost",
        accType: "Non-Current Assets",
      },
    ],
  };

export const GET_ACCOUNTS_SELECTION_LIST_KEY =
  "GET_ACCOUNTS_SELECTION_LIST_KEY";

export const getAccountsSelectionList = async (
  dbId: string,
  activeYN: string = "Y",
  postableYN: string = "N",
): Promise<AccountsSelectionListResponse> => {
  const headers = getHeaders(dbId);
  const api = `https://${envConfig.apiDev1Exacc}/api/v1/en-au/accounts/get-selection-list?BaseHostURL=${envConfig.mainServiceUrl}&activeYN=${activeYN}&postableYN=${postableYN}`;

  if (process.env.NODE_ENV === "development") {
    return EXAMPLE_ACCOUNTS_SELECTION_LIST_RESPONSE;
  }

  const response = await fetch(api, {
    method: "GET",
    // @ts-ignore
    headers: {
      ...headers,
    },
  });

  if (!response.ok) {
    throw new Error(
      `Failed to get Accounts Selection List: ${response.statusText}`,
    );
  }

  const result = await response.json();

  if (result?.SuccessYN === "Y" || result?.successYN === "Y") {
    return result;
  } else {
    throw new Error(
      `Failed to return Accounts Selection List: ${JSON.stringify(result)}`,
    );
  }
};

type UseGetAccountsSelectionList = {
  activeYN?: string;
  postableYN?: string;
  queryOptions?: Omit<
    UseQueryOptions<
      AccountsSelectionListResponse,
      Error,
      AccountsSelectionListResponse
    >,
    "queryKey" | "queryFn"
  >;
};

export const useAccountsSelectionList = ({
  activeYN = "Y",
  postableYN = "N",
  queryOptions = {},
}: UseGetAccountsSelectionList = {}) => {
  const { dbId } = useParams();

  return useQuery({
    ...queryOptions,
    queryKey: [GET_ACCOUNTS_SELECTION_LIST_KEY, activeYN, postableYN],
    queryFn: () => getAccountsSelectionList(dbId!, activeYN, postableYN),
  });
};
