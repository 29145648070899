import { TextField } from "@mui/material";
import { useState } from "react";

export const FormattedTextInput = ({
  label,
  value = "",
  valueKey,
  loading,

  isFieldRequired = false,
  isFieldValid = true,
  errorMessage = "",
  maxWidth,
  multiline = false,
  regexPattern = /^\w{3}-\w{3}$/, // Pattern for ###-### format
  customErrorMessage = "Invalid format. Expected format is ###-###",

  onChange = () => {},
  onBlur = () => {},
  formTextStyles = {},
  rows = 1,
  disabled = false,
}) => {
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState(errorMessage);

  const handleChange = (e) => {
    const newValue = e.target.value;
    if (!regexPattern.test(newValue) && newValue !== "") {
      setError(true);
      setHelperText(customErrorMessage);
    } else {
      setError(false);
      setHelperText("");
    }
    onChange(valueKey, newValue);
  };

  const handleBlur = (e) => {
    if (regexPattern.test(e.target.value)) {
      onBlur(valueKey, e.target.value);
    }
  };

  return (
    <TextField
      size="small"
      label={
        <span>
          {label}
          {isFieldRequired ? <span style={{ color: "red" }}> *</span> : null}
        </span>
      }
      disabled={loading || disabled}
      variant="outlined"
      error={!isFieldValid || error}
      helperText={helperText || errorMessage}
      value={value}
      multiline={multiline}
      rows={rows}
      onChange={handleChange}
      onBlur={handleBlur}
      fullWidth
      sx={{
        maxWidth: maxWidth,
        padding: 0,
        ".MuiOutlinedInput-root": {
          borderRadius: "8px",
        },
        ".MuiInputLabel-outlined": {
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        ...formTextStyles,
      }}
      padding={0}
    />
  );
};
