import React from "react";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import useApi from "../../../hooks/useApi";
import envConfig from "../../../config";

export const useReopenPayEvent = ({
  id,
  onSuccess,
  onError,
}: {
  id: string;
  onSuccess?: () => void;
  onError?: (error: string) => void;
}) => {
  const { apiCall } = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { dbId } = useParams();

  const [isLoading, setIsLoading] = React.useState(false);
  const [isError, setIsError] = React.useState(false);

  const reopenPayEvent = React.useCallback(async () => {
    setIsLoading(true);
    setIsError(false);

    try {
      await apiCall({
        url: `https://${envConfig.apiDev2}/api/en-au/payroll-au/reopen-pay-event?BaseHostURL=${envConfig.mainServiceUrl}`,
        method: "POST",
        // @ts-ignore
        body: {
          PrlAu1103: "",
          PevID: id,
        },
        // @ts-ignore
        onSuccess: () => {
          if (onSuccess) onSuccess();
          enqueueSnackbar("Pay event reopened successfully", {
            variant: "success",
          });
          navigate(`/${dbId}/en-au/payroll/pay-event-open/${id}`);
        },
        // @ts-ignore
        onError: (error: string) => {
          setIsError(true);
          enqueueSnackbar(error, { variant: "error" });
          if (onError) onError(error);
        },
      });
    } catch (error) {
      console.error("reopenPayEvent error", error);
      setIsError(true);
      enqueueSnackbar((error as Error).message, { variant: "error" });
      if (onError) onError((error as Error).message);
    } finally {
      setIsLoading(false);
    }
  }, [apiCall, enqueueSnackbar, id, navigate, onSuccess, onError, dbId]);

  return {
    isLoading,
    isError,
    reopenPayEvent,
  };
};
