import envConfig from "../../../../config";
import { useQuery } from "../../../../hooks/useQuery";
import { EXAMPLE_GET_LIST_OF_TAX_CODES_RESPONSE } from "../../../../data/example-get-list-of-tax-codes";

export interface TaxCode {
  txcID: number;
  txcName: string;
  txcRate: number;
  txcCountry: string;
  txcCode: string;
  txcDocName: string;
  txcAccount_accNo: number;
}

export interface GetListOfTaxCodesResponse {
  listOfTaxCodes: TaxCode[];
  errorMessage: string;
  successYN: "Y" | "N";
}

const url = `https://${envConfig.apiDev1Exacc}/api/v1/en-au/tax-codes/get-list-of-tax-codes?BaseHostURL=${envConfig.mainServiceUrl}`;

export const useListOfTaxCodes = () => {
  const { isLoading, isError, error, data, refetch } =
    useQuery<GetListOfTaxCodesResponse>(url);

  if (process.env.NODE_ENV === "development") {
    return {
      isLoading: false,
      isError: false,
      error: null,
      data: EXAMPLE_GET_LIST_OF_TAX_CODES_RESPONSE,
      refetch: () => {},
    };
  }

  return { isLoading, isError, error, data, refetch };
};
