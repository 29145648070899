import dayjs from "dayjs";
import { create } from "zustand";
import { PeriodType } from "../../../../components/ui/date-predefined-period-picker";
import { createSelectors } from "../../../../utils/createSelectors";

type IDate = dayjs.Dayjs | null;

export type ShowFilters = {
  ShowZeroAmountsYN: boolean;
  // ShowAccGroupsYN: boolean;
  // ShowAccountsYN: boolean;
  ShowPreviousPeriodYN: boolean;
  ReportingEntityYN: boolean;
  ShowFooterAuditedUnauditedAU: boolean;
  MultipleSignatoriesYN: boolean;
  CorporateTrusteeYN: boolean;
};

interface State {
  startDate: IDate;
  endDate: IDate;
  predefinedPeriod: PeriodType;
  showFilters: ShowFilters;
  Signatory1: string;
  Signatory2: string;
  CompilantSignatory: string;
  MyFooter: string;
}

interface Actions {
  setStartDate: (startDate: IDate) => void;
  setEndDate: (endDate: IDate) => void;
  setPredefinedPeriod: (predefinedPeriod: PeriodType) => void;
  setShowFilterByType: (
    filterType: keyof ShowFilters,
    checked: boolean,
  ) => void;
  setSignatory1: (signatory1: string) => void;
  setSignatory2: (signatory2: string) => void;
  setCompilantSignatory: (compilantSignatory: string) => void;
  setMyFooter: (myFooter: string) => void;
  reset: () => void;
}

export const useFinancialReportStoreBase = create<State & Actions>()((set) => ({
  // STATE
  startDate: null,
  endDate: null,
  predefinedPeriod: "custom",
  showFilters: {
    ShowZeroAmountsYN: false,
    // ShowAccGroupsYN: false,
    // ShowAccountsYN: false,
    ShowPreviousPeriodYN: false,
    ReportingEntityYN: false,
    ShowFooterAuditedUnauditedAU: false,
    MultipleSignatoriesYN: false,
    CorporateTrusteeYN: false,
  },
  Signatory1: "",
  Signatory2: "",
  CompilantSignatory: "",
  MyFooter: "",

  // ACTIONS
  setStartDate: (startDate) => set(() => ({ startDate })),
  setEndDate: (endDate) => set(() => ({ endDate })),
  setPredefinedPeriod: (predefinedPeriod) => set(() => ({ predefinedPeriod })),
  setShowFilterByType: (filterType, checked) =>
    set((state) => ({
      showFilters: {
        ...state.showFilters,
        [filterType]: checked,
      },
    })),
  setSignatory1: (signatory1) => set(() => ({ Signatory1: signatory1 })),
  setSignatory2: (signatory2) => set(() => ({ Signatory2: signatory2 })),
  setCompilantSignatory: (compilantSignatory) =>
    set(() => ({ CompilantSignatory: compilantSignatory })),
  setMyFooter: (myFooter) => set(() => ({ MyFooter: myFooter })),
  reset: () =>
    set(() => ({
      startDate: null,
      endDate: null,
      predefinedPeriod: "custom",
      showFilters: {
        ShowZeroAmountsYN: false,
        // ShowAccGroupsYN: false,
        // ShowAccountsYN: false,
        ShowPreviousPeriodYN: false,
        ReportingEntityYN: false,
        ShowFooterAuditedUnauditedAU: false,
        MultipleSignatoriesYN: false,
        CorporateTrusteeYN: false,
      },
      Signatory1: "",
      Signatory2: "",
      CompilantSignatory: "",
      MyFooter: "",
    })),
}));

export const useFinancialReportStore = createSelectors(
  useFinancialReportStoreBase,
);
