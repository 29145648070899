import React, { useCallback, useMemo, useRef, useState } from "react";
import { Box } from "@mui/material";
import { Workbook } from "exceljs";
import { exportDataGrid } from "devextreme/excel_exporter";
import { saveAs } from "file-saver-es";
import {
  PayEventSummaryData,
  PayEventSummaryColumn,
  PayEventSummaryAction,
} from "../types";
import { PayrollGridToolbar } from "./PayrollGridToolbar";
import {
  formatGridCellValue,
  getColumnAlignment,
  convertYNtoBool,
  isMoneyColumn,
} from "../utils/grid-helpers";
import { useTheme } from "../../../context/ThemeContext";
import { DataGrid } from "devextreme-react/cjs/data-grid";
import {
  Summary,
  TotalItem,
  Column,
  SearchPanel,
  Sorting,
} from "devextreme-react/cjs/data-grid";
import { DataGridWrapper } from "../../../components/ui/table/data-grid-wrapper";
import { handleGridAction, GridAction } from "../../../utils/grid-actions";
import { useInterfacePreference } from "../../../hooks/use-interface-preference";
import useApi from "../../../hooks/useApi";
import { useParams } from "react-router-dom";
import { ActionMenu } from "../../../components/ui/table/action-menu";

interface PayrollEventGridProps {
  data: PayEventSummaryData[];
  columns: PayEventSummaryColumn[];
  actionsList: PayEventSummaryAction[];
  tooltipsList: any[];
  onRefresh: () => void;
}

export const PayrollEventGrid: React.FC<PayrollEventGridProps> = ({
  data,
  columns,
  actionsList,
  tooltipsList,
  onRefresh,
}) => {
  const { isDarkMode } = useTheme();
  const { apiCall } = useApi();
  const { dbId } = useParams();
  const interfacePreference = useInterfacePreference();

  const gridRef = useRef<DataGrid<PayEventSummaryData>>(null);

  const [isFullScreen, setIsFullScreen] = useState(false);

  const actions: GridAction[] = useMemo(
    () =>
      actionsList.map((action) => ({
        text: action.DisplayText,
        ...action,
      })),
    [actionsList],
  );

  const handleActionClick = useCallback(
    async (event: any, rowData: any) => {
      await handleGridAction({
        event,
        rowData,
        apiCall,
        dbId,
      });
    },
    [apiCall, dbId],
  );

  const handleExcelExport = useCallback(async () => {
    try {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Payroll Event");

      await exportDataGrid({
        component: gridRef.current?.instance,
        worksheet,
        autoFilterEnabled: true,
      });

      const buffer = await workbook.xlsx.writeBuffer();
      saveAs(
        new Blob([buffer], { type: "application/octet-stream" }),
        "PayrollEventDraft.xlsx",
      );
    } catch (error) {
      console.error("Export failed:", error);
    }
  }, []);

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      gridRef.current?.instance.searchByText(e.target.value);
    },
    [],
  );

  const handleSearchClear = useCallback(() => {
    gridRef.current?.instance.searchByText("");
  }, []);

  console.log(actions);

  return (
    <DataGridWrapper isFullScreen={isFullScreen}>
      <PayrollGridToolbar
        onRefresh={onRefresh}
        onExcelExport={handleExcelExport}
        onSearchChange={handleSearchChange}
        onSearchClear={handleSearchClear}
        onFullScreen={() => setIsFullScreen(!isFullScreen)}
      />

      <DataGrid
        ref={gridRef}
        dataSource={data}
        keyExpr="empID"
        showBorders={false}
        rowAlternationEnabled={true}
        columnAutoWidth={true}
        wordWrapEnabled={true}
        showColumnLines={true}
        showRowLines={true}
        style={{ height: "100%" }}
        repaintChangesOnly
      >
        <SearchPanel visible={false} />
        <Sorting mode="multiple" />

        {interfacePreference === "left" && (
          <Column
            key={"actions-column-left"}
            width={65}
            caption=""
            fixed={true}
            alignment={"left"}
            cellRender={({ data }) => (
              <ActionMenu
                dataSource={actions}
                onItemClick={(e) => handleActionClick(e, data)}
              />
            )}
          />
        )}

        {columns.map((column) => (
          <Column
            key={column.RpcHeader}
            dataField={column.RpcDataSource}
            caption={column.RpcHeader}
            alignment={getColumnAlignment(column.RpcAlign)}
            allowFiltering={convertYNtoBool(column.RpcFilter)}
            allowSorting={convertYNtoBool(column.RpcSort)}
            allowSearch={convertYNtoBool(column.RpcSearchable)}
            visible={convertYNtoBool(column.RpcVisible)}
            fixed={convertYNtoBool(column.RpcFreeze)}
            minWidth={column.RpcMinWidth}
            width={
              column.RpcHeader === "Employee" ? undefined : column.RpcMaxWidth
            }
            cellRender={(cellInfo) => formatGridCellValue(cellInfo, column)}
          />
        ))}

        {interfacePreference !== "left" && (
          <Column
            key="actions-column-right"
            width={65}
            caption=""
            fixed={false}
            alignment={"right"}
            cellRender={({ data }) => (
              <ActionMenu
                dataSource={actions}
                onItemClick={(e) => handleActionClick(e, data)}
              />
            )}
          />
        )}

        <Summary>
          {columns
            .filter((column) => isMoneyColumn(column.RpcDataType_rpdID))
            .map((column) => (
              <TotalItem
                key={column.RpcHeader}
                column={column.RpcDataSource}
                summaryType="sum"
                displayFormat="{0}"
                valueFormat={{
                  type: "fixedPoint",
                  precision: column.RpcRounding,
                }}
              />
            ))}
          <TotalItem
            column="EmpName"
            summaryType="count"
            displayFormat="Total Employees: {0}"
          />
        </Summary>
      </DataGrid>
    </DataGridWrapper>
  );
};
