import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import BankJournalHeader from "./BankJournalHeader";
import BankJournalGrid from "./BankJournalGrid";
import { useParams, useLocation, useNavigate } from "react-router-dom"; // useLocation to access URL params
import { getHeaders } from "../../utils/api";
import BackdropLoading from "../BackdropLoading/BackdropLoading";
import envConfig from "../../config";
import PageTitle from "../PageTitle/PageTitle";
import useApi from "../../hooks/useApi";
import { enqueueSnackbar } from "notistack";
import { format } from "date-fns";
import { formatDateTime, formatDateToISO } from "../../utils/format-date";
import utc from "dayjs/plugin/utc";

// Extend Day.js with the utc plugin
dayjs.extend(utc);

export const formatDateToYYYYMMDD = (dateInput) => {
  if (!dateInput) return "";

  let date;

  // Check if dateInput is a Day.js object
  if (dayjs.isDayjs(dateInput)) {
    date = dateInput.utc(); // Ensure it's in UTC mode
  } else {
    // Try to parse the input as UTC first
    date = dayjs.utc(dateInput);

    // If the date is invalid, try parsing it without UTC
    if (!date.isValid()) {
      date = dayjs(dateInput);
    }
  }

  // If still invalid, return an empty string
  if (!date.isValid()) {
    console.warn("Invalid date input:", dateInput);
    return "";
  }

  // Format the date in UTC to 'YYYY-MM-DD'
  return date.utc().format("YYYY-MM-DD");
};

const BankJournalPage = () => {
  const { dbId, lang, journalId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const headers = getHeaders(dbId);
  const { apiCall } = useApi();

  const queryParams = new URLSearchParams(location.search);
  const feedId = queryParams.get("feedId");
  const hasFeedId = !!feedId;

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [pageKey, setPageKey] = useState(1);

  // Form state with defaults for the URL parameters
  const [formState, setFormState] = useState({
    jnlDate: null,
    jnlContact_cntID: null,
    jnlMemo: "",
    showPrivatePortion: false,
    showVaryManualTax: false,
    jnlCode: "",
    jnlAccount_accNo: null,
    JournalAmountType: "G",
    lockedDate: false,
    lockedAccount: false,
    lockedAmount: false,
    finalSave: false,
  });

  const reloadPage = () => {
    const { jnlDate } = formState;

    setFormState((prevState) => ({
      ...prevState,
      jnlDate: null,
    }));
    if (!journalId || isNaN(journalId)) {
      setPageKey((prev) => prev + 1);
      const searchParams = new URLSearchParams(location.search);
      searchParams.set("date", formatDateToYYYYMMDD(dayjs(jnlDate)));
      navigate(`${location.pathname}?${searchParams.toString()}`, {
        replace: true,
      });
    } else {
      navigate(0);
      setPageKey((prev) => prev + 1);
    }
  };

  useEffect(() => {
    if (hasFeedId) {
      apiCall({
        url: `https://${envConfig.apiDev2}/api/en-au/bank-feeds/get-feed-details?FeedID=${feedId}&BaseHostURL=${envConfig.mainServiceUrl}`,
        method: "GET",
        onSuccess: (data) => {
          setFormState((prevState) => ({
            ...prevState,
            jnlDate: data.TrDate ? dayjs(data.TrDate) : null,
            jnlAccount_accNo: data.AccNo || "",
            jnlMemo: data.Description || "",
            jnlCode: data.Amount < 0 ? "CO" : data.Amount > 0 ? "CI" : "",
            feedAmount: Math.abs(parseFloat(data.Amount)),
            lockDescription: !!data.Description,
            lockAccount: !!data.AccNo,
            lockedDate: !!data.TrDate,
          }));
          setLoading(false);
        },
        onError: (errorMessage) => {
          enqueueSnackbar(errorMessage || "Failed to get feed details", {
            variant: "error",
          });
          enqueueSnackbar(errorMessage || "Failed to get feed details", {
            variant: "error",
          });

          const newQueryParams = new URLSearchParams(location.search);
          newQueryParams.delete("feedId");

          const newSearch = newQueryParams.toString();

          // Navigate to the same path without 'feedId'
          navigate(`${location.pathname}${newSearch ? `?${newSearch}` : ""}`, {
            replace: true,
          });
          navigate(0);
        },
      });
    }
  }, [feedId]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const dateParam = queryParams.get("date");
    const accountParam = queryParams.get("account");
    const amountParam = queryParams.get("amount");
    // Parse the URL parameters only if no journalId is provided
    if (!journalId || isNaN(journalId)) {
      setFormState((prevState) => ({
        ...prevState,
        jnlDate: dateParam
          ? dayjs(dateParam)
          : prevState.jnlDate
            ? prevState.jnlDate
            : null,
        jnlAccount_accNo: Number(accountParam) || prevState.jnlAccount_accNo,
      }));

      if (amountParam) {
        const parsedAmount = parseFloat(amountParam);
        setFormState((prevState) => ({
          ...prevState,
          jnlCode: parsedAmount < 0 ? "CO" : parsedAmount > 0 ? "CI" : "",
        }));
      }
    }
  }, [location.search, journalId]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const dateParam = queryParams.get("date");
    const accountParam = queryParams.get("account");
    const amountParam = queryParams.get("amount");
    const fetchData = async () => {
      setLoading(true);
      await apiCall({
        url: `https://${envConfig.apiDev1Exacc}/api/v1/en-au/bank-journal/get-journal-data?journalId=${journalId && !isNaN(journalId) ? journalId : 0}&BaseHostURL=${envConfig.mainServiceUrl}`,
        method: "GET",
        ip: "127.0.0.2",
        headers,
        onSuccess: (data) => {
          const showVaryManualTax = data?.JournalDetails?.some(
            (detail) => detail.jnsVaryTaxYN === "Y",
          );
          const showPrivatePortion = data?.JournalDetails?.some(
            (detail) => detail.jnsPrivAmt != null,
          );
          const footerTextCreated =
            data?.JournalHeader?.jnlCreatedByUserFullName &&
            data?.JournalHeader?.jnlCreatedDateTime
              ? `Created by ${data.JournalHeader.jnlCreatedByUserFullName} on ${formatDateTime(data.JournalHeader.jnlCreatedDateTime)}`
              : "";
          const footerTextUpdated =
            data?.JournalHeader?.jnlUpdatedByUserFullName &&
            data?.JournalHeader?.jnlUpdatedDateTime
              ? `- Updated by ${data.JournalHeader.jnlUpdatedByUserFullName} on ${formatDateTime(data.JournalHeader.jnlUpdatedDateTime)}.`
              : ".";

          setData(data);

          if (!hasFeedId) {
            setFormState((prevState) => ({
              ...prevState, // Keep the existing state from URL params
              jnlDate: dateParam
                ? dayjs(dateParam)
                : prevState.jnlDate
                  ? prevState.jnlDate
                  : data?.JournalHeader?.jnlDate
                    ? dayjs(data?.JournalHeader?.jnlDate)
                    : null, // Only overwrite if not set from URL
              jnlAccount_accNo:
                Number(accountParam) || data?.JournalHeader?.jnlAccount_accNo,
              jnlCode: prevState.jnlCode || data?.JournalHeader?.jnlCode,
              jnlContact_cntID: data?.JournalHeader?.jnlContact_cntID || null,
              jnlMemo: data?.JournalHeader?.jnlMemo || "",
              showPrivatePortion: showPrivatePortion,
              showVaryManualTax: showVaryManualTax,
              JournalAmountType: data?.JournalHeader?.JournalAmountType || "G",
              footerText: footerTextCreated + footerTextUpdated,
            }));
          }
          setLoading(false);
        },
        onError: (errorMessage) => {
          console.log(errorMessage);
          enqueueSnackbar(errorMessage, { variant: "error" });
          setLoading(false);
        },
      });
    };

    fetchData();
  }, [journalId, pageKey]);

  const isReceiveMoney = formState.jnlCode === "CI";
  const isSpendMoney = formState.jnlCode === "CO";

  return (
    <div key={pageKey}>
      <BackdropLoading open={loading} />
      <PageTitle
        title={
          data?.JournalHeader?.jnlID
            ? `Bank Transaction ${formState?.jnlCode}-${data?.JournalHeader?.jnlID}`
            : "New Bank Transaction"
        }
      />

      <BankJournalHeader
        hasFeedId={hasFeedId}
        pageKey={pageKey}
        formState={formState}
        setFormState={setFormState}
        listOfContacts={data?.ListOfContacts}
        listOfAccounts={data?.ListOfAccounts?.filter(
          (acc) => acc.accPostableYN === "Y",
        )}
        isReceiveMoney={isReceiveMoney}
        isSpendMoney={isSpendMoney}
      />
      <BankJournalGrid
        isAmountNet={formState.JournalAmountType === "N"}
        isReceiveMoney={isReceiveMoney}
        isSpendMoney={isSpendMoney}
        formState={formState}
        headerData={data?.JournalHeader || {}}
        initialData={data?.JournalDetails || []}
        listOfAccounts={data?.ListOfAccounts}
        listOfTaxCodes={data?.ListOfTaxCodes}
        listOfSites={data?.ListOfSites}
        showPrivatePortion={formState?.showPrivatePortion}
        setShowPrivatePortion={(boolean) =>
          setFormState({ ...formState, showPrivatePortion: !!boolean })
        }
        showVaryManualTax={formState?.showVaryManualTax}
        reloadPage={reloadPage}
        loadingData={loading}
      />
    </div>
  );
};

export default BankJournalPage;
