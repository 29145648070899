import dayjs from "dayjs";
import { Box } from "@mui/material";
import React, { useEffect } from "react";
import BackdropLoading from "../../components/BackdropLoading/BackdropLoading";
import { PageTitle } from "../../components/page-title";
import { useFinancialReportInitialData } from "../../features/reports/financial-report/api/financial-report-initial-data";
import { useFinancialReportReportPdf } from "../../features/reports/financial-report/api/financial-report-report-pdf";
import { FinancialReportCheckboxes } from "../../features/reports/financial-report/components/financial-report-checkboxes";
import { FinancialReportDatePickers } from "../../features/reports/financial-report/components/financial-report-date-pickers";
import { FinancialReportSignatures } from "../../features/reports/financial-report/components/financial-report-signatures";
import { useFinancialReportStoreBase } from "../../features/reports/financial-report/store/financial-report-store";
import { useReportsDates } from "../../hooks/use-reports-dates";
import { boolToYN } from "../../utils/format-bool";
import { formatDateToISO } from "../../utils/format-date";

export function FinancialReport() {
  const { startDate, endDate } = useReportsDates();
  const initialData = useFinancialReportInitialData({
    onSuccess: (data) => {
      setSignatory1(data.Signatory1 ?? "");
      setSignatory2(data.Signatory2 ?? "");
      setCompilantSignatory(data.CompilationSignatory ?? "");
      setMyFooter(
        Boolean(data?.MyInfo) && typeof data?.MyInfo === "string"
          ? data.MyInfo.replace("<br>", "\n").replace("<br/>", "\n") ?? ""
          : "",
      );
    },
  });
  const mutation = useFinancialReportReportPdf();

  const state = useFinancialReportStoreBase((state) => state);
  const {
    // reset,
    setSignatory1,
    setSignatory2,
    setCompilantSignatory,
    setMyFooter,
  } = useFinancialReportStoreBase();

  console.log("DEBUGGING: initailData: ", initialData);
  console.log("DEBUGGING: mutation: ", mutation);
  console.log("DEBUGGING: state: ", state);

  const handleGeneratePDFReport = async () => {
    await mutation.getPDFReport({
      // Acc0091: "string",
      CompilationSignatory: state.CompilantSignatory,
      MultipleSignatoriesYN: boolToYN(state.showFilters.MultipleSignatoriesYN),
      EndDate: formatDateToISO(state.endDate?.toDate()),
      StartDate: formatDateToISO(state.startDate?.toDate()),
      MyFooter: state.MyFooter,
      CorporateTrusteeYN: boolToYN(state.showFilters.CorporateTrusteeYN),
      Signatory1: state.Signatory1,
      Signatory2: state.Signatory2,
      ReportingEntityYN: boolToYN(state.showFilters.ReportingEntityYN),
      ShowPreviousPeriodYN: boolToYN(state.showFilters.ShowPreviousPeriodYN),
      ShowZeroAmountsYN: boolToYN(state.showFilters.ShowZeroAmountsYN),
      ShowAccountNumbersYN: boolToYN(true),
      ShowFooterAuditedUnauditedAU: state.showFilters
        .ShowFooterAuditedUnauditedAU
        ? "A"
        : "U",
      ShowAccountsYN: "N",
      ShowAccGroupsYN: "N",
    });
    // reset();
  };

  useEffect(() => {
    const setStartDate = useFinancialReportStoreBase.getState().setStartDate;
    const setEndDate = useFinancialReportStoreBase.getState().setEndDate;

    if (startDate) {
      setStartDate(dayjs(startDate));
    }
    if (endDate) {
      setEndDate(dayjs(endDate));
    }
  }, [startDate, endDate]);

  return (
    <>
      <BackdropLoading open={initialData.isLoading || mutation.isLoading} />
      <PageTitle title={"Financial Report"} />
      <FinancialReportDatePickers />
      <Box display={"flex"} flexDirection="row" gap={4} my={4}>
        <FinancialReportCheckboxes
          ShowTrustDetsYN={initialData.data?.ShowTrustDetsYN === "Y"}
          MultipleSignatoryName={initialData.data?.MultipleSignatoryName}
          ShowMultipleSignatoriesYN={
            initialData.data?.ShowMultipleSignatoriesYN === "Y"
          }
        />
        <FinancialReportSignatures
          onGeneratePDFReport={handleGeneratePDFReport}
        />
      </Box>
    </>
  );
}
