import React, { useState, useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/en-gb';
import isoWeek from 'dayjs/plugin/isoWeek';

import { DateRangePickerProps, Period, TimeFrame } from './types';
import { DateInput } from './DateInput';
import { ShortcutsBar } from './ShortcutsBar';
import { generatePeriodDates } from './utils';

dayjs.extend(isoWeek);
dayjs.locale('en-gb');

export const DateRangePicker: React.FC<DateRangePickerProps> = ({
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
  startLabel = 'Start Date',
  endLabel = 'End Date',
  minDate,
  maxDate,
}) => {
  const [startInputValue, setStartInputValue] = useState('');
  const [endInputValue, setEndInputValue] = useState('');
  const [calendarOpen, setCalendarOpen] = useState<'start' | 'end' | null>(null);
  const [startError, setStartError] = useState(false);
  const [endError, setEndError] = useState(false);
  const startInputRef = useRef<HTMLDivElement>(null);
  const endInputRef = useRef<HTMLDivElement>(null);

  const validateDates = (start: Dayjs | null, end: Dayjs | null, isStartUpdate: boolean) => {
    if (start && end && end.isBefore(start)) { 
      if (isStartUpdate) {
        setStartError(true);
        setEndError(false);
      } else {
        setEndError(true);
        setStartError(false);
      }
    } else {
      setStartError(false);
      setEndError(false);
    }
  };
  
  const validateAndSetDate = (value: string, isStart: boolean) => {
    if (!value) return;
  
    const formats = ['DD/MM/YYYY', 'DD.MM.YYYY', 'DD-MM-YYYY', 'DDMMYYYY'];
    let parsedDate: Dayjs | null = null;
  
    for (const format of formats) {
      const date = dayjs(value, format, true);
      if (date.isValid()) {
        parsedDate = date;
        break;
      }
    }
  
    if (parsedDate) {
      if (isStart) {
        onStartDateChange(parsedDate);
        setStartInputValue(parsedDate.format('DD/MM/YYYY'));
        validateDates(parsedDate, endDate, true);
      } else {
        onEndDateChange(parsedDate);
        setEndInputValue(parsedDate.format('DD/MM/YYYY'));
        validateDates(startDate, parsedDate, false);
      }
    } else {
      if (isStart) {
        setStartError(true);
        setEndError(false);
      } else {
        setEndError(true);
        setStartError(false);
      }
    }
  };

  const handlePeriodSelect = (timeFrame: TimeFrame, period: Period) => {
    const [start, end] = generatePeriodDates(timeFrame, period);
    onStartDateChange(start);
    onEndDateChange(end);
    validateDates(start, end, true);
    setCalendarOpen(null);
  };

  useEffect(() => {
    if (startDate) {
      setStartInputValue(startDate.format('DD/MM/YYYY'));
    }
    if (endDate) {
      setEndInputValue(endDate.format('DD/MM/YYYY'));
    }
  }, [startDate, endDate]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
        <DateInput
          label={startLabel}
          value={startInputValue}
          onChange={(value) => {
            setStartInputValue(value);
            if (value.length >= 8) {
              validateAndSetDate(value, true);
            }
          }}
          error={startError}
          onOpenCalendar={() => setCalendarOpen('start')}
          ref={startInputRef}
        />

        <DateInput
          label={endLabel}
          value={endInputValue}
          onChange={(value) => {
            setEndInputValue(value);
            if (value.length >= 8) {
              validateAndSetDate(value, false);
            }
          }}
          error={endError}
          onOpenCalendar={() => setCalendarOpen('end')}
          ref={endInputRef}
        />

        {calendarOpen && (
          <DatePicker
          open={true}
          onClose={() => setCalendarOpen(null)}
          value={calendarOpen === 'start' ? startDate : endDate}
          onChange={(newDate) => {
            if (newDate) {
              if (calendarOpen === 'start') {
                onStartDateChange(newDate);
                setStartInputValue(newDate.format('DD/MM/YYYY'));
                validateDates(newDate, endDate, true);
              } else {
                onEndDateChange(newDate);
                setEndInputValue(newDate.format('DD/MM/YYYY'));
                validateDates(startDate, newDate, false);
              }
            } else {
              if (calendarOpen === 'start') {
                onStartDateChange(null);
                setStartInputValue('');
                setStartError(false);
              } else {
                onEndDateChange(null);
                setEndInputValue('');
                setEndError(false);
              }
            }
          }}
          onYearChange={(newDate) => {
            if (newDate) {
              setTimeout(() => {
                if (calendarOpen === 'start') {
                  startInputRef.current?.focus();
                } else {
                  endInputRef.current?.focus();
                }
              }, 1);
            }
          }}
          slots={{
            actionBar: () => (
              <ShortcutsBar
                onPeriodSelect={handlePeriodSelect}
                startDate={startDate}
                endDate={endDate}
              />
            ),
          }}
            slotProps={{
              textField: {
                sx: { display: 'none' },
              },
              popper: {
                anchorEl:
                  calendarOpen === 'start'
                    ? startInputRef.current?.parentElement
                    : endInputRef.current?.parentElement,
                sx: {
                  '& .MuiPickersLayout-root': {
                    display: 'flex',
                    flexDirection: 'row-reverse'
                  },
                  '.MuiDateCalendar-viewTransitionContainer .MuiYearCalendar-root': {
                    maxHeight: '220px'
                  },
                  
                },
              },
              actionBar: {actions: []}
            }}
            showDaysOutsideCurrentMonth
            minDate={minDate}
            maxDate={maxDate}
          />
        )}
      </Box>
    </LocalizationProvider>
  );
};
