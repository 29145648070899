import React, { useCallback, useMemo, useRef } from "react";
import { Box, useTheme, useMediaQuery } from "@mui/material";
import { DataGrid, Tooltip } from "devextreme-react";
import DataSource from "devextreme/data/data_source";
import { Column } from "devextreme-react/cjs/data-grid";

import { useInterfacePreference } from "../../../hooks/use-interface-preference";
import { ActionMenu } from "../../../components/ui/table/action-menu";
import { DailyTimesheet } from "../types";
import { SearchInput } from "../../../components/ui/inputs/search-input";
import TimesheetMobileCard from "./timesheet-mobile-card";

interface TimesheetListProps {
  timesheetList?: DailyTimesheet[];
  onDeleteTimesheet?: (id: string) => void;
  getActivityTypeNameById?: (id: number) => string;
  onStartNewEntry?: (timesheetRow: DailyTimesheet) => void;
}

const TimesheetList = ({
  timesheetList = [],
  onDeleteTimesheet,
  getActivityTypeNameById,
  onStartNewEntry,
}: TimesheetListProps) => {
  const interfacePreference = useInterfacePreference();
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));

  const dataGridRef = useRef<DataGrid>(null);

  const dataSource = new DataSource({
    store: timesheetList,
  });

  const actions = useMemo(
    () => [
      {
        text: "Delete",
        ActionType: "DELETE",
      },
      {
        text: "New entry / Start again",
        ActionType: "START_NEW_ENTRY",
      },
    ],
    [],
  );

  const handleActionClick = useCallback(
    async (event: any, rowData: any) => {
      console.log("event", event);
      console.log("rowData", rowData);

      if (event.itemData.ActionType === "DELETE") {
        if (onDeleteTimesheet) {
          onDeleteTimesheet(rowData.acvID);
        }
      }

      if (event.itemData.ActionType === "START_NEW_ENTRY") {
        if (onStartNewEntry) {
          onStartNewEntry(rowData);
        }
      }
    },
    [onDeleteTimesheet, onStartNewEntry],
  );

  const headerCellRender = useCallback((data: any) => {
    const columnId = `header-${data.column.dataField}`;
    const caption = data.column.caption;
    const title = data.column.dataField === "acvForActionYN" 
      ? "Mark for attention"
      : data.column.caption;

    return (
      <div id={columnId}>
        {caption}
        <Tooltip
          target={`#${columnId}`}
          showEvent="mouseenter"
          hideEvent="mouseleave"
          title={title}
        >
          <div>{title}</div>
        </Tooltip>
      </div>
    );
  }, []);

  if (isMobileOrTablet) {
    return (
      <Box>
        {timesheetList.map((timesheet, index) => (
          <TimesheetMobileCard
            key={timesheet.acvID}
            timesheet={timesheet}
            index={index}
            actions={actions}
            onActionClick={handleActionClick}
          />
        ))}
      </Box>
    );
  }

  return (
    <Box marginTop={2}>
      <Box>
        <Box display="flex" gap={2} mb={4}>
          <SearchInput
            onChange={(e) => {
              if (dataGridRef.current) {
                dataGridRef.current?.instance.searchByText(e.target.value);
              }
            }}
          />
        </Box>
      </Box>
      <Box marginTop={2}>
        <DataGrid ref={dataGridRef} dataSource={dataSource}>
          {interfacePreference === "left" && (
            <Column
              width={65}
              caption=""
              fixed={true}
              alignment="left"
              cellRender={({ data }) => (
                <ActionMenu
                  dataSource={actions}
                  onItemClick={(e) => handleActionClick(e, data)}
                />
              )}
            />
          )}

          <Column dataField="cntCode" caption="Client" allowSearch={true} />
          <Column
            dataField="acvForActionYN"
            caption="Attn"
            dataType="boolean"
            allowSearch={true}
            width={65}
            headerCellRender={headerCellRender}
            calculateCellValue={(data) => data.acvForActionYN === "Y"}
          />
          <Column
            dataField="acvStartTime"
            caption="Start Time"
            allowSearch={true}
            format="shortTime"
            customizeText={(cellInfo) => {
              if (!cellInfo.value || cellInfo.value === "0001-01-01T00:00:00") {
                return "";
              }
              const date = new Date(`${cellInfo.value}`);
              return date.toLocaleTimeString("en-AU", {
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              });
            }}
          />
          <Column
            dataField="acvEndTime"
            caption="End Time"
            allowSearch={true}
            format="shortTime"
            customizeText={(cellInfo) => {
              const date = new Date(`${cellInfo.value}`);
              if (!cellInfo.value || cellInfo.value === "0001-01-01T00:00:00") {
                return "";
              }
              return date.toLocaleTimeString("en-AU", {
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              });
            }}
          />
          <Column
            dataField="acvTimeUnits"
            caption="Time Units"
            allowSearch={true}
          />
          <Column
            dataField="acvDescription"
            caption="Description"
            allowSearch={true}
          />
          <Column
            dataField="acvType_avtID"
            caption="Activity"
            allowSearch={true}
            calculateCellValue={(data) =>
              getActivityTypeNameById?.(data.acvType_avtID) ||
              data.avtCode
            }
          />
          <Column
            dataField="acvCharge"
            caption="Charge, $"
            allowSearch={true}
            format="currency"
          />

          {interfacePreference !== "left" && (
            <Column
              width={65}
              caption=""
              fixed={false}
              alignment="right"
              cellRender={({ data }) => (
                <ActionMenu
                  dataSource={actions}
                  onItemClick={(e) => handleActionClick(e, data)}
                />
              )}
            />
          )}
        </DataGrid>
      </Box>
    </Box>
  );
};

export default TimesheetList;
