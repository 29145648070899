import React from "react";
import { Box } from "@mui/material";
import BackdropLoading from "../../components/BackdropLoading/BackdropLoading";
import { PageTitle } from "../../components/page-title";
import { TaxCodesTable } from "../../features/books/tax-codes/components/tax-codes-table";
import { useSelectionOfTaxCodes } from "../../features/books/tax-codes/api/selection-list";
import { useListOfTaxCodes } from "../../features/books/tax-codes/api/list-of-tax-codes";
import { useLoginPopup } from "../../context/LoginPopupContext";

export function TaxCodesPage() {
  const { handleOpen } = useLoginPopup();
  const listOfTaxCodes = useListOfTaxCodes();
  const selectionList = useSelectionOfTaxCodes({ handleOpen });

  return (
    <>
      <BackdropLoading
        open={listOfTaxCodes.isLoading || selectionList.isLoading}
      />
      <PageTitle title={"Tax Codes"} />
      <TaxCodesTable
        title={"Tax Codes"}
        data={listOfTaxCodes.data?.listOfTaxCodes || []}
        onRefresh={() => listOfTaxCodes.refetch()}
      />
    </>
  );
}
