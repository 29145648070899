import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { axiosService } from "../../../../api/axios";
import envConfig from "../../../../config";
import { useLoginPopup } from "../../../../context/LoginPopupContext";
import { EXAMPLE_RESPONSE_REVIEW_TRANSACTIONS } from "./example-response";

export interface UseTransactionsReviewReportProps {
  reportId?: number;
}

export interface ReviewTransactionsColumn {
    RpcID: number;
    RpcHeader: string;
    RpcSort: "Y" | "N";
    RpcFilter: "Y" | "N";
    RpcDataType_rpdID: number;
    RpcOrder: number;
    RpcVisible: "Y" | "N";
    RpcMinWidth: number;
    RpcMaxWidth: number;
    RpcWrapText: "Y" | "N";
    RpcFreeze: "Y" | "N";
    RpcDataSource: string;
    RpcAlign: number;
    RpcSearchable: "Y" | "N";
    RpcAvgOrSumOrCount: string;
    RpcRounding: number;
    RpcAllowResizing: "Y" | "N";
    RpcHeader_rphID: number;
    RpcGroupBy: "Y" | "N";
    RpcCellTemplate: string;
    HidingPriority: number;
    HideZeroOnAmountColumnYN: string | null;
    RpcZerosManagement: string | null;
}

export interface ReviewTransactionsDataRow {
    RowID: string;
    TrID: string;
    TrDate: string;
    accNo: number;
    GrossAmt: number;
    TaxAmt: number;
    TrRef: string;
    TrStatus: string;
    TrReview: string;
    TaxVary: string;
    AB01: string;
}

export interface ReviewTransactionsResponse {
    SuccessYN: "Y" | "N";
    ErrorMessage: string | null;
    RptTitle: string | null;
    RptSubTitle: string | null;
    RptUniqueKeyColumn: string | null;
    ExportFileName: string | null;
    QuickHelpText: string | null;
    HelpURL: string | null;
    GroupBy: string | null;
    PdfJson: string | null;
    HeaderBands: string | null;
    ColumnsList: ReviewTransactionsColumn[];
    DataDT: ReviewTransactionsDataRow[];
    TooltipsList: any | null;
    ActionsList: any | null;
}

export const useTransactionsReviewReport = ({ reportId }: UseTransactionsReviewReportProps = {}) => {
  const { dbId, lang } = useParams();
  const { reAuthenticate } = useLoginPopup();

  return useQuery({
    queryKey: ['transactions-review-report', dbId, lang, reportId],
    enabled: !!reportId,
    staleTime: 1000 * 60,
    queryFn: async () => {
      const searchParams = new URLSearchParams();
      searchParams.append("ReportID", reportId!.toString());
      searchParams.append("BaseHostURL", envConfig.mainServiceUrl);

      if (process.env.NODE_ENV === "development") {
        return EXAMPLE_RESPONSE_REVIEW_TRANSACTIONS;
    }

      const response = await axiosService.request<ReviewTransactionsResponse>({
        dbId: dbId,
        method: 'GET',
        baseURL: envConfig.apiDev2,
        endpoint: `/api/${lang}/accounting/transactions-review-report-rs1?${searchParams.toString()}`,
        reAuthenticate: reAuthenticate,
      });

      return response;
    }
  });
};
