// UserDetails.jsx
import React, { useState, useEffect } from "react";
import {
  Box,
  Tabs,
  Tab,
  Typography,
  TextField,
  Autocomplete,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import UserMainDetails from "./UserManagement";
import useApi from "../../hooks/useApi";
import envConfig from "../../config";
import { useSnackbar } from "notistack";
import EmployeeManagement from "./EmployeeManagement";
import { commonThemes } from "../../utils/themes/themes";
import UserNextOfKin from "./UserNextOfKin";

const UserDetails = () => {
  const { dbId, lang, usrID } = useParams();
  const navigate = useNavigate();
  const { apiCall } = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedTab, setSelectedTab] = useState(0);
  const [userList, setUserList] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);

  useEffect(() => {
    const fetchUserList = async () => {
      const url = `https://${envConfig.apiDev1Exacc}/api/v1/en-au/users/get-selection-list`;
      try {
        await apiCall({
          url,
          method: "GET",
          onSuccess: (data) => {
            setUserList(data.ListOfUsers || []);
            if (usrID) {
              const user = data.ListOfUsers.find(
                (u) => u.usrID === parseInt(usrID, 10),
              );
              if (user) setSelectedUser(user);
            }
          },
          onError: (error) => {
            enqueueSnackbar(error || "Failed to fetch user list", {
              variant: "error",
            });
          },
        });
      } catch (error) {
        enqueueSnackbar(error || "An unexpected error occurred", {
          variant: "error",
        });
      }
    };

    fetchUserList();
  }, [apiCall, enqueueSnackbar, usrID]);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleUserChange = (event, newValue) => {
    if (newValue && newValue.usrID) {
      navigate(`/${dbId}/${lang}/user-details/${newValue.usrID}`);
    }
  };

  const tabLabels = [
    "Main details",
    "Next of Kin",
    "Permissions",
    "Employment",
  ];

  return (
    <Box>
      <Autocomplete
        options={userList}
        getOptionLabel={(option) => `${option.usrName} (${option.usrCode})`}
        value={selectedUser}
        onChange={handleUserChange}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select User"
            margin="normal"
            variant="outlined"
            fullWidth
          />
        )}
      />
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        aria-label="User Details Tabs"
        variant="scrollable"
        scrollButtons="auto"
      >
        {tabLabels.map((label, index) => (
          <Tab disableRipple key={label} label={label} />
        ))}
      </Tabs>
      {selectedTab === 0 && <UserMainDetails selectedUser={selectedUser} />}
      {selectedTab === 1 && <UserNextOfKin selectedUser={selectedUser} />}
      {selectedTab === 2 && <>Permissions to do</>}
      {selectedTab === 3 && <EmployeeManagement selectedUser={selectedUser} />}
    </Box>
  );
};

export default UserDetails;
