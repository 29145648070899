// import { Autocomplete, TextField } from "@mui/material";

// export const FormDropdown = ({
//   label,
//   valueKey,
//   value = null,
//   loading,

//   options,
//   optionLabel,

//   isFieldRequired = false,
//   isFieldValid = true,
//   errorMessage = "",

//   maxWidth,

//   onChange = () => {},
// }) => {
//   return (
//     <Autocomplete
//       size="small"
//       value={value}
//       onChange={(_, newValue) => {
//         console.log("newValue", newValue);
//         onChange(valueKey, newValue.value);
//       }}
//       disabled={loading}
//       isOptionEqualToValue={(option, value) => option.value === value}
//       options={options}
//       // getOptionLabel={(option) => option}
//       renderInput={(params) => (
//         <TextField
//           {...params}
//           label={`${label} ${isFieldRequired ? "*" : ""}`}
//           fullWidth
//           sx={{ width: "100%", padding: 0 }}
//           variant="outlined"
//           disabled={loading}
//           error={!isFieldValid}
//           helperText={errorMessage}
//         />
//       )}
//       fullWidth
//       sx={{
//         maxWidth: maxWidth,
//         padding: 0,
//         ".MuiOutlinedInput-root": {
//           padding: "0px 9px",
//           borderRadius: "8px",
//         },
//         "& .MuiInputLabel-formControl:not(.MuiInputLabel-shrink)": {
//           transform: "translate(14px, 8px) scale(1)",
//           whiteSpace: "nowrap",
//           overflow: "hidden",
//           textOverflow: "ellipsis",
//           width: "calc(100% - 40px)",
//         },
//       }}
//     />
//   );
// };
//
import { Autocomplete, TextField } from "@mui/material";

export const FormDropdown = ({
  label,
  valueKey,
  value = null,
  loading,

  options,
  optionLabel,

  isFieldRequired = false,
  isFieldValid = true,
  errorMessage = "",

  maxWidth,

  onChange = () => {},
}) => {
  return (
    <Autocomplete
      size="small"
      value={value}
      onChange={(_, newValue) => {
        console.log("newValue", newValue);
        onChange(valueKey, newValue);
      }}
      disabled={loading}
      isOptionEqualToValue={(option, value) =>
        option[valueKey] == value[valueKey]
      }
      options={options}
      getOptionLabel={(option) => option[optionLabel]}
      autoHighlight={true}
      renderInput={(params) => (
        <TextField
          {...params}
          label={`${label} ${isFieldRequired ? "*" : ""}`}
          fullWidth
          sx={{ width: "100%", padding: 0 }}
          variant="outlined"
          disabled={loading}
          error={!isFieldValid}
          helperText={errorMessage}
        />
      )}
      fullWidth
      sx={{
        maxWidth: maxWidth,
        padding: 0,
        ".MuiOutlinedInput-root": {
          padding: "0px 9px",
          borderRadius: "8px",
        },
        "& .MuiInputLabel-formControl:not(.MuiInputLabel-shrink)": {
          transform: "translate(14px, 8px) scale(1)",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          width: "calc(100% - 40px)",
        },
      }}
    />
  );
};
