import React from "react";
import { useSnackbar } from "notistack";
import useApi from "../../../hooks/useApi";
import envConfig from "../../../config";
import { InvoicePaymentDetailsResponse } from "../types/invoice-payment";
import { EXAMPLE_RESPONSE_INVOICE_PAYMENTS_DETAILS } from "./example-response";

interface UseInvoicePaymentDetailsProps {
  paymentId?: string | number;
  invoiceId?: string | number;
  clientId?: string | number;
  onSuccess?: (data: InvoicePaymentDetailsResponse) => void;
  onError?: (error: string) => void;
}

export const useInvoicePaymentDetails = ({
  paymentId,
  invoiceId,
  clientId,
  onSuccess,
  onError,
}: UseInvoicePaymentDetailsProps) => {
  const { apiCall } = useApi();
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [data, setData] = React.useState<InvoicePaymentDetailsResponse | null>(
    null,
  );

  const fetchDetails = React.useCallback(
    async (params?: {
      paymentId?: string | number;
      invoiceId?: string | number;
      clientId?: string | number;
    }) => {
      console.log(
        "DEBUGGING fetchDetails called with params: ",
        params,
        " or ",
        paymentId,
        invoiceId,
        clientId,
      );
      setIsLoading(true);
      setIsError(false);

      if (process.env.NODE_ENV === "development") {
        setData(EXAMPLE_RESPONSE_INVOICE_PAYMENTS_DETAILS);
        setIsLoading(false);
        if (onSuccess) onSuccess(EXAMPLE_RESPONSE_INVOICE_PAYMENTS_DETAILS);
        return;
      }

      const searchParams = new URLSearchParams();
      const effectivePaymentId = params?.paymentId ?? paymentId;
      const effectiveInvoiceId = params?.invoiceId ?? invoiceId;
      const effectiveClientId = params?.clientId ?? clientId;

      if (effectivePaymentId)
        searchParams.append("paymentId", effectivePaymentId.toString());
      if (effectiveInvoiceId)
        searchParams.append("invoiceId", effectiveInvoiceId.toString());
      if (effectiveClientId)
        searchParams.append("clientId", effectiveClientId.toString());
      searchParams.append("BaseHostURL", envConfig.mainServiceUrl);

      console.log(
        "DEBUGGING fetchDetails called with params: ",
        searchParams.toString(),
      );

      try {
        await apiCall({
          url: `https://${envConfig.apiDev1}/api/v3/en-au/invoices-payments/get-details?${searchParams.toString()}`,
          method: "GET",
          // @ts-ignore
          onSuccess: (response: InvoicePaymentDetailsResponse) => {
            setData(response);
            if (onSuccess) onSuccess(response);
          },
          // @ts-ignore
          onError: (error: string) => {
            setIsError(true);
            enqueueSnackbar(error, { variant: "error" });
            if (onError) onError(error);
          },
        });
      } catch (error) {
        setIsError(true);
        const errorMessage = (error as Error).message;
        enqueueSnackbar(errorMessage, { variant: "error" });
        if (onError) onError(errorMessage);
      } finally {
        setIsLoading(false);
      }
    },
    [
      apiCall,
      enqueueSnackbar,
      paymentId,
      invoiceId,
      clientId,
      onSuccess,
      onError,
    ],
  );

  React.useEffect(() => {
    if (!paymentId && !invoiceId && !clientId) {
      fetchDetails();
      return;
    }

    fetchDetails({
      paymentId,
      invoiceId,
      clientId,
    });
  }, [fetchDetails, paymentId, invoiceId, clientId]);

  return {
    isLoading,
    isError,
    data,
    refetch: fetchDetails,
  };
};
