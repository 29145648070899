import { DailyTimesheetResponse } from "../types";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { axiosService } from "../../../api/axios";
import envConfig from "../../../config";
import { useLoginPopup } from "../../../context/LoginPopupContext";
import { EXAMPLE_RESPONSE_DAILY_TIMESHEET } from "./example-responses";

export interface UseNewTimesheetDailyListProps {
  date: dayjs.Dayjs;
}

export const useNewTimesheetDailyList = ({ date }: UseNewTimesheetDailyListProps) => {
    const { dbId, lang } = useParams();
    const { reAuthenticate } = useLoginPopup();
    
    const dateString = date.format("DD/MM/YYYY");

    return useQuery({
        queryKey: ['timesheet-daily-list', dbId, lang, dateString],
        queryFn: async() => {
            // optional - used for development purposes only
            if (process.env.NODE_ENV === 'development') {
                return Promise.resolve(EXAMPLE_RESPONSE_DAILY_TIMESHEET);
            }

            const searchParams = new URLSearchParams();
            searchParams.append("startDate", dateString);
            searchParams.append("endDate", dateString);   
            searchParams.append("BaseHostURL", envConfig.mainServiceUrl);

            const response = await axiosService.request<DailyTimesheetResponse>({
                dbId: dbId,
                method: 'GET',
                baseURL: envConfig.apiDev1Exacc,
                endpoint: `/api/v2/${lang}/timesheet/get-daily-list-async?${searchParams.toString()}`,
                reAuthenticate: reAuthenticate,
            })

            return response;
        }
    })
};