import envConfig from "../config";
import { getCookie } from "./api";
import { createSessionForExternal } from "./reusable";

export const openInNewTab = (url: string): void => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

export const onClickUrl =
  (url: string): (() => void) =>
  () =>
    openInNewTab(url);

export const openInNewTabCustom = (
  partialUrl: string,
  dbId: string | undefined,
): void => {
  const lang = "en-au";
  const url = `${window.location.origin}/${dbId}/${lang}/${partialUrl}`;

  openInNewTab(url);
};

const isLegacySystem = (partialUrl: string): boolean => {
  return partialUrl.includes("[A]");
};

export const openInCurrentTabFromPartialUrl = (
  partialUrl: string,
  apiCall: any,
  dbId: string | undefined,
): void => {
  if (isLegacySystem(partialUrl)) {
    const updatedUrl = partialUrl
      .replace("[A]", envConfig.correspondingService)
      .replace("[DbID]", dbId as string)
      .replace("[Lang]", "en-au");

    const url = `https://${updatedUrl}`;
    createSessionForExternal(url, "127.0.0.2", false, apiCall, dbId);

    return;
  } else if (
    partialUrl.includes("[S]") &&
    partialUrl.includes("[DbID]") &&
    partialUrl.includes("[Lang]")
  ) {
    const updatedUrl = partialUrl
      .replace("[S]", window.location.origin)
      .replace("[DbID]", dbId as string)
      .replace("[Lang]", "en-au");

    window.location.href = updatedUrl;
    return;
  } else {
    window.location.href = `${window.location.origin}/${dbId}/en-au${partialUrl}`;
    return;
  }
};

export const openInNewTabFromPartialUrl = (
  partialUrl: string,
  apiCall,
  dbId: string | undefined,
): void => {
  if (isLegacySystem(partialUrl)) {
    const updatedUrl = partialUrl
      .replace("[A]", envConfig.correspondingService)
      .replace("[DbID]", dbId as string)
      .replace("[Lang]", "en-au");

    const url = `https://${updatedUrl}`;
    createSessionForExternal(url, "127.0.0.2", true, apiCall, dbId);

    return;
  } else if (
    partialUrl.includes("[S]") &&
    partialUrl.includes("[DbID]") &&
    partialUrl.includes("[Lang]")
  ) {
    const updatedUrl = partialUrl
      .replace("[S]", window.location.origin)
      .replace("[DbID]", dbId as string)
      .replace("[Lang]", "en-au");

    openInNewTab(updatedUrl);
    return;
  } else {
    openInNewTab(`${window.location.origin}/${dbId}/en-au${partialUrl}`);
    return;
  }
};
