import React from "react";
import { useSnackbar } from "notistack";
import useApi from "../../../hooks/useApi";
import envConfig from "../../../config";

interface UpdateNotesPayload {
  noteText: string;
  isPrivate?: boolean;
}

interface UpdateDraftPayEventNotesParams {
  id: string;
  onSuccess?: () => void;
  onError?: (error: string) => void;
}

export const useUpdateDraftPayEventNotes = ({
  id,
  onSuccess,
  onError,
}: UpdateDraftPayEventNotesParams) => {
  const { apiCall } = useApi();
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = React.useState(false);
  const [isError, setIsError] = React.useState(false);

  const updateNotes = React.useCallback(
    async ({ noteText, isPrivate = false }: UpdateNotesPayload) => {
      setIsLoading(true);
      setIsError(false);

      try {
        await apiCall({
          url: `https://${envConfig.apiDev2}/api/en-au/payroll-au/update-draft-pay-event-notes?BaseHostURL=${envConfig.mainServiceUrl}`,
          method: "POST",
          // @ts-ignore body type mismatch with null | undefined
          body: {
            PrlAu1105: "",
            PevID: id,
            PrivateY_ManagementN: isPrivate ? "Y" : "N",
            Note: noteText,
          },
          // @ts-ignore onSuccess callback signature mismatch
          onSuccess: () => {
            if (onSuccess) onSuccess();
            enqueueSnackbar("Notes updated successfully", {
              variant: "success",
            });
          },
          // @ts-ignore onError callback signature mismatch
          onError: (error: string) => {
            setIsError(true);
            enqueueSnackbar(error, { variant: "error" });
            if (onError) onError(error);
          },
        });
      } catch (error) {
        console.error("updateDraftPayEventNotes error", error);
        setIsError(true);
        enqueueSnackbar((error as Error).message, { variant: "error" });
        if (onError) onError((error as Error).message);
      } finally {
        setIsLoading(false);
      }
    },
    [apiCall, enqueueSnackbar, id, onSuccess, onError],
  );

  return {
    isLoading,
    isError,
    updateNotes,
  };
};
