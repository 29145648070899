import React from "react";
import { Alert, AlertTitle, Box } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { createSessionForExternal } from "../../utils/reusable";
import useApi from "../../hooks/useApi";

const Banner = ({
  severity,
  title,
  description,
  linkText,
  linkUrl,
  backgroundColor,
  ip,
}) => {
  const { apiCall } = useApi();
  const navigate = useNavigate();
  const { dbId } = useParams();

  if (severity === "custom") {
    return (
      <Alert
        sx={{
          background: backgroundColor,
          margin: "10px 0 20px 0",
        }}
        severity=""
        style={{ position: "relative", width: "100%" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              "a:hover": {
                opacity: 0.9,
              },
            }}
            style={{ width: "100%" }}
          >
            <AlertTitle>
              <div dangerouslySetInnerHTML={{ __html: title }} />
            </AlertTitle>
            <div dangerouslySetInnerHTML={{ __html: description }} />

            {linkText && linkUrl && (
              <span
                onClick={(e) => {
                  e.stopPropagation();
                  if (linkUrl?.startsWith("http")) {
                    createSessionForExternal(linkUrl, ip, false, apiCall, dbId);
                  } else {
                    navigate(`${linkUrl}`);
                  }
                }}
              >
                <a
                  style={{
                    color: "inherit",
                    textDecoration: "none",
                    position: "absolute",
                    top: "10px",
                    right: "20px",
                  }}
                  onClick={(e) => e.preventDefault()}
                  href={linkUrl}
                  rel="noopener noreferrer"
                >
                  <div dangerouslySetInnerHTML={{ __html: linkText }} />
                </a>
              </span>
            )}
          </Box>
        </div>
      </Alert>
    );
  }

  if (
    severity !== "success" &&
    severity !== "error" &&
    severity !== "warning"
  ) {
    console.log("banner severity is incorrect: ", severity);
    return;
  }

  return (
    <Alert
      sx={{
        margin: "10px 0 20px 0",
      }}
      severity={severity}
      style={{ position: "relative", width: "100%" }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            "a:hover": {
              opacity: 0.9,
            },
          }}
          style={{ width: "100%" }}
        >
          <AlertTitle>{title}</AlertTitle>
          {description}

          {linkText && linkUrl && (
            <span
              onClick={(e) => {
                e.stopPropagation();
                if (linkUrl?.startsWith("http")) {
                  createSessionForExternal(linkUrl, ip, false, apiCall, dbId);
                } else {
                  navigate(`${linkUrl}`);
                }
              }}
            >
              <a
                onClick={(e) => e.preventDefault()}
                style={{
                  color: "inherit",
                  textDecoration: "none",
                  fontWeight: 700,
                  position: "absolute",
                  top: "10px",
                  right: "20px",
                }}
                href={linkUrl}
                rel="noopener noreferrer"
              >
                {linkText}
              </a>
            </span>
          )}
        </Box>
      </div>
    </Alert>
  );
};

export default Banner;
