import React from "react";
import { useSnackbar } from "notistack";
import useApi from "../../../hooks/useApi";
import envConfig from "../../../config";

interface StripeEnableAccountProps {
  onSuccessCallback?: () => void;
  onErrorCallback?: () => void;
}

export const useStripeEnableAccount = ({
  onSuccessCallback,
  onErrorCallback
}: StripeEnableAccountProps = {}) => {
  const { apiCall } = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isError, setIsError] = React.useState(false);

  const onSuccess = () => {
    enqueueSnackbar("Stripe account enabled successfully", { variant: "success" });
    onSuccessCallback?.();
  };

  const onError = () => {
    setIsError(true);
    enqueueSnackbar("Failed to enable Stripe account", { variant: "error" });
    onErrorCallback?.();
  };

  const enableAccount = React.useCallback(async () => {
    setIsLoading(true);
    setIsError(false);

    if (process.env.NODE_ENV === "development") {
      await new Promise(resolve => setTimeout(resolve, 1000)); // Simulate API call
      setIsLoading(false);
      return;
    }

    try {
      await apiCall({
        url: `https://${envConfig.apiDev1Exacc}/api/v1/en-au/stripe/enable-account-async`,
        method: "POST",
        onSuccess,
        onError,
      });
    } catch (error) {
      console.error("enableStripeAccount error", error);
      setIsError(true);
      enqueueSnackbar((error as Error).message, { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  }, [apiCall, enqueueSnackbar]);

  return {
    isLoading,
    isError,
    enableAccount
  };
};
