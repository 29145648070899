import React from "react";
import { Box, Card, Checkbox, Typography } from "@mui/material";

import { DailyTimesheet } from "../types";
import { ActionMenu } from "../../../components/ui/table/action-menu";
import { useTheme } from "../../../context/ThemeContext";

interface TimesheetMobileCardProps {
  timesheet: DailyTimesheet;
  index: number;
  actions: any[];
  onActionClick: (action: any, timesheet: DailyTimesheet) => void;
}

export default function TimesheetMobileCard({
  timesheet,
  index,
  actions,
  onActionClick,
}: TimesheetMobileCardProps) {
  const { isDarkMode } = useTheme();

  return (
    <Card
      sx={{
        borderRadius: 0,
        p: 2,
        ...(index % 2 === 0
          ? {
              bgcolor: "transparent",
              border: "1px solid",
              borderColor: "#535D6E",
            }
          : {
              bgcolor: isDarkMode ? "#2F2F2F" : "#EDEDED",
              border: "none",
            }),
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" alignItems="center" gap={1}>
          <Typography
            // variant="h4"
            // #todo: add proper styles
            sx={{
              fontWeight: 400,
              fontSize: "24px",
            }}
          >
            {timesheet.cntCode || "No Client specified"}
          </Typography>
        </Box>
        <ActionMenu
          dataSource={actions}
          onItemClick={(action) => onActionClick(action, timesheet)}
        />
      </Box>

      <Box display="flex" gap={1} mt={1} alignItems="center">
        <Typography variant="subtitle1">
          {new Date(timesheet.acvStartTime).toLocaleTimeString("en-AU", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          })}
          {" - "}
          {new Date(timesheet.acvEndTime).toLocaleTimeString("en-AU", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          })}
        </Typography>
        <Typography variant="subtitle1">
          {timesheet.acvTimeUnits} units
        </Typography>
        <Typography variant="body1">${timesheet.acvCharge}</Typography>
      </Box>

      <Box mt={1}>
        <Typography variant="subtitle1">
          {new Date().toLocaleDateString()} • {timesheet.acvID}
        </Typography>
      </Box>

      <Typography mt={1} variant="body1">
        {timesheet.acvDescription}
      </Typography>
      <Box display="flex" alignItems="center">
        <Checkbox
          checked={timesheet.acvForActionYN === "Y"}
          size="small"
          color="primary"
        />
        <Typography variant="caption">Mark for attention</Typography>
      </Box>
    </Card>
  );
}
