import React from "react";
import envConfig from "../../../config";
import { useSnackbar } from "notistack";
import { EXAMPLE_GET_SITES_RESPONSE } from "../../../data/example-get-sites-response";
import useApi from "../../../hooks/useApi";
import { ResponseBoolean } from "../../../types/api/common";

const sitesUrl = `https://${envConfig.apiDev1Exacc}/api/v1/en-au/sites/get-selection-list?activeYN=Y&BaseHostURL=${envConfig.mainServiceUrl}`;

// TODO: Move it somewhere else - probably types
export interface Site {
  dpsID: number;
  dpsName: string;
  dpsCode: string;
  dpsActiveYN: ResponseBoolean | string;
}

export interface SitesResponse {
  ListOfSites: Site[];
  errorMessage: ResponseBoolean;
  successYN: ResponseBoolean;
}

export const useListOfSites = () => {
  const { apiCall } = useApi();
  const { enqueueSnackbar } = useSnackbar();

  const [data, setData] = React.useState<SitesResponse | undefined>(undefined);
  const [isLoadingSites, setIsLoadingSites] = React.useState(false);
  const [isErrorSites, setIsErrorSites] = React.useState(false);

  const fetchListOfSites = React.useCallback(async () => {
    setIsLoadingSites(true);
    setIsErrorSites(false);

    try {
      await apiCall({
        url: sitesUrl,
        method: "GET",
        // @ts-ignore + TODO-FUTURE: add TypeScript support for this
        onSuccess: (data: SitesResponse) => {
          setData(data);
        },
        // @ts-ignore + TODO-FUTURE: add TypeScript support for this
        onError: (error: Error) => {
          setIsErrorSites(true);
          enqueueSnackbar(error.message, { variant: "error" });
        },
      });
    } catch (error) {
      console.error("fetchListOfSites error", error);
      setIsErrorSites(true);
      enqueueSnackbar((error as Error).message, { variant: "error" });
    } finally {
      if (process.env.NODE_ENV === "development") {
        setData(EXAMPLE_GET_SITES_RESPONSE as SitesResponse);
      }
      setIsLoadingSites(false);
    }
  }, [apiCall, enqueueSnackbar]);

  React.useEffect(() => {
    fetchListOfSites();
  }, [fetchListOfSites]);

  return {
    isLoading: isLoadingSites,
    isError: isErrorSites,
    data,
    refetch: fetchListOfSites,
  };
};
