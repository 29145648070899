import React from "react";
import { TextField } from "@mui/material";

interface PaymentSummaryNoteProps {
  note: string;
  onNoteChange: (note: string) => void;
}

export function PaymentSummaryNote({
  note,
  onNoteChange,
}: PaymentSummaryNoteProps) {
  const [currentNote, setCurrentNote] = React.useState(note);

  React.useEffect(() => {
    setCurrentNote(note);
  }, [note]);

  return (
    <TextField
      label="Note"
      multiline
      minRows={4}
      fullWidth
      value={currentNote}
      onChange={(e) => setCurrentNote(e.target.value)}
      onBlur={() => onNoteChange(currentNote)}
    />
  );
}
