import React from "react";
import envConfig from "../../../../config";
import { useSnackbar } from "notistack";
import useApi from "../../../../hooks/useApi";
import { EXAMPLE_USERS_LIST_RESPONSE } from "./example-responses";

export interface User {
  usrID: number;
  empID: number;
  usrFirstName: string;
  usrLastName: string;
  usrCode: string;
  usrEmail: string;
  usrPrivateEmail: string;
  usrPhone: string;
  UserStatus: string;
  EmployeeStatus: string;
  dpsCode: string | null;
  usrHireFee: number;
  AB01: string;
  AB02: string;
  usrStatus: string;
  empStatus: string;
}

export interface ListOfUsersResponse {
  SuccessYN: "Y" | "N";
  ErrorMessage: string;
  RptTitle: string;
  RptSubTitle: string;
  RptUniqueKeyColumn: string;
  ExportFileName: string;
  QuickHelpText: string;
  HelpURL: string;
  GroupBy: string;
  PdfJson: string;
  HeaderBands: any[];
  ColumnsList: any[];
  DataDT: User[];
  TooltipsList: any[];
  ActionsList: any[];
}

const url = `https://${envConfig.apiDev2}/api/en-au/front-end/get-users-list?BaseHostURL=${envConfig.mainServiceUrl}`;

export const useUsersList = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (data: ListOfUsersResponse) => void;
  onError?: (error: string) => void;
}) => {
  const { apiCall } = useApi();
  const { enqueueSnackbar } = useSnackbar();

  const [data, setData] = React.useState<ListOfUsersResponse | undefined>(
    undefined,
  );
  const [isLoadingUsers, setIsLoadingUsers] = React.useState(false);
  const [isErrorUsers, setIsErrorUsers] = React.useState(false);

  const fetchUsersList = React.useCallback(async () => {
    setIsLoadingUsers(true);
    setIsErrorUsers(false);

    try {
      await apiCall({
        url,
        method: "GET",
        // @ts-ignore + TODO-FUTURE: add TypeScript support for this
        onSuccess: (data: ListOfUsersResponse) => {
          setData(data);
          if (onSuccess) onSuccess(data);
        },
        // @ts-ignore + TODO-FUTURE: add TypeScript support for this
        onError: (error: string) => {
          setIsErrorUsers(true);
          enqueueSnackbar(error, { variant: "error" });
          if (onError) onError(error);
        },
      });
    } catch (error) {
      console.error("fetchUsersList error", error);
      setIsErrorUsers(true);
      enqueueSnackbar((error as Error).message, { variant: "error" });
      if (onError) onError((error as Error).message);
    } finally {
      setIsLoadingUsers(false);

      if (process.env.NODE_ENV === "development") {
        setData(EXAMPLE_USERS_LIST_RESPONSE as ListOfUsersResponse);
      }
    }
  }, [apiCall, enqueueSnackbar, onSuccess, onError]);

  React.useEffect(() => {
    fetchUsersList();
  }, [fetchUsersList]);

  return {
    isLoading: isLoadingUsers,
    isError: isErrorUsers,
    data,
    refetch: fetchUsersList,
  };
};
