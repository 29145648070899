import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  Typography,
  TextField,
  Autocomplete,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  ListItemText,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import useApi from "../../hooks/useApi";
import envConfig from "../../config";
import { useSnackbar } from "notistack";
import BackdropLoading from "../../components/BackdropLoading/BackdropLoading";

const CreateSite = () => {
  const { apiCall } = useApi();
  const navigate = useNavigate();
  const { dbId, lang, dptId } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  // State variables
  const [loading, setLoading] = useState(true);
  const [inputValue, setInputValue] = useState("");
  const [departments, setDepartments] = useState([]);
  const [managers, setManagers] = useState([]);

  // Form fields
  const [dpsName, setDpsName] = useState("");
  const [dpsCode, setDpsCode] = useState("");
  const [dpsDeptDptID, setDpsDeptDptID] = useState(null);
  const [dpsManagerUsrID, setDpsManagerUsrID] = useState(null);
  const [dpsRestrictedYN, setDpsRestrictedYN] = useState("N");
  const [dpsActiveYN, setDpsActiveYN] = useState("Y");
  const [dpsNotes, setDpsNotes] = useState("");

  // Fetch lists for Autocomplete fields
  const fetchInitialData = useCallback(async () => {
    setLoading(true);
    try {
      await apiCall({
        url: `https://${envConfig.apiDev1Exacc}/api/v1/en-au/sites/get-list-of-sites-async?departmentId=${dptId}`,
        method: "GET",
        onSuccess: (data) => {
          if (data.SuccessYN !== "Y") {
            enqueueSnackbar(data.ErrorMessage || "Failed to fetch data.", {
              variant: "error",
            });
            return;
          }

          setDepartments(data.ListOfDepartments || []);
          setManagers(data.ListOfManagers || []);
        },
        onError: (error) => {
          enqueueSnackbar(error, { variant: "error" });
        },
      });
    } catch (error) {
      console.error("Error fetching initial data:", error);
      enqueueSnackbar("An unexpected error occurred.", { variant: "error" });
    } finally {
      setLoading(false);
    }
  }, [apiCall, enqueueSnackbar]);

  useEffect(() => {
    fetchInitialData();
  }, [fetchInitialData]);

  // Handle form submission
  const handleSave = async () => {
    // Basic validation
    if (!dpsName.trim() || !dpsCode.trim()) {
      enqueueSnackbar("Site Name and Code are required.", {
        variant: "warning",
      });
      return;
    }

    setLoading(true);
    try {
      // Construct payload, skipping fields if no data
      const payload = {
        dpsName: dpsName.trim(),
        dpsCode: dpsCode.trim(),
        dpsDept_dptID: dptId,
        ...(dpsManagerUsrID && { dpsManager_usrID: dpsManagerUsrID }),
        ...(dpsRestrictedYN && { dpsRestrictedYN }),
        ...(dpsActiveYN && { dpsActiveYN }),
        ...(dpsNotes && { dpsNotes: dpsNotes.trim() }),
      };

      await apiCall({
        url: `https://${envConfig.apiDev1Exacc}/api/v1/en-au/sites/insert-async`,
        method: "POST",
        body: payload,
        onSuccess: (data) => {
          if (data.SuccessYN === "Y") {
            enqueueSnackbar("Site created successfully.", {
              variant: "success",
            });
            navigate(`/${dbId}/${lang}/sites/${dptId}/`);
          } else {
            enqueueSnackbar(data.ErrorMessage || "Failed to create site.", {
              variant: "error",
            });
          }
        },
        onError: (error) => {
          enqueueSnackbar(error, { variant: "error" });
        },
      });
    } catch (error) {
      console.error("Error creating site:", error);
      enqueueSnackbar("An unexpected error occurred.", { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  const handleDepartmentDetails = () => {
    navigate(`/${dbId}/${lang}/department/${dptId}/`);
  };

  useEffect(() => {
    if (departments?.length > 0) {
      const match = departments.find((dept) => dept.dptID == dptId);
      if (!match) {
        enqueueSnackbar("Department not found", { variant: "error" });
        navigate(`/${dbId}/${lang}/departments`);
      }
    }
  }, [dptId, departments]);

  return (
    <Box sx={{ padding: 2, maxWidth: 800, margin: "0 auto" }}>
      {/* Loading Backdrop */}
      <BackdropLoading open={loading} />

      {/* Page Header */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 3,
        }}
      >
        <Typography variant="h4">Create New Site</Typography>
        <Box>
          <Button
            onClick={handleDepartmentDetails}
            color="primary"
            variant="contained"
            sx={{ mr: 2 }}
          >
            Department details
          </Button>
          <Button
            variant="outlined"
            onClick={() => navigate(`/${dbId}/${lang}/sites/${dptId}/`)}
            sx={{ mr: 2 }}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            disabled={loading}
          >
            Save
          </Button>
        </Box>
      </Box>

      {/* Form */}
      <Box
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
        }}
        noValidate
        autoComplete="off"
      >
        {/* Department */}
        <Autocomplete
          options={departments}
          getOptionLabel={(option) => `${option.dptName} (${option.dptCode})`}
          value={departments.find((dept) => dept.dptID == dptId) || null}
          onChange={(event, newValue) =>
            setDpsDeptDptID(newValue ? newValue.dptID : null)
          }
          disabled
          renderInput={(params) => (
            <TextField {...params} disabled label="Department" />
          )}
          isOptionEqualToValue={(option, value) => option.dptID === value.dptID}
        />

        {/* Site Name */}
        <TextField
          label="Site Name"
          value={dpsName}
          onChange={(e) => setDpsName(e.target.value)}
          required
          autoFocus
        />

        {/* Site Code */}
        <TextField
          label="Site Code"
          value={dpsCode}
          onChange={(e) => setDpsCode(e.target.value)}
          required
        />

        {/* Manager */}
        <Autocomplete
          options={managers}
          renderOption={(props, item) => (
            <li {...props} key={item.key}>
              <ListItemText>{item.usrName}</ListItemText>
            </li>
          )}
          getOptionLabel={(option) => `${option.usrName}`}
          value={
            managers.find((manager) => manager.usrID === dpsManagerUsrID) ||
            null
          }
          onChange={(event, newValue) =>
            setDpsManagerUsrID(newValue ? newValue.usrID : null)
          }
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          renderInput={(params) => <TextField {...params} label="Manager" />}
          isOptionEqualToValue={(option, value) => option.usrID === value.usrID}
          // filterOptions={(options) => {
          //   console.log(options);
          //   const searchValue = inputValue.toLowerCase();
          //   console.log("searchValue", searchValue);
          //   const filtered = options.filter((option) => {
          //     return option.usrName.toLowerCase().includes(searchValue);
          //   });
          //   console.log("filtered", filtered);
          //   return filtered;
          // }}
        />

        {/* Restricted Status */}
        <FormControl component="fieldset">
          <FormLabel component="legend">Restricted Status</FormLabel>
          <RadioGroup
            row
            value={dpsRestrictedYN}
            onChange={(e) => setDpsRestrictedYN(e.target.value)}
          >
            <FormControlLabel value="Y" control={<Radio />} label="Yes" />
            <FormControlLabel value="N" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>

        {/* Active Status */}
        <FormControl component="fieldset">
          <FormLabel component="legend">Active Status</FormLabel>
          <RadioGroup
            row
            value={dpsActiveYN}
            onChange={(e) => setDpsActiveYN(e.target.value)}
          >
            <FormControlLabel value="Y" control={<Radio />} label="Yes" />
            <FormControlLabel value="N" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>

        {/* Department Notes */}
        {/* <TextField
          label="Department Notes"
          value={dpsNotes}
          onChange={(e) => setDpsNotes(e.target.value)}
          multiline
          rows={4}
        /> */}

        {/* Save Button */}
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            disabled={loading}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default CreateSite;
