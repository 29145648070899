import React from "react";
import { Button, SxProps, Theme } from "@mui/material";

// TODO: replace with the correct icon if changed
// @ts-ignore TODO: Fix webpack configuration (probably)
import { ReactComponent as FullscreenIcon } from "../../../assets/full-size.svg";

interface FullScreenButtonProps {
  title?: string;
  // TODO: add more variants
  variant?: "text" | "outlined" | "contained";
  sx?: SxProps<Theme> | undefined;
  onClick: () => void;
}

export const FullScreenButton: React.FC<FullScreenButtonProps> = ({ title, sx, onClick }) => {
  return (
    <Button
      // @ts-ignore
      variant="outlined"
      onClick={onClick}
      sx={{ fontWeight: 700, ...sx }}
    >
      <FullscreenIcon style={{ marginRight: "8px" }} /> {title ? title : "Full Screen"}
    </Button>
  );
};
