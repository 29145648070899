import React from "react";
import { useTheme } from "@mui/material/styles";
import { ButtonOwnProps } from "@mui/material";
 
export interface IconWrapperProps {
  color: ButtonOwnProps["color"];
  paletteType?: "main" | "light" | "dark" | "contrastText";
  children: (color: string) => React.ReactNode;
}
 
export const IconWrapper: React.FC<IconWrapperProps> = ({
  color,
  paletteType = "contrastText",
  children,
}) => {
  const theme = useTheme();
  const strokeColor = theme.palette?.[color ?? "primary"]?.[paletteType] ?? theme.palette.primary.main;


  return <>{children(strokeColor)}</>;
};