import { TimesheetDetailsResponse } from "../types";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { axiosService } from "../../../api/axios";
import envConfig from "../../../config";
import { useLoginPopup } from "../../../context/LoginPopupContext";
import { EXAMPLE_RESPONSE_TIMESHEET_DETAILS } from "./example-responses";

export interface UseTimesheetDetailsProps {
  activityId?: string;
}

export const useTimesheetDetails = ({ activityId }: UseTimesheetDetailsProps = {}) => {
  const { dbId, lang } = useParams();
  const { reAuthenticate } = useLoginPopup();

  return useQuery({
    queryKey: ['timesheet-details', activityId, dbId, lang],
    queryFn: async () => {

      if (process.env.NODE_ENV === "development") {
        return Promise.resolve(EXAMPLE_RESPONSE_TIMESHEET_DETAILS);
      }

      const searchParams = new URLSearchParams();

      if (activityId) {
        searchParams.append("activityId", activityId);
      }

      searchParams.append("BaseHostURL", envConfig.mainServiceUrl);

      const response = await axiosService.request<TimesheetDetailsResponse>({
        dbId: dbId,
        method: 'GET',
        baseURL: envConfig.apiDev1Exacc,
        endpoint: `/api/v2/${lang}/timesheet/get-details-async?${searchParams.toString()}`,
        reAuthenticate: reAuthenticate,
      });

      return response;
    }
  });
};
