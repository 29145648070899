import React, { useRef } from "react";
import { Box, Button, Stack } from "@mui/material";
import { DataGridWrapper } from "../../../../components/ui/table/data-grid-wrapper";
import { useTheme } from "../../../../context/ThemeContext";
import { useInterfacePreference } from "../../../../hooks/use-interface-preference";
import { DataGrid } from "devextreme-react";
import {
  Column,
  Grouping,
  SearchPanel,
  Sorting,
} from "devextreme-react/cjs/data-grid";
import { ActionMenu } from "../../../../components/ui/table/action-menu";
import { YNToBool } from "../../../../utils/format-bool";
import { getAlignment } from "../../../../helpers/table";
import { RefreshButton } from "../../../../components/ui/buttons/refresh-button";
import { SearchInput } from "../../../../components/ui/inputs/search-input";
import { FullScreenButton } from "../../../../components/ui/buttons/fullscreen-button";
import { useNavigate, useParams } from "react-router-dom";

interface UsersListDataGridProps {
  columnsList: Array<any>;
  data: Array<any>;
  actionsList: Array<any>;
  rptUniqueKeyColumn: string;
  onRefresh: () => void;
}

export const UsersListDataGrid: React.FC<UsersListDataGridProps> = ({
  data,
  columnsList,
  actionsList,
  rptUniqueKeyColumn,
  onRefresh,
}) => {
  const dataGridRef = useRef<any>(null);

  const { isDarkMode } = useTheme();
  const { interfacePreference } = useInterfacePreference();
  const { dbId } = useParams();
  const navigate = useNavigate();

  const [openFullscreen, setOpenFullscreen] = React.useState(false);

  const onActionMenuItemClick = (e, data: any) => {
    // @ts-ignore
    const action = e.itemData?.ActionType;
    // @ts-ignore
    const column = e.itemData?.ColumnWithLink;

    // TODO: should I gather all action in one function? Is that even possible?
    switch (action) {
      case "MAIL-TO":
        window.location.href = data[column];
        break;
      default:
        alert(`Unknow Action: ${action}`);
        break;
    }
  };

  const onSearch = (e) => {
    if (dataGridRef?.current) {
      dataGridRef.current.instance.searchByText(e.target.value);
    }
  };

  const actions = actionsList.map((action) => {
    return {
      text: action?.DisplayText,
      actionType: action?.ActionType,
      ...action,
    };
  });

  const displayActions = actions.length > 0;

  return (
    <DataGridWrapper isFullScreen={openFullscreen} height="calc(100vh - 320px)">
      <Box
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        flexWrap={"wrap"}
        gap={3}
        sx={{ marginBottom: 3, marginTop: 1 }}
      >
        <Box
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          gap={3}
          flexWrap={"wrap"}
        >
          <RefreshButton title={"Refresh"} onClick={onRefresh} />
        </Box>
        <Box
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          gap={3}
          flexWrap={"wrap"}
        >
          <SearchInput onChange={onSearch} />
          <FullScreenButton
            onClick={() => setOpenFullscreen(!openFullscreen)}
          />
        </Box>
      </Box>
      <Box
        sx={{
          ".MuiBox-root > .MuiDataGrid-root": {
            marginTop: 0,
          },
          ".dx-datagrid .dx-row-alt > td, .dx-datagrid .dx-row-alt > tr > td": {
            backgroundColor: isDarkMode ? "#1E2432" : "#FAFAFA",
          },
          // Custom CSS to always show horizontal scrollbar
          ".dx-datagrid-rowsview .dx-scrollable-container": {
            overflowX: "scroll !important",
          },
        }}
      >
        <DataGrid
          ref={dataGridRef}
          dataSource={data}
          keyExpr={rptUniqueKeyColumn}
          showBorders={false}
          rowAlternationEnabled={true}
          style={{
            border: "none",
            borderRadius: "8px",
            marginTop: "10px",
            marginBottom: "20px",
            maxHeight: "1400px",
          }}
          scrolling={{
            mode: "virtual",
            showScrollbar: "always",
          }}
          hoverStateEnabled={true}
          wordWrapEnabled={true}
          width={"100%"}
          repaintChangesOnly={true}
          onRowClick={(e) => {
            // const userId = e.data.UserId;
            // console.log(e.data);
            // window.location.href = `/user-details/${userId}`;
            navigate(`../${dbId}/en-au/user-details/${e.data.usrID}`);
          }}
        >
          <SearchPanel visible={false} highlightCaseSensitive={true} />
          {/* <Grouping autoExpandAll={collapseAll} allowCollapsing /> */}
          <Sorting mode="multiple" />

          {displayActions && interfacePreference === "left" && (
            <Column
              width={65}
              caption=""
              fixed={true}
              alignment="left"
              cellRender={({ data }) => (
                <ActionMenu
                  dataSource={actions}
                  onItemClick={(e) => onActionMenuItemClick(e, data)}
                />
              )}
            />
          )}

          {columnsList.map((column, index) => (
            <Column
              key={index}
              dataField={column.RpcDataSource}
              caption={column.RpcHeader}
              allowResizing={YNToBool(column.RpcAllowResizing)}
              allowFiltering={YNToBool(column.RpcFilter)}
              alignment={getAlignment(column.RpcAlign)}
              allowSearch={YNToBool(column.RpcSearchable)}
              allowSorting={YNToBool(column.RpcSort)}
              allowGrouping={YNToBool(column.RpcGroupBy)}
              groupCellRender={(cellInfo) => cellInfo?.displayValue}
              groupIndex={
                column.RpcGroupBy === "Y" || column.RpcGroupBy === "y"
                  ? 0
                  : undefined
              }
              visible={YNToBool(column.RpcVisible)}
              fixed={YNToBool(column.RpcFreeze)}
              minWidth={column.RpcMinWidth}
              width={column.RpcMaxWidth}
              // cellRender={(cellInfo) =>
              //   renderGeneralTableCell(cellInfo, column)
              // }
            />
          ))}

          {displayActions && interfacePreference !== "left" && (
            <Column width={undefined} />
          )}

          {displayActions && interfacePreference !== "left" && (
            <Column
              width={65}
              caption=""
              alignment="right"
              // fixed={true}
              cellRender={({ data }) => {
                return (
                  <ActionMenu
                    dataSource={actions}
                    onItemClick={(e) => onActionMenuItemClick(e, data)}
                  />
                );
              }}
            />
          )}

          {!displayActions && <Column caption="" width={undefined} />}
          {displayActions && interfacePreference === "left" && (
            <Column caption="" width={undefined} />
          )}
        </DataGrid>
      </Box>
    </DataGridWrapper>
  );
};
