import React from "react";
import { useSnackbar } from "notistack";
import useApi from "../../../hooks/useApi";
import envConfig from "../../../config";

interface AddEmployeePayload {
  empId: number;
  pevId: number;
}

interface AddEmployeeToPayEventParams {
  onSuccess?: () => void;
  onError?: (error: string) => void;
}

export const useAddEmployeeToPayEvent = ({
  onSuccess,
  onError,
}: AddEmployeeToPayEventParams) => {
  const { apiCall } = useApi();
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = React.useState(false);
  const [isError, setIsError] = React.useState(false);

  const addEmployee = React.useCallback(
    async ({ empId, pevId }: AddEmployeePayload) => {
      setIsLoading(true);
      setIsError(false);

      try {
        await apiCall({
          url: `https://${envConfig.apiDev2}/api/en-au/payroll-au/add-employee-to-pay-event?BaseHostURL=${envConfig.mainServiceUrl}`,
          method: "POST",
          // @ts-ignore body type mismatch with null | undefined
          body: {
            EmpID: empId,
            PevID: pevId,
          },
          // @ts-ignore onSuccess callback signature mismatch
          onSuccess: () => {
            if (onSuccess) onSuccess();
            enqueueSnackbar("Employee added successfully", {
              variant: "success",
            });
          },
          // @ts-ignore onError callback signature mismatch
          onError: (error: string) => {
            setIsError(true);
            enqueueSnackbar(error, { variant: "error" });
            if (onError) onError(error);
          },
        });
      } catch (error) {
        console.error("addEmployeeToPayEvent error", error);
        setIsError(true);
        enqueueSnackbar((error as Error).message, { variant: "error" });
        if (onError) onError((error as Error).message);
      } finally {
        setIsLoading(false);
      }
    },
    [apiCall, enqueueSnackbar, onSuccess, onError],
  );

  return {
    isLoading,
    isError,
    addEmployee,
  };
};
