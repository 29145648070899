import { AxiosHeaders } from "axios";

export const createDefaultHeaders = (authApiKey?: string, sessionId?: string) => {
  const headers = new AxiosHeaders();

  headers.set('Content-Type', 'application/json');
  headers.set('Accept', 'application/json');
  headers.set('session-id', sessionId);
  headers.set('browser-agent', window.navigator.userAgent);
  headers.set('api-key', authApiKey);
  
  // TODO: move these to the .env file in the future
  headers.set('olivs-api-real-ip', '31.42.0.186');
  headers.set('olivs-api-computer-name', 'BTM-WS2019');
  headers.set('olivs-root-password', 'OlivsWillBeBetterThanBTMSoft!');

  return headers;
};
