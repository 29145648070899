import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { PageTitle } from "../../../components/page-title";
import BackdropLoading from "../../../components/BackdropLoading/BackdropLoading";
import { Box, Grid, TextField } from "@mui/material";
import { Typography } from "@mui/material";
import { PaymentSummaryDetailsFormTop } from "../components/payment-summary-details-form";
import { useInvoicePaymentDetails } from "../api/invoice-payment-details";
import { formatDateToISO } from "../../../utils/format-date";
import { useListOfContacts } from "../../accounting/api/list-of-contacts";
import { PaymentSummaryDetailsFormMiddle } from "../components/payment-summary-details-form-middle";
import { PaymentMethod, PostableAccount } from "../types/invoice-payment";
import { PaymentSummaryDataGrid } from "../components/payment-summary-data-grid";
import { PaymentSummaryToolbar } from "../components/payment-summary-toolbar";
import { Button, Stack } from "@mui/material";
import DataGrid from "devextreme-react/cjs/data-grid";
import { useUpdatePayment } from "../api/update-payment";
import { useDeletePayment } from "../api/delete-payment";
import { useSnackbar } from "notistack";
import { PaymentSummaryNote } from "../components/payment-summary-note";
import dayjs from "dayjs";

export function SalesPaymentSummaryPage() {
  const { paymentId } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const { isLoading, data, refetch } = useInvoicePaymentDetails({
    paymentId,
  });

  const [selectedDate, setSelectedDate] = React.useState<dayjs.Dayjs | null>(null);
  const [note, setNote] = React.useState("");
  const [selectedMethod, setSelectedMethod] =
    React.useState<PaymentMethod | null>(null);
  const [selectedAccount, setSelectedAccount] =
    React.useState<PostableAccount | null>(null);

  const [clientError, setClientError] = React.useState<string | null>(null);
  const [dateError, setDateError] = React.useState<string | null>(null);
  const [paymentMethodError, setPaymentMethodError] = React.useState<
    string | null
  >(null);
  const [accountError, setAccountError] = React.useState<string | null>(null);

  const handleDateChange = (date: dayjs.Dayjs | null) => {
    setDateError(null);
    setSelectedDate(date);
  };

  const handleOnPaymentMethodChange = (method: PaymentMethod | null) => {
    setPaymentMethodError(null);
    setSelectedMethod(method);

    if (method && method.pmm_accNo > 0) {
      const account = data?.ListOfPostableAccounts.find(
        (acc) => acc.accNo === method.pmm_accNo,
      );
      setSelectedAccount(account || null);
    } else {
      setSelectedAccount(null);
    }
  };

  const handleOnAccountChange = (account: PostableAccount | null) => {
    setAccountError(null);
    setSelectedAccount(account);
  };

  React.useEffect(() => {
    if (data?.PaymentHeader.pmtDate) {
      setSelectedDate(dayjs(formatDateToISO(data.PaymentHeader.pmtDate)));
    }
    setNote(data?.PaymentHeader.pmtNotes || "");
    const selectedMethod = data?.ListOfPaymentMethods.find(
      (method) => method.pmmID === data.PaymentHeader.pmtMethod_pmmID,
    );
    const selectedAccount = data?.ListOfPostableAccounts.find(
      (account) => account.accNo === data.PaymentHeader.pmtAccount_accNo,
    );

    if (selectedMethod) {
      setSelectedMethod(selectedMethod);
    }
    if (selectedAccount) {
      setSelectedAccount(selectedAccount);
    }
  }, [data]);

  const selectedClient = {
    cntID: data?.PaymentHeader.pmtContact_cntID,
    cntName: data?.PaymentHeader.pmtContactName,
    cntCode: data?.PaymentHeader.pmtContactCode,
  };

  const dataGridRef = React.useRef<DataGrid<any>>(null);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (dataGridRef.current) {
      dataGridRef.current?.instance.searchByText(e.target.value);
    }
  };

  const handleSearchClear = () => {
    if (dataGridRef.current) {
      // dataGridRef.current?.instance.clearFilter();
    }
  };

  const handleExport = () => {
    // Implement export functionality
    enqueueSnackbar("Export functionality is not implemented", {
      variant: "info",
    });
  };

  const navigate = useNavigate();

  const { updatePayment, isLoading: isUpdating } = useUpdatePayment({
    onSuccess: () => {
      refetch();
    },
    onError: (error) => {
      enqueueSnackbar(error, { variant: "error" });
    },
  });

  const { deletePayment, isLoading: isDeleting } = useDeletePayment({
    onSuccess: () => {
      navigate("/sales/payments");
    },
    onError: (error) => {
      enqueueSnackbar(error, { variant: "error" });
    },
  });

  const handleUpdate = () => {
    let hasError = false;

    if (!selectedClient) {
      setClientError("Client is required");
      hasError = true;
    }
    if (!selectedDate) {
      setDateError("Date is required");
      hasError = true;
    }
    if (!selectedMethod) {
      setPaymentMethodError("Payment method is required");
      hasError = true;
    }
    if (!selectedAccount) {
      setAccountError("Account is required");
      hasError = true;
    }

    if (hasError) {
      enqueueSnackbar("Please fill in all required fields", {
        variant: "error",
      });
      return;
    }

    // @ts-ignore
    const payload = {
      pmtId: Number(paymentId),
      // @ts-ignore
      pmtContact_cntID: selectedClient.cntId,
      pmtDate: formatDateToISO(selectedDate?.toDate()),
      // @ts-ignore
      pmtMethod_pmmID: selectedMethod.pmmID,
      // @ts-ignore
      pmtAccount_accNo: selectedAccount.accNo,
      pmtNotes: note,
    };

    updatePayment(payload);
  };

  const handleDelete = () => {
    if (!paymentId) {
      enqueueSnackbar("Payment ID is required", { variant: "error" });
      return;
    }

    deletePayment(Number(paymentId));
  };

  console.log("note", note);

  return (
    <>
      <BackdropLoading open={isLoading || isUpdating || isDeleting} />
      <PageTitle title={`Payment ${paymentId}`} />

      {data && (
        <>
          <Box
            display={"flex"}
            flexDirection={"column"}
            padding={4}
            boxShadow="0px 0px 10px 0px rgba(69, 90, 100, 0.10)"
            borderRadius={2}
            marginY={4}
            gap={4}
          >
            <Typography variant="subtitle1" color={"secondary"}>
              Payment Summary
            </Typography>
            <PaymentSummaryDetailsFormTop
              client={selectedClient}
              selectedDate={selectedDate}
              amount={data?.PaymentHeader.pmtAmount ?? 0}
              onDateChange={handleDateChange}
              dateError={dateError}
              clientError={clientError}
            />
            <PaymentSummaryDetailsFormMiddle
              paymentMethods={data?.ListOfPaymentMethods || []}
              postableAccounts={data?.ListOfPostableAccounts || []}
              selectedPaymentMethod={selectedMethod}
              selectedAccount={selectedAccount}
              onPaymentMethodChange={handleOnPaymentMethodChange}
              onAccountChange={handleOnAccountChange}
              paymentMethodError={paymentMethodError}
              accountError={accountError}
            />
            <PaymentSummaryNote note={note} onNoteChange={setNote} />
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            padding={4}
            boxShadow="0px 0px 10px 0px rgba(69, 90, 100, 0.10)"
            borderRadius={2}
            marginY={4}
          >
            <Typography variant="subtitle1" color={"secondary"}>
              Invoice Payment List
            </Typography>

            <PaymentSummaryToolbar
              onSearchChange={handleSearchChange}
              onSearchClear={handleSearchClear}
              onExport={handleExport}
            />

            <PaymentSummaryDataGrid
              ref={dataGridRef}
              paymentDetails={data.PaymentDetails || []}
              date={selectedDate}
            />
          </Box>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-start"
            marginY={4}
          >
            <Button variant="contained" onClick={handleUpdate} size="large">
              Update
            </Button>
            <Button variant="outlined" onClick={handleDelete} size="large">
              Delete
            </Button>
          </Stack>
        </>
      )}
    </>
  );
}
