import React from "react";
import { Box, Checkbox, FormControlLabel, useMediaQuery } from "@mui/material";

import { commonThemes } from "../../../../utils/themes/themes";
import { useLedgedStore } from "../store";

const CheckboxLabelByShowFilter = {
  cashMethod: "Cash Method",
  payrollByPayDate: "Payroll By Pay Date",
  showDeptSiteYN: "Show Site",
  showUserYN: "Show User",
  showRecDateYN: "Show Rec Date",
  showCntCodeYN: "Show Contact",
  showIfNoTransactionsYN: "Show If No Transactions",
  showPrintDetailsYN: "Show Print Details",
};

export const GeneralLedgerShowFilters = ({
  hideCashMethod = false,
  hidePayrollByPayDate = true,
  hideShowRecDateYN = true,
  hideShowPrintDetailsYN = true,
}: {
  hideCashMethod?: boolean;
  hidePayrollByPayDate?: boolean;
  hideShowRecDateYN?: boolean;
  hideShowPrintDetailsYN?: boolean;
}) => {
  const breakAdvanced = useMediaQuery("(max-width: 847px)");

  const showFilters = useLedgedStore.use.showFilters();
  const setShowFilterByType = useLedgedStore.use.setShowFilterByType();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: breakAdvanced ? "column" : "row",
        flexWrap: "wrap",
        maxWidth: "750px",
      }}
    >
      {Object.keys(showFilters).map((filterKey: any) => {
        if (
          (hideCashMethod && filterKey === "cashMethod") ||
          (hidePayrollByPayDate && filterKey === "payrollByPayDate") ||
          (hideShowRecDateYN && filterKey === "showRecDateYN") ||
          (hideShowPrintDetailsYN && filterKey === "showPrintDetailsYN")
        ) {
          return null;
        }
        return (
          <FormControlLabel
            key={filterKey}
            value={!!showFilters[filterKey]}
            control={
              <Checkbox
                checked={!!showFilters[filterKey]}
                onChange={() =>
                  setShowFilterByType(filterKey, !showFilters[filterKey])
                }
                id="showAccountsNumbers"
              />
            }
            label={CheckboxLabelByShowFilter[filterKey]}
            // @ts-ignore
            sx={{ mr: 2  }}
          />
        );
      })}
    </Box>
  );
};
