import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
} from "@mui/material";
import {
  EntryRow,
  UnprocessedTransactionsTableRowEntry,
} from "./process-transactions-table-row-entry";
import { useStoreProcessTransactionsDependencies } from "../store/process-transactions-store-deps";
import { useCreateBankJournalTransactions } from "../api/process-transactions-create-bank-journal-transactions";
import { enqueueSnackbar } from "notistack";
import { useProcessTransactionsGridData } from "../api/process-transcations-grid-data";
import { useStoreAccounts } from "../store/process-transactions-store-accounts";
import { useTransactions } from "../store/process-transactions-store-transactions";

interface ProcessTransactionsQuickAddSelectedProps {
  accNo: any;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  selectedTransactions: any[];
  handleClearFilters: () => void;
}

export const ProcessTransactionsQuickAddSelected = ({
  accNo,
  isOpen,
  setIsOpen,
  selectedTransactions,
  handleClearFilters,
}: ProcessTransactionsQuickAddSelectedProps) => {
  const setTransactions = useTransactions.use.setTransactions();
  const setInitialTransactions = useTransactions.use.setInitialTransactions();
  const setSelectedAccount = useStoreAccounts.use.setSelectedAccount();

  const gridDataMutation = useProcessTransactionsGridData({
    onSuccess: (data) => {
      handleClearFilters();
      if (data?.Transactions) {
        setTransactions(data?.Transactions);
        setInitialTransactions(data?.Transactions);
      } else {
        setTransactions([]);
        setInitialTransactions([]);
      }
      // setSelectedAccount(null);
    },
    // @ts-ignore
    onError: (error) => {
      enqueueSnackbar(`Error fetching transactions: ${error}`, {
        variant: "error",
      });
    },
  });
  const selectedAccount = useStoreAccounts.use.selectedAccount();

  const listOfTaxCodes =
    useStoreProcessTransactionsDependencies.use.listOfTaxCodes();
  const listOfAllSites =
    useStoreProcessTransactionsDependencies.use.listOfSites();
  const listOfContacts =
    useStoreProcessTransactionsDependencies.use.listOfContacts();
  const listOfAccounts =
    useStoreProcessTransactionsDependencies.use.listOfAccounts();

  const createBankJournalTransactions = useCreateBankJournalTransactions({
    onSuccess: () => {
      enqueueSnackbar("Transactions added successfully", {
        variant: "success",
      });
      setIsOpen(false);
    },
    onError: (error) => {
      enqueueSnackbar(`Error adding transactions: ${error}`, {
        variant: "error",
      });
    },
  });

  const handleClose = () => {
    setIsOpen(false);
  };

  const onSaveAndPostEntry = async (args) => {
    await createBankJournalTransactions.createBankJournalTransactions({
      ...args,
      ListOfTransactions: selectedTransactions.map(
        (transaction) => transaction.ID,
      ),
    });

    if (!selectedAccount) {
      return;
    }

    await gridDataMutation.fetchGridData({
      AccountID: selectedAccount.ID,
      Bnk0114: "",
      OffsetNo: 0,
      ReturnNo: 100,
      RuleFilter: "",
    });
  };

  const isOneSelected = selectedTransactions.length === 1;

  const initialEntryData: EntryRow[] = [
    {
      accNo: undefined,
      taxId: undefined,
      memo: "",
      contact: "",
      review: false,
      site: "",
    },
  ];

  React.useEffect(() => {
    if (
      createBankJournalTransactions.isError ||
      createBankJournalTransactions.error
    ) {
      enqueueSnackbar(
        `Error adding transactions: ${createBankJournalTransactions.error}`,
        {
          variant: "error",
        },
      );
    }
  }, [createBankJournalTransactions]);

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      sx={{
        ">div>div": { maxWidth: "xl" },
      }}
    >
      <DialogTitle id="dialog-title">
        {isOneSelected
          ? "Post selected transaction"
          : "Post selected transactions"}
      </DialogTitle>

      <DialogContent id="dialog-description">
        <Box sx={{ minWidth: "800px", maxWidth: "800px" }}>
          <UnprocessedTransactionsTableRowEntry
            isLoading={createBankJournalTransactions.isLoading}
            initialData={initialEntryData}
            listOfTaxCodes={listOfTaxCodes || []}
            listOfSites={listOfAllSites || []}
            listOfContacts={listOfContacts || []}
            listOfAccounts={listOfAccounts || []}
            onClose={handleClose}
            onSaveAndPostEntry={onSaveAndPostEntry}
            hideMemo={true}
            hideReview={true}
          />
        </Box>
      </DialogContent>
      {/* <DialogActions sx={{ justifyContent: "flex-start" }}>
      </DialogActions> */}
    </Dialog>
  );
};
