import React from "react";
import { ButtonOwnProps } from "@mui/material";
import { IconWrapper } from "./icon-wrapper";

export const IconReceiptAlt3 = ({
  color,
  paletteType,
}: {
  color: ButtonOwnProps["color"];
  paletteType?: "main" | "light" | "dark" | "contrastText";
}) => {
  return (
    <IconWrapper color={color} paletteType={paletteType}>
      {(strokeColor) => (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17 13.0001H21V19.0001C21 20.1047 20.1046 21.0001 19 21.0001M17 13.0001V19.0001C17 20.1047 17.8954 21.0001 19 21.0001M17 13.0001V5.75719C17 4.8518 17 4.3991 16.8098 4.13658C16.6439 3.90758 16.3888 3.75953 16.1076 3.72909C15.7853 3.6942 15.3923 3.9188 14.6062 4.368L14.2938 4.54649C14.0045 4.71183 13.8598 4.7945 13.7062 4.82687C13.5702 4.85551 13.4298 4.85551 13.2938 4.82687C13.1402 4.7945 12.9955 4.71183 12.7062 4.54649L10.7938 3.45372C10.5045 3.28838 10.3598 3.20571 10.2062 3.17334C10.0702 3.14469 9.92978 3.14469 9.79383 3.17334C9.64019 3.20571 9.49552 3.28838 9.20618 3.45372L7.29382 4.54649C7.00448 4.71183 6.85981 4.7945 6.70617 4.82687C6.57022 4.85551 6.42978 4.85551 6.29383 4.82687C6.14019 4.7945 5.99552 4.71183 5.70618 4.54649L5.39382 4.368C4.60772 3.9188 4.21467 3.6942 3.89237 3.72909C3.61123 3.75953 3.35611 3.90758 3.1902 4.13658C3 4.3991 3 4.8518 3 5.75719V16.2001C3 17.8803 3 18.7203 3.32698 19.3621C3.6146 19.9266 4.07354 20.3855 4.63803 20.6731C5.27976 21.0001 6.11984 21.0001 7.8 21.0001H19M12 10.5001C11.5 10.3761 10.6851 10.3716 10 10.3761C9.77091 10.3776 9.90941 10.3679 9.6 10.3761C8.79258 10.4013 8.00165 10.7369 8 11.6876C7.99825 12.7005 9 13.0001 10 13.0001C11 13.0001 12 13.2313 12 14.3126C12 15.1252 11.1925 15.4813 10.1861 15.5992C9.3861 15.5992 9 15.6251 8 15.5001M10 16.0001V17.0001M10 9.0001V10.0001"
            stroke={strokeColor}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </IconWrapper>
  );
};
