import { enqueueSnackbar } from "notistack";
import envConfig from "../../../../config";
import { useQuery } from "../../../../hooks/useQuery";

const url = `https://${envConfig.apiDev2}/api/en-au/accounting/get-financial-reports-initial-data?BaseHostURL=${envConfig.mainServiceUrl}`;

interface FinancialReportInitialData {
  ErrorMessage: null | string;
  MultipleSignatoryName: string;
  ShowMultipleSignatoriesYN: "Y" | "N";
  ShowTrustDetsYN: "Y" | "N";
  SuccessYN: "Y" | "N";
  Signatory1: string;
  Signatory2: string;
  CompilationSignatory: string;
  MyInfo: string;
}

interface FinancialReportInitialDataProps {
  onSuccess?: (data: FinancialReportInitialData) => void;
}

export const useFinancialReportInitialData = ({
  onSuccess,
}: FinancialReportInitialDataProps) => {
  const { isLoading, isError, error, data } =
    useQuery<FinancialReportInitialData>(url, {
      onSuccess: onSuccess,
      onError: (errorMessage) => {
        enqueueSnackbar(errorMessage, { variant: "error" });
      },
    });

  if (process.env.NODE_ENV === "development") {
    return {
      isLoading: false,
      isError: false,
      error: null,
      data: {
        ErrorMessage: null,
        MultipleSignatoryName: "John Doe",
        ShowMultipleSignatoriesYN: "Y",
        ShowTrustDetsYN: "Y",
        SuccessYN: "Y",
        Signatory1: "John Doe",
        Signatory2: "Jane Doe",
        CompilationSignatory: "John Doe",
        MyInfo: "John Doe",
      },
    };
  }

  return { isLoading, isError, error, data };
};
