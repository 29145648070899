import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useNavigateInternal } from "../../../hooks/use-navigate-internal";
import { SettingsIcon } from "lucide-react";

export function PayrollConfigurationMenu() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { openLegacyInNewTab } = useNavigateInternal();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (path: string) => {
    openLegacyInNewTab(path);
    handleClose();
  };

  return (
    <div>
      <Button
        variant="outlined"
        id="configuration-button"
        aria-controls={open ? "configuration-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        startIcon={<SettingsIcon />}
      >
        Configuration
      </Button>
      <Menu
        id="configuration-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "configuration-button",
        }}
      >
        <MenuItem onClick={() => handleMenuItemClick("/payitems/list")}>
          Pay Details
        </MenuItem>
        <MenuItem onClick={() => handleMenuItemClick("/paytypes/list")}>
          Pay Groups
        </MenuItem>
        <MenuItem onClick={() => handleMenuItemClick("/paycycles/list")}>
          Pay Cycles
        </MenuItem>
        <MenuItem onClick={() => handleMenuItemClick("/leave-types/list")}>
          Pay Leave Types
        </MenuItem>
      </Menu>
    </div>
  );
}
