import React from "react";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { SxProps, Theme } from "@mui/material/styles";
import { NumericFormat } from "react-number-format";

interface NumberInputProps extends Omit<TextFieldProps, "onChange" | "value"> {
  label?: string;
  value: number | string;
  onChange: (value: number) => void;
  min?: number;
  max?: number;
  error?: boolean;
  helperText?: string;
  disabled?: boolean;
  sx?: SxProps<Theme>;
}

interface CustomProps {
  onChange: (event: { target: { value: string } }) => void;
}

const NumberFormatCustom = React.forwardRef<HTMLInputElement, CustomProps>(
  function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        thousandSeparator=","
        decimalSeparator="."
        decimalScale={2}
        fixedDecimalScale
        allowNegative
        onValueChange={(values) => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
      />
    );
  },
);

const NumberField: React.FC<NumberInputProps> = ({
  label = "Number",
  value,
  onChange,
  min,
  max,
  error = false,
  helperText = "",
  disabled = false,
  sx,
  ...props
}) => {
  const [inputValue, setInputValue] = React.useState<string>(
    typeof value === "number" ? value.toFixed(2) : value,
  );

  React.useEffect(() => {
    setInputValue(typeof value === "number" ? value.toFixed(2) : value);
  }, [value]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log("handleChange: ", event.target.value);
    setInputValue(event.target.value);
  };

  const handleBlur = () => {
    console.log("handleBlur: ", inputValue, "something else");
    const numericValue = parseFloat(inputValue.replace(/,/g, ""));

    if (!isNaN(numericValue)) {
      if (
        (min !== undefined && numericValue < min) ||
        (max !== undefined && numericValue > max)
      ) {
        setInputValue(value.toString());
        return;
      }
      onChange(numericValue);
    } else {
      onChange(0);
      setInputValue("0.00");
    }
  };

  return (
    <TextField
      label={label}
      value={inputValue}
      onChange={handleChange}
      onBlur={handleBlur}
      error={error}
      helperText={helperText}
      disabled={disabled}
      sx={sx}
      InputProps={{
        inputComponent: NumberFormatCustom as any,
        inputProps: {
          min,
          max,
        },
      }}
      {...props}
    />
  );
};

export default NumberField;
