import React from "react";
import { TextField } from "@mui/material";

interface PaymentNoteProps {
  value: string;
  onChange: (value: string) => void;
}

export const PaymentNote: React.FC<PaymentNoteProps> = ({
  value,
  onChange,
}) => {
  const [localNote, setLocalNote] = React.useState(value);

  React.useEffect(() => {
    setLocalNote(value);
  }, [value]);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setLocalNote(e.target.value);
  };

  const handleBlur = () => {
    onChange(localNote);
  };

  return (
    <TextField
      multiline
      rows={4}
      fullWidth
      label="Note"
      variant="outlined"
      placeholder="Enter note here..."
      value={localNote}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
};
