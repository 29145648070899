import { enqueueSnackbar } from "notistack";
import { useEffect, useCallback } from "react";
import envConfig from "../../../../config";
import useMutation from "../../../../hooks/useMutation";
import { ResponseBoolean } from "../../../../types/api/common";
import { downloadPDF } from "../../../../utils/reusable";

interface FinancialReportPayload {
  StartDate: string;
  EndDate: string;
  MyFooter: string;
  MultipleSignatoriesYN: ResponseBoolean;
  CorporateTrusteeYN: ResponseBoolean;
  Signatory1: string;
  Signatory2: string;
  CompilationSignatory: string;
  ReportingEntityYN: ResponseBoolean;
  ShowPreviousPeriodYN: ResponseBoolean;
  ShowZeroAmountsYN: ResponseBoolean;
  ShowAccountNumbersYN: ResponseBoolean;
  ShowFooterAuditedUnauditedAU: string;
  ShowAccountsYN: ResponseBoolean;
  ShowAccGroupsYN: ResponseBoolean;
}

interface FinancialPDFReportResponse {
  SuccessYN: ResponseBoolean;
  ErrorMessage: string | null;
  ReportBase64: string;
  FileName: string | null;
}

const API_URL = `https://${envConfig.apiDev2}/api/en-au/accounting/get-financial-reports-base64-rs1?BaseHostURL=${envConfig.mainServiceUrl}`;

export const useFinancialReportReportPdf = () => {
  const { mutate, isLoading, isError, error, data } =
    useMutation<FinancialPDFReportResponse>({
      url: API_URL,
      method: "POST",
      onError: (errorMessage) => {
        enqueueSnackbar(errorMessage, { variant: "error" });
      },
    });

  const getPDFReport = useCallback(
    async (payload: FinancialReportPayload) => {
      await mutate(payload);
    },
    [mutate],
  );

  const generatePDFReport = useCallback(
    async (reportBase64: string, fileName: string) => {
      return await downloadPDF(reportBase64, fileName);
    },
    [],
  );

  useEffect(() => {
    const generateReport = async () => {
      if (data?.ReportBase64 && data?.FileName) {
        console.log("DEBUGGING: useEffect is called to generate PDF report");
        const isReportGenerated = await generatePDFReport(
          data.ReportBase64,
          data.FileName,
        );
        enqueueSnackbar(
          isReportGenerated
            ? "Report generated successfully"
            : "Failed to generate report",
          { variant: isReportGenerated ? "success" : "error" },
        );
      }
    };

    generateReport();
  }, [data?.ReportBase64, data?.FileName, generatePDFReport]);

  return { getPDFReport, isLoading, isError, error, data };
};
