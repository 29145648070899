import React from "react";
import { useSnackbar } from "notistack";
import useApi from "../../../hooks/useApi";
import envConfig from "../../../config";
import { GetEmployeesListResponse, UseGetEmployeesListParams } from "../types";
import { EXAMPLE_EMPLOYEE_LIST } from "./example-response";

export const useGetEmployeesList = ({
  id,
  onSuccess,
  onError,
}: UseGetEmployeesListParams) => {
  const { apiCall } = useApi();
  const { enqueueSnackbar } = useSnackbar();

  const [data, setData] = React.useState<GetEmployeesListResponse | null>(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isError, setIsError] = React.useState(false);

  const fetchEmployeesList = React.useCallback(async () => {
    setIsLoading(true);
    setIsError(false);

    if (process.env.NODE_ENV === "development") {
      setData(EXAMPLE_EMPLOYEE_LIST);
      setIsLoading(false);
      setIsError(false);
      return;
    }

    try {
      await apiCall({
        url: `https://${envConfig.apiDev2}/api/en-au/payroll-au/get-employees-list-for-adding-to-pay-event?BaseHostURL=${envConfig.mainServiceUrl}&PevID=${id}`,
        method: "GET",
        // @ts-ignore onSuccess callback signature mismatch
        onSuccess: (response: GetEmployeesListResponse) => {
          setData(response);
          if (onSuccess) onSuccess(response);
        },
        // @ts-ignore onError callback signature mismatch
        onError: (error: string) => {
          setIsError(true);
          enqueueSnackbar(error, { variant: "error" });
          if (onError) onError(error);
        },
      });
    } catch (error) {
      console.error("fetchEmployeesList error", error);
      setIsError(true);
      enqueueSnackbar((error as Error).message, { variant: "error" });
      if (onError) onError((error as Error).message);
    } finally {
      setIsLoading(false);
    }
  }, [apiCall, enqueueSnackbar, id, onSuccess, onError]);

  React.useEffect(() => {
    fetchEmployeesList();
  }, [fetchEmployeesList]);

  return {
    isLoading,
    isError,
    data,
    refetch: fetchEmployeesList,
  };
};
