// ReportDatesContext.js
import React, { createContext, useState } from "react";

const ReportDatesContext = createContext();

export const ReportDatesProvider = ({ children }) => {
  const [reportDates, setReportDates] = useState(null);
  const [interfacePreference, setInterfacePreference] = useState(null);
  const [pageMainData, setStatePageMainData] = useState(null);

  const setPageMainData = ({ dates, interfacePreference, pageMainData }) => {
    setReportDates(dates);
    setInterfacePreference(interfacePreference);
    setStatePageMainData(pageMainData);
  };

  return (
    <ReportDatesContext.Provider
      value={{
        setPageMainData,
        reportDates,
        interfacePreference,
        pageMainData,
      }}
    >
      {children}
    </ReportDatesContext.Provider>
  );
};

export default ReportDatesContext;
