import React, { useState, useMemo, useCallback } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { usePayEventSummaryDraft } from "../../features/payroll/api/get-pay-event-summary-draft-page";
import { PageTitle } from "../../components/page-title";
import BackdropLoading from "../../components/BackdropLoading/BackdropLoading";
import { PayrollEventDetails } from "../../features/payroll/components/PayrollEventDetails";
import {
  PayrollEventFilters,
  FilterType,
} from "../../features/payroll/components/PayrollEventFilters";
import { PayrollEventGrid } from "../../features/payroll/components/PayrollEventGrid";
import { PayrollEventActions } from "../../features/payroll/components/PayrollEventActions";
import { enqueueSnackbar } from "notistack";
import { useLoginPopup } from "../../context/LoginPopupContext";
import { PayrollEventFinalizeDialog } from "../../features/payroll/components/PayrollEventFinalizeDialog";
import { useCheckPayEventBeforeApproving } from "../../features/payroll/api/check-pay-event-before-approving";
import { PayrollEventDeleteDialog } from "../../features/payroll/components/PayrollEventDeleteDialog";
import { useDeletePayEvent } from "../../features/payroll/api/delete-pay-event";
import { useApprovePayEvent } from "../../features/payroll/api/approve-pay-event";
import { useUpdateDraftPayEventNotes } from "../../features/payroll/api/update-draft-pay-event-notes";
import { useGetEmployeesList } from "../../features/payroll/api/get-employees-list-for-adding-to-pay-event";
import { AddEmployeeToPayEventDialog } from "../../features/payroll/components/AddEmployeeToPayEventDialog";
import { PayrollEventChangeDatesDialog } from "../../features/payroll/components/PayrollEventChangeDatesDialog";
import { useUpdateDraftPayEventDates } from "../../features/payroll/api/update-draft-pay-event-dates";
import { formatDateToISO } from "../../utils/format-date";

export function PayrollEventOpen() {
  const { payEventID, dbId } = useParams();
  const [searchParams] = useSearchParams();
  const { isReAuthCompleted } = useLoginPopup();
  const navigate = useNavigate();

  const [selectedFilter, setSelectedFilter] = useState<FilterType>("all");
  const [isFinalizingDialogOpen, setIsFinalizingDialogOpen] = useState(false);
  const [finalizeErrorPrompts, setFinalizeErrorPrompts] = useState({
    ErrorsPrompt: "",
    EmptyEmployeesPrompt: "",
    TerminatingEmployeesPrompt: "",
    ZeroNetPayPrompt: "",
  });
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isAddEmployeeDialogOpen, setIsAddEmployeeDialogOpen] = useState(false);
  const [isChangeDatesDialogOpen, setIsChangeDatesDialogOpen] = useState(false);

  const payrollEvent = usePayEventSummaryDraft({
    id: payEventID ? payEventID : "",
    enable: process.env.NODE_ENV === "development" ? true : isReAuthCompleted,
  });

  const deletePayEvent = useDeletePayEvent({
    id: payEventID || "",
    onError: (error) => {
      enqueueSnackbar(error, { variant: "error" });
    },
  });

  const approvePayEvent = useApprovePayEvent({
    id: payEventID || "",
    onSuccess: () => {
      navigate(`/${dbId}/en-au/payroll/pay-event-finalised/${payEventID}`);
    },
  });

  const checkPayEvent = useCheckPayEventBeforeApproving({
    id: payEventID || "",
    onSuccess: (response) => {
      const {
        ErrorsPrompt,
        EmptyEmployeesPrompt,
        ZeroNetPayPrompt,
        TerminatingEmployeesPrompt,
      } = response;

      console.log("checkPayEvent response: ", response);

      const hasErrors = [
        ErrorsPrompt,
        EmptyEmployeesPrompt,
        ZeroNetPayPrompt,
        TerminatingEmployeesPrompt,
      ].some((prompt) => prompt && prompt.trim() !== "");

      if (!hasErrors) {
        // Call the API directly here
        console.log("All prompts are empty or null. Proceeding with API call.");
        approvePayEvent.approvePayEvent();
        // Example: finalizePayEvent(); // You will add this API call later
      } else {
        // Show the PayrollEventFinalizeDialog modal
        setFinalizeErrorPrompts({
          ErrorsPrompt: ErrorsPrompt || "",
          EmptyEmployeesPrompt: EmptyEmployeesPrompt || "",
          TerminatingEmployeesPrompt: TerminatingEmployeesPrompt || "",
          ZeroNetPayPrompt: ZeroNetPayPrompt || "",
        });
        setIsFinalizingDialogOpen(true);
      }
    },
    onError: (error) => {
      enqueueSnackbar(error, { variant: "error" });
    },
  });

  const updateDraftPayEventNotes = useUpdateDraftPayEventNotes({
    id: payEventID || "",
  });

  const updateDraftPayEventDates = useUpdateDraftPayEventDates({
    id: payEventID || "",
    onSuccess: () => {
      setIsChangeDatesDialogOpen(false);
      payrollEvent.refetch();
    },
  });

  const filteredDataSets = useMemo(() => {
    if (!payrollEvent.data) return {};

    const data = payrollEvent.data.GridPack.DataDT;
    return {
      all: data,
      warnings: data.filter((employee) => employee.Warnings > 0),
      zeroNetPay: data.filter((employee) => employee.NetPay === 0),
      inactive: data.filter((employee) => employee.empStatus !== "A"),
    };
  }, [payrollEvent.data]);

  // Get the currently filtered employees based on selected filter
  const filteredEmployees = useMemo(
    () => filteredDataSets[selectedFilter] || [],
    [filteredDataSets, selectedFilter],
  );

  // Memoize the filter change handler
  const handleFilterChange = useCallback((filter: FilterType) => {
    setSelectedFilter(filter);
  }, []);

  const handleFinalize = useCallback(() => {
    checkPayEvent.checkPayEvent();
    // setIsFinalizingDialogOpen(true);
  }, [checkPayEvent]);

  const handleFinalizeConfirm = useCallback(() => {
    enqueueSnackbar("Finalize action to be implemented", { variant: "info" });
    setIsFinalizingDialogOpen(false);
  }, []);

  const handleAddEmployee = useCallback(() => {
    setIsAddEmployeeDialogOpen(true);
  }, []);

  const handleDelete = useCallback(() => {
    setIsDeleteDialogOpen(true);
  }, []);

  const handleDeleteConfirm = useCallback(() => {
    deletePayEvent.deletePayEvent();
    setIsDeleteDialogOpen(false);
  }, [deletePayEvent]);

  const handleSeeWarnings = useCallback(() => {
    // setIsFinalizingDialogOpen(false);
    window.open(
      `/${dbId}/en-au/reports/event-warnings?p0=2&p1=${payEventID}`,
      "_blank",
    );
  }, [dbId, payEventID]);

  const handleChangeDates = useCallback(() => {
    setIsChangeDatesDialogOpen(true);
  }, []);

  const handleChangeDatesConfirm = (dates: {
    paymentDate: string | null;
    startDate: string | null;
    endDate: string | null;
  }) => {
    if (dates.paymentDate && dates.startDate && dates.endDate) {
      updateDraftPayEventDates.updateDates({
        paymentDate: formatDateToISO(dates.paymentDate),
        startDate: formatDateToISO(dates.startDate),
        endDate: formatDateToISO(dates.endDate),
      });
      payrollEvent.refetch();
    } else {
      enqueueSnackbar("Please fill in all dates", { variant: "error" });
    }
  };

  const handlePevNotesBlur = useCallback(
    (noteText: string) => {
      updateDraftPayEventNotes.updateNotes({
        noteText: noteText,
        isPrivate: true,
      });
    },
    [updateDraftPayEventNotes],
  );

  const handlePevPayslipCommentBlur = useCallback(
    (noteText: string) => {
      updateDraftPayEventNotes.updateNotes({
        noteText: noteText,
        isPrivate: false,
      });
    },
    [updateDraftPayEventNotes],
  );

  const handleBack = useCallback(() => {
    // backURL exmaple: ?backURL=/21104/en-au/payroll/pay-event-finalised/1
    const backURL = searchParams.get("backURL");
    if (backURL) {
      navigate(backURL);
    } else {
      navigate(-1);
    }
  }, [navigate, searchParams]);

  const warningsCount = useMemo(() => {
    if (!payrollEvent.data) return 0;
    return payrollEvent.data.GridPack.DataDT.filter(
      (employee) => employee.Warnings > 0,
    ).length;
  }, [payrollEvent.data]);

  const employeesList = useGetEmployeesList({
    id: payEventID || "",
  });

  const handleAddEmployeeDialogClose = useCallback(() => {
    setIsAddEmployeeDialogOpen(false);
  }, []);

  console.log("[DEBUGGING][PAYROLL EVENT OPEN] data: ", payrollEvent.data);

  return (
    <>
      <BackdropLoading
        open={
          payrollEvent.isLoading ||
          checkPayEvent.isLoading ||
          deletePayEvent.isLoading ||
          updateDraftPayEventNotes.isLoading ||
          updateDraftPayEventDates.isLoading
        }
      />
      <PageTitle title="Payroll Event Summary - Draft" />

      {payrollEvent.data && (
        <>
          <PayrollEventDetails
            mainDatesTxt={payrollEvent.data.MainDatesTxt}
            createdTxt={payrollEvent.data.CreatedTxt}
            warnings={payrollEvent.data.Warnings}
            pevNotes={payrollEvent.data.PevNotes}
            pevPayslipComment={payrollEvent.data.PevPayslipComment}
            onPevNotesBlur={handlePevNotesBlur}
            onPeyPayslipCommentBlur={handlePevPayslipCommentBlur}
            finalized={false}
          />
          <PayrollEventActions
            onFinalize={handleFinalize}
            onAddEmployee={handleAddEmployee}
            onDelete={handleDelete}
            onSeeWarnings={handleSeeWarnings}
            onChangeDates={handleChangeDates}
            onBack={handleBack}
            warningsCount={warningsCount}
          />
          <PayrollEventFilters
            selectedFilter={selectedFilter}
            onFilterChange={handleFilterChange}
            data={payrollEvent.data.GridPack.DataDT}
          />
          <PayrollEventGrid
            data={filteredEmployees}
            columns={payrollEvent.data.GridPack.ColumnsList}
            actionsList={payrollEvent.data.GridPack.ActionsList}
            tooltipsList={payrollEvent.data.GridPack.TooltipsList}
            onRefresh={() => payrollEvent.refetch()}
          />
          <PayrollEventFinalizeDialog
            open={isFinalizingDialogOpen}
            onClose={() => setIsFinalizingDialogOpen(false)}
            onFinalize={handleFinalizeConfirm}
            onSeeWarnings={handleSeeWarnings}
            hasErrors={!!finalizeErrorPrompts.ErrorsPrompt}
            errorPrompts={finalizeErrorPrompts}
          />
          <PayrollEventDeleteDialog
            open={isDeleteDialogOpen}
            onClose={() => setIsDeleteDialogOpen(false)}
            onConfirm={handleDeleteConfirm}
          />
          <AddEmployeeToPayEventDialog
            open={isAddEmployeeDialogOpen}
            onClose={handleAddEmployeeDialogClose}
            data={employeesList.data?.DataDT}
            columns={employeesList.data?.ColumnsList}
            isLoading={employeesList.isLoading}
            payEventId={Number(payEventID)}
            // onSuccess={() => payrollEvent.refetch()}
          />
          <PayrollEventChangeDatesDialog
            open={isChangeDatesDialogOpen}
            onClose={() => setIsChangeDatesDialogOpen(false)}
            onConfirm={handleChangeDatesConfirm}
            currentPaymentDate={payrollEvent.data?.PevPayDate}
            currentStartDate={payrollEvent.data?.PevStartDate}
            currentEndDate={payrollEvent.data?.PevEndDate}
            periodType={"MISSING FROM THE API"}
          />
        </>
      )}
    </>
  );
}
