import { create } from "zustand";
import { createSelectors } from "../../../../utils/createSelectors";
import { UnprocessedTransactions } from "../types";

interface State {
  initialTransactions: UnprocessedTransactions[]; // ones from the backend
  transactions: UnprocessedTransactions[]; // ones that are in data grid
  selectedRowsKeys: string[]; // keys of selected transactions

  ///
  isFiltered: boolean;
  filteredRowsKeys: string[];
  searchTerm: string;
  appliedRule: string;
}

interface Actions {
  setSearchTerm: (searchTerm: string) => void;
  setIsFiltered: (isFiltered: boolean) => void;
  setTransactions: (transactions: UnprocessedTransactions[]) => void;
  setInitialTransactions: (transactions: UnprocessedTransactions[]) => void;
  setSelectedRowsKeys: (keys: string[]) => void;
  setFilteredRowsKeys: (keys: string[]) => void;
  setAppliedRule: (rule: string) => void;
}

export const useStoreTransactionsBase = create<State & Actions>()((set) => ({
  transactions: [],
  initialTransactions: [],
  selectedRowsKeys: [],
  filteredRowsKeys: [],
  isFiltered: false,
  searchTerm: "",
  appliedRule: "",
  setAppliedRule: (rule) => set({ appliedRule: rule }),
  setSearchTerm: (searchTerm) => set({ searchTerm }),
  setIsFiltered: (isFiltered) => set({ isFiltered }),
  setTransactions: (transactions) => set({ transactions }),
  setInitialTransactions: (t) => set({ initialTransactions: t }),
  setSelectedRowsKeys: (keys) => set({ selectedRowsKeys: keys }),
  setFilteredRowsKeys: (keys) => set({ filteredRowsKeys: keys }),
}));

export const useTransactions = createSelectors(useStoreTransactionsBase);
