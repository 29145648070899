import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import RefreshIcon from "@mui/icons-material/Refresh";
import { DataGrid } from "devextreme-react";
import { Column, Paging, SearchPanel } from "devextreme-react/data-grid";
import { useNavigate, useParams } from "react-router-dom";
import useApi from "../../hooks/useApi";
import envConfig from "../../config";
import { enqueueSnackbar } from "notistack";
import BackdropLoading from "../../components/BackdropLoading/BackdropLoading";

const Sites = () => {
  const { dbId, lang, dptId } = useParams();
  const { apiCall } = useApi();
  const navigate = useNavigate();

  const [sites, setSites] = useState([]);
  const [holidays, setHolidays] = useState([]);
  const [loading, setLoading] = useState(true);
  const [anchorPosition, setAnchorPosition] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const fetchSites = useCallback(async () => {
    setLoading(true);
    try {
      await apiCall({
        url: `https://${envConfig.apiDev1Exacc}/api/v1/en-au/sites/get-list-of-sites-async?departmentId=${dptId}`,
        method: "GET",
        onSuccess: (data) => {
          setSites(data.ListOfSites || []);
          setHolidays(data.ListOfHolidays || []);
        },
        onError: (error) => {
          enqueueSnackbar(error, { variant: "error" });
        },
      });
    } catch (error) {
      console.error("Error fetching Sitesf:", error);
    } finally {
      setLoading(false);
    }
  }, [apiCall]);

  useEffect(() => {
    fetchSites();
  }, [fetchSites]);

  const handleRowClick = (e) => {
    navigate(`/${dbId}/${lang}/site/${dptId}/${e.data.dpsID}`);
  };

  const handleContextMenuClick = (event, rowData) => {
    event.stopPropagation();
    setAnchorPosition({
      top: event.nativeEvent.clientY,
      left: event.nativeEvent.clientX,
    });
    setSelectedRow(rowData);
  };

  const handleMenuClose = () => {
    setAnchorPosition(null);
  };

  const handleDeleteClick = () => {
    handleMenuClose();
    setConfirmOpen(true);
  };

  const handleConfirmDelete = async () => {
    setConfirmOpen(false);
    setLoading(true);
    if (selectedRow) {
      try {
        await apiCall({
          url: `https://${envConfig.apiDev1Exacc}/api/v1/en-au/sites/delete-async?siteId=${selectedRow.dpsID}&BaseHostURL=s1.olivs.com`,
          method: "POST",
          onSuccess: (data) => {
            console.log(data);
            enqueueSnackbar("Site deleted successfully.", {
              variant: "success",
            });
            fetchSites();
          },
          onError: (error) => {
            enqueueSnackbar(error, { variant: "error" });
          },
        });
      } catch (error) {
        console.error(error || "Failed to delete site");
      } finally {
        setLoading(false);
        handleMenuClose();
        setSelectedRow(null);
      }
    }
  };

  const renderActionsCell = (cellData) => {
    return (
      <IconButton
        onClick={(event) => handleContextMenuClick(event, cellData.data)}
      >
        <MoreVertIcon />
      </IconButton>
    );
  };

  const handleRefresh = () => {
    fetchSites();
  };

  const getHolidayDisplay = (xphID) => {
    if (!xphID) return xphID;
    const holiday = holidays.find((h) => h.Value === xphID);
    return holiday ? holiday.Display : "";
  };

  return (
    <Box sx={{ padding: 2 }}>
      <BackdropLoading open={loading} />

      <Dialog open={confirmOpen} onClose={() => setConfirmOpen(false)}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this site?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmOpen(false)}>Cancel</Button>
          <Button onClick={handleConfirmDelete} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Typography variant="h4">Sites</Typography>

      <Box sx={{ display: "flex", gap: 2, marginY: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate(`/${dbId}/${lang}/departments`)}
        >
          See all Departments
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate(`/${dbId}/${lang}/site/${dptId}`)}
        >
          + New Site
        </Button>
        <Button
          variant="outlined"
          onClick={handleRefresh}
          startIcon={<RefreshIcon />}
        >
          Refresh
        </Button>
      </Box>

      <DataGrid
        dataSource={sites}
        keyExpr="dpsID"
        showBorders={true}
        onRowClick={handleRowClick}
        hoverStateEnabled={true}
        loading={loading}
      >
        <SearchPanel visible={true} width={240} placeholder="Search..." />
        <Paging defaultPageSize={10} />
        <Column dataField="dpsName" caption="Name" />
        <Column dataField="dpsSiteCodeOrName" caption="Code" />
        <Column dataField="dptName" caption="Department" />
        <Column dataField="dpsRestrictedYN" caption="Restricted" />
        <Column dataField="adrFullAddress" caption="Address" />
        <Column dataField="usrManagerFullName" caption="Manager" />
        <Column
          caption="Public Holidays"
          cellRender={(cellData) => {
            return (
              <span>
                {getHolidayDisplay(cellData.data.dpsHolidayGrp_xphID)}
              </span>
            );
          }}
        />
        <Column
          caption="Actions"
          cellRender={renderActionsCell}
          allowSorting={false}
          width={100}
        />
      </DataGrid>

      <Menu
        anchorReference="anchorPosition"
        anchorPosition={anchorPosition}
        open={Boolean(anchorPosition)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleDeleteClick}>Delete</MenuItem>
      </Menu>
    </Box>
  );
};

export default Sites;
