import React, { useCallback, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useReportsParameters } from "../../features/reports/custom-reports/api/get-reports-parameters";
import BackdropLoading from "../../components/BackdropLoading/BackdropLoading";
import { PageTitle } from "../../components/page-title";
import { CustomReportsFiltersPanel } from "../../features/reports/custom-reports/components/custom-reports-filter-panel";
import { boolToYN, YNToBool } from "../../utils/format-bool";
import { CustomReportsTable } from "../../features/reports/custom-reports/components/custom-reports-table";
import { useGetCustomReportDataGrid } from "../../features/reports/custom-reports/api/get-reports-data-grid";
import { enqueueSnackbar } from "notistack";
import {
  convertSimpleDateToDayjs,
  formatDateToISO,
  isOneOfSupportedDateFormat,
} from "../../utils/format-date";
import { useLoginPopup } from "../../context/LoginPopupContext";
import { Box, Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import { EditButton } from "../../components/ui/buttons/edit-button";
import { usePageMainData } from "../../hooks/use-page-main-data";
import { useUpdatePageTitle } from "../../hooks/use-update-page-title";
import {
  CustomReportFieldValidationState,
  CustomReportParameter,
  CustomReportState,
} from "../../features/reports/custom-reports/types";
import {
  getStateValueFromParam,
  updateUrlParamsWithState,
} from "../../features/reports/custom-reports/helpers";
import { isDate } from "../../features/books/import-transactions-from-csv/helpers/get-headers-from-csv";

const getDefaultValue = (parameter: CustomReportParameter): any => {
  switch (parameter.ControlType) {
    case "checkbox":
      return YNToBool(parameter.DefaultValue);
    case "users":
      return [];
    case "single-selection":
      return undefined;
    case "date":
      return parameter.DefaultValue !== "" && parameter.DefaultValue !== "Y"
        ? convertSimpleDateToDayjs(parameter.DefaultValue)
        : null;
    default:
      return parameter.DefaultValue;
  }
};

export function CustomReports() {
  const params = useParams();
  const reportName = params.reportName;
  const [searchParams] = useSearchParams();
  const { handleOpen } = useLoginPopup();

  const initialState: CustomReportState = {
    predefinedPeriod: { value: "custom" },
  };

  const [state, setState] = React.useState<CustomReportState>(initialState);
  const [isFieldValid, setIsFieldValid] =
    React.useState<CustomReportFieldValidationState>({});
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [mainTitle, setMainTitle] = React.useState("");

  const pageMainData = usePageMainData();
  const businessCode = pageMainData?.BusinessCode;

  const {
    data,
    isLoading: isLoadingReportParameters,
    isSuccess,
    isError,
    error,
    // @ts-ignore
  } = useReportsParameters({ reportId: reportName });

  const customReportDataGridMutation = useGetCustomReportDataGrid({
    mutationConfig: {
      onSuccess: (data) => {
        setMainTitle(data?.RptTitle || mainTitle);
      },
      onError: (error) => {
        enqueueSnackbar(error?.message, { variant: "error" });
        setIsModalOpen(true);
      },
    },
  });

  useEffect(() => {
    if (isSuccess) {
      const parameters = data?.Parameters || [];

      const newState: CustomReportState = parameters.reduce(
        (acc, parameter) => {
          const key = `p${parameter.Order}`;
          return {
            ...acc,
            [key]: {
              value: getDefaultValue(parameter),
              ControlType: parameter.ControlType,
              RequiredYN: YNToBool(parameter.RequiredYN),
              MaxValue: parameter.MaxValue,
              MinValue: parameter.MinValue,
            },
          };
        },
        {
          predefinedPeriod: {
            value: "custom",
          },
        },
      );

      const newIsFieldValid = parameters.reduce((acc, parameter) => {
        const key = `p${parameter.Order}`;
        return {
          ...acc,
          [key]: {
            isValid: true,
            errorMessage: "",
          },
        };
      }, {});

      if (searchParams.size > 0) {
        let tmpState = { ...newState };

        searchParams.forEach((value, key) => {
          tmpState = {
            ...tmpState,
            [key]: {
              ...tmpState[key],
              value: getStateValueFromParam(value, tmpState[key].value),
            },
          };
        });
        console.log("[DEBUGGING] TMP STATE: ", tmpState);

        // validate all fields
        const newValidations = validateAllFields(tmpState, parameters);

        console.log("[DEBUGGING] NEW VALIDATIONS: ", newValidations);

        if (
          Object.values(newValidations).some(
            // @ts-ignore
            (validation) => !validation.isValid,
          )
        ) {
          setIsFieldValid(newValidations);
          setState(tmpState);
          enqueueSnackbar("Please fill in all required fields", {
            variant: "error",
          });
          setIsModalOpen(true);
          return;
        } else {
          const requestBody = {
            ReportID: reportName,
            Parameters: getParametersForRequest(tmpState, parameters),
          };

          customReportDataGridMutation.mutate(requestBody, handleOpen);
          setState(tmpState);
          setIsModalOpen(false);
          updateUrlParamsWithState(tmpState);
        }
      } else {
        setState(newState);
        setIsFieldValid(newIsFieldValid);
        setIsModalOpen(true);
      }

      setMainTitle(data?.ReportTitle || "");
    }
  }, [isSuccess, searchParams]);

  console.log("[DEBUGGING] STATE: ", state);
  console.log("[DEBUGGING] VALIDATION: ", isFieldValid);

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(error, { variant: "error" });
    }
  }, [isError, error]);

  const handleOnChange = useCallback((label, value) => {
    setIsFieldValid((prev) => {
      return {
        ...prev,
        [label]: {
          isValid: true,
          errorMessage: "",
        },
      };
    });

    setState((prev) => {
      return {
        ...prev,
        [label]: {
          ...prev[label],
          value: value,
        },
      };
    });
  }, []);

  const getParametersForRequest = (passedState, dataParameters) => {
    return dataParameters.map((parameter) => {
      const key = `p${parameter.Order}`;
      if (passedState[key]) {
        if (parameter.ControlType === "checkbox") {
          return `${boolToYN(passedState[key].value)}`;
        } else if (parameter.ControlType === "users") {
          return passedState[key].value.map((user) => user.Value).join(",");
        } else if (parameter.ControlType === "single-selection") {
          return `${passedState[key].value}`;
        } else if (parameter.ControlType === "multi-selection") {
          return passedState[key].value.map((option) => option.Value).join(",");
        } else if (passedState[key].value === "" || !passedState[key].value) {
          return parameter.DefaultValue;
        } else if (parameter.ControlType === "date") {
          return formatDateToISO(passedState[key].value?.toDate());
        } else {
          return `${passedState[key].value}`;
        }
      }
    });
  };

  const validateAllFields = (currentState, parameters) => {
    return parameters.reduce((acc, parameter) => {
      const key = `p${parameter.Order}`;
      if (
        parameter.RequiredYN === "Y" &&
        parameter.ControlType === "single-selection" &&
        currentState[key] &&
        !currentState[key].value
      ) {
        return {
          ...acc,
          [key]: {
            isValid: false,
            errorMessage: "This field is required",
          },
        };
      }

      if (
        parameter.RequiredYN === "Y" &&
        parameter.ControlType === "multi-selection" &&
        currentState[key] &&
        currentState[key].value.length > 0
      ) {
        return {
          ...acc,
          [key]: {
            isValid: false,
            errorMessage: "This field is required",
          },
        };
      }

      if (
        parameter.RequiredYN === "Y" &&
        currentState[key] &&
        !currentState[key].value
      ) {
        return {
          ...acc,
          [key]: {
            isValid: false,
            errorMessage: "This field is required",
          },
        };
      }

      if (
        parameter.ControlType === "integer" &&
        currentState[key].value &&
        isNaN(currentState[key].value)
      ) {
        return {
          ...acc,
          [key]: {
            isValid: false,
            errorMessage: "This field should be a number",
          },
        };
      }

      console.log(
        "IS ONE OF SUPPORTED DATE FORMAT: ",
        isOneOfSupportedDateFormat(currentState[key].value),
      );

      if (
        parameter.ControlType === "date" &&
        currentState[key].value &&
        !isOneOfSupportedDateFormat(currentState[key].value)
        // &&
        // !currentState[key].value?.isValid()
      ) {
        return {
          ...acc,
          [key]: {
            isValid: false,
            errorMessage: "This field should be a valid date",
          },
        };
      }

      return {
        ...acc,
        [key]: {
          isValid: true,
          errorMessage: "",
        },
      };
    }, {});
  };

  const onApplyFilters = () => {
    const newValidations = validateAllFields(state, data?.Parameters);

    if (
      // @ts-ignore
      Object.values(newValidations).some((validation) => !validation.isValid)
    ) {
      setIsFieldValid(newValidations);
      enqueueSnackbar("Please fill in all required fields", {
        variant: "error",
      });
      return;
    }

    const requestBody = {
      ReportID: reportName,
      Parameters: getParametersForRequest(state, data?.Parameters),
    };

    customReportDataGridMutation.mutate(requestBody, handleOpen);
    setIsModalOpen(false);
    updateUrlParamsWithState(state);
  };

  const isLoading = customReportDataGridMutation.isPending;
  const addDateSelector = data?.AddDateSelector;

  useUpdatePageTitle(
    `${!!businessCode ? businessCode + " - " : ""}  ${data?.ReportTitle || data?.RptTitle || "Custom Report"}`,
  );

  if (isLoadingReportParameters) {
    return <BackdropLoading open={isLoadingReportParameters} />;
  }

  return (
    <>
      <BackdropLoading open={isLoading} />
      <Box display={"flex"} alignItems={"center"} gap={2} marginTop={2}>
        <Box sx={{ mr: 3 }}>
          <PageTitle title={mainTitle} />
        </Box>
        <EditButton title="Update" onClick={() => setIsModalOpen(true)} />
      </Box>

      <Dialog
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        sx={{
          ">div>div": { maxWidth: 1000 },
        }}
      >
        <DialogTitle>{data?.ReportTitle || "Custom Report"} </DialogTitle>
        <CustomReportsFiltersPanel
          state={state}
          isFieldValid={isFieldValid}
          handleOnChange={handleOnChange}
          parameters={data?.Parameters || []}
          onApplyFilters={onApplyFilters}
          addDateSelector={addDateSelector}
        />
        <DialogActions sx={{ pt: 0, mt: 5 }}>
          <Button
            onClick={() => {
              setIsModalOpen(false);
              setIsFieldValid({});
            }}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={onApplyFilters}
            variant="outlined"
            disableRipple
          >
            Apply
          </Button>
        </DialogActions>
      </Dialog>
      <CustomReportsTable
        title={data?.ReportTitle || "Custom Report"}
        reportTitle={customReportDataGridMutation?.data?.RptTitle}
        reportSubTitle={customReportDataGridMutation?.data?.RptSubTitle}
        data={customReportDataGridMutation?.data?.DataDT}
        columns={customReportDataGridMutation?.data?.ColumnsList}
        actionsList={customReportDataGridMutation?.data?.ActionsList}
        tooltipsList={customReportDataGridMutation?.data?.TooltipsList}
        onRefresh={onApplyFilters}
        groupBy={customReportDataGridMutation?.data?.GroupBy}
        PdfJson={customReportDataGridMutation?.data?.PdfJson}
      />
    </>
  );
}
