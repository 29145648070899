/**
 * Converts a time string in "HH:MM:SS" format to the number of time units.
 * @param timeString - The time string in "HH:MM:SS" format.
 * @param unitInSeconds - The size of one time unit in seconds (default: 60 seconds).
 * @returns The number of time units in the given time string.
 */
export function calculateTimeUnits(
  timeString: string,
  unitInSeconds: number = 60,
): number {
  // Split the time string into hours, minutes, and seconds
  const [hours, minutes, seconds] = timeString.split(":").map(Number);

  // Convert the time into total seconds
  const totalSeconds = hours * 3600 + minutes * 60 + seconds;

  // Calculate the number of time units
  return totalSeconds / unitInSeconds;
}
