import React from "react";
import {
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import NumberField from "../../../components/ui/inputs/number-input";

interface TimesheetChargeProps {
  isChargeable: boolean;
  onChargeableChange: (value: boolean) => void;
  charge: number;
  onChargeChange: (value: number) => void;
  hourlyRate: number;
  onHourlyRateChange: (value: number) => void;
}

const TimesheetCharge = ({
  isChargeable,
  onChargeableChange,
  charge,
  onChargeChange,
  hourlyRate,
  onHourlyRateChange,
}: TimesheetChargeProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const formatValue = (value: number | null) => {
    if (value === null || isNaN(value)) return "0.00";
    return value.toFixed(2);
  };

  return (
    <Box>
      <Typography variant="subtitle1" mb={2}>
        Activity Charge
      </Typography>

      <Box
        display="flex"
        gap={2}
        alignItems="center"
        flexDirection={isMobile ? "column" : "row"}
        sx={{
          "& > *": {
            width: isMobile ? "100%" : "auto",
            flex: isMobile ? "none" : 1,
          },
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={isChargeable}
              onChange={(e) => onChargeableChange(e.target.checked)}
            />
          }
          label="Chargeable"
          sx={{ width: isMobile ? "100%" : "100px", maxWidth: "150px" }}
        />

        <NumberField
          label="Charge"
          size="small"
          value={formatValue(charge)}
          onChange={onChargeChange}
          min={0}
          fullWidth
          sx={{ maxWidth: "350px" }}
        />

        <NumberField
          label="Hourly Rate"
          size="small"
          value={formatValue(hourlyRate)}
          onChange={onHourlyRateChange}
          min={0}
          fullWidth
          sx={{ maxWidth: "350px" }}
        />
      </Box>
    </Box>
  );
};

export default TimesheetCharge;
