import React, { createContext, useState, useContext, useCallback } from "react";
import LoginPopup from "../components/LoginPopup/LoginPopup";

const PopupContext = createContext();

export function PopupProvider({ children }) {
  const [state, setState] = useState({
    isOpen: false,
    popupType: "login",
    cooldownUntil: 0,
    isReAuthCompleted: false,
  });

  const [reAuthResolver, setReAuthResolver] = useState(null);

  const handleOpen = useCallback(
    (type = "login") => {
      if (process.env.NODE_ENV === "development" || state.isOpen) return;

      const now = Date.now();
      if (now < state.cooldownUntil) return;

      setState((prev) => ({
        ...prev,
        isOpen: true,
        popupType: type,
        isReAuthCompleted: false,
      }));

      return new Promise((resolve) => setReAuthResolver(() => resolve));
    },
    [state.cooldownUntil],
  );

  const handleClose = useCallback(() => {
    setState((prev) => ({
      ...prev,
      isOpen: false,
      cooldownUntil: Date.now() + 5000,
    }));
  }, []);

  const onReAuthSuccess = useCallback(() => {
    if (reAuthResolver) {
      reAuthResolver();
      setReAuthResolver(null);
    }

    setState((prev) => ({
      ...prev,
      isReAuthCompleted: true,
      isOpen: false,
      cooldownUntil: Date.now() + 5000,
    }));
  }, [reAuthResolver]);

  const waitForReAuth = useCallback(() => {
    return reAuthResolver
      ? new Promise((resolve) => {
          setReAuthResolver((prev) => () => {
            prev?.();
            resolve();
          });
        })
      : Promise.resolve();
  }, [reAuthResolver]);

  const reAuthenticate = useCallback(async () => {
    await handleOpen();
    await waitForReAuth();
  }, [handleOpen, waitForReAuth]);

  return (
    <PopupContext.Provider
      value={{
        open: state.isOpen,
        handleOpen,
        handleClose,
        waitForReAuth,
        onReAuthSuccess,
        popupType: state.popupType,
        isReAuthCompleted: state.isReAuthCompleted,
        setIsReAuthCompleted: (value) =>
          setState((prev) => ({ ...prev, isReAuthCompleted: value })),
        reAuthenticate,
      }}
    >
      {children}
      <LoginPopup
        open={state.isOpen}
        popupType={state.popupType}
        onReAuthSuccess={onReAuthSuccess}
        handleClose={handleClose}
      />
    </PopupContext.Provider>
  );
}

export const useLoginPopup = () => useContext(PopupContext);
