import React from "react";
import { Box } from "@mui/material";
import { DownloadButton } from "../../../components/ui/buttons/download-button";
import { FullScreenButton } from "../../../components/ui/buttons/fullscreen-button";
import { RefreshButton } from "../../../components/ui/buttons/refresh-button";
import { SearchInput } from "../../../components/ui/inputs/search-input";
import GridDownloadExcelPdfButtons from "../../../components/GridDownloadExcelPdfButtons/GridDownloadExcelPdfButtons";

interface PayrollGridToolbarProps {
  onRefresh: () => void;
  onExcelExport: () => void;
  onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSearchClear?: () => void;
  onFullScreen: () => void;
}

export const PayrollGridToolbar: React.FC<PayrollGridToolbarProps> = ({
  onRefresh,
  onExcelExport,
  onSearchChange,
  onSearchClear,
  onFullScreen,
}) => {
  return (
    <Box
      display={"flex"}
      flexDirection={"row"}
      alignItems={"center"}
      justifyContent={"space-between"}
      flexWrap={"wrap"}
      gap={3}
      sx={{ marginBottom: 3, marginTop: 1 }}
    >
      <Box
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        gap={3}
        flexWrap={"wrap"}
      >
        <RefreshButton title={"Refresh"} onClick={onRefresh} />
        <GridDownloadExcelPdfButtons
          downloadExcel={onExcelExport}
          downloadPDF={() => {}}
          displayDownloadPDF={false}
        />
      </Box>
      <Box
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        gap={3}
        flexWrap={"wrap"}
      >
        <SearchInput onChange={onSearchChange} onClear={onSearchClear} />
        <FullScreenButton onClick={onFullScreen} />
      </Box>
    </Box>
  );
};
