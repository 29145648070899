import React from "react";
import {
  Box,
  Typography,
  TextField,
  Autocomplete,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { Contact } from "../../accounting/api/list-of-contacts";
import { ActivityType, Site } from "../types";
import { TimesheetErrors } from "../../../pages/TimesheetDetailed/TimesheetDetailed";
import { VirtualizedAutocomplete } from "../../../components/ui/autocomlete/virtualized-autocomplete";
import { BasicTimesheetErrors } from "../../../pages/Timesheet/Timesheet";

interface TimesheetActivityDetailsProps {
  client: Contact | null;
  onClientChange: (client: Contact | null) => void;
  user: any;
  onUserChange: (user: any) => void;
  department: any;
  onDepartmentChange: (department: any) => void;
  activityType: ActivityType | null;
  onActivityTypeChange: (activityType: ActivityType | null) => void;
  description: string;
  onDescriptionChange: (description: string) => void;
  task: any;
  onTaskChange: (task: any) => void;
  contacts: Contact[];
  activityTypes: ActivityType[];
  users: any[];
  departments: Site[];
  tasks: any[];
  errors: BasicTimesheetErrors;
}

const TimesheetActivityDetails: React.FC<TimesheetActivityDetailsProps> = ({
  client,
  onClientChange,
  user,
  onUserChange,
  department,
  onDepartmentChange,
  activityType,
  onActivityTypeChange,
  description,
  onDescriptionChange,
  task,
  onTaskChange,
  contacts,
  activityTypes,
  users,
  departments,
  tasks,
  errors,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box>
      <Typography variant="subtitle1" mb={2}>
        Activity Details
      </Typography>

      <Box
        display="flex"
        flexDirection="column"
        gap={2}
        sx={{
          "& > *": {
            width: "100%",
          },
        }}
      >
        <Box
          display="flex"
          gap={2}
          flexDirection={isMobile ? "column" : "row"}
          sx={{
            "& > *": {
              width: isMobile ? "100%" : "auto",
              flex: 1,
            },
          }}
        >
          <VirtualizedAutocomplete
            value={client}
            onChange={(_, newValue) => onClientChange(newValue)}
            options={contacts}
            getOptionLabel={(option: Contact) =>
              `${option.cntCode} - ${option.cntName}`
            }
            label="Client *"
            width="100%"
            error={!!errors.client}
            helperText={errors.client}
            otherAutoCompleteProps={{
              size: "small",
            }}
          />

          <Autocomplete
            value={user}
            onChange={(_, newValue) => onUserChange(newValue)}
            options={users}
            getOptionLabel={(option: any) => option?.usrName || ""}
            renderInput={(params) => (
              <TextField
                {...params}
                label="User *"
                size="small"
                error={!!errors.user}
                helperText={errors.user}
              />
            )}
          />

          <Autocomplete
            value={department}
            onChange={(_, newValue) => onDepartmentChange(newValue)}
            options={departments}
            getOptionLabel={(option: Site) =>
              `${option.dpsCode} - ${option.dpsName}`
            }
            renderInput={(params) => (
              <TextField {...params} label="Department Site" size="small" />
            )}
          />
        </Box>

        <Box
          display="flex"
          gap={2}
          flexDirection={isMobile ? "column" : "row"}
          sx={{
            "& > *": {
              width: isMobile ? "100%" : "auto",
              flex: 1,
            },
          }}
        >
          <Autocomplete
            value={activityType}
            onChange={(_, newValue) => onActivityTypeChange(newValue)}
            options={activityTypes}
            getOptionLabel={(option: ActivityType) => option?.avtName || ""}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Activity Type *"
                size="small"
                error={!!errors.activityType}
                helperText={errors.activityType}
              />
            )}
          />

          <TextField
            label="Description *"
            size="small"
            multiline
            rows={1}
            value={description}
            onChange={(e) => onDescriptionChange(e.target.value)}
            error={!!errors.description}
            helperText={errors.description}
          />

          <Autocomplete
            value={task}
            onChange={(_, newValue) => onTaskChange(newValue)}
            options={tasks}
            getOptionLabel={(option: any) => option.name}
            renderInput={(params) => (
              <TextField {...params} label="Task" size="small" />
            )}
            disabled
          />
        </Box>
      </Box>
    </Box>
  );
};

export default TimesheetActivityDetails;
