import React from "react";
import DataGrid, { Column } from "devextreme-react/cjs/data-grid";

import { IssueListDataRow, PayrollDashboardData } from "../types";
import { getAlignment } from "../../../helpers/table";

interface PayrollDashboardIssueListGridProps {
  data: IssueListDataRow[];
  columns: PayrollDashboardData["IssuesList"]["ColumnsList"];
  tooltips?: PayrollDashboardData["IssuesList"]["TooltipsList"];
  actionsList?: PayrollDashboardData["IssuesList"]["ActionsList"];
  onRowDoubleClick?: (detsCode: string, params?: Record<string, any>) => void;
}

export const PayrollDashboardIssueListGrid: React.FC<
  PayrollDashboardIssueListGridProps
> = ({ data, columns, tooltips, actionsList, onRowDoubleClick }) => {
  const handleRowDblClick = (e: any) => {
    if (onRowDoubleClick && e.data) {
      const detsCode = e.data.DetsCode;
      // Pass any additional parameters from the row data
      const params = { ...e.data };
      delete params.DetsCode;
      onRowDoubleClick(detsCode, params);
    }
  };

  return (
    <DataGrid
      dataSource={data}
      showBorders
      allowColumnResizing
      columnAutoWidth
      rowAlternationEnabled
      onRowDblClick={handleRowDblClick}
    >
      {columns.map((col) => (
        <Column
          key={col.RpcID}
          dataField={col.RpcDataSource}
          caption={col.RpcHeader}
          dataType={col.RpcDataType_rpdID === 5 ? "date" : undefined}
          format={col.RpcDataType_rpdID === 5 ? "dd/MM/yyyy" : undefined}
          alignment={getAlignment(col.RpcAlign)}
          minWidth={col.RpcMinWidth}
          width={col.RpcMaxWidth}
          allowSorting={col.RpcSort === "Y"}
          allowFiltering={col.RpcFilter === "Y"}
          allowResizing={col.RpcAllowResizing === "Y"}
          hidingPriority={col.HidingPriority}
          visible={col.RpcVisible === "Y"}
        />
      ))}
    </DataGrid>
  );
};
