import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import GeneralJournalHeader from "./GeneralJournalHeader";
import GeneralJournalGrid from "./GeneralJournalGrid";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getHeaders } from "../../utils/api";
import BackdropLoading from "../BackdropLoading/BackdropLoading";
import envConfig from "../../config";
import PageTitle from "../PageTitle/PageTitle";
import useApi from "../../hooks/useApi";
import { enqueueSnackbar } from "notistack";
import { format } from "date-fns";
import { formatDateToISO } from "../../utils/format-date";
import { formatDateToYYYYMMDD } from "../BankJournal/BankJournalPage";

const GeneralJournalPage = () => {
  const { dbId, lang, journalId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const headers = getHeaders(dbId);
  const { apiCall } = useApi();

  const queryParams = new URLSearchParams(location.search);

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [pageKey, setPageKey] = useState(1);
  const [formState, setFormState] = useState({
    jnlDate: null,
    jnlContact_cntID: null,
    jnlMemo: null,
    showPrivatePortion: false,
    showVaryManualTax: false,
  });

  const reloadPage = () => {
    const { jnlDate } = formState;

    if (!journalId) {
      setPageKey((prev) => prev + 1);
      const searchParams = new URLSearchParams(location.search);
      searchParams.set("date", formatDateToYYYYMMDD(dayjs(jnlDate)));
      navigate(`${location.pathname}?${searchParams.toString()}`, {
        replace: true,
      });
    } else {
      navigate(0);
      setPageKey((prev) => prev + 1);
    }
  };

  useEffect(() => {
    const dateParam = queryParams.get("date");
    const fetchData = async () => {
      setLoading(true);
      await apiCall({
        url: `https://${envConfig.apiDev1Exacc}/api/v1/en-au/general-journal/get-journal-data?journalId=${journalId && !isNaN(journalId) ? journalId : 0}&BaseHostURL=${envConfig.mainServiceUrl}`,
        method: "GET",
        ip: "127.0.0.2",
        headers,
        onSuccess: (data) => {
          // Check conditions in JournalDetails
          const showVaryManualTax = data?.JournalDetails?.some(
            (detail) => detail.jnsVaryTaxYN === "Y",
          );
          const showPrivatePortion = data?.JournalDetails?.some(
            (detail) => detail.jnsPrivAmt > 0,
          );

          const footerTextCreated = data?.JournalHeader
            ?.jnlCreatedByUserFullName
            ? `Created by ${data?.JournalHeader?.jnlCreatedByUserFullName} on ${format(new Date(data?.JournalHeader?.jnlCreatedDateTime), "dd MMM yyyy HH:mm")}`
            : "";
          const footerTextUpdated = data?.JournalHeader
            ?.jnlUpdatedByUserFullName
            ? `- Updated by ${data?.JournalHeader?.jnlUpdatedByUserFullName} on ${format(new Date(data?.JournalHeader?.jnlUpdatedDateTime), "dd MMM yyyy HH:mm")}.`
            : ".";

          setData(data);
          setFormState({
            jnlDate: journalId
              ? dayjs(data?.JournalHeader?.jnlDate)
              : dateParam
                ? dayjs(dateParam)
                : dayjs(data?.JournalHeader?.jnlDate),
            jnlContact_cntID: data?.JournalHeader?.jnlContact_cntID || null,
            jnlMemo: data?.JournalHeader?.jnlMemo || "",
            showPrivatePortion: showPrivatePortion,
            showVaryManualTax: showVaryManualTax,
            footerText: footerTextCreated + footerTextUpdated,
          });
          setLoading(false);
        },
        onError: (errorMessage) => {
          console.log(errorMessage);
          enqueueSnackbar(errorMessage, { variant: "error" });
          setLoading(false);
        },
      });
    };

    fetchData();
  }, [journalId, pageKey]);

  return (
    <div key={pageKey}>
      <BackdropLoading open={loading} />
      <PageTitle title="General Journal" />
      <GeneralJournalHeader
        formState={formState}
        setFormState={setFormState}
        listOfContacts={data?.ListOfContacts}
      />
      <GeneralJournalGrid
        loadingData={loading}
        formState={formState}
        headerData={data?.JournalHeader || {}}
        initialData={data?.JournalDetails || []}
        listOfAccounts={data?.ListOfAccounts}
        listOfTaxCodes={data?.ListOfTaxCodes}
        listOfSites={data?.ListOfSites}
        showPrivatePortion={formState?.showPrivatePortion}
        setShowPrivatePortion={(boolean) =>
          setFormState({ ...formState, showPrivatePortion: !!boolean })
        }
        showVaryManualTax={formState?.showVaryManualTax}
        reloadPage={reloadPage}
      />
    </div>
  );
};

export default GeneralJournalPage;
