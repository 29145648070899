import { useContext } from "react";
import ReportDatesContext from "../context/ReportDatesContext";

export const usePageMainData = () => {
  const { pageMainData } = useContext(ReportDatesContext);

  console.log("DEBUGGING pageMainData", pageMainData);

  return pageMainData;
};
