import React from "react";
import { useSnackbar } from "notistack";
import useApi from "../../../hooks/useApi";
import envConfig from "../../../config";
import { GetPayEventSummaryResponse } from "../types";
import { EXAMPLE_GET_PAY_EVENT_SUMMARY_FINALISED_RESPONSE } from "./example-response";

export const usePayEventSummaryFinalised = ({
  id,
  onSuccess,
  onError,
  enable = false,
}: {
  id: string;
  onSuccess?: (data: any) => void;
  onError?: (error: string) => void;
  enable?: boolean;
}) => {
  const { apiCall } = useApi();
  const { enqueueSnackbar } = useSnackbar();

  const [data, setData] = React.useState<null | GetPayEventSummaryResponse>(
    null,
  );
  const [isLoadingPayEventSummary, setIsLoadingPayEventSummary] =
    React.useState(false);
  const [isErrorPayEventSummary, setIsErrorPayEventSummary] =
    React.useState(false);

  const fetchPayEventSummary = React.useCallback(async () => {
    setIsLoadingPayEventSummary(true);

    console.log("[DEBUGGING][FETCH PAY EVENT SUMMARY FINALISED] called");
    if (!enable) {
      setData(null);
      setIsLoadingPayEventSummary(false);
      setIsErrorPayEventSummary(false);
      return;
    }

    if (process.env.NODE_ENV === "development") {
      setData(EXAMPLE_GET_PAY_EVENT_SUMMARY_FINALISED_RESPONSE);
      setIsLoadingPayEventSummary(false);
      setIsErrorPayEventSummary(false);
      return;
    }

    try {
      await apiCall({
        url: `https://${envConfig.apiDev2}/api/en-au/payroll-au/get-pay-event-summary-page-finalised-rs1?BaseHostURL=${envConfig.mainServiceUrl}`,
        method: "POST",
        // @ts-ignore
        body: {
          PevID: id,
        },
        // @ts-ignore
        onSuccess: (data: GetPayEventSummaryResponse) => {
          setData(data);
          if (onSuccess) onSuccess(data);
        },
        // @ts-ignore
        onError: (error: string) => {
          setIsErrorPayEventSummary(true);
          enqueueSnackbar(error, { variant: "error" });
          if (onError) onError(error);
        },
      });
    } catch (error) {
      console.error("fetchPayEventSummary error", error);
      setIsErrorPayEventSummary(true);
      enqueueSnackbar((error as Error).message, { variant: "error" });
      if (onError) onError((error as Error).message);
    } finally {
      setIsLoadingPayEventSummary(false);
    }
  }, [apiCall, enqueueSnackbar, onSuccess, onError, id, enable]);

  React.useEffect(() => {
    fetchPayEventSummary();
  }, [fetchPayEventSummary]);

  return {
    isLoading: isLoadingPayEventSummary,
    isError: isErrorPayEventSummary,
    data,
    refetch: fetchPayEventSummary,
  };
};
