import React from "react";
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  useMediaQuery,
} from "@mui/material";

import { commonThemes } from "../../../../utils/themes/themes";
import { useLedgedStore } from "../store";

export const GeneralLedgerRecordStatus = () => {
  const breakAdvanced = useMediaQuery("(max-width: 847px)");

  const recordStatus = useLedgedStore.use.recordStatus();
  const setRecordStatus = useLedgedStore.use.setRecordStatus();

  return (
    <RadioGroup
      sx={{
        display: "flex",
        flexDirection: breakAdvanced ? "column" : "row",
      }}
    >
      <FormControlLabel
        value="all"
        onChange={() => setRecordStatus("1")}
        control={<Radio checked={recordStatus === "1"} />}
        label="All Items"
        // @ts-ignore
        sx={{ mr: 2  }}
      />
      <FormControlLabel
        value="draft"
        onChange={() => setRecordStatus("2")}
        control={<Radio checked={recordStatus === "2"} />}
        label="Drafts"
        // @ts-ignore
        sx={{ mr: 2  }}
      />
      <FormControlLabel
        value="finalized"
        onChange={() => setRecordStatus("3")}
        control={<Radio checked={recordStatus === "3"} />}
        label="Finalized"
        // @ts-ignore
        sx={{ mr: 2  }}
      />
            <FormControlLabel
        value="voided"
        onChange={() => setRecordStatus("4")}
        control={<Radio checked={recordStatus === "4"} />}
        label="Void Deleted"
        // @ts-ignore
        sx={{ mr: 2  }}
      />
    </RadioGroup>
  );
};
