import React, { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import { getCookie } from "../../utils/api";

const LogoutTimer = () => {
  const [timeRemaining, setTimeRemaining] = useState(null);

  useEffect(() => {
    const updateTimer = () => {
      const dateString = getCookie("apiKeyExpirationUTCDate");
      let expirationDate = null;

      if (dateString) {
        // Ensure the date string is parsed as UTC
        const utcDateString = dateString.endsWith("Z")
          ? dateString
          : `${dateString}Z`;
        expirationDate = new Date(utcDateString);

        if (isNaN(expirationDate.getTime())) {
          // Invalid date format
          expirationDate = null;
        }
      }

      if (expirationDate) {
        const now = new Date();
        const diff = expirationDate.getTime() - now.getTime();

        if (diff > 0) {
          const days = Math.floor(diff / (1000 * 60 * 60 * 24));
          const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
          const minutes = Math.floor((diff / (1000 * 60)) % 60);
          const seconds = Math.floor((diff / 1000) % 60);

          setTimeRemaining({ days, hours, minutes, seconds });
        } else {
          // Time has passed
          setTimeRemaining("expired");
        }
      } else {
        // Date is invalid or null
        setTimeRemaining("invalid");
      }
    };

    updateTimer(); // Initial call to set state immediately
    const timerId = setInterval(updateTimer, 1000); // Update every second

    return () => clearInterval(timerId); // Cleanup on unmount
  }, []);

  if (timeRemaining === "invalid") {
    return (
      <Typography sx={{ mt: -1, mb: 1 }}>
        Session expiration date not available.
      </Typography>
    );
  }

  if (timeRemaining === "expired") {
    return <Typography sx={{ mt: -1, mb: 1 }}>Session expired.</Typography>;
  }

  if (!timeRemaining) {
    return null; // You can return a loading indicator here if you prefer
  }

  const { days, hours, minutes, seconds } = timeRemaining;

  return (
    <Typography variant="body1" sx={{ mt: -1, mb: 1 }}>
      Session expires in: {days}d {hours}h {minutes}m {seconds}s
    </Typography>
  );
};

export default LogoutTimer;
