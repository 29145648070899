import React from "react";
import { ButtonOwnProps } from "@mui/material";
import { IconWrapper } from "./icon-wrapper";

export const IconFileAdd2 = ({
  color,
  paletteType,
}: {
  color: ButtonOwnProps["color"];
  paletteType?: "main" | "light" | "dark" | "contrastText";
}) => {
  return (
    <IconWrapper color={color} paletteType={paletteType}>
      {(strokeColor) => (
        <svg
          width="14"
          height="18"
          viewBox="0 0 14 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.66669 3.16671C2.84306 3.16735 2.41134 3.17782 2.0767 3.34832C1.7631 3.50811 1.50813 3.76308 1.34834 4.07668C1.16669 4.4332 1.16669 4.89991 1.16669 5.83333V13.8333C1.16669 14.7668 1.16669 15.2335 1.34834 15.59C1.50813 15.9036 1.7631 16.1586 2.0767 16.3183C2.43322 16.5 2.89993 16.5 3.83335 16.5H10.1667C11.1001 16.5 11.5668 16.5 11.9233 16.3183C12.2369 16.1586 12.4919 15.9036 12.6517 15.59C12.8334 15.2335 12.8334 14.7668 12.8334 13.8333V5.83333C12.8334 4.89991 12.8334 4.4332 12.6517 4.07668C12.4919 3.76308 12.2369 3.50811 11.9233 3.34832C11.5887 3.17782 11.157 3.16735 10.3334 3.16671M3.66669 3.16671V4.83333H10.3334V3.16671M3.66669 3.16671V2.92259C3.66669 2.54529 3.81657 2.18345 4.08335 1.91667C4.35014 1.64988 4.71198 1.5 5.08928 1.5H8.91076C9.28806 1.5 9.6499 1.64988 9.91669 1.91667C10.1835 2.18345 10.3334 2.54529 10.3334 2.92259V3.16671M7.00002 8.16667V13.1667M4.50002 10.6667H9.50002"
            stroke={strokeColor}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </IconWrapper>
  );
};
