import React from "react";
import { useSnackbar } from "notistack";
import useApi from "../../../hooks/useApi";
import envConfig from "../../../config";

interface StripeAccountCreationConfirmationResponse {
  SuccessYN: "Y" | "N";
  ErrorMessage: string;
}

interface StripeAccountCreationConfirmationProps {
  accountId: string;
  onSuccess?: (response: StripeAccountCreationConfirmationResponse) => void;
  onError?: (error: string) => void;
}

export const useStripeAccountCreationConfirmation = ({
  accountId,
  onSuccess,
  onError,
}: StripeAccountCreationConfirmationProps) => {
  const { apiCall } = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [data, setData] = React.useState<StripeAccountCreationConfirmationResponse | null>(null);

  const confirmAccountCreation = React.useCallback(async () => {
    if (!accountId) {
      enqueueSnackbar("Account ID is required", { variant: "error" });
      return;
    }

    setIsLoading(true);
    setIsError(false);
    setIsSuccess(false);

    if (process.env.NODE_ENV === "development") {
      const mockResponse: StripeAccountCreationConfirmationResponse = {
        SuccessYN: "Y",
        ErrorMessage: ""
      };
      setData(mockResponse);
      setIsSuccess(true);
      setIsLoading(false);
      if (onSuccess) onSuccess(mockResponse);
      return;
    }

    try {
      const searchParams = new URLSearchParams();
      searchParams.append("BaseHostURL", envConfig.mainServiceUrl);
      searchParams.append("accountId", accountId);

      await apiCall({
        url: `https://${envConfig.apiDev1Exacc}/api/v1/en-au/stripe/account-creation-confirmation-async?${searchParams.toString()}`,
        method: "GET",
        // @ts-ignore
        onSuccess: (response: StripeAccountCreationConfirmationResponse) => {
          setData(response);
          if (response.SuccessYN === "Y") {
            setIsSuccess(true);
            enqueueSnackbar("Stripe account creation confirmed successfully", { variant: "success" });
            if (onSuccess) onSuccess(response);
          } else {
            setIsError(true);
            const errorMessage = response.ErrorMessage || "Failed to confirm Stripe account creation";
            enqueueSnackbar(errorMessage, { variant: "error" });
            if (onError) onError(errorMessage);
          }
        },
        // @ts-ignore
        onError: (error: string) => {
          setIsError(true);
          enqueueSnackbar(error, { variant: "error" });
          if (onError) onError(error);
        }
      });
    } catch (error) {
      setIsError(true);
      const errorMessage = (error as Error).message;
      enqueueSnackbar(errorMessage, { variant: "error" });
      if (onError) onError(errorMessage);
    } finally {
      setIsLoading(false);
    }
  }, [apiCall, enqueueSnackbar, accountId]);

  return {
    isLoading,
    isError,
    isSuccess,
    data,
    confirmAccountCreation
  };
};
