import React from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SxProps,
  Theme,
} from "@mui/material";

import { useTheme } from "../../../context/ThemeContext";
import {
  commonThemes,
  darkTheme,
  lightTheme,
} from "../../../utils/themes/themes";

export type PeriodType =
  | "current_month"
  | "previous_month"
  | "current_quarter"
  | "previous_quarter"
  | "current_financialYear"
  | "previous_financialYear"
  | "current_calendarYear"
  | "previous_calendarYear"
  | "custom";

interface DatePredefinedPeriodPickerProps {
  value: PeriodType;
  onChange: (peroidType: string) => void;
  style?: React.CSSProperties | undefined;
  sx?: SxProps<Theme>;
}

export const DatePredefinedPeriodPicker = ({
  value,
  onChange,
  style,
  sx,
}: DatePredefinedPeriodPickerProps) => {
  const { isDarkMode } = useTheme();

  return (
    <FormControl
      variant="outlined"
      size="small"
      style={{ ...style }}
      sx={{ ...sx }}
      // sx={{ minWidth: isScreenBelow1050px ? "260px" : "240px" }}
    >
      <InputLabel
        
      >
        Predefined Period
      </InputLabel>
      <Select
        label="Predefined Period"
        labelId="Predefined_Period"
        value={value}
        onChange={(e) => onChange(e.target.value as PeriodType)}
      >
        <MenuItem sx={{ fontSize: "12px" }} value="current_month">
          Current Month
        </MenuItem>
        <MenuItem sx={{ fontSize: "12px" }} value="current_quarter">
          Current Quarter
        </MenuItem>
        <MenuItem sx={{ fontSize: "12px" }} value="current_financialYear">
          Current Financial Year
        </MenuItem>
        <MenuItem sx={{ fontSize: "12px" }} value="current_calendarYear">
          Current Calendar Year
        </MenuItem>
        <MenuItem sx={{ fontSize: "12px" }} value="previous_month">
          Previous Month
        </MenuItem>
        <MenuItem sx={{ fontSize: "12px" }} value="previous_quarter">
          Previous Quarter
        </MenuItem>
        <MenuItem sx={{ fontSize: "12px" }} value="previous_financialYear">
          Previous Financial Year
        </MenuItem>
        <MenuItem sx={{ fontSize: "12px" }} value="previous_calendarYear">
          Previous Calendar Year
        </MenuItem>
        <MenuItem sx={{ fontSize: "12px" }} value="custom">
          Custom
        </MenuItem>
      </Select>
    </FormControl>
  );
};
