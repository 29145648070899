import React from "react";
import { useSnackbar } from "notistack";
import useApi from "../../../hooks/useApi";
import envConfig from "../../../config";
import { getCookie } from "../../../utils/api";

export interface BusinessActivity {
  Display: string;
  Value: string;
}

export const useBusinessActivities = () => {
  const { apiCall } = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [activities, setActivities] = React.useState<BusinessActivity[]>([]);

  const fetchActivities = React.useCallback(async () => {
    setIsLoading(true);
    setIsError(false);

    if (process.env.NODE_ENV === "development") {
      setActivities([
        {
          Display: "Accommodation (ACCOMMODATION AND FOOD SERVICES)",
          Value: "440",
        },
        {
          Display:
            "Adult, community and other education (EDUCATION AND TRAINING)",
          Value: "821",
        },
        {
          Display:
            "Advertising services (PROFESSIONAL, SCIENTIFIC AND TECHNICAL SERVICES)",
          Value: "694",
        },
      ]);
      setIsLoading(false);
      return;
    }

    try {
      await apiCall({
        url: `https://${envConfig.apiDev1Exacc}/api/v1/en-au/business-details/get-business-main-activities-async`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "olivs-api-real-ip": "31.42.0.186",
          "olivs-api-computer-name": "BTM-WS2019",
          "session-id": getCookie("sessionId"),
          "browser-agent": window.navigator.userAgent,
          "olivs-root-password": "OlivsWillBeBetterThanBTMSoft!",
          'api-key': `OlivsAuth`
        },
        // @ts-ignore
        onSuccess: (response) => {
          setActivities(response?.ListOfBusinessActivities);
        },
        // @ts-ignore
        onError: (error: string) => {
          setIsError(true);
          enqueueSnackbar(error, { variant: "error" });
        },
      });
    } catch (error) {
      console.error("fetchActivities error", error);
      setIsError(true);
      enqueueSnackbar((error as Error).message, { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  }, [apiCall, enqueueSnackbar]);

  React.useEffect(() => {
    fetchActivities();
  }, [fetchActivities]);

  return {
    isLoading,
    isError,
    activities,
    refetch: fetchActivities,
  };
};
