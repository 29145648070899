import React from "react";
import { useSnackbar } from "notistack";
import useApi from "../../../hooks/useApi";
import envConfig from "../../../config";

export interface TimesheetDeleteResponse {
  SuccessYN: "Y" | "N";
  ErrorMessage: string;
  ActivityId: string;
}

export interface UseTimesheetDeleteProps {
  onSuccess?: (data: TimesheetDeleteResponse) => void;
  onError?: (error: string) => void;
}

export const useTimesheetDelete = ({
  onSuccess,
  onError,
}: UseTimesheetDeleteProps = {}) => {
  const { apiCall } = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isError, setIsError] = React.useState(false);

  const deleteTimesheet = React.useCallback(
    async (id: string) => {
      setIsLoading(true);
      setIsError(false);

      try {
        await apiCall({
          url: `https://${envConfig.apiDev1Exacc}/api/v2/en-au/timesheet/delete-async?id=${id}&BaseHostURL=${envConfig.mainServiceUrl}`,
          method: "POST",
          // @ts-ignore
          onSuccess: (response: TimesheetDeleteResponse) => {
            if (onSuccess) onSuccess(response);
          },
          // @ts-ignore
          onError: (error: string) => {
            setIsError(true);
            enqueueSnackbar(error, { variant: "error" });
            if (onError) onError(error);
          },
        });
      } catch (error) {
        setIsError(true);
        const errorMessage = (error as Error).message;
        enqueueSnackbar(errorMessage, { variant: "error" });
        if (onError) onError(errorMessage);
      } finally {
        setIsLoading(false);
      }
    },
    [apiCall],
  );

  return {
    isLoading,
    isError,
    deleteTimesheet,
  };
};
