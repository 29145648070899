import dayjs from 'dayjs';
import { TimeFrame, Period } from './types';
import isoWeek from 'dayjs/plugin/isoWeek';

dayjs.extend(isoWeek);

export const generatePeriodDates = (timeFrame: TimeFrame, period: Period): [dayjs.Dayjs, dayjs.Dayjs] => {
  const now = dayjs();
  const currentMonth = now.month() + 1;
  const fiscalYear = currentMonth > 6 ? now.year() : now.year() - 1;
  
  switch(timeFrame) {
    case 'Week':
      if (period === 'Current') {
        return [now.startOf('week'), now.endOf('week')];
      }
      if (period === 'CurrentToDate') {
        return [now.startOf('week'), now];
      }
      return [
        now.subtract(1, 'week').startOf('week'),
        now.subtract(1, 'week').endOf('week')
      ];
      
    case 'Fortnight':
      if (period === 'Current') {
        return [now.startOf('week'), now.add(13, 'day').endOf('day')];
      }
      if (period === 'CurrentToDate') {
        return [now.startOf('week'), now];
      }
      return [
        now.subtract(2, 'week').startOf('week'),
        now.subtract(1, 'week').endOf('week')
      ];
      
    case 'Month':
      if (period === 'Current') {
        return [now.startOf('month'), now.endOf('month')];
      }
      if (period === 'CurrentToDate') {
        return [now.startOf('month'), now];
      }
      return [
        now.subtract(1, 'month').startOf('month'),
        now.subtract(1, 'month').endOf('month')
      ];
      
    case 'Quarter':
      if (period === 'Current') {
        return [now.startOf('quarter'), now.endOf('quarter')];
      }
      if (period === 'CurrentToDate') {
        return [now.startOf('quarter'), now];
      }
      return [
        now.subtract(1, 'quarter').startOf('quarter'),
        now.subtract(1, 'quarter').endOf('quarter')
      ];
      
    case 'Year':
      if (period === 'Current') {
        return [
          dayjs(`01/07/${fiscalYear}`, 'DD/MM/YYYY'),
          dayjs(`30/06/${fiscalYear + 1}`, 'DD/MM/YYYY')
        ];
      }
      if (period === 'CurrentToDate') {
        return [
          dayjs(`01/07/${fiscalYear}`, 'DD/MM/YYYY'),
          now
        ];
      }
      return [
        dayjs(`01/07/${fiscalYear - 1}`, 'DD/MM/YYYY'),
        dayjs(`30/06/${fiscalYear}`, 'DD/MM/YYYY')
      ];
      
    default:
      return [now, now];
  }
};
