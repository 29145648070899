import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Grid,
  Typography,
  Box,
  Autocomplete,
  CircularProgress,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
} from "@mui/material";
import { useSnackbar } from "notistack";
import useApi from "../../hooks/useApi";
import envConfig from "../../config";
import { useTheme } from "../../context/ThemeContext";
import { commonThemes } from "../../utils/themes/themes";
import { format } from "date-fns";

const EmployeeManagement = ({ selectedUser }) => {
  const { isDarkMode } = useTheme();
  const { apiCall } = useApi();
  const { enqueueSnackbar } = useSnackbar();

  const [selectionLists, setSelectionLists] = useState({});
  const [formData, setFormData] = useState(null);
  const [loadingData, setLoadingData] = useState(false);

  const fetchData = async () => {
    const url = `https://${envConfig.apiDev1Exacc}/api/v1/en-au/employee/get-employee-data-async?localUserId=${selectedUser.usrID}`;
    try {
      await apiCall({
        url: url,
        method: "GET",
        onSuccess: (data) => {
          const {
            LocalUser,
            UserAddress,
            NextOfKinPerson,
            NextOfKinPersonAddress,
            Employee,
            SelectionLists,
          } = data.EmployeeData;

          // Extract only the required fields for the payload
          const limitedLocalUser = {
            usrID: LocalUser.usrID || 0,
            usrFirstName: LocalUser.usrFirstName || "",
            usrLastName: LocalUser.usrLastName || "",
            usrFullName: LocalUser.usrFullName || "",
            usrEmail: LocalUser.usrEmail || "",
            usrPrivateEmail: LocalUser.usrPrivateEmail || "",
            usrCode: LocalUser.usrCode || "",
            usrNickName: LocalUser.usrNickName || "",
            usrPhone: LocalUser.usrPhone || "",
            usrGender: LocalUser.usrGender || "",
            usrHireFee: LocalUser.usrHireFee || 0,
            usrCountry: LocalUser.usrCountry || "",
            usrFooter: LocalUser.usrFooter || "",
            usrSMTPUserName: LocalUser.usrSMTPUserName || "",
            usrSMTPSenderEmail: LocalUser.usrSMTPSenderEmail || "",
            usrSMTPHost: LocalUser.usrSMTPHost || "",
            usrSMTPPort: LocalUser.usrSMTPPort || 0,
            usrSMTPSSLEnabledYN: LocalUser.usrSMTPSSLEnabledYN || "N",
            usrBCC: LocalUser.usrBCC || "",
            usrCC: LocalUser.usrCC || "",
            usrPIN: LocalUser.usrPIN || "",
            usrAvgCost: LocalUser.usrAvgCost || 0,
            usrVerificationLevel: LocalUser.usrVerificationLevel || 0,
            usrSupervisor_usrID: LocalUser.usrSupervisor_usrID || 0,
            usrStatus: LocalUser.usrStatus || "",
            usrSite_dpsID: LocalUser.usrSite_dpsID || 0,
            usrLang: LocalUser.usrLang || "",
            usrPrefix: LocalUser.usrPrefix || "",
            usrDOB: LocalUser.usrDOB || "",
            usrAvailOnPubHolYNAsk: LocalUser.usrAvailOnPubHolYNAsk || "N",
            usrOtherNames: LocalUser.usrOtherNames || "",
            usrPrevFirstName: LocalUser.usrPrevFirstName || "",
            usrPrevLastName: LocalUser.usrPrevLastName || "",
            usrPrevOtherNames: LocalUser.usrPrevOtherNames || "",
            usrPhHome: LocalUser.usrPhHome || "",
            usrVisaExpDate: LocalUser.usrVisaExpDate || "",
            usrMy01: LocalUser.usrMy01 || "",
            usrMy02: LocalUser.usrMy02 || "",
            usrMy03: LocalUser.usrMy03 || "",
            usrMy04: LocalUser.usrMy04 || "",
            usrMy05: LocalUser.usrMy05 || "",
            usrMy06: LocalUser.usrMy06 || "",
            usrWorkRights_ewrID: LocalUser.usrWorkRights_ewrID || 0,
          };

          const limitedUserAddress = {
            adrStreet1: UserAddress.adrStreet1 || "",
            adrStreet2: UserAddress.adrStreet2 || "",
            adrTown: UserAddress.adrTown || "",
            adrRegion: UserAddress.adrRegion || "",
            adrPostCode: UserAddress.adrPostCode || "",
            adrCountry: UserAddress.adrCountry || "",
            adrAddressee: UserAddress.adrAddressee || "",
            adrType_Enum5: UserAddress.adrType_Enum5 || 0,
          };

          const limitedNextOfKinPerson = {
            pplFirstName: NextOfKinPerson.pplFirstName || "",
            pplLastName: NextOfKinPerson.pplLastName || "",
            pplOtherNames: NextOfKinPerson.pplOtherNames || "",
            pplPrefix: NextOfKinPerson.pplPrefix || "",
            pplGender: NextOfKinPerson.pplGender || "",
            pplDOB: NextOfKinPerson.pplDOB || "",
            pplWorkEmail: NextOfKinPerson.pplWorkEmail || "",
            pplWorkEmailUse_Enum9: NextOfKinPerson.pplWorkEmailUse_Enum9 || 0,
            pplPrivEmail: NextOfKinPerson.pplPrivEmail || "",
            pplWorkPhone: NextOfKinPerson.pplWorkPhone || "",
            pplPrivPhone: NextOfKinPerson.pplPrivPhone || "",
            pplPosition_Enum10: NextOfKinPerson.pplPosition_Enum10 || 0,
            pplSalutation: NextOfKinPerson.pplSalutation || "",
          };

          const limitedNextOfKinPersonAddress = {
            adrStreet1: NextOfKinPersonAddress.adrStreet1 || "",
            adrStreet2: NextOfKinPersonAddress.adrStreet2 || "",
            adrTown: NextOfKinPersonAddress.adrTown || "",
            adrRegion: NextOfKinPersonAddress.adrRegion || "",
            adrPostCode: NextOfKinPersonAddress.adrPostCode || "",
            adrCountry: NextOfKinPersonAddress.adrCountry || "",
            adrAddressee: NextOfKinPersonAddress.adrAddressee || "",
            adrType_Enum5: NextOfKinPersonAddress.adrType_Enum5 || 0,
          };

          const limitedEmployee = {
            empStatus: Employee.empStatus || "",
            empRate: Employee.empRate || 0,
            empDefHours: Employee.empDefHours || 0,
            empRatePeriod: Employee.empRatePeriod || "",
            empEmploymentType: Employee.empEmploymentType || "",
            empUseTimesheet: Employee.empUseTimesheet || "N",
            empMaxHrs: Employee.empMaxHrs || 0,
            empMaxHrsPeriod: Employee.empMaxHrsPeriod || "",
            empMainPayItem_ptiID: Employee.empMainPayItem_ptiID || 0,
            empSuper: Employee.empSuper || "",
            empPayCycle_prcID: Employee.empPayCycle_prcID || 0,
            empIsDirectCost: Employee.empIsDirectCost || "N",
            empWHEarnings: Employee.empWHEarnings || 0,
            empLeaveMinBal: Employee.empLeaveMinBal || 0,
            empStartDate: Employee.empStartDate || "",
            empFinishDate: Employee.empFinishDate || "",
            empTFN: Employee.empTFN || "",
            empOccupation_pocID: Employee.empOccupation_pocID || 0,
            empUseAward: Employee.empUseAward || "N",
            empAddLoading: Employee.empAddLoading || "N",
            empAccountName: Employee.empAccountName || "",
            empBankCode: Employee.empBankCode || "",
            empAccountNo: Employee.empAccountNo || "",
            empSF_psfID: Employee.empSF_psfID || 0,
            empSFMembNo: Employee.empSFMembNo || "",
            empOvertime: Employee.empOvertime || "",
            empSavAccName: Employee.empSavAccName || "",
            empSavBankCode: Employee.empSavBankCode || "",
            empSavAccNo: Employee.empSavAccNo || "",
            empSavAmt: Employee.empSavAmt || 0,
            empLongSrvNo: Employee.empLongSrvNo || "",
            empCessationCode: Employee.empCessationCode || "",
            empHelpDebt: Employee.empHelpDebt || "N",
            empTaxScale: Employee.empTaxScale || 0,
            empTaxVariation: Employee.empTaxVariation || 0,
            empTaxTreatmentCode: Employee.empTaxTreatmentCode || "",
            empIncomeStreamCode: Employee.empIncomeStreamCode || "",
            empTaxCountry: Employee.empTaxCountry || "",
            empTaxOffset: Employee.empTaxOffset || 0,
          };

          // Set form data with only the required fields
          setFormData({
            LocalUser: limitedLocalUser,
            UserAddress: limitedUserAddress,
            NextOfKinPerson: limitedNextOfKinPerson,
            NextOfKinPersonAddress: limitedNextOfKinPersonAddress,
            Employee: limitedEmployee,
          });
          setSelectionLists(SelectionLists);
        },
        onError: (error) => {
          enqueueSnackbar(error || "Failed to fetch employee data", {
            variant: "error",
          });
        },
      });
    } catch (error) {
      enqueueSnackbar(error || "An unexpected error occurred", {
        variant: "error",
      });
    } finally {
      setLoadingData(false);
    }
  };

  useEffect(() => {
    if (!selectedUser?.usrID) {
      return;
    }
    setLoadingData(true);
    fetchData();
  }, [selectedUser]);

  const handleInputChange = (section, field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [section]: {
        ...prevData[section],
        [field]: value,
      },
    }));
  };

  const handleSubmit = async () => {
    const url = `https://${envConfig.apiDev1Exacc}/api/v1/en-au/employee/save-employee-data-async`;

    // Prepare the payload with only the required fields
    const payload = {
      LocalUser: formData.LocalUser,
      UserAddress: formData.UserAddress,
      NextOfKinPerson: formData.NextOfKinPerson,
      NextOfKinPersonAddress: formData.NextOfKinPersonAddress,
      Employee: formData.Employee,
    };

    try {
      await apiCall({
        url: url,
        method: "POST",
        body: payload,
        onSuccess: () => {
          enqueueSnackbar("Employee data updated successfully", {
            variant: "success",
          });
        },
        onError: (error) => {
          enqueueSnackbar(error || "Failed to update employee data", {
            variant: "error",
          });
        },
      });
    } catch (error) {
      enqueueSnackbar("An unexpected error occurred", { variant: "error" });
    }
  };

  const footerTextCreated =
    formData?.LocalUser?.usrCreatedDateTime &&
    formData?.LocalUser?.usrCreatedByFullName
      ? `Created by ${formData.LocalUser.usrCreatedByFullName} on ${format(new Date(formData.LocalUser.usrCreatedDateTime), "dd MMM yyyy HH:mm")}`
      : "";
  const footerTextUpdated =
    formData?.LocalUser?.usrUpdatedDateTime &&
    formData?.LocalUser?.usrUpdatedByFullName
      ? `- Updated by ${formData?.LocalUser?.usrUpdatedByFullName} on ${format(new Date(formData?.LocalUser?.usrUpdatedDateTime), "dd MMM yyyy HH:mm")}.`
      : ".";
  const footerText = footerTextCreated + footerTextUpdated;

  return (
    <Box p={3}>
      {/* Show loading indicator while fetching data */}
      {loadingData && (
        <Box display="flex" justifyContent="center" my={2}>
          <CircularProgress />
        </Box>
      )}

      {/* Display form only if data is loaded */}
      {formData && !loadingData && (
        <Box mt={3}>
          <Grid container spacing={3}>
            {/* User Address Section */}

            {/*  */}

            {/* Local User Section */}
            <Grid item xs={12} md={6} lg={3}>
              <Typography variant="h6">Personal Information</Typography>
              {/* Include all fields from the POST payload */}
              <TextField
                fullWidth
                label="User ID"
                value={formData.LocalUser.usrID}
                onChange={(e) =>
                  handleInputChange("LocalUser", "usrID", e.target.value)
                }
                margin="normal"
                disabled
              />
              <TextField
                fullWidth
                label="First Name"
                value={formData.LocalUser.usrFirstName || ""}
                onChange={(e) =>
                  handleInputChange("LocalUser", "usrFirstName", e.target.value)
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Last Name"
                value={formData.LocalUser.usrLastName || ""}
                onChange={(e) =>
                  handleInputChange("LocalUser", "usrLastName", e.target.value)
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Full Name"
                value={formData.LocalUser.usrFullName || ""}
                onChange={(e) =>
                  handleInputChange("LocalUser", "usrFullName", e.target.value)
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Email"
                value={formData.LocalUser.usrEmail || ""}
                onChange={(e) =>
                  handleInputChange("LocalUser", "usrEmail", e.target.value)
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Private Email"
                value={formData.LocalUser.usrPrivateEmail || ""}
                onChange={(e) =>
                  handleInputChange(
                    "LocalUser",
                    "usrPrivateEmail",
                    e.target.value,
                  )
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Code"
                value={formData.LocalUser.usrCode || ""}
                onChange={(e) =>
                  handleInputChange("LocalUser", "usrCode", e.target.value)
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Nickname"
                value={formData.LocalUser.usrNickName || ""}
                onChange={(e) =>
                  handleInputChange("LocalUser", "usrNickName", e.target.value)
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Phone"
                value={formData.LocalUser.usrPhone || ""}
                onChange={(e) =>
                  handleInputChange("LocalUser", "usrPhone", e.target.value)
                }
                margin="normal"
              />
              <Autocomplete
                fullWidth
                options={
                  selectionLists?.DictionaryOfDisplayValueString
                    ?.ListOfGenders || []
                }
                getOptionLabel={(option) => option.Display || ""}
                value={
                  selectionLists?.DictionaryOfDisplayValueString?.ListOfGenders?.find(
                    (gender) => gender.Value === formData.LocalUser.usrGender,
                  ) || null
                }
                onChange={(event, newValue) => {
                  handleInputChange(
                    "LocalUser",
                    "usrGender",
                    newValue ? newValue.Value : "",
                  );
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Gender" margin="normal" />
                )}
              />
              <TextField
                fullWidth
                label="Hire Fee"
                type="number"
                value={formData.LocalUser.usrHireFee || ""}
                onChange={(e) =>
                  handleInputChange("LocalUser", "usrHireFee", e.target.value)
                }
                margin="normal"
              />
              <Autocomplete
                fullWidth
                options={
                  selectionLists?.DictionaryOfDisplayValueString
                    ?.ListOfCountries || []
                }
                getOptionLabel={(option) => option.Display || ""}
                value={
                  selectionLists?.DictionaryOfDisplayValueString?.ListOfCountries?.find(
                    (country) =>
                      country.Value === formData.LocalUser.usrCountry,
                  ) || null
                }
                onChange={(event, newValue) => {
                  handleInputChange(
                    "LocalUser",
                    "usrCountry",
                    newValue ? newValue.Value : "",
                  );
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Country" margin="normal" />
                )}
              />
              <TextField
                fullWidth
                label="Footer"
                value={formData.LocalUser.usrFooter || ""}
                onChange={(e) =>
                  handleInputChange("LocalUser", "usrFooter", e.target.value)
                }
                margin="normal"
                multiline
                rows={4}
              />
              <TextField
                fullWidth
                label="SMTP User Name"
                value={formData.LocalUser.usrSMTPUserName || ""}
                onChange={(e) =>
                  handleInputChange(
                    "LocalUser",
                    "usrSMTPUserName",
                    e.target.value,
                  )
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="SMTP Sender Email"
                value={formData.LocalUser.usrSMTPSenderEmail || ""}
                onChange={(e) =>
                  handleInputChange(
                    "LocalUser",
                    "usrSMTPSenderEmail",
                    e.target.value,
                  )
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="SMTP Host"
                value={formData.LocalUser.usrSMTPHost || ""}
                onChange={(e) =>
                  handleInputChange("LocalUser", "usrSMTPHost", e.target.value)
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="SMTP Port"
                type="number"
                value={formData.LocalUser.usrSMTPPort || ""}
                onChange={(e) =>
                  handleInputChange("LocalUser", "usrSMTPPort", e.target.value)
                }
                margin="normal"
              />
              {/* Radio Button for SMTP SSL Enabled */}
              <FormLabel component="legend" style={{ marginTop: "16px" }}>
                SMTP SSL Enabled
              </FormLabel>
              <RadioGroup
                row
                name="usrSMTPSSLEnabledYN"
                value={formData.LocalUser.usrSMTPSSLEnabledYN || "N"}
                onChange={(e) =>
                  handleInputChange(
                    "LocalUser",
                    "usrSMTPSSLEnabledYN",
                    e.target.value,
                  )
                }
              >
                <FormControlLabel value="Y" control={<Radio />} label="Yes" />
                <FormControlLabel value="N" control={<Radio />} label="No" />
              </RadioGroup>
              <TextField
                fullWidth
                label="BCC"
                value={formData.LocalUser.usrBCC || ""}
                onChange={(e) =>
                  handleInputChange("LocalUser", "usrBCC", e.target.value)
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="CC"
                value={formData.LocalUser.usrCC || ""}
                onChange={(e) =>
                  handleInputChange("LocalUser", "usrCC", e.target.value)
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="PIN"
                value={formData.LocalUser.usrPIN || ""}
                onChange={(e) =>
                  handleInputChange("LocalUser", "usrPIN", e.target.value)
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Average Cost"
                type="number"
                value={formData.LocalUser.usrAvgCost || ""}
                onChange={(e) =>
                  handleInputChange("LocalUser", "usrAvgCost", e.target.value)
                }
                margin="normal"
                sx={{
                  "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
                    {
                      WebkitAppearance: "none",
                      margin: 0,
                    },

                  "input[type=number]": {
                    MozAppearance: "textfield",
                  },
                }}
              />
              <TextField
                fullWidth
                label="Verification Level"
                type="number"
                value={formData.LocalUser.usrVerificationLevel || ""}
                onChange={(e) =>
                  handleInputChange(
                    "LocalUser",
                    "usrVerificationLevel",
                    e.target.value,
                  )
                }
                margin="normal"
              />
              <Autocomplete
                fullWidth
                options={
                  selectionLists?.DictionaryOfDisplayValueInteger
                    ?.ListOfUsers || []
                }
                getOptionLabel={(option) => option.Display || ""}
                value={
                  selectionLists?.DictionaryOfDisplayValueInteger?.ListOfUsers?.find(
                    (user) =>
                      user.Value === formData.LocalUser.usrSupervisor_usrID,
                  ) || null
                }
                onChange={(event, newValue) => {
                  handleInputChange(
                    "LocalUser",
                    "usrSupervisor_usrID",
                    newValue ? newValue.Value : 0,
                  );
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Supervisor" margin="normal" />
                )}
              />
              <FormLabel component="legend" style={{ marginTop: "16px" }}>
                Status
              </FormLabel>
              <RadioGroup
                row
                name="usrStatus"
                value={formData.LocalUser.usrStatus || "A"}
                onChange={(e) =>
                  handleInputChange("LocalUser", "usrStatus", e.target.value)
                }
              >
                <FormControlLabel
                  value="A"
                  control={<Radio />}
                  label="Active"
                />
                <FormControlLabel
                  value="I"
                  control={<Radio />}
                  label="Inactive"
                />
              </RadioGroup>
              <Autocomplete
                fullWidth
                options={
                  selectionLists?.DictionaryOfDisplayValueInteger
                    ?.ListOfSites || []
                }
                getOptionLabel={(option) => option.Display || ""}
                value={
                  selectionLists?.DictionaryOfDisplayValueInteger?.ListOfSites?.find(
                    (site) => site.Value === formData.LocalUser.usrSite_dpsID,
                  ) || null
                }
                onChange={(event, newValue) => {
                  handleInputChange(
                    "LocalUser",
                    "usrSite_dpsID",
                    newValue ? newValue.Value : 0,
                  );
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Site" margin="normal" />
                )}
              />
              <Autocomplete
                fullWidth
                options={
                  selectionLists?.DictionaryOfDisplayValueString
                    ?.ListOfLanguages || []
                }
                getOptionLabel={(option) => option.Display || ""}
                value={
                  selectionLists?.DictionaryOfDisplayValueString?.ListOfLanguages?.find(
                    (lang) => lang.Value === formData.LocalUser.usrLang,
                  ) || null
                }
                onChange={(event, newValue) => {
                  handleInputChange(
                    "LocalUser",
                    "usrLang",
                    newValue ? newValue.Value : "",
                  );
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Language" margin="normal" />
                )}
              />
              <TextField
                fullWidth
                label="Prefix"
                value={formData.LocalUser.usrPrefix || ""}
                onChange={(e) =>
                  handleInputChange("LocalUser", "usrPrefix", e.target.value)
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Date of Birth"
                type="date"
                value={
                  formData.LocalUser.usrDOB
                    ? formData.LocalUser.usrDOB.split("T")[0]
                    : ""
                }
                onChange={(e) =>
                  handleInputChange("LocalUser", "usrDOB", e.target.value)
                }
                margin="normal"
                InputLabelProps={{ shrink: true }}
              />
              {/* Radio Button for Available on Public Holiday */}
              <FormLabel component="legend" style={{ marginTop: "16px" }}>
                Available on Public Holiday
              </FormLabel>
              <RadioGroup
                row
                name="usrAvailOnPubHolYNAsk"
                value={formData.LocalUser.usrAvailOnPubHolYNAsk || "N"}
                onChange={(e) =>
                  handleInputChange(
                    "LocalUser",
                    "usrAvailOnPubHolYNAsk",
                    e.target.value,
                  )
                }
              >
                <FormControlLabel value="Y" control={<Radio />} label="Yes" />
                <FormControlLabel value="N" control={<Radio />} label="No" />
              </RadioGroup>
              <TextField
                fullWidth
                label="Other Names"
                value={formData.LocalUser.usrOtherNames || ""}
                onChange={(e) =>
                  handleInputChange(
                    "LocalUser",
                    "usrOtherNames",
                    e.target.value,
                  )
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Previous First Name"
                value={formData.LocalUser.usrPrevFirstName || ""}
                onChange={(e) =>
                  handleInputChange(
                    "LocalUser",
                    "usrPrevFirstName",
                    e.target.value,
                  )
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Previous Last Name"
                value={formData.LocalUser.usrPrevLastName || ""}
                onChange={(e) =>
                  handleInputChange(
                    "LocalUser",
                    "usrPrevLastName",
                    e.target.value,
                  )
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Previous Other Names"
                value={formData.LocalUser.usrPrevOtherNames || ""}
                onChange={(e) =>
                  handleInputChange(
                    "LocalUser",
                    "usrPrevOtherNames",
                    e.target.value,
                  )
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Home Phone"
                value={formData.LocalUser.usrPhHome || ""}
                onChange={(e) =>
                  handleInputChange("LocalUser", "usrPhHome", e.target.value)
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Visa Expiry Date"
                type="date"
                value={
                  formData.LocalUser.usrVisaExpDate
                    ? formData.LocalUser.usrVisaExpDate.split("T")[0]
                    : ""
                }
                onChange={(e) =>
                  handleInputChange(
                    "LocalUser",
                    "usrVisaExpDate",
                    e.target.value,
                  )
                }
                margin="normal"
                InputLabelProps={{ shrink: true }}
              />
              {/* Custom Fields usrMy01 - usrMy06 */}
              <TextField
                fullWidth
                label="Custom Field 1"
                value={formData.LocalUser.usrMy01 || ""}
                onChange={(e) =>
                  handleInputChange("LocalUser", "usrMy01", e.target.value)
                }
                margin="normal"
              />
              {/* Repeat for usrMy02 to usrMy06 */}
              <TextField
                fullWidth
                label="Custom Field 2"
                value={formData.LocalUser.usrMy02 || ""}
                onChange={(e) =>
                  handleInputChange("LocalUser", "usrMy02", e.target.value)
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Custom Field 3"
                value={formData.LocalUser.usrMy03 || ""}
                onChange={(e) =>
                  handleInputChange("LocalUser", "usrMy03", e.target.value)
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Custom Field 4"
                value={formData.LocalUser.usrMy04 || ""}
                onChange={(e) =>
                  handleInputChange("LocalUser", "usrMy04", e.target.value)
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Custom Field 5"
                value={formData.LocalUser.usrMy05 || ""}
                onChange={(e) =>
                  handleInputChange("LocalUser", "usrMy05", e.target.value)
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Custom Field 6"
                value={formData.LocalUser.usrMy06 || ""}
                onChange={(e) =>
                  handleInputChange("LocalUser", "usrMy06", e.target.value)
                }
                margin="normal"
              />
              <Autocomplete
                fullWidth
                options={
                  selectionLists?.DictionaryOfDisplayValueInteger
                    ?.ListOfWorkingRights || []
                }
                getOptionLabel={(option) => option.Display || ""}
                value={
                  selectionLists?.DictionaryOfDisplayValueInteger?.ListOfWorkingRights?.find(
                    (item) =>
                      item.Value === formData.LocalUser.usrWorkRights_ewrID,
                  ) || null
                }
                onChange={(event, newValue) => {
                  handleInputChange(
                    "LocalUser",
                    "usrWorkRights_ewrID",
                    newValue ? newValue.Value : 0,
                  );
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Work Rights" margin="normal" />
                )}
              />

              {/*  */}
              <Typography variant="h6">Address Information</Typography>
              <TextField
                fullWidth
                label="Street 1"
                value={formData.UserAddress.adrStreet1 || ""}
                onChange={(e) =>
                  handleInputChange("UserAddress", "adrStreet1", e.target.value)
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Street 2"
                value={formData.UserAddress.adrStreet2 || ""}
                onChange={(e) =>
                  handleInputChange("UserAddress", "adrStreet2", e.target.value)
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Town"
                value={formData.UserAddress.adrTown || ""}
                onChange={(e) =>
                  handleInputChange("UserAddress", "adrTown", e.target.value)
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Region"
                value={formData.UserAddress.adrRegion || ""}
                onChange={(e) =>
                  handleInputChange("UserAddress", "adrRegion", e.target.value)
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Post Code"
                value={formData.UserAddress.adrPostCode || ""}
                onChange={(e) =>
                  handleInputChange(
                    "UserAddress",
                    "adrPostCode",
                    e.target.value,
                  )
                }
                margin="normal"
              />
              <Autocomplete
                fullWidth
                options={
                  selectionLists?.DictionaryOfDisplayValueString
                    ?.ListOfCountries || []
                }
                getOptionLabel={(option) => option.Display || ""}
                value={
                  selectionLists?.DictionaryOfDisplayValueString?.ListOfCountries?.find(
                    (country) =>
                      country.Value === formData.UserAddress.adrCountry,
                  ) || null
                }
                onChange={(event, newValue) => {
                  handleInputChange(
                    "UserAddress",
                    "adrCountry",
                    newValue ? newValue.Value : "",
                  );
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Country" margin="normal" />
                )}
              />
              <TextField
                fullWidth
                label="Addressee"
                value={formData.UserAddress.adrAddressee || ""}
                onChange={(e) =>
                  handleInputChange(
                    "UserAddress",
                    "adrAddressee",
                    e.target.value,
                  )
                }
                margin="normal"
              />
              <Autocomplete
                fullWidth
                options={
                  selectionLists?.DictionaryOfDisplayValueInteger
                    ?.ListOfAddressTypes || []
                }
                getOptionLabel={(option) => option.Display || ""}
                value={
                  selectionLists?.DictionaryOfDisplayValueInteger?.ListOfAddressTypes?.find(
                    (type) => type.Value === formData.UserAddress.adrType_Enum5,
                  ) || null
                }
                onChange={(event, newValue) => {
                  handleInputChange(
                    "UserAddress",
                    "adrType_Enum5",
                    newValue ? newValue.Value : 0,
                  );
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Address Type" margin="normal" />
                )}
              />
            </Grid>

            {/* Next of Kin Section */}
            <Grid item xs={12} md={6} lg={3}>
              <Typography variant="h6">Next of Kin Information</Typography>
              <TextField
                fullWidth
                label="First Name"
                value={formData.NextOfKinPerson.pplFirstName || ""}
                onChange={(e) =>
                  handleInputChange(
                    "NextOfKinPerson",
                    "pplFirstName",
                    e.target.value,
                  )
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Last Name"
                value={formData.NextOfKinPerson.pplLastName || ""}
                onChange={(e) =>
                  handleInputChange(
                    "NextOfKinPerson",
                    "pplLastName",
                    e.target.value,
                  )
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Other Names"
                value={formData.NextOfKinPerson.pplOtherNames || ""}
                onChange={(e) =>
                  handleInputChange(
                    "NextOfKinPerson",
                    "pplOtherNames",
                    e.target.value,
                  )
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Prefix"
                value={formData.NextOfKinPerson.pplPrefix || ""}
                onChange={(e) =>
                  handleInputChange(
                    "NextOfKinPerson",
                    "pplPrefix",
                    e.target.value,
                  )
                }
                margin="normal"
              />
              <Autocomplete
                fullWidth
                options={
                  selectionLists?.DictionaryOfDisplayValueString
                    ?.ListOfGenders || []
                }
                getOptionLabel={(option) => option.Display || ""}
                value={
                  selectionLists?.DictionaryOfDisplayValueString?.ListOfGenders?.find(
                    (gender) =>
                      gender.Value === formData.NextOfKinPerson.pplGender,
                  ) || null
                }
                onChange={(event, newValue) => {
                  handleInputChange(
                    "NextOfKinPerson",
                    "pplGender",
                    newValue ? newValue.Value : "",
                  );
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Gender" margin="normal" />
                )}
              />
              <TextField
                fullWidth
                label="Date of Birth"
                type="date"
                value={
                  formData.NextOfKinPerson.pplDOB
                    ? formData.NextOfKinPerson.pplDOB.split("T")[0]
                    : ""
                }
                onChange={(e) =>
                  handleInputChange("NextOfKinPerson", "pplDOB", e.target.value)
                }
                margin="normal"
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                fullWidth
                label="Work Email"
                value={formData.NextOfKinPerson.pplWorkEmail || ""}
                onChange={(e) =>
                  handleInputChange(
                    "NextOfKinPerson",
                    "pplWorkEmail",
                    e.target.value,
                  )
                }
                margin="normal"
              />
              {/* Work Email Usage */}
              <Autocomplete
                fullWidth
                options={
                  selectionLists?.DictionaryOfDisplayValueInteger
                    ?.ListOfWorkEmailUsage || []
                }
                getOptionLabel={(option) => option.Display || ""}
                value={
                  selectionLists?.DictionaryOfDisplayValueInteger?.ListOfWorkEmailUsage?.find(
                    (usage) =>
                      usage.Value ===
                      formData.NextOfKinPerson.pplWorkEmailUse_Enum9,
                  ) || null
                }
                onChange={(event, newValue) => {
                  handleInputChange(
                    "NextOfKinPerson",
                    "pplWorkEmailUse_Enum9",
                    newValue ? newValue.Value : 0,
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Work Email Usage"
                    margin="normal"
                  />
                )}
              />
              <TextField
                fullWidth
                label="Private Email"
                value={formData.NextOfKinPerson.pplPrivEmail || ""}
                onChange={(e) =>
                  handleInputChange(
                    "NextOfKinPerson",
                    "pplPrivEmail",
                    e.target.value,
                  )
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Work Phone"
                value={formData.NextOfKinPerson.pplWorkPhone || ""}
                onChange={(e) =>
                  handleInputChange(
                    "NextOfKinPerson",
                    "pplWorkPhone",
                    e.target.value,
                  )
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Private Phone"
                value={formData.NextOfKinPerson.pplPrivPhone || ""}
                onChange={(e) =>
                  handleInputChange(
                    "NextOfKinPerson",
                    "pplPrivPhone",
                    e.target.value,
                  )
                }
                margin="normal"
              />
              {/* Position */}
              <Autocomplete
                fullWidth
                options={
                  selectionLists?.DictionaryOfDisplayValueInteger
                    ?.ListOfPositions || []
                }
                getOptionLabel={(option) => option.Display || ""}
                value={
                  selectionLists?.DictionaryOfDisplayValueInteger?.ListOfPositions?.find(
                    (position) =>
                      position.Value ===
                      formData.NextOfKinPerson.pplPosition_Enum10,
                  ) || null
                }
                onChange={(event, newValue) => {
                  handleInputChange(
                    "NextOfKinPerson",
                    "pplPosition_Enum10",
                    newValue ? newValue.Value : 0,
                  );
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Position" margin="normal" />
                )}
              />
              <TextField
                fullWidth
                label="Salutation"
                value={formData.NextOfKinPerson.pplSalutation || ""}
                onChange={(e) =>
                  handleInputChange(
                    "NextOfKinPerson",
                    "pplSalutation",
                    e.target.value,
                  )
                }
                margin="normal"
              />
            </Grid>

            {/* Next of Kin Address Section */}
            <Grid item xs={12} md={6} lg={3}>
              <Typography variant="h6">Next of Kin Address</Typography>
              <TextField
                fullWidth
                label="Street 1"
                value={formData.NextOfKinPersonAddress.adrStreet1 || ""}
                onChange={(e) =>
                  handleInputChange(
                    "NextOfKinPersonAddress",
                    "adrStreet1",
                    e.target.value,
                  )
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Street 2"
                value={formData.NextOfKinPersonAddress.adrStreet2 || ""}
                onChange={(e) =>
                  handleInputChange(
                    "NextOfKinPersonAddress",
                    "adrStreet2",
                    e.target.value,
                  )
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Town"
                value={formData.NextOfKinPersonAddress.adrTown || ""}
                onChange={(e) =>
                  handleInputChange(
                    "NextOfKinPersonAddress",
                    "adrTown",
                    e.target.value,
                  )
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Region"
                value={formData.NextOfKinPersonAddress.adrRegion || ""}
                onChange={(e) =>
                  handleInputChange(
                    "NextOfKinPersonAddress",
                    "adrRegion",
                    e.target.value,
                  )
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Post Code"
                value={formData.NextOfKinPersonAddress.adrPostCode || ""}
                onChange={(e) =>
                  handleInputChange(
                    "NextOfKinPersonAddress",
                    "adrPostCode",
                    e.target.value,
                  )
                }
                margin="normal"
              />
              <Autocomplete
                fullWidth
                options={
                  selectionLists?.DictionaryOfDisplayValueString
                    ?.ListOfCountries || []
                }
                getOptionLabel={(option) => option.Display || ""}
                value={
                  selectionLists?.DictionaryOfDisplayValueString?.ListOfCountries?.find(
                    (country) =>
                      country.Value ===
                      formData.NextOfKinPersonAddress.adrCountry,
                  ) || null
                }
                onChange={(event, newValue) => {
                  handleInputChange(
                    "NextOfKinPersonAddress",
                    "adrCountry",
                    newValue ? newValue.Value : "",
                  );
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Country" margin="normal" />
                )}
              />
              <TextField
                fullWidth
                label="Addressee"
                value={formData.NextOfKinPersonAddress.adrAddressee || ""}
                onChange={(e) =>
                  handleInputChange(
                    "NextOfKinPersonAddress",
                    "adrAddressee",
                    e.target.value,
                  )
                }
                margin="normal"
              />
              <Autocomplete
                fullWidth
                options={
                  selectionLists?.DictionaryOfDisplayValueInteger
                    ?.ListOfAddressTypes || []
                }
                getOptionLabel={(option) => option.Display || ""}
                value={
                  selectionLists?.DictionaryOfDisplayValueInteger?.ListOfAddressTypes?.find(
                    (type) =>
                      type.Value ===
                      formData.NextOfKinPersonAddress.adrType_Enum5,
                  ) || null
                }
                onChange={(event, newValue) => {
                  handleInputChange(
                    "NextOfKinPersonAddress",
                    "adrType_Enum5",
                    newValue ? newValue.Value : 0,
                  );
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Address Type" margin="normal" />
                )}
              />
            </Grid>

            {/* Employee Section */}
            <Grid item xs={12} md={6} lg={3}>
              <Typography variant="h6">Employment Details</Typography>
              {/* Status */}
              <FormLabel component="legend" style={{ marginTop: "16px" }}>
                Status
              </FormLabel>
              <RadioGroup
                row
                name="empStatus"
                value={formData.Employee.empStatus || "A"}
                onChange={(e) =>
                  handleInputChange("Employee", "empStatus", e.target.value)
                }
              >
                <FormControlLabel
                  value="A"
                  control={<Radio />}
                  label="Active"
                />
                <FormControlLabel
                  value="I"
                  control={<Radio />}
                  label="Inactive"
                />
              </RadioGroup>
              <TextField
                fullWidth
                label="Rate"
                type="number"
                value={formData.Employee.empRate || ""}
                onChange={(e) =>
                  handleInputChange("Employee", "empRate", e.target.value)
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Default Hours"
                type="number"
                value={formData.Employee.empDefHours || ""}
                onChange={(e) =>
                  handleInputChange("Employee", "empDefHours", e.target.value)
                }
                margin="normal"
              />
              {/* Rate Period */}
              <Autocomplete
                fullWidth
                options={
                  selectionLists?.DictionaryOfDisplayValueString
                    ?.ListOfRatePeriods || []
                }
                getOptionLabel={(option) => option.Display || ""}
                value={
                  selectionLists?.DictionaryOfDisplayValueString?.ListOfRatePeriods?.find(
                    (item) => item.Value === formData.Employee.empRatePeriod,
                  ) || null
                }
                onChange={(event, newValue) => {
                  handleInputChange(
                    "Employee",
                    "empRatePeriod",
                    newValue ? newValue.Value : "",
                  );
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Rate Period" margin="normal" />
                )}
              />
              {/* Employment Type */}
              <Autocomplete
                fullWidth
                options={
                  selectionLists?.DictionaryOfDisplayValueString
                    ?.ListOfEmploymentType || []
                }
                getOptionLabel={(option) => option.Display || ""}
                value={
                  selectionLists?.DictionaryOfDisplayValueString?.ListOfEmploymentType?.find(
                    (item) =>
                      item.Value === formData.Employee.empEmploymentType,
                  ) || null
                }
                onChange={(event, newValue) => {
                  handleInputChange(
                    "Employee",
                    "empEmploymentType",
                    newValue ? newValue.Value : "",
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Employment Type"
                    margin="normal"
                  />
                )}
              />
              {/* Use Timesheet */}
              <FormLabel component="legend" style={{ marginTop: "16px" }}>
                Use Timesheet
              </FormLabel>
              <RadioGroup
                row
                name="empUseTimesheet"
                value={formData.Employee.empUseTimesheet || "N"}
                onChange={(e) =>
                  handleInputChange(
                    "Employee",
                    "empUseTimesheet",
                    e.target.value,
                  )
                }
              >
                <FormControlLabel value="Y" control={<Radio />} label="Yes" />
                <FormControlLabel value="N" control={<Radio />} label="No" />
              </RadioGroup>
              <TextField
                fullWidth
                label="Max Hours"
                type="number"
                value={formData.Employee.empMaxHrs || ""}
                onChange={(e) =>
                  handleInputChange("Employee", "empMaxHrs", e.target.value)
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Max Hours Period"
                value={formData.Employee.empMaxHrsPeriod || ""}
                onChange={(e) =>
                  handleInputChange(
                    "Employee",
                    "empMaxHrsPeriod",
                    e.target.value,
                  )
                }
                margin="normal"
              />
              {/* Main Pay Item */}
              <Autocomplete
                fullWidth
                options={
                  selectionLists?.DictionaryOfDisplayValueInteger
                    ?.ListOfDirectBenefits || []
                }
                getOptionLabel={(option) => option.Display || ""}
                value={
                  selectionLists?.DictionaryOfDisplayValueInteger?.ListOfDirectBenefits?.find(
                    (item) =>
                      item.Value === formData.Employee.empMainPayItem_ptiID,
                  ) || null
                }
                onChange={(event, newValue) => {
                  handleInputChange(
                    "Employee",
                    "empMainPayItem_ptiID",
                    newValue ? newValue.Value : 0,
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Main Pay Item"
                    margin="normal"
                  />
                )}
              />
              {/* Super */}
              <Autocomplete
                fullWidth
                options={
                  selectionLists?.DictionaryOfDisplayValueString
                    ?.ListOfSuperTypes || []
                }
                getOptionLabel={(option) => option.Display || ""}
                value={
                  selectionLists?.DictionaryOfDisplayValueString?.ListOfSuperTypes?.find(
                    (item) => item.Value === formData.Employee.empSuper,
                  ) || null
                }
                onChange={(event, newValue) => {
                  handleInputChange(
                    "Employee",
                    "empSuper",
                    newValue ? newValue.Value : "",
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Superfund type"
                    margin="normal"
                  />
                )}
              />
              {/* Pay Cycle */}
              <Autocomplete
                fullWidth
                options={
                  selectionLists?.DictionaryOfDisplayValueInteger
                    ?.ListOfPayCycles || []
                }
                getOptionLabel={(option) => option.Display || ""}
                value={
                  selectionLists?.DictionaryOfDisplayValueInteger?.ListOfPayCycles?.find(
                    (item) =>
                      item.Value === formData.Employee.empPayCycle_prcID,
                  ) || null
                }
                onChange={(event, newValue) => {
                  handleInputChange(
                    "Employee",
                    "empPayCycle_prcID",
                    newValue ? newValue.Value : 0,
                  );
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Pay Cycle" margin="normal" />
                )}
              />
              {/* Is Direct Cost */}
              <FormLabel component="legend" style={{ marginTop: "16px" }}>
                Is Direct Cost
              </FormLabel>
              <RadioGroup
                row
                name="empIsDirectCost"
                value={formData.Employee.empIsDirectCost || "N"}
                onChange={(e) =>
                  handleInputChange(
                    "Employee",
                    "empIsDirectCost",
                    e.target.value,
                  )
                }
              >
                <FormControlLabel value="Y" control={<Radio />} label="Yes" />
                <FormControlLabel value="N" control={<Radio />} label="No" />
              </RadioGroup>
              <TextField
                fullWidth
                label="Withholding Earnings"
                type="number"
                value={formData.Employee.empWHEarnings || ""}
                onChange={(e) =>
                  handleInputChange("Employee", "empWHEarnings", e.target.value)
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Leave Minimum Balance"
                type="number"
                value={formData.Employee.empLeaveMinBal || ""}
                onChange={(e) =>
                  handleInputChange(
                    "Employee",
                    "empLeaveMinBal",
                    e.target.value,
                  )
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Start Date"
                type="date"
                value={
                  formData.Employee.empStartDate
                    ? formData.Employee.empStartDate.split("T")[0]
                    : ""
                }
                onChange={(e) =>
                  handleInputChange("Employee", "empStartDate", e.target.value)
                }
                margin="normal"
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                fullWidth
                label="Finish Date"
                type="date"
                value={
                  formData.Employee.empFinishDate
                    ? formData.Employee.empFinishDate.split("T")[0]
                    : ""
                }
                onChange={(e) =>
                  handleInputChange("Employee", "empFinishDate", e.target.value)
                }
                margin="normal"
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                fullWidth
                label="Tax File Number"
                value={formData.Employee.empTFN || ""}
                onChange={(e) =>
                  handleInputChange("Employee", "empTFN", e.target.value)
                }
                margin="normal"
              />
              {/* Occupation */}
              <Autocomplete
                fullWidth
                options={
                  selectionLists?.DictionaryOfDisplayValueInteger
                    ?.ListOfOccupations || []
                }
                getOptionLabel={(option) => option.Display || ""}
                value={
                  selectionLists?.DictionaryOfDisplayValueInteger?.ListOfOccupations?.find(
                    (item) =>
                      item.Value === formData.Employee.empOccupation_pocID,
                  ) || null
                }
                onChange={(event, newValue) => {
                  handleInputChange(
                    "Employee",
                    "empOccupation_pocID",
                    newValue ? newValue.Value : 0,
                  );
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Occupation" margin="normal" />
                )}
              />
              {/* Use Award */}
              <FormLabel component="legend" style={{ marginTop: "16px" }}>
                Use Award
              </FormLabel>
              <RadioGroup
                row
                name="empUseAward"
                value={formData.Employee.empUseAward || "N"}
                onChange={(e) =>
                  handleInputChange("Employee", "empUseAward", e.target.value)
                }
              >
                <FormControlLabel value="Y" control={<Radio />} label="Yes" />
                <FormControlLabel value="N" control={<Radio />} label="No" />
              </RadioGroup>
              {/* Add Loading */}
              <FormLabel component="legend" style={{ marginTop: "16px" }}>
                Add Loading
              </FormLabel>
              <RadioGroup
                row
                name="empAddLoading"
                value={formData.Employee.empAddLoading || "N"}
                onChange={(e) =>
                  handleInputChange("Employee", "empAddLoading", e.target.value)
                }
              >
                <FormControlLabel value="Y" control={<Radio />} label="Yes" />
                <FormControlLabel value="N" control={<Radio />} label="No" />
              </RadioGroup>
              {/* Bank Details */}
              <TextField
                fullWidth
                label="Account Name"
                value={formData.Employee.empAccountName || ""}
                onChange={(e) =>
                  handleInputChange(
                    "Employee",
                    "empAccountName",
                    e.target.value,
                  )
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Bank Code"
                value={formData.Employee.empBankCode || ""}
                onChange={(e) =>
                  handleInputChange("Employee", "empBankCode", e.target.value)
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Account Number"
                value={formData.Employee.empAccountNo || ""}
                onChange={(e) =>
                  handleInputChange("Employee", "empAccountNo", e.target.value)
                }
                margin="normal"
              />
              {/* Super Fund */}
              <Autocomplete
                fullWidth
                options={
                  selectionLists?.DictionaryOfDisplayValueInteger
                    ?.ListOfSuperfunds || []
                }
                getOptionLabel={(option) => option.Display || ""}
                value={
                  selectionLists?.DictionaryOfDisplayValueInteger?.ListOfSuperfunds?.find(
                    (item) => item.Value === formData.Employee.empSF_psfID,
                  ) || null
                }
                onChange={(event, newValue) => {
                  handleInputChange(
                    "Employee",
                    "empSF_psfID",
                    newValue ? newValue.Value : 0,
                  );
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Superfund" margin="normal" />
                )}
              />
              <TextField
                fullWidth
                label="Super Fund Membership Number"
                value={formData.Employee.empSFMembNo || ""}
                onChange={(e) =>
                  handleInputChange("Employee", "empSFMembNo", e.target.value)
                }
                margin="normal"
              />
              {/* Overtime */}
              <Autocomplete
                fullWidth
                options={
                  selectionLists?.DictionaryOfDisplayValueString
                    ?.ListOfOvertimeCode || []
                }
                getOptionLabel={(option) => option.Display || ""}
                value={
                  selectionLists?.DictionaryOfDisplayValueString?.ListOfOvertimeCode?.find(
                    (item) => item.Value === formData.Employee.empOvertime,
                  ) || null
                }
                onChange={(event, newValue) => {
                  handleInputChange(
                    "Employee",
                    "empOvertime",
                    newValue ? newValue.Value : "",
                  );
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Overtime" margin="normal" />
                )}
              />
              {/* Savings Account */}
              <TextField
                fullWidth
                label="Savings Account Name"
                value={formData.Employee.empSavAccName || ""}
                onChange={(e) =>
                  handleInputChange("Employee", "empSavAccName", e.target.value)
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Savings Bank Code"
                value={formData.Employee.empSavBankCode || ""}
                onChange={(e) =>
                  handleInputChange(
                    "Employee",
                    "empSavBankCode",
                    e.target.value,
                  )
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Savings Account Number"
                value={formData.Employee.empSavAccNo || ""}
                onChange={(e) =>
                  handleInputChange("Employee", "empSavAccNo", e.target.value)
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Savings Amount"
                type="number"
                value={formData.Employee.empSavAmt || ""}
                onChange={(e) =>
                  handleInputChange("Employee", "empSavAmt", e.target.value)
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Long Service Number"
                value={formData.Employee.empLongSrvNo || ""}
                onChange={(e) =>
                  handleInputChange("Employee", "empLongSrvNo", e.target.value)
                }
                margin="normal"
              />
              {/* Cessation Code */}
              <Autocomplete
                fullWidth
                options={
                  selectionLists?.DictionaryOfDisplayValueString
                    ?.ListOfTerminationCode || []
                }
                getOptionLabel={(option) => option.Display || ""}
                value={
                  selectionLists?.DictionaryOfDisplayValueString?.ListOfTerminationCode?.find(
                    (item) => item.Value === formData.Employee.empCessationCode,
                  ) || null
                }
                onChange={(event, newValue) => {
                  handleInputChange(
                    "Employee",
                    "empCessationCode",
                    newValue ? newValue.Value : "",
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Cessation Code"
                    margin="normal"
                  />
                )}
              />
              {/* Help Debt */}
              <FormLabel component="legend" style={{ marginTop: "16px" }}>
                HELP Debt
              </FormLabel>
              <RadioGroup
                row
                name="empHelpDebt"
                value={formData.Employee.empHelpDebt || "N"}
                onChange={(e) =>
                  handleInputChange("Employee", "empHelpDebt", e.target.value)
                }
              >
                <FormControlLabel value="Y" control={<Radio />} label="Yes" />
                <FormControlLabel value="N" control={<Radio />} label="No" />
              </RadioGroup>
              <TextField
                fullWidth
                label="Tax Scale"
                type="number"
                value={formData.Employee.empTaxScale || ""}
                onChange={(e) =>
                  handleInputChange("Employee", "empTaxScale", e.target.value)
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Tax Variation"
                type="number"
                value={formData.Employee.empTaxVariation || ""}
                onChange={(e) =>
                  handleInputChange(
                    "Employee",
                    "empTaxVariation",
                    e.target.value,
                  )
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Tax Treatment Code"
                value={formData.Employee.empTaxTreatmentCode || ""}
                onChange={(e) =>
                  handleInputChange(
                    "Employee",
                    "empTaxTreatmentCode",
                    e.target.value,
                  )
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Income Stream Code"
                value={formData.Employee.empIncomeStreamCode || ""}
                onChange={(e) =>
                  handleInputChange(
                    "Employee",
                    "empIncomeStreamCode",
                    e.target.value,
                  )
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Tax Country"
                value={formData.Employee.empTaxCountry || ""}
                onChange={(e) =>
                  handleInputChange("Employee", "empTaxCountry", e.target.value)
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Tax Offset"
                type="number"
                value={formData.Employee.empTaxOffset || ""}
                onChange={(e) =>
                  handleInputChange("Employee", "empTaxOffset", e.target.value)
                }
                margin="normal"
              />
            </Grid>
          </Grid>

          <Box mt={3}>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Update
            </Button>
          </Box>
          <Box
            sx={{
              mt: 2,
              mb: 2,
              width: "100%",
            }}
          >
            <Typography fontSize="12px" sx={{ textAlign: "right" }}>
              {footerText}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default EmployeeManagement;
