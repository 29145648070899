import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  Typography,
  TextField,
  Autocomplete,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import useApi from "../../hooks/useApi";
import envConfig from "../../config";
import { useSnackbar } from "notistack";
import BackdropLoading from "../../components/BackdropLoading/BackdropLoading";

const DepartmentDetails = () => {
  const { dbId, lang, dptID } = useParams();
  const { apiCall } = useApi();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  // State variables
  const [loading, setLoading] = useState(true);
  const [department, setDepartment] = useState(null);
  const [sites, setSites] = useState([]);
  const [managers, setManagers] = useState([]);

  // Form fields
  const [dptName, setDptName] = useState("");
  const [dptCode, setDptCode] = useState("");
  const [dptHeadUsrID, setDptHeadUsrID] = useState(null);
  const [dptActive, setDptActive] = useState("Y");
  const [dptNotes, setDptNotes] = useState("");
  const [dptDefSiteDpsID, setDptDefSiteDpsID] = useState(null);

  // Delete confirmation dialog
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  // Fetch department details
  const fetchDepartmentDetails = useCallback(async () => {
    setLoading(true);
    try {
      await apiCall({
        url: `https://${envConfig.apiDev1Exacc}/api/v1/en-au/departments/get-list-of-departments-async`,
        method: "GET",
        onSuccess: (data) => {
          if (data.SuccessYN !== "Y") {
            enqueueSnackbar(data.ErrorMessage || "Failed to fetch data.", {
              variant: "error",
            });
            return;
          }

          const dept = data.ListOfDepartments.find(
            (d) => d.dptID === parseInt(dptID, 10),
          );

          if (!dept) {
            enqueueSnackbar("Department not found.", { variant: "error" });
            navigate(`/${dbId}/${lang}/departments`);
            return;
          }

          setDepartment(dept);
          setDptName(dept.dptName);
          setDptCode(dept.dptCode);
          setDptHeadUsrID(dept.dptHead_usrID);
          setDptActive(dept.dptActive);
          setDptNotes(dept.dptNotes);
          setDptDefSiteDpsID(dept.dptDefSite_dpsID);

          setSites(data.ListOfSites || []);
          setManagers(data.ListOfManagers || []);
        },
        onError: (error) => {
          enqueueSnackbar(error, { variant: "error" });
        },
      });
    } catch (error) {
      console.error("Error fetching department details:", error);
      enqueueSnackbar("An unexpected error occurred.", { variant: "error" });
    } finally {
      setLoading(false);
    }
  }, [apiCall, dptID, enqueueSnackbar, navigate]);

  useEffect(() => {
    fetchDepartmentDetails();
  }, [fetchDepartmentDetails]);

  // Handle form submission
  const handleSave = async () => {
    // Basic validation
    if (!dptName || !dptCode) {
      enqueueSnackbar("Name and Code are required.", { variant: "warning" });
      return;
    }

    setLoading(true);
    try {
      const payload = {
        dptID: parseInt(dptID, 10),
        dptName,
        dptCode,
        dptHead_usrID: dptHeadUsrID,
        dptActive,
        dptNotes,
        dptDefSite_dpsID: dptDefSiteDpsID,
      };

      await apiCall({
        url: `https://${envConfig.apiDev1Exacc}/api/v1/en-au/departments/update-async`,
        method: "POST",
        body: payload,
        onSuccess: (data) => {
          if (data.SuccessYN === "Y") {
            enqueueSnackbar("Department updated successfully.", {
              variant: "success",
            });
            navigate(`/${dbId}/${lang}/departments`);
          } else {
            enqueueSnackbar(
              data.ErrorMessage || "Failed to update department.",
              { variant: "error" },
            );
          }
        },
        onError: (error) => {
          enqueueSnackbar(error, { variant: "error" });
        },
      });
    } catch (error) {
      console.error("Error updating department:", error);
      enqueueSnackbar("An unexpected error occurred.", { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  // Handle delete
  const handleDelete = async () => {
    setLoading(true);
    try {
      await apiCall({
        url: `https://${envConfig.apiDev1Exacc}/api/v1/en-au/departments/delete-async?departmentId=${dptID}&BaseHostURL=s1.olivs.com`,
        method: "POST",
        onSuccess: (data) => {
          if (data.SuccessYN === "Y") {
            enqueueSnackbar("Department deleted successfully.", {
              variant: "success",
            });
            navigate(`/${dbId}/${lang}/departments`);
          } else {
            enqueueSnackbar(
              data.ErrorMessage || "Failed to delete department.",
              { variant: "error" },
            );
          }
        },
        onError: (error) => {
          enqueueSnackbar(error, { variant: "error" });
        },
      });
    } catch (error) {
      console.error("Error deleting department:", error);
      enqueueSnackbar("An unexpected error occurred.", { variant: "error" });
    } finally {
      setLoading(false);
      setIsDeleteDialogOpen(false);
    }
  };

  // Render loading state
  if (loading && !department) {
    return <BackdropLoading open={loading} />;
  }

  // Render if department is not found
  if (!department) {
    return (
      <Box sx={{ padding: 2 }}>
        <Typography variant="h6">Department not found.</Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate(`/${dbId}/${lang}/departments`)}
          sx={{ mt: 2 }}
        >
          Back to Departments
        </Button>
      </Box>
    );
  }

  return (
    <Box sx={{ padding: 2, maxWidth: 800, margin: "0 auto" }}>
      {/* Loading Backdrop */}
      <BackdropLoading open={loading} />

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this department?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDeleteDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Page Title */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 3,
        }}
      >
        <Typography variant="h4">Department Details</Typography>
        <Box>
          <Button
            variant="contained"
            color="error"
            onClick={() => setIsDeleteDialogOpen(true)}
            sx={{ mr: 2 }}
          >
            Delete
          </Button>
          <Button
            variant="outlined"
            onClick={() => navigate(`/${dbId}/${lang}/departments`)}
          >
            Back
          </Button>
        </Box>
      </Box>

      {/* Form */}
      <Box
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
        }}
        noValidate
        autoComplete="off"
      >
        {/* Department Name */}
        <TextField
          label="Department Name"
          value={dptName}
          onChange={(e) => setDptName(e.target.value)}
          required
        />

        {/* Department Code */}
        <TextField
          label="Department Code"
          value={dptCode}
          onChange={(e) => setDptCode(e.target.value)}
          required
        />

        {/* Department Head */}
        <Autocomplete
          options={managers}
          getOptionLabel={(option) => `${option.usrName}`}
          value={
            managers.find((manager) => manager.usrID === dptHeadUsrID) || null
          }
          onChange={(event, newValue) =>
            setDptHeadUsrID(newValue ? newValue.usrID : null)
          }
          renderInput={(params) => (
            <TextField {...params} label="Department Head" />
          )}
        />

        {/* Active Status */}
        <FormControl component="fieldset">
          <FormLabel component="legend">Active Status</FormLabel>
          <RadioGroup
            row
            value={dptActive}
            onChange={(e) => setDptActive(e.target.value)}
          >
            <FormControlLabel value="Y" control={<Radio />} label="Yes" />
            <FormControlLabel value="N" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>

        {/* Department Notes */}
        <TextField
          label="Department Notes"
          value={dptNotes}
          onChange={(e) => setDptNotes(e.target.value)}
          multiline
          rows={4}
        />

        {/* Default Site */}
        <Autocomplete
          options={sites}
          getOptionLabel={(option) => `${option.dpsName} (${option.dpsCode})`}
          value={sites.find((site) => site.dpsID === dptDefSiteDpsID) || null}
          onChange={(event, newValue) =>
            setDptDefSiteDpsID(newValue ? newValue.dpsID : null)
          }
          renderInput={(params) => (
            <TextField {...params} label="Default Site" />
          )}
        />

        {/* Save Button */}
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            disabled={loading}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default DepartmentDetails;
