import React from "react";
import {
  Box,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

import { UserStatus } from "../types";
import { commonThemes } from "../../../../utils/themes/themes";
import { useTheme } from "../../../../context/ThemeContext";

interface UsersListStatusFiltersProps {
  userStatus: UserStatus;
  setUserStatus: (status: UserStatus) => void;
}

export const UsersListStatusFilters = ({
  userStatus,
  setUserStatus,
}: UsersListStatusFiltersProps) => {
  const { isDarkMode } = useTheme();

  return (
    <Box mt={2}>
      <Box
        px={4}
        py={2}
        border={isDarkMode ? "1px solid #3e424d" : "1px solid #e0e0e0"}
      >
        <FormLabel>Status</FormLabel>
        <RadioGroup
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <FormControlLabel
            value="A"
            onChange={() => setUserStatus("A")}
            control={<Radio checked={userStatus === "A"} />}
            label="Active"
            // @ts-ignore
            sx={{ mr: 2  }}
          />
          <FormControlLabel
            value="P"
            onChange={() => setUserStatus("P")}
            control={<Radio checked={userStatus === "P"} />}
            label="Pending"
            // @ts-ignore
            sx={{ mr: 2  }}
          />
          <FormControlLabel
            value="NOT ACTIVE"
            onChange={() => setUserStatus("NOT ACTIVE")}
            control={<Radio checked={userStatus === "NOT ACTIVE"} />}
            label="Not Active"
            // @ts-ignore
            sx={{ mr: 2  }}
          />
          <FormControlLabel
            value="ALL"
            onChange={() => setUserStatus("ALL")}
            control={<Radio checked={userStatus === "ALL"} />}
            label="All"
            // @ts-ignore
            sx={{ mr: 2  }}
          />
        </RadioGroup>
      </Box>
    </Box>
  );
};
