import { Box, Menu, Typography, useMediaQuery } from "@mui/material";
import { GridMoreVertIcon } from "@mui/x-data-grid-pro";
import { useTheme } from "../../context/ThemeContext";
import { darkTheme, lightTheme } from "../../utils/themes/themes";
import OverflowTip from "../OverflowTip/OverflowTip";
import DataGrid, {
  Column,
  Grouping,
  Scrolling,
  Sorting,
  Paging,
  Pager,
} from "devextreme-react/data-grid";
import dayjs from "dayjs";
import { useState } from "react";

const ThreeDots = ({ data, handleActivate, handleDeactivate }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <GridMoreVertIcon
        sx={{ cursor: "pointer" }}
        color="primary"
        onClick={handleClick}
      />
      <Menu
        sx={{ ".MuiPaper-root": { px: "10px !important" } }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <RenderButton
          params={data}
          handleActivate={handleActivate}
          handleDeactivate={handleDeactivate}
          accountId={data.bfaID || data.data?.bfaID}
          connectionId={
            data.ConnectionId ||
            data.data?.ConnectionId ||
            data.bfaConnectionId ||
            data.data?.bfaConnectionId
          }
          active={data.data?.bfaLinkStatus === "Linked"}
          isInstitutionName={data.data?.isInstitutionName}
        />
      </Menu>
    </div>
  );
};

const getColumns = ({
  isMobile,
  handleActivate,
  handleDeactivate,
  onLinkAnother,
}) =>
  isMobile
    ? [
        {
          dataField: "empty",
          caption: "",
          width: 50,
          allowSorting: false,
          allowResizing: false,
          cellRender: (data) => {
            if (typeof data.data?.id !== "string") {
              return (
                <ThreeDots
                  data={data}
                  handleActivate={handleActivate}
                  handleDeactivate={handleDeactivate}
                />
              );
            }
          },
        },
        {
          dataField: "bfbCustomName",
          caption: "",
          allowSorting: false,
          allowResizing: false,
          groupIndex: 0,
          groupCellRender: (data) => {
            const connectionId = data.data.items?.at(0)?.bfaConnectionId;
            return (
              <Box display="flex" alignItems="flex-start">
                <Typography>{data.value}</Typography>
                <Typography
                  sx={{
                    cursor: "pointer",
                    ml: 4,
                  }}
                  color="primary"
                  onClick={() => onLinkAnother(connectionId)}
                >
                  Link another account
                </Typography>
              </Box>
            );
          },
          cellRender: (data) => (
            <OverflowTip value={data.value} content={data.value} />
          ),
        },
        {
          dataField: "bfaMobileColumn",
          caption: "",
          allowSorting: false,
          allowResizing: false,
          cellRender: (data) => (
            <OverflowTip value={data.value} content={data.value} />
          ),
        },
      ]
    : [
        {
          dataField: "empty",
          caption: "",
          width: 50,
          allowSorting: false,
          allowResizing: false,
          cellRender: (data) => {
            if (typeof data.data?.id !== "string") {
              return (
                <ThreeDots
                  data={data}
                  handleActivate={handleActivate}
                  handleDeactivate={handleDeactivate}
                />
              );
            }
          },
        },
        {
          dataField: "bfbCustomName",
          caption: "",
          allowSorting: false,
          allowResizing: false,
          groupIndex: 0,
          groupCellRender: (data) => {
            const connectionId = data.data.items?.at(0)?.bfaConnectionId;
            return (
              <Box display="flex" alignItems="flex-start">
                <Typography>{data.value}</Typography>
                <Typography
                  sx={{
                    cursor: "pointer",
                    ml: 4,
                  }}
                  color="primary"
                  onClick={() => onLinkAnother(connectionId)}
                >
                  Link another account
                </Typography>
              </Box>
            );
          },
          cellRender: (data) => (
            <OverflowTip value={data.value} content={data.value} />
          ),
        },
        {
          dataField: "bfaAccountName",
          caption: "Name",
          minWidth: 250,
          allowSorting: false,
          allowResizing: false,
          cellRender: (data) => (
            <OverflowTip value={data.value} content={data.value} />
          ),
        },
        {
          dataField: "bfaAccountDisplay",
          caption: "BSB and Number",
          allowSorting: false,
          allowResizing: false,
          cellRender: (data) => (
            <OverflowTip value={data.value} content={data.value} />
          ),
        },
        {
          dataField: "bfaLinkStatus",
          caption: "Status",
          allowSorting: false,
          allowResizing: false,
          cellRender: (data) => (
            <OverflowTip value={data.value} content={data.value} />
          ),
        },
        {
          dataField: "xxbBusinessName",
          caption: "Linked to",
          allowSorting: false,
          allowResizing: false,
          cellRender: (data) => (
            <OverflowTip value={data.value} content={data.value} />
          ),
        },
      ];

const RenderButton = ({
  params,
  handleActivate,
  handleDeactivate,
  accountId,
  connectionId,
  active,
  isInstitutionName,
}) => {
  const { isDarkMode } = useTheme();
  const theme = isDarkMode ? darkTheme : lightTheme;

  if (typeof params?.id === "string") return;
  if (isInstitutionName) return;
  if (active) {
    return (
      <Typography
        sx={{
          fontSize: "14px",
          fontWeight: 700,
          cursor: "pointer",
        }}
        onClick={() =>
          handleDeactivate({
            accountId: params.data.bfaID || params.data.AccountId,
            connectionId,
          })
        }
      >
        Deactivate
      </Typography>
    );
  }
  return (
    <Typography
      sx={{
        fontSize: "14px",
        fontWeight: 700,
        cursor: "pointer",
      }}
      onClick={() => {
        handleActivate({
          accountId: params.data.bfaID || params.data.AccountId,
          connectionId,
        });
      }}
    >
      Connect
    </Typography>
  );
};

const BankFeedsNotConnected = ({
  handleDeactivate,
  handleActivate,
  data = [],
  onLinkAnother,
}) => {
  const { isDarkMode } = useTheme();
  const isMobile = useMediaQuery("(max-width: 620px)");
  const theme = isDarkMode ? darkTheme : lightTheme;

  const cols = getColumns({
    isMobile: isMobile,
    handleDeactivate: handleDeactivate,
    handleActivate: handleActivate,
    onLinkAnother: onLinkAnother,
  });

  return (
    <Box
      sx={{
        height: "400px",
        width: "100%",
        ".dx-datagrid .dx-row-alt > td, .dx-datagrid .dx-row-alt > tr > td": {
          backgroundColor: isDarkMode ? "#1E2432" : "#FAFAFA",
        },
      }}
    >
      <DataGrid
        rowAlternationEnabled={true}
        dataSource={data}
        showBorders={false}
      >
        <Grouping autoExpandAll={true} />
        <Scrolling mode="virtual" />
        <Sorting mode="none" />
        <Paging defaultPageSize={5} />
        <Pager
          showPageSizeSelector={true}
          allowedPageSizes={[5]}
          showInfo={true}
        />
        {cols?.map((column) => (
          <Column
            groupCellRender={(params) => {
              return (
                <Typography
                  sx={{
                    marginTop: "-3px",
                    marginBottom: "-3px",
                    fontWeight: 600,
                    color: theme.typography.linkColor,
                  }}
                >
                  {params.value}
                </Typography>
              );
            }}
            groupIndex={
              column.dataField === "bfbCustomName" ||
              column.dataField === "bfaCustomName"
                ? 0
                : -1
            }
            key={column.dataField}
            {...column}
          />
        ))}
      </DataGrid>
    </Box>
  );
};

export default BankFeedsNotConnected;
