import React from "react";
import { useSnackbar } from "notistack";
import useApi from "../../../hooks/useApi";
import envConfig from "../../../config";

interface UseDeletePaymentProps {
  onSuccess?: () => void;
  onError?: (error: string) => void;
}

export const useDeletePayment = ({
  onSuccess,
  onError,
}: UseDeletePaymentProps) => {
  const { apiCall } = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isError, setIsError] = React.useState(false);

  const deletePayment = React.useCallback(
    async (paymentId: number) => {
      setIsLoading(true);
      setIsError(false);

      try {
        await apiCall({
          url: `https://${envConfig.apiDev1}/api/v3/en-au/invoices-payments/delete?id=${paymentId}&BaseHostURL=${envConfig.mainServiceUrl}`,
          method: "POST",
          onSuccess: () => {
            enqueueSnackbar("Payment deleted successfully", {
              variant: "success",
            });
            if (onSuccess) onSuccess();
          },
          // @ts-ignore
          onError: (error: string) => {
            setIsError(true);
            enqueueSnackbar(error, { variant: "error" });
            if (onError) onError(error);
          },
        });
      } catch (error) {
        setIsError(true);
        const errorMessage = (error as Error).message;
        enqueueSnackbar(errorMessage, { variant: "error" });
        if (onError) onError(error);
      } finally {
        setIsLoading(false);
      }
    },
    [apiCall, enqueueSnackbar, onSuccess, onError],
  );

  return { isLoading, isError, deletePayment };
};
