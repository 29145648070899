import React from "react";
import envConfig from "../../../../config";
import { useSnackbar } from "notistack";
import { EXAMPLE_GET_LIST_OF_TAX_CODES_RESPONSE } from "../../../../data/example-get-list-of-tax-codes";
import useApi from "../../../../hooks/useApi";

export interface TaxCode {
  txcID: number;
  txcName: string;
  txcRate: number;
  txcCountry: string;
  txcCode: string;
  txcDocName: string;
  txcAccount_accNo: number;
}

export interface ListOfTaxCodesResponse {
  listOfTaxCodes: TaxCode[];
  errorMessage: string;
  successYN: "Y" | "N";
}

const url = `https://${envConfig.apiDev1Exacc}/api/v1/en-au/tax-codes/get-list-of-tax-codes?BaseHostURL=${envConfig.mainServiceUrl}`;

// TODO-NOW: remove it to useListOfTaxCodes
export const useNewListOfTaxCodes = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (data: ListOfTaxCodesResponse) => void;
  onError?: (error: string) => void;
}) => {
  const { apiCall } = useApi();
  const { enqueueSnackbar } = useSnackbar();

  const [data, setData] = React.useState<ListOfTaxCodesResponse | undefined>(
    undefined,
  );
  const [isLoadingTaxCodes, setIsLoadingTaxCodes] = React.useState(false);
  const [isErrorTaxCodes, setIsErrorTaxCodex] = React.useState(false);

  const fetchListOfTaxCodes = React.useCallback(async () => {
    setIsLoadingTaxCodes(true);
    setIsErrorTaxCodex(false);

    try {
      await apiCall({
        url,
        method: "GET",
        // @ts-ignore + TODO-FUTURE: add TypeScript support for this
        onSuccess: (data: ListOfTaxCodesResponse) => {
          setData(data);
          // if (onSuccess) onSuccess(data);
        },
        // @ts-ignore + TODO-FUTURE: add TypeScript support for this
        onError: (error: string) => {
          setIsErrorTaxCodex(true);
          enqueueSnackbar(error, { variant: "error" });
          // if (onError) onError(error);
        },
      });
    } catch (error) {
      console.error("fetchListOfTaxCodes error", error);
      setIsErrorTaxCodex(true);
      enqueueSnackbar((error as Error).message, { variant: "error" });
      // if (onError) onError((error as Error).message);
    } finally {
      if (process.env.NODE_ENV === "development") {
        setData(
          EXAMPLE_GET_LIST_OF_TAX_CODES_RESPONSE as ListOfTaxCodesResponse,
        );
      }
      setIsLoadingTaxCodes(false);
    }
  }, [apiCall, enqueueSnackbar]);

  React.useEffect(() => {
    fetchListOfTaxCodes();
  }, [fetchListOfTaxCodes]);

  return {
    isLoading: isLoadingTaxCodes,
    isError: isErrorTaxCodes,
    data,
    refetch: fetchListOfTaxCodes,
  };
};
