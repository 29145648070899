import React from "react";
import { Box, TextField, Typography } from "@mui/material";

interface PayrollEventDetailsProps {
  mainDatesTxt: string;
  createdTxt: string;
  warnings: string;
  pevNotes: string;
  pevPayslipComment: string;
  onPevNotesBlur: (noteText: string) => void;
  onPeyPayslipCommentBlur: (noteText: string) => void;
  finalized: boolean;
}

export const PayrollEventDetails: React.FC<PayrollEventDetailsProps> = ({
  mainDatesTxt,
  createdTxt,
  warnings,
  pevNotes,
  pevPayslipComment,
  onPevNotesBlur,
  onPeyPayslipCommentBlur,
  finalized,
}) => {
  const [privateNote, setPrivateNote] = React.useState(pevNotes);
  const [payslipNote, setPayslipNote] = React.useState(pevPayslipComment);

  // Update state when props change
  React.useEffect(() => {
    setPrivateNote(pevNotes);
    setPayslipNote(pevPayslipComment);
  }, [pevNotes, pevPayslipComment]);

  return (
    <Box
      mt={4}
      display={"flex"}
      flexDirection={{ xs: "column", md: "row" }}
      gap={4}
    >
      <Box display={"flex"} flexDirection={"column"} flex={2}>
        <Typography variant="body1" fontWeight={700} mb={0}>
          {mainDatesTxt}
        </Typography>
        <Typography variant="body1">{createdTxt}</Typography>
        <Typography
          variant="body1"
          dangerouslySetInnerHTML={{ __html: warnings }}
        />
      </Box>
      <Box flex={1}>
        {finalized ? (
          <Typography variant="body1">
            Private Note: <br /> {pevNotes}
          </Typography>
        ) : (
          <TextField
            multiline
            rows={4}
            fullWidth
            label="Private Note"
            variant="outlined"
            placeholder="Enter private note here..."
            value={privateNote}
            onChange={(e) => setPrivateNote(e.target.value)}
            InputProps={{
              readOnly: finalized,
            }}
            onBlur={() => onPevNotesBlur(privateNote)}
          />
        )}
      </Box>
      <Box flex={1}>
        {finalized ? (
          <Typography variant="body1">
            Note for all Payslips: <br /> {pevPayslipComment}
          </Typography>
        ) : (
          <TextField
            multiline
            rows={4}
            fullWidth
            label="Note for all Payslips"
            variant="outlined"
            placeholder="Enter note for all payslips here..."
            value={payslipNote}
            onChange={(e) => setPayslipNote(e.target.value)}
            InputProps={{
              readOnly: finalized,
            }}
            onBlur={() => onPeyPayslipCommentBlur(payslipNote)}
          />
        )}
      </Box>
    </Box>
  );
};
