import { enqueueSnackbar } from "notistack";
import envConfig from "../../../../config";
import { useQuery } from "../../../../hooks/useQuery";
import {
  ANOTHER_EXAMPLE,
  EXAMPLE_GET_REPORTS_PARAMETERS_RESPONSE,
} from "./example-response";
import { ResponseBoolean } from "../../../../types/api/common";
import { CustomReportParameter } from "../types";

export interface CustomReportParameterResponse {
  SuccessYN: ResponseBoolean;
  ErrorMessageHtml: string | null;
  HelpURL: string;
  ReportTitle: string;
  RptTitle?: string;
  RunOnOpenYN: ResponseBoolean;
  AddDateSelector: "P" | "E" | undefined;
  Parameters: CustomReportParameter[];
}

interface ReportsParametersProps {
  reportId: string;
  onSuccess?: (data: CustomReportParameterResponse) => void;
}

export const useReportsParameters = ({
  reportId,
  onSuccess,
}: ReportsParametersProps) => {
  const url = `https://${envConfig.apiDev2}/api/en-au/reports/get-grid-report-parameters-rs1?ReportID=${reportId}`;

  const { isLoading, isError, error, data, isSuccess } =
    useQuery<CustomReportParameterResponse>(url, {
      onSuccess: onSuccess,
      onError: (errorMessage) => {
        enqueueSnackbar(errorMessage, { variant: "error" });
      },
    });

  if (process.env.NODE_ENV === "development") {
    return {
      isLoading: false,
      isError: false,
      error: null,
      isSuccess: true,
      data: ANOTHER_EXAMPLE as unknown as CustomReportParameterResponse,
      // data: EXAMPLE_GET_REPORTS_PARAMETERS_RESPONSE as CustomReportParameterResponse,
    };
  }

  return { isLoading, isError, error, data, isSuccess };
};
