import React from "react";
import { Box, Grid, Autocomplete, TextField } from "@mui/material";
import { PaymentMethod, PostableAccount } from "../types/invoice-payment";

interface PaymentSummaryDetailsFormMiddleProps {
  paymentMethods: PaymentMethod[];
  postableAccounts: PostableAccount[];
  selectedPaymentMethod: PaymentMethod | null;
  selectedAccount: PostableAccount | null;
  paymentMethodError?: string | null;
  accountError?: string | null;
  onPaymentMethodChange: (method: PaymentMethod | null) => void;
  onAccountChange: (account: PostableAccount | null) => void;
}

export function PaymentSummaryDetailsFormMiddle({
  paymentMethods,
  postableAccounts,
  selectedPaymentMethod,
  selectedAccount,
  paymentMethodError,
  accountError,
  onPaymentMethodChange,
  onAccountChange,
}: PaymentSummaryDetailsFormMiddleProps) {
  return (
    <Box>
      <Grid container item spacing={3.5}>
        <Grid item xs={12} md={6}>
          <Autocomplete
            fullWidth
            options={paymentMethods}
            value={selectedPaymentMethod}
            onChange={(_, newValue) => onPaymentMethodChange(newValue)}
            getOptionLabel={(option) => option.pmmName}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Method"
                error={!!paymentMethodError}
                helperText={paymentMethodError ?? ""}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Autocomplete
            fullWidth
            options={postableAccounts}
            value={selectedAccount}
            onChange={(_, newValue) => onAccountChange(newValue)}
            getOptionLabel={(option) => `${option.accNo} - ${option.accName}`}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Account"
                error={!!accountError}
                helperText={accountError ?? ""}
              />
            )}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
