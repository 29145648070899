import React, { useState } from "react";
import { Autocomplete, Box, TextField, Tooltip, Typography } from "@mui/material";
import DataGrid, { Column, DataGridTypes } from "devextreme-react/cjs/data-grid";

import BackdropLoading from "../../../../components/BackdropLoading/BackdropLoading";
import { PageTitle } from "../../../../components/page-title";
import { useTransactionsReviewReport } from "../api/review-transactions-report-r1";
import { getAlignment } from "../../../../helpers/table";
import { useNavigateInternal } from "../../../../hooks/use-navigate-internal";
import { formatDate } from "../../../../utils/format-date";
import { getColumnNameTooltip, isColumnDateType } from "../../../../utils/tables";
import { formatFinancialNumber } from "../../../../utils/format-number";
import { isColumnMoneyType } from "../../../../utils/tables";
import { isValidDateString } from "../../../../utils/format-date";
import { ITooltip } from "../../../../types/utils";
import { YNToBool } from "../../../../utils/format-bool";
import { ItemClickEvent } from "devextreme/ui/context_menu";
import { ActionMenu } from "../../../../components/ui/table/action-menu";
import { useInterfacePreference } from "../../../../hooks/use-interface-preference";

type ReportOption = {
  Label: string;
  Value: number;
};

const REPORTS_SELECT_OPTIONS: ReportOption[] = [
  { Label: "Records marked for review", Value: 1 },
  { Label: "Draft records", Value: 2 },
  { Label: "Records using suspense account", Value: 3 },
  { Label: "Records with varied tax amount", Value: 4 },
];

export default function ReviewTransactionsPage() {
  const [selectedReport, setSelectedReport] = useState<ReportOption | null>(null);
  const interfacePreference = useInterfacePreference();

  const { openInNewTab } = useNavigateInternal();

  const { data: reportData, isLoading: isReportLoading } = useTransactionsReviewReport({ reportId: selectedReport?.Value });

  console.log("reportData", reportData);

  const data = reportData?.DataDT || [];
  const columns = reportData?.ColumnsList || [];
  const actionsList = reportData?.ActionsList || [];

  const onActionMenuItemClick = async (e: ItemClickEvent<any>, data: any) => {
    // @ts-ignore - they have fuckup types
    const action = e.itemData?.ActionType;
    // @ts-ignore - they have fuckup types
    const column = e.itemData?.ColumnWithLink;

    switch (action) {
      case "OPEN_NEW_TAB":
        openInNewTab(data[column]);
        break;
    }
  }

  const actions = actionsList.map((action) => {
    return {
      text: action?.DisplayText,
      actionType: action?.ActionType,
      ...action,
    };
  });

  const displayActions = actions.length > 0;


  // const handleRowClick = (e: any) => {
  //   if (!e.data?.AB01) return;
  //   openInNewTab(`${e.data.AB01}`);
  // };

  return (
    <>
      <BackdropLoading open={isReportLoading} />
      <PageTitle title="Review Transactions" />
      <Box
        display={"flex"}
        flexDirection={"column"}
        padding={{ xs: 0, md: 4 }}
        boxShadow={{
          xs: "none",
          md: "0px 0px 10px 0px rgba(69, 90, 100, 0.10)",
        }}
        borderRadius={2}
        marginY={4}
      >
        <Typography variant="h6">Bank Feed Transactions Filter</Typography>
        <Box
          display="flex"
          flexDirection="row"
          gap={2}
          width="100%"
          mt={4}
          alignItems={{ xs: "stretch", md: "center" }}
        >
          <Autocomplete
            value={selectedReport}
            options={REPORTS_SELECT_OPTIONS}
            onChange={(_, newValue) => setSelectedReport(newValue)}
            getOptionLabel={(option) => option.Label}
            fullWidth
            sx={{ maxWidth: 400 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Report Type"
                size="small"
              />
            )}
          />
        </Box>
        <Box mt={4} width={"100%"}>
          <DataGrid
            dataSource={data}
            showBorders
            allowColumnResizing
            columnAutoWidth
            rowAlternationEnabled
          // onRowClick={handleRowClick}
          >

            {displayActions && interfacePreference === "left" && (
              <Column
                width={65}
                caption=""
                fixed={true}
                alignment="left"
                cellRender={({ data }) => (
                  <ActionMenu
                    dataSource={actions}
                    onItemClick={(e) => onActionMenuItemClick(e, data)}
                  />
                )}
              />
            )}

            {columns.map((column) => (
              <Column
                key={column.RpcID}
                // format={col.RpcDataType_rpdID === 5 ? "dd/MM/yyyy" : undefined}
                // dataType={col.RpcDataType_rpdID === 5 ? "date" : undefined}
                dataField={column.RpcDataSource}
                caption={column.RpcHeader}
                allowResizing={YNToBool(column.RpcAllowResizing)}
                allowFiltering={YNToBool(column.RpcFilter)}
                alignment={getAlignment(column.RpcAlign)}
                allowSearch={YNToBool(column.RpcSearchable)}
                allowSorting={YNToBool(column.RpcSort)}
                groupCellRender={(cellInfo) => cellInfo?.displayValue}
                groupIndex={
                  column.RpcGroupBy === "Y" ||
                    // @ts-ignore
                    column.RpcGroupBy === "y"
                    // column.RpcDataSource === groupBy ||
                    // column.RpcHeader === groupBy
                    ? 0
                    : undefined
                }
                cellRender={(cellInfo) =>
                  renderGeneralTableCell(cellInfo, column)
                }
                visible={YNToBool(column.RpcVisible)}
                fixed={YNToBool(column.RpcFreeze)}
                minWidth={column.RpcMinWidth}
                width={column.RpcMaxWidth}
                allowGrouping={YNToBool(column.RpcGroupBy)}
              />


            ))}

            {displayActions && interfacePreference !== "left" && (
              <Column width={undefined} />
            )}

            {displayActions && interfacePreference !== "left" && (
              <Column
                width={65}
                caption=""
                alignment="right"
                cellRender={({ data }) => {
                  return (
                    <ActionMenu
                      dataSource={actions}
                      onItemClick={(e) => onActionMenuItemClick(e, data)}
                    />
                  );
                }}
              />
            )}

            {!displayActions && <Column caption="" width={undefined} />}
            {displayActions && interfacePreference === "left" && (
              <Column caption="" width={undefined} />
            )}
          </DataGrid>
        </Box>
      </Box>
    </>
  )
}

export const renderGeneralTableCell = (
  cellInfo: DataGridTypes.ColumnCellTemplateData,
  currentColumn: any,
  // tooltips: ITooltip[],
) => {
  // const tooltipedColumn = getColumnNameTooltip(
  //   tooltips,
  //   currentColumn.RpcHeader,
  // );

  // if (tooltipedColumn) {
  //   return (
  //     <Tooltip title={cellInfo.data?.[tooltipedColumn]} placement="top">
  //       {formatCellValue(cellInfo, currentColumn)}
  //     </Tooltip>
  //   );
  // }

  return formatCellValue(cellInfo, currentColumn);
};

export const formatCellValue = (
  cellInfo: DataGridTypes.ColumnCellTemplateData,
  currentColumn: any,
) => {
  if (isColumnMoneyType(currentColumn.RpcDataType_rpdID)) {
    return formatFinancialNumber(cellInfo.value, {
      hideZero: currentColumn.HideZeroOnAmountColumnYN === "Y",
      rounding: currentColumn.RpcRounding,
      defaultValue: "-",
    });
  }

  if (
    (cellInfo.column.dataField === "TrDate" ||
      cellInfo.column.dataField === "Date" ||
      isColumnDateType(currentColumn.RpcDataType_rpdID)) &&
    isValidDateString(cellInfo.value)
  ) {
    return formatDate(cellInfo.value);
  }

  return cellInfo.value;
};
