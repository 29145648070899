import { useState, useCallback } from "react";
import useApi from "./useApi";

interface MutationOptions<TData, TVariables> {
  url: string;
  method?: "POST" | "PUT" | "DELETE" | "PATCH";
  headers?: Record<string, string>;
  onSuccess?: (data: TData) => void;
  onError?: (error: string) => void;
}

interface MutationResult<TData, TVariables> {
  mutate: (variables: TVariables) => Promise<void>;
  isLoading: boolean;
  isError: boolean;
  error: string | null;
  data: TData | null;
  reset: () => void;
}

function useMutation<TData = unknown, TVariables = unknown>(
  options: MutationOptions<TData, TVariables>,
): MutationResult<TData, TVariables> {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<TData | null>(null);

  const { apiCall } = useApi();

  const mutate = useCallback(
    async (variables: TVariables) => {
      setIsLoading(true);
      setIsError(false);
      setError(null);

      try {
        const result = await apiCall({
          url: options.url,
          method: options.method || "POST",
          headers: options.headers,
          // @ts-ignore
          body: variables,
          // @ts-ignore
          onSuccess: (responseData: TData) => {
            setData(responseData);
            setIsLoading(false);
            if (options.onSuccess) {
              options.onSuccess(responseData);
            }
          },
          // @ts-ignore
          onError: (errorMessage: string, responseData) => {
            setIsError(true);
            setError(errorMessage);
            setIsLoading(false);
            if (options.onError) {
              options.onError(errorMessage);
            }
          },
        });
      } catch (err) {
        const errorMessage = err.message || "An unexpected error occurred";
        setIsError(true);
        setError(errorMessage);
        setIsLoading(false);
        if (options.onError) {
          options.onError(errorMessage);
        }
      }
    },
    [apiCall, options],
  );

  const reset = useCallback(() => {
    setIsLoading(false);
    setIsError(false);
    setError(null);
    setData(null);
  }, []);

  return { mutate, isLoading, isError, error, data, reset };
}

export default useMutation;
