import React from "react";
import { Dialog, DialogContent, Button, Typography, Box } from "@mui/material";
import { ReactComponent as CheckCircleIcon } from "../../assets/journal-saved-tick.svg";
import { enqueueSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const JournalSavedPopup = ({
  open,
  onClose,
  journalId,
  isGeneralJournal,
  reloadPage,
  jnlDate,
  feedId,
  formState,
}) => {
  const navigate = useNavigate();
  const { dbId, journalId: jnlIdFromUrl } = useParams();

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <DialogContent
        sx={{
          display: "flex",
          width: "573px",
          padding: "40px",
          flexDirection: "column",
          alignItems: "center",
          gap: "24px",
        }}
      >
        <CheckCircleIcon
          style={{
            width: "85.5px",
            height: "85.5px",
          }}
        />
        <Typography
          sx={{
            textAlign: "center",
            fontSize: "24px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "150%",
          }}
        >
          Journal was saved
        </Typography>
        <Typography
          sx={{
            mt: -1,
            textAlign: "center",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "150%",
            letterSpacing: "-0.176px",
          }}
        >
          Journal ID: {journalId}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "16px",
            marginBottom: "24px",
          }}
        >
          <Button
            variant="contained"
            sx={{
              padding: "8px 16px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "8px",
              borderRadius: "8px",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 500,
            }}
            onClick={() => {
              if (jnlIdFromUrl) {
                navigate(
                  isGeneralJournal
                    ? `/${dbId}/en-au/books/general-journal?date=${jnlDate}`
                    : `/${dbId}/en-au/books/bank-journal?date=${jnlDate}`,
                );
                onClose();
                reloadPage();
              } else {
                reloadPage();
                onClose();
              }
            }}
          >
            Create another
          </Button>
          <Button
            variant="contained"
            sx={{
              padding: "8px 16px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "8px",
              borderRadius: "8px",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 500,
            }}
            onClick={() => {
              if (jnlIdFromUrl) {
                onClose();
                reloadPage();
                return;
              }
              navigate(
                isGeneralJournal
                  ? `/${dbId}/en-au/books/general-journal/${journalId}`
                  : `/${dbId}/en-au/books/bank-journal/${journalId}`,
              );
              onClose();
            }}
          >
            Continue editing
          </Button>
          {/* {!isGeneralJournal && ( */}
          {false && (
            <Button
              variant="contained"
              sx={{
                padding: "8px 16px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "8px",
                borderRadius: "8px",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 500,
              }}
              onClick={() =>
                enqueueSnackbar("Feature not yet implemented", {
                  variant: "warning",
                })
              }
            >
              Create rule
            </Button>
          )}
        </Box>
        <Typography
          sx={{
            textAlign: "center",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "150%",
            letterSpacing: "-0.176px",
            cursor: "pointer",
            mt: -3,
            display: "flex",
            alignItems: "center",
          }}
          color="primary"
          onClick={() => {
            if (feedId) {
              navigate(
                `/${dbId}/en-au/books/process-feeds?accNo=${formState.jnlAccount_accNo}`,
              );
            } else {
              navigate(`/${dbId}/en-au/mobile/dashboard`);
            }
          }}
        >
          <ArrowBackIcon sx={{ mr: 1 }} />
          {feedId ? "Go to Feeds" : "Go to dashboard"}
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default JournalSavedPopup;
