import React from "react";
import { useSnackbar } from "notistack";
import useApi from "../../../hooks/useApi";
import envConfig from "../../../config";

interface PaymentHeader {
  pmtContact_cntID: number;
  pmtDate: string;
  pmtAmount: number;
  pmtMethod_pmmID: number;
  pmtAccount_accNo: number;
  pmtNotes: string;
  pmtReconciled_rcnID: number;
  pmtBankFeed_bftID: string;
}

interface PaymentDetail {
  documentId: number;
  documentPayableAmt: number;
}

interface SaveNewPaymentPayload {
  PaymentHeader: PaymentHeader;
  ListOfPaymentDetails: PaymentDetail[];
}

interface UseSaveNewPaymentProps {
  onSuccess?: (response: any) => void;
  onError?: (error: string) => void;
}

export const useSaveNewPayment = ({
  onSuccess,
  onError,
}: UseSaveNewPaymentProps) => {
  const { apiCall } = useApi();
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = React.useState(false);
  const [isError, setIsError] = React.useState(false);

  const saveNewPayment = React.useCallback(
    async (payload: SaveNewPaymentPayload) => {
      setIsLoading(true);
      setIsError(false);

      try {
        await apiCall({
          url: `https://${envConfig.apiDev1}/api/v3/en-au/invoices-payments/insert?BaseHostURL=${envConfig.mainServiceUrl}`,
          method: "POST",
          // @ts-ignore
          body: payload,
          // @ts-ignore
          onSuccess: (response: any) => {
            if (onSuccess) onSuccess(response);
          },
          // @ts-ignore
          onError: (error: string) => {
            setIsError(true);
            enqueueSnackbar(error, { variant: "error" });
            if (onError) onError(error);
          },
        });
      } catch (error) {
        setIsError(true);
        const errorMessage = (error as Error).message;
        enqueueSnackbar(errorMessage, { variant: "error" });
        if (onError) onError(errorMessage);
      } finally {
        setIsLoading(false);
      }
    },
    [apiCall, enqueueSnackbar, onSuccess, onError],
  );

  return {
    isLoading,
    isError,
    saveNewPayment,
  };
};

export const useSaveNewPayment2 = ({
  onSuccess,
  onError,
}: UseSaveNewPaymentProps) => {
  const { apiCall } = useApi();
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = React.useState(false);
  const [isError, setIsError] = React.useState(false);

  const saveNewPayment = React.useCallback(
    async (payload: SaveNewPaymentPayload) => {
      setIsLoading(true);
      setIsError(false);

      try {
        await apiCall({
          url: `https://${envConfig.apiDev1}/api/v3/en-au/invoices-payments/insert?BaseHostURL=${envConfig.mainServiceUrl}`,
          method: "POST",
          // @ts-ignore
          body: payload,
          // @ts-ignore
          onSuccess: (response: any) => {
            if (onSuccess) onSuccess(response);
          },
          // @ts-ignore
          onError: (error: string) => {
            setIsError(true);
            enqueueSnackbar(error, { variant: "error" });
            if (onError) onError(error);
          },
        });
      } catch (error) {
        setIsError(true);
        const errorMessage = (error as Error).message;
        enqueueSnackbar(errorMessage, { variant: "error" });
        if (onError) onError(errorMessage);
      } finally {
        setIsLoading(false);
      }
    },
    [apiCall, enqueueSnackbar, onSuccess, onError],
  );

  return {
    isLoading,
    isError,
    saveNewPayment,
  };
};
