import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useStripeAccountCreationConfirmation } from "../api/stripe-account-creation-confirmation";
import { useNavigateInternal } from "../../../hooks/use-navigate-internal";
import BackdropLoading from "../../../components/BackdropLoading/BackdropLoading";
import { useSnackbar } from "notistack";

const StripeSuccessPage = () => {
  const [searchParams] = useSearchParams();
  const { navigateTo } = useNavigateInternal();
  const { enqueueSnackbar } = useSnackbar();
  const accountId = searchParams.get("accountId");

  const { 
    isLoading: isLoadingConfirmAccountCreation, 
    confirmAccountCreation,
    isError: isErrorConfirmAccountCreation,
    data
  } = useStripeAccountCreationConfirmation({
    accountId: accountId || "",
  });

  React.useEffect(() => {
    confirmAccountCreation();
  }, [accountId, confirmAccountCreation]);

  const handleStripeSetup = () => {
    enqueueSnackbar("Not implemented yet", { variant: "info" });
  };

  console.log("Success Page search params: ", searchParams);

  return (
    <>
      <BackdropLoading open={isLoadingConfirmAccountCreation} />    
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "60vh",
          gap: 2,
        }}
      >
        {data?.SuccessYN === "Y" && (
          <>
            <Typography variant="h4" component="h1">
              Congratulations
            </Typography>
            <Typography variant="h6" component="h2">
              Stripe connected successfully
            </Typography>
            <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
              <Button 
                variant="contained" 
                color="primary"
                onClick={() => navigateTo("/business-details?tab=stripe")}
              >
                Go to Business Details
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleStripeSetup}
              >
                Go to Stripe Setup
              </Button>
            </Box>
          </>
        )}
        {isErrorConfirmAccountCreation && (
          <Typography variant="h6" component="h2">
            Something went wrong: {data?.ErrorMessage}
          </Typography>
        )}
      </Box>
    </>
  );
};

export default StripeSuccessPage;
