import { PayEventSummaryColumn, PayEventSummaryData } from "../types";
import { formatDate, isValidDateString } from "../../../utils/format-date";
import { formatFinancialNumber } from "../../../utils/format-number";
import { DataGridTypes } from "devextreme-react/cjs/data-grid";

export const isMoneyColumn = (dataTypeId: number): boolean => {
  return dataTypeId === 2; // Assuming 2 represents money columns
};

export const isDateColumn = (dataTypeId: number): boolean => {
  return dataTypeId === 3; // Assuming 3 represents date columns
};

export const formatGridCellValue = (
  cellInfo: DataGridTypes.ColumnCellTemplateData,
  column: PayEventSummaryColumn,
): string => {
  if (isMoneyColumn(column.RpcDataType_rpdID)) {
    // @ts-ignore
    return formatFinancialNumber(cellInfo.value, {
      hideZero: column.HideZeroOnAmountColumnYN === "Y",
      rounding: column.RpcRounding,
      defaultValue: "-",
    });
  }

  if (
    isDateColumn(column.RpcDataType_rpdID) &&
    isValidDateString(cellInfo.value)
  ) {
    return formatDate(cellInfo.value);
  }

  return cellInfo.value?.toString() ?? "";
};

export const getColumnAlignment = (
  alignValue: number,
): "left" | "right" | "center" => {
  switch (alignValue) {
    case 1:
      return "left";
    case 2:
      return "center";
    case 3:
      return "right";
    default:
      return "left";
  }
};

export const convertYNtoBool = (value?: string): boolean => {
  return value?.toUpperCase() === "Y";
};
