import { TextField } from "@mui/material";

export const FormTextInput = ({
  label,
  value = "",
  valueKey,
  loading,

  isFieldRequired = false,
  isFieldValid = true,
  errorMessage = "",
  maxWidth,
  multiline = false,

  onChange = () => {},
  formTextStyles = {},
  rows = 1,
  disabled = false,
}) => {
  return (
    <TextField
      size="small"
      // label={`${label} ${isFieldRequired ? '*' : ''}`}
      label={
        <span>
          {label}
          {isFieldRequired ? <span style={{ color: "red" }}> *</span> : null}
        </span>
      }
      disabled={loading || disabled}
      variant="outlined"
      error={!isFieldValid}
      helperText={errorMessage}
      value={value}
      multiline={multiline}
      rows={rows}
      // onBlur={(e) => onChange(valueKey, e.target.value)}
      onChange={(e) => onChange(valueKey, e.target.value)}
      fullWidth
      sx={{
        maxWidth: maxWidth,
        padding: 0,
        ".MuiOutlinedInput-root": {
          borderRadius: "8px",
        },
        ".MuiInputLabel-outlined": {
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        ...formTextStyles,
      }}
      padding={0}
    />
  );
};
