import React from "react";
import useApi from "./useApi";

interface QueryOptions<T> {
  method?: "GET" | "POST" | "PUT" | "DELETE" | "PATCH";
  headers?: Record<string, string>;
  body?: any;
  onSuccess?: (data: T) => void;
  onError?: (error: string) => void;
}

interface QueryResult<T> {
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  error: string | null;
  data: T | null;
  refetch: () => void;
  cancel: () => void;
}

export const useQuery = <T = any>(
  url: string,
  options: QueryOptions<T> = {},
  initialData: T | null = null,
): QueryResult<T> => {
  const [isLoading, setIsLoading] = React.useState<boolean>(
    initialData === null,
  );
  const [data, setData] = React.useState<T | null>(initialData);
  const [isError, setIsError] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string | null>(null);
  const { apiCall } = useApi();
  const abortControllerRef = React.useRef<AbortController | null>(null);

  const fetchData = React.useCallback(async () => {
    setIsLoading(true);
    setIsError(false);
    setError(null);

    abortControllerRef.current = new AbortController();

    try {
      await apiCall({
        url,
        method: options.method || "GET",
        headers: options.headers,
        body: options.body,
        // @ts-ignore
        signal: abortControllerRef.current.signal,
        // @ts-ignore
        onSuccess: (responseData: T) => {
          setData(responseData);
          setIsLoading(false);
          if (options.onSuccess) {
            options.onSuccess(responseData);
          }
        },
        // @ts-ignore
        onError: (errorMessage: string) => {
          setIsError(true);
          setError(errorMessage);
          setIsLoading(false);
          if (options.onError) {
            options.onError(errorMessage);
          }
        },
      });
    } catch (err) {
      if (err.name === "AbortError") {
        setIsError(true);
        setError("Request was cancelled");
        setIsLoading(false);
        return;
      }
      const errorMessage = err.message || "An unexpected error occurred";
      setIsError(true);
      setError(errorMessage);
      setIsLoading(false);
      if (options.onError) {
        options.onError(errorMessage);
      }
    }
  }, [url, apiCall]);

  React.useEffect(() => {
    fetchData();

    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, []);

  const refetch = React.useCallback(() => {
    fetchData();
  }, [fetchData]);

  const cancel = React.useCallback(() => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      setIsLoading(false);
    }
  }, []);

  const isSuccess = !isLoading && !isError && data !== null;
  return { isLoading, isError, error, data, refetch, cancel, isSuccess };
};
