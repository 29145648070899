import React, { useRef } from "react";
import {
  Box,
  Button,
  useMediaQuery,
  Autocomplete,
  TextField,
  ListItem,
  Typography,
  FormControl,
} from "@mui/material";
import { UploadFile } from "@mui/icons-material";
import { Account } from "../api/accounts-list";
import { useTheme } from "../../../../context/ThemeContext";
import { commonThemes } from "../../../../utils/themes/themes";

interface ImportTransactionsFromCSVPanelProps {
  accounts: Account[];
  selectedAccount: Account | undefined;
  onAccountChange: (account: Account | undefined) => void;
  onFileUpload: (file: File | null) => void;
  onImport: () => void;
  onShowRecords: () => void;
}

export const ImportTransactionsFromCSVPanel = ({
  accounts,
  selectedAccount,
  onAccountChange,
  onFileUpload,
  onImport,
  onShowRecords,
}: ImportTransactionsFromCSVPanelProps) => {
  const { isDarkMode } = useTheme();
  const isMobile = useMediaQuery("(max-width: 580px)");
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [selectedFileName, setSelectedFileName] = React.useState<string>("");

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    setSelectedFileName(file?.name || "");
    onFileUpload(file);
  };

  const handleUploadClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <Box
      display={"flex"}
      flexDirection={"row"}
      marginBottom={1}
      padding={3}
      boxShadow="0px 0px 10px 0px rgba(69, 90, 100, 0.10)"
      borderRadius={1}
      marginY={2}
    >
      <Box
        flex={1}
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          gap: 4,
        }}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          gap={2}
          sx={{ width: "100%", maxWidth: "260px" }}
        >
          <Typography sx={{ fontSize: 16 }}>Select File</Typography>
          <Box>
            <input
              ref={fileInputRef}
              type="file"
              accept=".csv"
              onChange={handleFileChange}
              style={{ display: "none" }}
              id="csv-upload"
            />
            <Button
              variant="outlined"
              onClick={handleUploadClick}
              startIcon={<UploadFile />}
              fullWidth
              sx={{ marginBottom: 1 }}
            >
              Choose CSV File
            </Button>
            {selectedFileName && (
              <Typography variant="body2" sx={{ mb: 1, wordBreak: "break-all" }}>
                {selectedFileName}
              </Typography>
            )}
          </Box>
          <Box display="flex" flexDirection="row" gap={2}>
            <Button
              onClick={onShowRecords}
              variant="outlined"
              sx={{ flex: 2 }}
            >
              Show Records
            </Button>
            <Button
              disabled
              onClick={onImport}
              variant="outlined"
              sx={{ flex: 1 }}
            >
              Back
            </Button>
          </Box>
        </Box>

        <Box
          display={"flex"}
          flexDirection={"column"}
          gap={2}
          sx={{ width: "100%", maxWidth: "260px" }}
        >
          <Typography sx={{ fontSize: 16 }}>Select Target Account</Typography>
          <FormControl>
            <Autocomplete
              options={accounts}
              value={selectedAccount}
              getOptionLabel={(option) => `${option.accNo} - ${option.accName}`}
              onChange={(event, newValue) => onAccountChange(newValue)}
              renderOption={(props, option, { selected }) => (
                <ListItem {...props}>
                  <Box>
                    <Box fontWeight={"bold"}>{option.accNo}</Box>
                    <div>{option.accName}</div>
                  </Box>
                </ListItem>
              )}
              disableClearable
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Account"
                  sx={{
                    label: {
                      fontSize: 16,
                      // @ts-ignore
                      ...commonThemes.inputLabel,
                      color: isDarkMode ? "#FFF" : "rgba(0, 0, 0, 0.6)",
                    },
                  }}
                />
              )}
            />
          </FormControl>
        </Box>
      </Box>
    </Box>
  );
};